import { faEraser, faFilter, faStream, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { filtersInterviewers, filtersPhases, handleEvent } from "../../redux/AgendaSlice";
import { Loading, Toast, ToastTypes } from "../global/Utils";

export const Filters = () => {
    const dispatch = useDispatch();
    const access_token = useSelector(state => state.main.access_token);
    const selPhases = useSelector(state => state.agenda.filter.phases);
    const selInterviewers = useSelector(state => state.agenda.filter.interviewers);

    const [state, setState] = useState({ isOpen: false, template: "", phases: [], interviewers: [], source: [], select: [] });

    useEffect(async () => {

        const requestOptions = { method: "GET", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" } };
        dispatch(Loading(true));

        try {
            const [resPhases, resInterviewers] = await Promise.all(
                [
                    fetch("/api/agenda/phases", requestOptions),
                    fetch("/api/agenda/interviewers", requestOptions)
                ]);

            if (!resPhases.ok || !resInterviewers.ok) throw "";

            const [jsonPhases, jsonInterviewers] = await Promise.all([resPhases.json(), resInterviewers.json()]);

            setState(prev => ({ ...prev, phases: jsonPhases, interviewers: jsonInterviewers }));
        } catch {
            dispatch(Toast("Não foi possível obter os filtros.", ToastTypes.Danger, false))
        } finally {
            dispatch(Loading(false));
        }
    }, [])

    const handleToggle = () => {
        setState(prev => ({ ...prev, isOpen: !prev.isOpen }));
    }

    const handleUsers = () => {
        setState(prev => ({ ...prev, isOpen: true, template: "i", source: prev.interviewers, select: selInterviewers }));
    }

    const handlePhases = () => {
        setState(prev => ({ ...prev, isOpen: true, template: "p", source: prev.phases, select: selPhases }));
    }

    const handleChange = (e) => {
        setState(prev => ({ ...prev, select: Array.from(e.selectedOptions, (option) => option.value) }));
    }

    const handleFilter = () => {
        setState(prev => ({ ...prev, isOpen: false }));
        dispatch(handleEvent({ isOpen: false, needRefresh: true }));

        if (state.template == "i")
            return dispatch(filtersInterviewers(state.select));

        return dispatch(filtersPhases(state.select));
    }

    return (
        <>
            <Button color="primary" outline={selInterviewers.length == 0 ? true : false} size="sm" style={{ marginRight: `10px` }} title="Responsáveis pelo Processo" onClick={handleUsers}><FontAwesomeIcon icon={faUsers} /></Button>
            <Button color="primary" outline={selPhases.length == 0 ? true : false}  size="sm" style={{ marginRight: `20px` }} title="Fases" onClick={handlePhases}><FontAwesomeIcon icon={faStream} /></Button>

            <Modal isOpen={state.isOpen} toggle={handleToggle}>
                <ModalHeader toggle={handleToggle}>Filtros</ModalHeader>
                <ModalBody>
                    <Label>{state.template == "i" ? "Responsáveis pelo Processo" : "Fases"} <span onClick={_ => setState(prev => ({ ...prev, select: [] }))}
                        className="filter-remove-selection" title="Remover seleção">
                        <FontAwesomeIcon icon={faEraser} /></span></Label>
                    <Input id="list" type="select" multiple value={state.select} onChange={(e) => handleChange(e.target)}>
                        {state.source.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                    </Input>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleFilter()}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}