import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Container, ModalFooter, Label, Button } from 'reactstrap';
import { Loading, Toast, ToastTypes, formatHour, formatDate, IsValidNIF, formatDateHours, formatDisplayHour } from "../../global/Utils";
import { connect, useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { faPencil, faFileCertificate, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttendanceDeclaration from "../modals/AttendanceDeclaration";
import Templates from "../modals/Templates";
import { individualRecordError, hiredRedux } from "../../../redux/MainSlice";
import { costCenter } from "../../../redux/CandidateSlice";

const General = (props) => {

    const [ObjData, setObjData] = useState({});
    const [Stores, setStores] = useState([]);
    const [States, setStates] = useState([]);
    const [Schedules, setSchedules] = useState([]);
    const [modal, setModal] = useState({ isOpen: false, labelName: '' });
    const [modalAttendance, setModalAttendance] = useState({ isOpen: false });
    const [modalTemplates, setModalTemplates] = useState({ isOpen: false });
    const [template, setTemplate] = useState(-1);
    const [hired, setHired] = useState(false);
    const [profileId] = useState(props.profileId);
    const [modalType, setModalType] = useState(-1);
    const [idCandidate] = useState(props.idCandidate);
    const [accessToken] = useState(props.access_token);
    const dispatch = useDispatch()
    const [fiscalNumber, setFiscalNumber] = useState(0);

    const localCostCenter = useSelector(state => state.candidate.costCenter);
    const localCostCenters = useSelector(state => state.candidate.lstCostCenter);

    useEffect(() => {
        dispatch(individualRecordError(undefined));
        LoadData();
    }, []);

    function LoadData() {
        props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        fetch("/api/candidates/candidatedetailgeneral?idCandidate=" + props.idCandidate, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                setObjData(json);
                setModal({ ...modal, store: json.costCenter });
                dispatch(costCenter(json.costCenter));
                setHired(json.IdState === 2)

                props.dispatch(Loading(false));
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os registos do tab Geral", ToastTypes.Danger, false)))
    }

    function LoadComboData() {

        var arrStores = []

        if (States.length === 0 || Stores.length === 0) {
            props.dispatch(Loading(true))

            const requestOptions = {
                method: "GET",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
            }

            fetch("/api/candidates/states", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    var objState = json.map(result => {
                        return { Key: result.idState, value: result.description, selected: result.idState === modal.state ? true : false }
                    });

                    //objState = objState.filter(a => a.Key !== 8 && a.Key !== 9);

                    setStates(objState)
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os estados", ToastTypes.Danger, false)))

            if (Stores.length === 0) {
                localCostCenters.forEach(item => arrStores.push({ Key: item.key, value: item.value, selected: false }));
                setStores(arrStores);
            }
        }
    }

    function toggle() {
        setModal({ ...modal, isOpen: !modal.isOpen })
    }

    function toggleAttendance() {
        setModalAttendance({ ...modalAttendance, isOpen: !modalAttendance.isOpen })
    }

    function toggleTemplates() {
        setModalTemplates({ ...modalTemplates, isOpen: !modalTemplates.isOpen })
    }

    function OpenModalByType(modalType, labelName) {
        LoadComboData()
        setModal({ ...modal, isOpen: !modal.isOpen, labelName: labelName })
        setHired(false)
        setModalType(modalType)

        if (modalType === 3) {

            const store = localCostCenters.find(a => a.key == localCostCenter);
            if (store == undefined)
                return;

            loadAvailableSchedules(localCostCenter);

        }

    }

    function loadAvailableSchedules(store) {
        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        if (ObjData.idWorkflow === undefined)
            return

        fetch("/api/candidates/availableschedules?storeNumber=" + store + "&idWorkflow=" + ObjData.idWorkflow, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                setSchedules(json)
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os horários", ToastTypes.Danger, false)))

    }

    function handleChangeCombo(e) {
        var index = e.nativeEvent.target.selectedIndex;
        var SelectedText = e.nativeEvent.target[index].text
        var hasError = false
        var dataValue = e.target.value

        setModal({ ...modal, isOpen: !modal.isOpen })
        if (e.target.id === 'lstStore') {

            if (parseInt(e.target.value) === -1)
                return props.dispatch(Toast("Por favor selecione um restaurante. A Informação não será gravada!", ToastTypes.Warning, true))

            save(2, { idState: null, stateName: null, idEmployeeRequestSchedule: null, scheduleCode: null, costCenter: parseInt(dataValue) });

            setObjData(state => ({ ...state, costCenter: parseInt(dataValue), idEmployeeRequestSchedule: -1, scheduleCode: '' }));
            dispatch(costCenter(parseInt(dataValue)));
        }
        else if (e.target.id === 'lstState') {

            if (parseInt(e.target.value) === -1) {
                props.dispatch(Toast("Por favor selecione um estado. A Informação não será gravada!", ToastTypes.Warning, true))
                setObjData(state => ({ ...state, idState: -1, stateName: '' }))
                return
            }
            else {
                if (parseInt(e.target.value) === 2) {
                    setModal({ ...modal, isOpen: true })
                    setHired(true)
                }
                else {
                    if (ObjData.idState !== null & ObjData.idState === 2 && dataValue !== 2)
                        dispatch(hiredRedux(false));

                    dispatch(costCenter(null));
                    save(1, { idState: parseInt(dataValue), stateName: SelectedText, idEmployeeRequestSchedule: null, scheduleCode: null, costCenter: null, costCenterName: null });
                }
            }
        }
    }

    function handleChangeScheduleSelection(e) {
        let idEmployeeRequestSchedule = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);
        var arrObj = [];

        Schedules.forEach(a => {
            let selected = false;
            if (parseInt(a.idEmployeeRequestSchedule) === parseInt(idEmployeeRequestSchedule))
                selected = e.target.checked;

            const z = { ...a };

            z.selected = selected;
            arrObj.push(z);
        });

        let scheduleCode = '';

        if (arrObj.find(a => a.selected) !== undefined)
            scheduleCode = arrObj.find(a => a.selected).scheduleCode;
        else {
            idEmployeeRequestSchedule = -1;
        }
            
        setSchedules(arrObj);

        save(2, {
            idState: null, stateName: null, idEmployeeRequestSchedule: parseInt(idEmployeeRequestSchedule), scheduleCode: scheduleCode,
            costCenter: parseInt(ObjData.costCenter)
        });

        dispatch(costCenter(parseInt(ObjData.costCenter)));

        setModal({ ...modal, isOpen: !modal.isOpen });
    }

    function save(Type, obj) {
        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + accessToken, "Content-Type": "application/json" },
            body: JSON.stringify({
                Type: Type,
                IdState: obj.idState,
                CostCenter: obj.costCenter,
                IdEmployeeRequestSchedule: obj.idEmployeeRequestSchedule,
                IdCandidate: idCandidate
            })
        }

        fetch("/api/candidates/general", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (Type === 1)
                    setObjData(state => ({ ...state, idState: obj.idState, stateName: obj.stateName, idWorkflow: parseInt(json) }))
                else if (Type === 2)
                    setObjData(state => ({ ...state, idEmployeeRequestSchedule: obj.idEmployeeRequestSchedule, scheduleCode: obj.scheduleCode, costCenter: obj.costCenter }))

                props.dispatch(Toast("Informação gravada com sucesso!", ToastTypes.Success, false))

            })
            .catch(() => props.dispatch(Toast("Não foi possível gravar", ToastTypes.Danger, false)))
    }

    function handlerCheckFieldsAttendance(data) {
        if (data === true)
            setModalAttendance({ ...modalAttendance, isOpen: !modalAttendance.isOpen })
    }

    function handleChangeFiscalNumber(e) {
        var value = e.target.value
        setFiscalNumber(value)
    }

    function handleConfirm() {

        if (fiscalNumber === null || fiscalNumber === '')
            return props.dispatch(Toast("Por favor, insira o NIF do candidato!", ToastTypes.Warning, true));

        if (!IsValidNIF(fiscalNumber))
            return props.dispatch(Toast("NIF inválido.", ToastTypes.Danger, false));

        // check if it's candidate NIF
        if (ObjData.fiscalNumber != null && ObjData.fiscalNumber !== fiscalNumber)
            return props.dispatch(Toast("O NIF inserido não é igual ao NIF do candidato.", ToastTypes.Danger, false));

        //Check Fields
        const requestOptionsCheck = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/checkindividualrecord?idCandidate=" + idCandidate, requestOptionsCheck)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json !== null) {
                    let hasError = false;
                    for (var propt in json.personalData) {
                        if (json.personalData[propt] === true) {
                            hasError = true
                        }
                    }

                    for (var propt in json.workflow) {
                        if (json.workflow[propt] === true) {
                            hasError = true
                        }
                    }

                    if (hasError) {
                        //use redux
                        dispatch(individualRecordError(json));
                        props.dispatch(Toast("Para finalizar o processo todos os campos da ficha individual têm de estar preenchidos", ToastTypes.Danger, false))
                        return
                    }
                    else {
                        dispatch(hiredRedux(true));
                        dispatch(costCenter(null));

                        save(1, { idState: 2, stateName: "Contratado", idEmployeeRequestSchedule: null, scheduleCode: null, costCenter: null, costCenterName: null });
                        toggle();
                    }
                }
                else {
                    props.dispatch(Toast("Ocorreu um erro a validar a ficha individual", ToastTypes.Danger, false))
                }

            })
            .catch(() => props.dispatch(Toast("Ocorreu um erro a validar a ficha individual2", ToastTypes.Danger, false)))
    }

    useEffect(() => {

        const store = localCostCenters.find(a => a.key == localCostCenter);
        if (store == undefined)
            return;

        setObjData(state => ({ ...state, costCenterName: store.value }));

        loadAvailableSchedules(localCostCenter);
    }, [localCostCenter]);

    return (
        
        <>
            <div className="row">
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Nome</b></label>
                    <div id="lblName">{ObjData.name}</div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Email</b></label>
                    <div id="lblEmail">{ObjData.email}</div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Contato</b></label>
                    <div id="lblContact">{ObjData.phoneNumber}</div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Fase</b></label>
                    <div id="lblPhaseName">{ObjData.phaseName}</div>
                </div>

            </div>

            <div className="row" style={{ paddingTop: 20 + 'px' }}>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Estado</b></label>
                    <div id="lblState">{ObjData.stateName}
                        {profileId === 1 || profileId === 101 ?
                            <FontAwesomeIcon title="Editar" onClick={() => OpenModalByType(2, 'Estado')} style={{ marginLeft: 5 + 'px', verticalAlign: "baseline", cursor: "pointer", color: "#4fa570" }} icon={faPencil} />
                            :<></>
                            }
                    </div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Data SO</b></label>
                    <div id="lblSoDate">{ObjData.soDate !== undefined && ObjData.soDate !== null ? formatDateHours(ObjData.soDate.toString()) : ''}</div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Data FIMA</b></label>
                    <div id="lblFimaDate">{ObjData.fimaDate !== undefined && ObjData.fimaDate !== null ? formatDateHours(ObjData.fimaDate.toString()) : ''}</div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Data Admissão</b></label>
                    <div id="lblAdmissionDate">{ObjData.admissionDate !== null && ObjData.admissionDate !== undefined ? formatDate(ObjData.admissionDate) : ''}</div>
                </div>

            </div>

            <div className="row" style={{ paddingTop: 20 + 'px' }}>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Centro de Custo</b></label>
                    <div id="lblStoreName">{ObjData.costCenterName}
                        {profileId === 1 || profileId === 101 ?
                            <FontAwesomeIcon onClick={() => OpenModalByType(1, 'Restaurante')} title="Editar"
                                style={{ marginLeft: 5 + 'px', verticalAlign: "baseline", cursor: "pointer", color: "#4fa570", display: ObjData.idState === 2 ? "none" : "inline-block" }} icon={faPencil} />
                            :<></>}
                    </div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Horário</b></label>
                    <div id="lblSchedule">{ObjData.scheduleCode}
                        {profileId === 1 || profileId === 101 ?
                            <FontAwesomeIcon onClick={() => OpenModalByType(3, 'Horário')} title="Editar"
                                style={{ marginLeft: 5 + 'px', verticalAlign: "baseline", cursor: "pointer", color: "#4fa570", display: ObjData.idState === 2 ? "none" : "inline-block" }} icon={faPencil} />
                            : <></>}
                            </div>
                </div>

                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Aguarda Info. Candidato</b></label>
                    <div id="lblInfoCandidate">{ObjData.waitCandidate}</div>
                </div>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Mercado Geográfico</b></label>
                    <div id="lblInfoRegionMarket">{ObjData.regionMarket}</div>
                </div>
            </div>

            <div className="row" style={{ paddingTop: 20 + 'px' }}>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Restaurantes Favoritos</b></label>
                    <div id="lblInfoFavouritStores">{ObjData.favouritStores}</div>
                </div>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Horário tipo</b></label>
                    <div id="lblInfoFavouritStores">{ObjData.scheduleType}</div>
                </div>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>1º Emprego</b></label>
                    <div id="lblFirstJob">{ObjData.firstJob}</div>
                </div>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Já trabalhaste em restauração</b></label>
                    <div id="lblWorkkedInRestaurant">{ObjData.workkedInRestaurant}</div>
                </div>
            </div>

            <div className="row" style={{ paddingTop: 20 + 'px' }}>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Já foste efetivo</b></label>
                    <div id="lblBeEfective">{ObjData.beEfective}</div>
                </div>
                <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <label><b>Duração Contrato</b></label>
                    <div id="lblContractLength">{ObjData.contractLength}</div>
                </div>
                <div className="col-lg-6" style={{ textAlign: "left" }}>
                    <label><b>Número McPessoal</b></label>
                    <div id="lblMcPessoalIdEmployee">{ObjData.mcPessoalIdEmployee != null ? ObjData.mcPessoalIdEmployee : '---'}</div>
                </div>
            </div>

            {profileId === 1 || profileId === 101 ?

                <div className="row" style={{ paddingTop: 20 + 'px' }}>
                    <div className="col-lg-3" style={{ textAlign: "left" }}>
                        <label htmlFor="btnAttendance"><b>Declaração de Presença</b></label>
                    </div>
                    <div className="col-lg-9" style={{ textAlign: "left" }}>
                        <label htmlFor="btnTemplates"><b>Templates</b></label>
                    </div>
                </div>

                : <></>}

            {profileId === 1 || profileId === 101 ?

                <div className="row">
                    <div className="col-lg-3" style={{ textAlign: "left" }}>
                        <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                            <FontAwesomeIcon id="btnAttendance"
                                style={{ marginLeft: 5 + 'px', verticalAlign: "baseline", color: "#1b6ec2", height: 47 + 'px', width: 51 + 'px', paddingTop: 10 + 'px', cursor: 'pointer' }}
                                onClick={() => toggleAttendance()} icon={faFileCertificate} />
                        </div>
                    </div>
                    <div className="col-lg-9" style={{ textAlign: "left" }}>
                        <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                            <FontAwesomeIcon id="btnTemplates"
                                style={{ marginLeft: 5 + 'px', verticalAlign: "baseline", color: "#1b6ec2", height: 47 + 'px', width: 51 + 'px', paddingTop: 10 + 'px', cursor: 'pointer' }}
                                onClick={() => toggleTemplates()} icon={faFileCertificate} />
                        </div>
                    </div>
                </div>
                :<></>
                }

            <Modal isOpen={modal.isOpen} toggle={toggle} size={modalType === 3 ? "xl" : "sm"}>
                <ModalHeader toggle={toggle}>{modal.labelName}</ModalHeader>
                <ModalBody>
                    <Container>
                        {
                            modalType === 1 ?
                                <Row>
                                    <Col>
                                        <Input type="select" id="lstStore" value={localCostCenter} onChange={(e) => handleChangeCombo(e)}>
                                            <option key={-1} value={-1}></option>
                                            {Stores.map(a => <option key={a.Key} value={a.Key}>{a.value}</option>)}
                                        </Input>
                                    </Col>
                                </Row>
                                : null
                        }

                        {
                            modalType === 2 ?
                                <Row>
                                    <Col>
                                        <Input type="select" id="lstState" value={ObjData.idState} style={{ display: hired ? "none" : "inline-block" }} onChange={(e) => handleChangeCombo(e)}>
                                            {States.map(a => <option key={a.Key} value={a.Key}>{a.value}</option>)}
                                        </Input>
                                        <div style={{ marginTop: 10 + 'px', display: hired ? "inline-block" : "none" }}>
                                            <Input id="inputState" type="text" className="form-control" style={{ width: 240 + 'px' }} autoComplete="off" readOnly value="Contratado" />
                                            <Label style={{ marginTop: 10 + 'px' }}><b>Nº Contribuinte</b></Label>
                                            <Input id="fiscalNumber" type="number" maxLength="9" className="form-control" style={{ width: 240 + 'px' }} autoComplete="off" onChange={(e) => handleChangeFiscalNumber(e)} />
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }

                        {
                            modalType === 3 ?
                                <Row>
                                    <Col>
                                        <table className="table table-borderless">
                                            <thead className="grid-header">
                                                <tr>
                                                    <th className="align-self-center"><span className="d-sm-block">Código</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Mês</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Segunda</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Terça</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Quarta</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Quinta</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Sexta</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Sábado</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Domingo</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Total Horas</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block">Venc. Carga Horária</span></th>
                                                    <th className="align-self-center"><span className="d-sm-block"></span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Schedules.map(result => {
                                                        return (
                                                            <tr key={"tr_" + result.idEmployeeRequestSchedule} className="grid-rows" style={{ minHeight: 35 + 'px', height: 35 + 'px', borderTopWidth: 1 + 'px', borderTopStyle: "solid", borderTopColor: "gainsboro" }}>
                                                                <td key={"tdScheduleCode_" + result.idEmployeeRequestSchedule} className="align-self-center">{result.scheduleCode}</td>
                                                                <td key={"tdScheduleCodeMonth_" + result.idEmployeeRequestSchedule} className="align-self-center">{result.strMonth}</td>
                                                                <td key={"tdMonday_" + result.idEmployeeRequestSchedule} title={result.mondayDetail} className="align-self-center">{result.mondayResumed}</td>
                                                                <td key={"tdTuesday_" + result.idEmployeeRequestSchedule} title={result.tuesdayDetail} className="align-self-center">{result.tuesdayResumed}</td>
                                                                <td key={"tdWednesday_" + result.idEmployeeRequestSchedule} title={result.wednesdayDetail} className="align-self-center">{result.wednesdayResumed}</td>
                                                                <td key={"tdThrusday_" + result.idEmployeeRequestSchedule} title={result.thrusdayDetail} className="align-self-center">{result.thrusdayResumed}</td>
                                                                <td key={"tdFriday_" + result.idEmployeeRequestSchedule} title={result.fridayDetail} className="align-self-center">{result.fridayResumed}</td>
                                                                <td key={"tdSaturday_" + result.idEmployeeRequestSchedule} title={result.saturdayDetail} className="align-self-center">{result.saturdayResumed}</td>
                                                                <td key={"tdSunday_" + result.idEmployeeRequestSchedule} title={result.sundayDetail} className="align-self-center">{result.sundayResumed}</td>
                                                                <td key={"tdTotalHours_" + result.idEmployeeRequestSchedule} className="align-self-center">{formatDisplayHour(result.totalHours)}</td>
                                                                <td key={"tdEstimatedSalary_" + result.idEmployeeRequestSchedule} className="align-self-center">{result.estimatedSalary + '€'}</td>
                                                                <td className="align-self-center">
                                                                    <Input key={"chkSchedule_" + result.idEmployeeRequestSchedule} checked={result.selected}
                                                                        onChange={(e) => handleChangeScheduleSelection(e)} type="switch" id={"chkSchedule_" + result.idEmployeeRequestSchedule} />
                                                                </td>
                                                            </tr>

                                                        )
                                                    })

                                                }


                                            </tbody>
                                        </table>

                                    </Col>
                                </Row>
                                : null
                        }

                    </Container>
                </ModalBody>
                <ModalFooter style={{ display: hired && modalType === 2 ? "inline-block" : "none" }}>
                    <Button color="primary" style={{ float: "right" }} onClick={() => handleConfirm()}><FontAwesomeIcon icon={faUserCheck} /> Confirmar</Button>
                </ModalFooter>
            </Modal>

            <AttendanceDeclaration isOpen={modalAttendance.isOpen} toggle={toggleAttendance} handlerCheckFieldsAttendance={handlerCheckFieldsAttendance} name={ObjData.name}
                email={ObjData.email} idCandidate={idCandidate} profileId={profileId} />

            <Templates isOpen={modalTemplates.isOpen} toggle={toggleTemplates} idCandidate={idCandidate} profileId={profileId} />

        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(General)