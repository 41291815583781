import React, { useState, useEffect } from "react"
import { Loading, Toast, ToastTypes } from "../../global/Utils"
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap'
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faDownload, faPlus, faTrash, faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import McdTable from "../../global/Table"

const Documents = (props) => {

    const [idCandidate] = useState(props.idCandidate);
    const [grid, setGrid] = useState({
        table: {
            title: "",
            columns: { title: [], width: [], align: [], search: [] },
            rows: [],
            pagination: true,
            actions: []
        }
    });
    const [modal, setModal] = useState({
        isOpen: false
    });

    const [ObjComboFileType, setObjComboFileType] = useState({ fileTypes: [] })

    const [fileType, setFileType] = useState(-1) 
        
    useEffect(() => {
        LoadComboFileTypes();
        BindGrid();
    }, []);


    function LoadComboFileTypes() {

        props.dispatch(Loading(true));

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/documentsfiletypes", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                setObjComboFileType({
                    fileTypes: json
                })

            })
            .catch(error => {
                console.log(error)
                props.dispatch(Toast("Não foi possível obter os tipos de ficheiro.", ToastTypes.Danger, false))
            })

    }

    const BindGrid = () => {
        props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        fetch("/api/candidates/documents?idCandidate=" + idCandidate, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                setGrid({
                    table: {
                        title: "Documentos",
                        columns: {
                            title: ["Tipo Ficheiro", "Nome Ficheiro", ""],
                            width: [50, 50, 10],
                            align: ["left", "left", "right"],
                            search: [true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idFile,
                                columns: [
                                    { column: row.fileTypeDescription },
                                    { column: row.fileName }
                                ],
                                actions: [
                                    { column: faDownload, title: "Download", action: (e) => downloadFile(e, row.idFile) },
                                    { column: faTrash, title: "Eliminar", action: (e) => deleteFile(e, row.idFile, row.fileName) }
                                ]
                            }
                        }),
                        actions: [
                            { action: (e) => handleNewFile(e), icon: faPlus, title: "Novo Documento" },
                            { action: (e) => handleRefresh(e), icon: faRotate, title: "Refresh" }
                        ],
                        filters: []
                    }
                })
                props.dispatch(Loading(false))
            })
            .catch((error) => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel obter os registos dos documentos.", ToastTypes.Danger, false))
            })
    }

    const toggle = () => {
        setModal({ ...modal, isOpen: !modal.isOpen })
        setFileType(-1)
    }

    function handleRefresh(e) {
        e.preventDefault();
        BindGrid();
    }

    function handleNewFile(e) {
        e.preventDefault();
        setModal({
            isOpen: true
        })
        setFileType(-1)
    }

    function handleChangeFile(e) {
        const file = e.target.files[0]

        if (file === undefined)
            return;

        if (file.type !== "application/pdf" && file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.type !== "image/png" && file.type !== "image/jpeg") {
            props.dispatch(Toast("Só é permitido selecionar PDF, DOCX, PNG e JPEG!", ToastTypes.Warning, false))
            return
        }
        if (file.size > 3145728) { // 3MB
            props.dispatch(Toast("Só é permitido documentos com tamanho até 3MB!", ToastTypes.Warning, false))
            return
        }

        var fileExtension = ""

        switch (file.type) {
            case 'application/msword':
                fileExtension = 'docx'
                break;

            case 'application/pdf':
                fileExtension = 'pdf'
                break;

            case 'image/png':
                fileExtension = 'png'
                break;

            case 'image/jpeg':
                fileExtension = 'jpg'
                break;

            default:
        }

        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = (e) => {

            var objFile = {
                base64File: fileReader.result.split(",")[1],
                fileName: file.name,
                idFile: -1,
                extention: fileExtension,
                idCandidate: idCandidate,
                idFileType: fileType
            }

            const requestOptions = {
                method: "PUT",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
                body: JSON.stringify(objFile)
            }

            props.dispatch(Loading(true))

            fetch("/api/candidates/documents", requestOptions)
                .then(response => {
                    if (response.status == 200) {
                        setModal({ ...modal, isOpen: !modal.isOpen })
                        setFileType(-1)
                        BindGrid();
                        props.dispatch(Toast("Ficheiro inserido com sucesso.", ToastTypes.Success, false));
                    }
                    else
                        props.dispatch(Toast("Não foi possível inserir o ficheiro.", ToastTypes.Danger, false))
                })
                .catch(error => {
                    props.dispatch(Toast("Não foi possível inserir o ficheiro.", ToastTypes.Danger, false))
                })

        }

        

    }

    const downloadFile = (e, idFile) => {
        e.preventDefault();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        props.dispatch(Loading(true))

        fetch("/api/admin/emailfile?idFile=" + idFile, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '') {
                    props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
                    return
                }
                window.open(JSON.parse(json)[0].filePath);
                props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
            })
    }

    const deleteFile = (e, idFile, fileName) => {
        e.preventDefault();

        if (!window.confirm("Tem a certeza que pretende eliminar o ficheiro com o nome " + fileName + " ?"))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        props.dispatch(Loading(true));

        fetch("/api/candidates/documents?idFile=" + idFile, requestOptions)
            .then(response => {
                if (response.status == 200) {
                    props.dispatch(Toast("Ficheiro eliminado com sucesso.", ToastTypes.Success, false));
                    BindGrid();
                    return
                }
                props.dispatch(Toast("Não foi possível eliminar o ficheiro.", ToastTypes.Danger, true));
            })
            .catch(_ => props.dispatch(Toast("Não foi possível eliminar o ficheiro.", ToastTypes.Danger, true)));

        props.dispatch(Loading(false))
    }

    function handleChangeFileType(e) {
        setFileType(e.target.value)
    }

    return (
        <>
            <McdTable title={grid.table.title} columns={grid.table.columns} rows={grid.table.rows} pagination={grid.table.pagination}
                actions={grid.table.actions} />

            <Modal isOpen={modal.isOpen} toggle={toggle} size="md">
                <ModalHeader toggle={toggle}>Ficheiro</ModalHeader>
                <ModalBody>
                    <Input type="select" id="idFileType" onChange={(e) => handleChangeFileType(e)}>
                        <option key={-1} value={-1}></option>
                        {ObjComboFileType.fileTypes.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                    <input id="newFile" style={{ marginTop: 10 + 'px'}} hidden={parseInt(fileType) !== -1 ? false : true} type="file" onChange={(e) => handleChangeFile(e)} />
                </ModalBody>
            </Modal>
        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Documents)