import React from 'react'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading, formatDate, formatDateHours, LoadLocalStorage } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faFilter, faStepForward, faEdit, faSitemap, faFileCertificate } from '@fortawesome/pro-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css"
import PhaseOptions from "./modals/PhaseOptions"
import StartForm from "./modals/StartForm"
import AttendanceDeclaration from "./modals/AttendanceDeclaration"

class Candidates extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                id: "",
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            lstState: [],
            lstCostCenters: [],
            lstNationalities: [],
            lstPhases: [],
            filterAdmissionDate: null,
            filterFimaDate: null,
            filterSoDate: null,
            filterName: '',
            filterEmail: '',
            filterPhone: '',
            filterFiscalNumber: '',
            profileId: props.profileId,
            query: "",
            modalPhase: { isOpen: false },
            checkFields: false,
            checkPhaseOptionsFields: 0,
            modal: {
                isOpen: false
            },
            modalAttendance: {
                isOpen: false, ObjData: {}
            },
            StartForm: true,
            idCandidate: -1,
            CandidateProcess: { PersonalData: null, Workflow: null },
            GridFilters: '',
            lstTypeCostCenter: [{ value: 0, text: 'Todos', selected: true }, { value: 1, text: 'Restaurante', selected: false }, { value: 2, text: 'Escritório', selected: false }],
            StateHired: 2
        }
        this.handleFilter = this.handleFilter.bind(this)
        this.handlerCheckFields = this.handlerCheckFields.bind(this)
        this.handlerPhaseOptionsCheckFields = this.handlerPhaseOptionsCheckFields.bind(this)
        this.handlerCheckFieldsAttendance = this.handlerCheckFieldsAttendance.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    togglePhase = () => {
        this.setState(prevState => { return { modalPhase: { ...prevState.modalPhase, isOpen: !prevState.modalPhase.isOpen } } })
    }

    toggleAttendance = () => {
        this.setState(prevState => { return { modalAttendance: { ...prevState.modalAttendance, isOpen: !prevState.modalAttendance.isOpen } } })
    }

    componentDidMount() {
        let objStates;
        let objCostCenters;
        let objNationalities;
        let objPhases;
        let states = '';
        let costcenters = '';
        let nationalities = '';
        let phases = '';

        if (this.state.profileId !== 1)
            this.setState({ lstTypeCostCenter: this.state.lstTypeCostCenter.filter(a => a.value < 2) })

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }
        fetch("/api/candidates/candidateFilters?ProfileId=" + this.state.profileId, requestOptions)
            .then((response) => { return response.json(); })
            .then(data => {

                objStates = data.states.map(result => {
                    if (LoadLocalStorage("candidates.lstState", "list", result.idState, true))
                        states += result.idState.toString() + ','

                    return { value: result.idState, text: result.description, selected: LoadLocalStorage("candidates.lstState", "list", result.idState, true), init: true }
                });

                objCostCenters = data.costCenters.map(result => {
                    if (LoadLocalStorage("candidates.lstCostCenters", "list", result.key, true))
                        costcenters += result.key.toString() + ','

                    return { value: result.key, text: result.value, selected: LoadLocalStorage("candidates.lstCostCenters", "list", result.key, true), init: true }
                });

                objNationalities = data.nationalities.filter(a => parseInt(a.key) != 0).map(result => {
                    if (LoadLocalStorage("candidates.lstNationalities", "list", result.key, true))
                        nationalities += result.key.toString() + ','

                    return { value: result.key, text: result.value, selected: LoadLocalStorage("candidates.lstNationalities", "list", result.key, true), init: true }
                });

                objPhases = data.phases.map(result => {
                    if (LoadLocalStorage("candidates.lstPhases", "list", result.idPhase, true))
                        phases += result.idPhase.toString() + ','

                    return { value: result.idPhase, text: result.phaseName, selected: LoadLocalStorage("candidates.lstPhases", "list", result.idPhase, true), init: true }
                });

                let fiscalNumber = LoadLocalStorage("candidates.txtFilterFiscalNumber", "text", "");
                let email = LoadLocalStorage("candidates.txtFilterEmail", "text", "");
                let admissionDate = LoadLocalStorage("candidates.txtAdmissionDate", "date", null);
                let fimaDate = LoadLocalStorage("candidates.txtFimaDate", "date", null);
                let SODate = LoadLocalStorage("candidates.txtSoDate", "date", null);

                this.setState({
                    lstState: objStates, lstCostCenters: objCostCenters, lstNationalities: objNationalities, lstPhases: objPhases,
                    GridFilters: states + ';' + costcenters + ';' + nationalities + ';' + phases + ';' + fiscalNumber + ';' + email + ';' + admissionDate + ';' + fimaDate + ';' + SODate,
                    filterFiscalNumber: fiscalNumber, filterEmail: email, filterAdmissionDate: admissionDate, filterFimaDate: fimaDate, filterSoDate: SODate

                    //';;;;;;;;' + states
                });


                this.bindGrid(email, fiscalNumber, states, costcenters, admissionDate, fimaDate, SODate, phases, nationalities)
            }).catch(() => { this.props.dispatch(Toast("Não foi possível obter os filtros.", ToastTypes.Danger, false)) });
    }

    bindGrid(Email, FiscalNumber, State, costCenters, admissionDate, fimaDate, soDate, phases, nationalities) {
        this.props.dispatch(Loading(true))

        var data = {
            email: Email,
            fiscalNumber: FiscalNumber,
            state: State,
            profileId: this.state.profileId,
            costCenter: costCenters,
            admissionDate: admissionDate,
            fimaDate: fimaDate,
            soDate: soDate,
            phase: phases,
            nationality: nationalities
        }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        fetch("/api/candidates/candidates", requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        id: "candidates",
                        title: "Candidatos",
                        columns: {
                            title: ["Nome", "Centro de Custo", "Contato", "Horário", "Fase", "Estado",  "Data FIMA", "Data Admissão", ""],
                            width: [20, 10, 10, 10, 10, 10, 10, 10, 10],
                            align: ["left", "left", "left", "left", "left", "left", "left", "left", "right"],
                            search: [true, true, true, true, true, true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idCandidate,
                                columns: [
                                    { column: row.name, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.costCenterName, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.phoneNumber, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.scheduleCode, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.phaseName, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.stateName, color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    /*{ column: row.soDate === null ? '' : formatDateHours(row.soDate.toString()), color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },*/
                                    { column: row.fimaDate === null ? '' : formatDateHours(row.fimaDate.toString()), color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) },
                                    { column: row.admissionDate === null ? '' : formatDate(row.admissionDate), color: (parseInt(row.idState) === this.state.StateHired ? "#568EDB" : null) }
                                ],
                                actions: [
                                    { column: faEdit, title: "Editar", action: "./candidatedetail?idcandidate=" + row.idCandidate },
                                    (this.state.profileId === 1 || this.state.profileId === 101) ? { column: faSitemap, title: "Fase", action: (e) => row.idState != this.state.StateHired ? this.handleSetPhase(row.idCandidate) : null, color: row.idState != this.state.StateHired ? undefined : "grey" } : null,
                                    (this.state.profileId === 1 || this.state.profileId === 101) ? { column: faFileCertificate, title: "Declaração de Presença", action: (e) => this.handleSetDeclaration(row) } : null

                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            (this.state.profileId === 1 || this.state.profileId === 101) ? { action: (e) => this.handleNewCandidate(e), icon: faPlus, title: "Novo Candidato" } : null
                        ],
                        filters: [
                            { id: "lstState", type: "list", data: this.state.lstState, label: "Estado", multiple: true },
                            { id: "lstCostCenters", type: "list", data: this.state.lstCostCenters, label: "Centros de Custo", multiple: true },
                            { id: "lstNationalities", type: "list", data: this.state.lstNationalities, label: "Nacionalidades", multiple: true },
                            { id: "lstPhases", type: "list", data: this.state.lstPhases, label: "Fases", multiple: true },
                            { id: "txtFilterFiscalNumber", type: "text", data: this.state.filterFiscalNumber, label: "Nº Contribuinte" },
                            { id: "txtFilterEmail", type: "text", data: this.state.filterEmail, label: "Email" },
                            { id: "txtAdmissionDate", type: "date", data: this.state.filterAdmissionDate !== null ? new Date(this.state.filterAdmissionDate) : null, label: "Data de Admissão" },
                            { id: "txtFimaDate", type: "date", data: this.state.filterFimaDate !== null ? new Date(this.state.filterFimaDate) : null, label: "Data da FIMA" },
                            { id: "txtSoDate", type: "date", data: this.state.filterSoDate !== null ? new Date(this.state.filterSoDate) : null, label: "Data de SO" }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(() => { this.props.dispatch(Toast("Não foi possivel obter os candidatos.", ToastTypes.Danger, false)) })
    }

    handleEditCandidate() { }

    handleSetPhase(idCandidate) {
        this.setState({ idCandidate: idCandidate, modal: { isOpen: true }, StartForm: false, checkPhaseOptionsFields: 0, checkFields: false })
    }

    handleSetDeclaration(row) {
        this.setState({ modalAttendance: { isOpen: true, ObjData: row } })
    }

    handleFilter(data) {

        var strState = ''
        var strCostcenter = ''
        var strNacionalities = ''
        var strPhases = ''

        data[0].forEach(a => { strState += a.toString() + ',' })
        data[1].forEach(a => { strCostcenter += a.toString() + ',' })
        data[2].forEach(a => { strNacionalities += a.toString() + ',' })
        data[3].forEach(a => { strPhases += a.toString() + ',' })

        this.bindGrid(data[5], data[4], strState, strCostcenter, data[6], data[7], data[8], strPhases, strNacionalities)
        this.setState({
            filterSoDate: data[8] === '' ? null : data[8], filterFimaDate: data[7] === '' ? null : data[7],
            filterAdmissionDate: data[6] === '' ? null : data[6],
            GridFilters: strState + ';' + strCostcenter + ';' + strNacionalities + ';' + strPhases + ';' + data[4] + ';' + data[5] + ';' + data[6] + ';' + data[7] + ';' + data[8]
        })
    }

    handleNewCandidate(e) {
        e.preventDefault()
        this.setState({ idCandidate: -1, modal: { isOpen: true }, StartForm: true, checkPhaseOptionsFields: 0, checkFields: false })
    }

    handlerCheckFields(data) {
        if (data === true) {
            this.props.dispatch(Toast("O candidato já existe na base de dados", ToastTypes.Danger, false))
            this.setState({ checkPhaseOptionsFields: 0, checkFields: false, StartForm: true, CandidateProcess: { ...this.state.CandidateProcess, PersonalData: data } })
            return
        } else {
            this.setState({
                checkPhaseOptionsFields: 0, checkFields: false, StartForm: data !== null && data !== true ? false : true,
                CandidateProcess: { ...this.state.CandidateProcess, PersonalData: data }
            })
        }
    }

    handleNextStep() {
        this.setState({ checkFields: true })
    }

    async handlerPhaseOptionsCheckFields(data) {

        var arrFilterData = []
        var arrFilterDataAux = []

        if (data === null) {
            return this.props.dispatch(Toast("Por favor adicione fases ao processo", ToastTypes.Warning, true))
        }

        if (this.state.CandidateProcess.PersonalData !== null) {
            data.Workflow.forEach((a, index) => {
                data.Workflow[index].Detail = {
                    CostCenter: parseInt(this.state.CandidateProcess.PersonalData.Detail.CostCenter),
                    IdMarket: parseInt(this.state.CandidateProcess.PersonalData.Detail.IdMarket), IdScheduleType: parseInt(this.state.CandidateProcess.PersonalData.Detail.IdScheduleType),
                    IdFavouriteStores: this.state.CandidateProcess.PersonalData.Detail.IdFavouriteStores,
                    sourceId: this.state.CandidateProcess.PersonalData.Detail.sourceId
                }
            })
        }

        if (this.state.GridFilters !== '') {
            //arrFilterDataAux = this.state.GridFilters.split(";");
            this.state.GridFilters.split(";").forEach(result => {
                arrFilterData.push(result === 'null' ? null : result)
            })
        }

        var objSave = { PersonalData: this.state.CandidateProcess.PersonalData, Workflow: data.Workflow, email: null }

        if (data !== null) {

            if (data.IdEmailTemplate !== null && data.IdEmailTemplate !== -1) {

                let jsonEmail = await this.checkEmailFields(objSave, data)

                if (jsonEmail === null) {
                    this.props.dispatch(Toast("Não foi possível validar o email", ToastTypes.Danger, true))
                    return
                }
                else if (jsonEmail.tagsNotMatched.length > 0) {
                    this.props.dispatch(Toast("Existem tags de email que não foram preenchidas. Por favor verifique no preview do email", ToastTypes.Danger, true))
                    return
                }
                else {
                    objSave = { ...objSave, email: jsonEmail.emailPreview }
                }
            }

            const requestOptions = {
                method: "PUT",
                headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
                body: JSON.stringify(objSave)
            }

            fetch("/api/candidates/save", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    if (json === true) {
                        this.props.dispatch(Toast("Registo inserido com sucesso", ToastTypes.Success, false))

                        var type = this.state.lstTypeCostCenter.find(a => a.selected).value;
                        this.bindGrid(arrFilterData[4], arrFilterData[5], arrFilterData[0], arrFilterData[1], arrFilterData[6], arrFilterData[7], arrFilterData[8], arrFilterData[3], arrFilterData[2])
                        //this.bindGrid(arrFilterData[1], arrFilterData[2], arrFilterData[3], arrFilterData[0], arrFilterData[4], type)

                        this.setState({
                            CandidateProcess: { PersonalData: null, Workflow: null }
                        })


                    }
                    else {
                        this.props.dispatch(Toast("Não foi possível gravar", ToastTypes.Danger, false))
                    }
                })
                .catch(() => { this.props.dispatch(Toast("Não foi possível gravar", ToastTypes.Danger, false)) })

            this.toggle()
        }
        else {
            this.props.dispatch(Toast("Por favor adicione fases ao processo", ToastTypes.Warning, true))
        }
    }

    async checkEmailFields(objSave, data) {
        let json = null;

        try {

            //Check Email Tags
            var AuxName = objSave.PersonalData !== null ? objSave.PersonalData.Name : ''
            var AuxEmail = objSave.PersonalData !== null ? objSave.PersonalData.Email : ''
            var AuxPhone = objSave.PersonalData !== null ? objSave.PersonalData.PhoneNumber : ''
            var AuxIdCitizenship = objSave.PersonalData !== null ? objSave.PersonalData.IdCitizenship : -1
            var ArrPhases = []

            data.Workflow.forEach(a => {
                ArrPhases.push({ idPhase: a.IdPhase, idEvent: a.IdEvent })
            })

            var objEmail = { Phases: ArrPhases, Name: AuxName, Email: AuxEmail, Phone: AuxPhone, IdCitizenship: AuxIdCitizenship, IdCandidate: this.state.idCandidate, IdEmailTemplate: data.IdEmailTemplate }

            const requestOptionsEmail = {
                method: "PUT",
                headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
                body: JSON.stringify(objEmail)
            }

            const response = await fetch("/api/candidates/checkemailtags", requestOptionsEmail)

            if (response.status === 200) {
                json = await response.json()
                return json;
            }
        }
        catch (e) {
            return null
        }

        return json;

    }

    handlerCheckFieldsAttendance(data) {
        if (data === true)
            this.setState(prevState => { return { modalAttendance: { ...prevState.modalAttendance, isOpen: !prevState.modalAttendance.isOpen } } })
    }

    handleSave() {
        this.setState({ checkPhaseOptionsFields: this.state.checkPhaseOptionsFields + 1 })
    }

    render() {
        return (
            <>
                <McdTable id={this.state.table.id} title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Candidato</ModalHeader>
                    <ModalBody>
                        {this.state.StartForm ?
                            < StartForm profileId={this.state.profileId} handlerCheckFields={this.handlerCheckFields} checkFields={this.state.checkFields} />
                            :
                            < PhaseOptions idCandidate={this.state.idCandidate} profileId={this.state.profileId} handlerPhaseOptionsCheckFields={this.handlerPhaseOptionsCheckFields}
                                checkPhaseOptionsFields={this.state.checkPhaseOptionsFields} PersonalData={this.state.CandidateProcess.PersonalData} />
                        }
                    </ModalBody>
                    <ModalFooter>
                        {this.state.StartForm ?
                            <Button color="primary" onClick={() => this.handleNextStep()}><FontAwesomeIcon icon={faStepForward} /> Avançar</Button>
                            :
                            <Button color="primary" onClick={() => this.handleSave()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        }
                    </ModalFooter>
                </Modal>
                <AttendanceDeclaration isOpen={this.state.modalAttendance.isOpen} toggle={this.toggleAttendance} handlerCheckFieldsAttendance={this.handlerCheckFieldsAttendance}
                    name={this.state.modalAttendance.ObjData.name} email={this.state.modalAttendance.ObjData.email} idCandidate={this.state.modalAttendance.ObjData.idCandidate}
                    profileId={this.state.profileId} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.main.access_token,
        profileId: state.main.profileId
    }
}

export default connect(mapStateToProps)(Candidates)