import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label } from 'reactstrap'
import { Loading, Toast, ToastTypes } from "../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faFileWord, faFilePdf } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser from "react-html-parser";

const EmailPreview = (props) => {
    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [emailTo, setEmailTo] = useState('');
    const [files, setFiles] = useState([]);
    const [emailData, setEmailData] = useState({ Phases: [], Name: '', Email: '', Phone: '', IdCitizenship : -1 });

    useEffect(() => {
        loadEmailData(props.idCandidate, props.idEmail)
    }, []);

    function loadEmailData(idcandidate, idEmail) {
        var AuxName = props.PersonalData !== null ? props.PersonalData.Name : ''
        var AuxEmail = props.PersonalData !== null ? props.PersonalData.Email : ''
        var AuxPhone = props.PersonalData !== null ? props.PersonalData.PhoneNumber : ''
        var AuxIdCitizenship = props.PersonalData !== null ? props.PersonalData.IdCitizenship : -1

        var objEmail = { Phases: props.PhaseObj, Name: AuxName, Email: AuxEmail, Phone: AuxPhone, IdCitizenship: AuxIdCitizenship, IdCandidate: idcandidate, IdEmailTemplate: idEmail }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objEmail)
        }
        fetch("/api/candidates/emailtemplatedata", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                setEmailBody(json.body)
                setEmailSubject(json.subject)
                setEmailTo(json.to)
                setFiles(json.emailAttach)
            })
            .catch(() => props.dispatch(Toast("Não foi possível carregar os dados do email", ToastTypes.Danger, false)))
    }

    function setDivFiles(indexAux) {
        var counter = -1
        return files.filter(x => x.deletedFile === false).map((result, index) => {
            counter = counter + 1

            return (
                index >= indexAux && index <= indexAux + 5 ?
                    result.newFile ?
                        <div className="col-lg-2 image-upload">
                            <label id="lblFile" htmlFor="newFile" key={"lblFile" + result.idFile + '_' + indexAux} style={{ cursor: "pointer" }}>
                                <img src={this.state.imagePath} key={"img" + result.idFile + '_' + indexAux} alt="Adicionar Ficheiro" style={{ border: "1px solid #ced4da", height: 60 + 'px', width: 60 + 'px' }} />
                            </label>
                            <input id="newFile" type="file" key={"newFile" + result.idFile + '_' + indexAux} onChange={(e) => this.handleChangeFile(e)} />
                        </div>
                        :
                        <div className="col-lg-2">
                            <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                                <FontAwesomeIcon title={result.fileName} onClick={(e) =>
                                    downloadFile(e, result.idFile)} key={"fileDownload" + result.idFile + '_' + indexAux} id={"fileDownload" + result.idFile} style={{
                                        height: 42 + 'px', width: 57 + 'px',
                                        color: result.extention === 'pdf' ? 'red' : 'blue', paddingTop: 13 + 'px', cursor: 'pointer'
                                    }} icon={result.extention === 'pdf' ? faFilePdf : faFileWord} />
                            </div>
                        </div>
                    : null
            );
        });
    }

   function downloadFile(e, idFile) {
        e.preventDefault();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        props.dispatch(Loading(true))

        fetch("/api/admin/emailfile?idFile=" + idFile, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '') {
                    props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
                    return
                }
                window.open(JSON.parse(json)[0].filePath);
                props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
            })
    }

    return (
        <>
            <Row>
                <Col>
                    <Label>Para</Label>
                    <Input id="txtTo" type="text" defaultValue={emailTo} maxLength="50" className="form-control" autoComplete="off"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Assunto</Label>
                    <Input id="txtSubject" type="text" defaultValue={emailSubject} maxLength="50" className="form-control" autoComplete="off" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Mensagem</Label>
                    <div className="emailBody">
                        {ReactHtmlParser(emailBody)}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Ficheiros</Label>
                    {
                        files.filter(x => x.deletedFile === false).map((result, index) => {

                            return (
                                index % 6 === 0 ?
                                    <div className="row" key={"rowFile" + result.idFile}>
                                        {setDivFiles(index)}
                                    </div>
                                    :
                                    null
                            )
                        })
                    }
                </Col>
            </Row>
        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(EmailPreview)