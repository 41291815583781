import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Container, Spinner, Row, Col, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { getStrMonth } from "../global/Utils";

const KPIFilter = (props) => {

    const access_token = useSelector(state => state.main.access_token);

    const [state, setState] = useState({ loading: true, value: 0, ytd: 0, title: "Mês" });
    const [month, setMonth] = useState({ isOpen: false, value: new Date().getMonth() + 1 });
    const [profileId] = useState(props.profileId);

    useEffect(async () => {
        try {
            const year = new Date().getFullYear();

            const requestOptions = { method: "GET", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" } };

            const response = await fetch(`/api/dashboard/${props.kpi}?filter=${year * 100 + month.value}&ProfileId=` + profileId , requestOptions);
            if (!response.ok)
                return setState(state => ({ ...state, loading: false }));

            const json = await response.json();
            setState(state => ({ ...state, loading: false, value: json.split(",")[0], ytd: json.split(",")[1], title: getStrMonth(month.value) }));
        } catch (e) {
            setState(state => ({ ...state, loading: false }));
        }

    }, [month.value]);

    return (
        <>
            <Container style={{ color: `rgb(115, 135, 156)`, paddingLeft: `10px`, paddingRight: `10px` }}>
                <nav className="navbar" style={{ borderBottom: `2px solid #e6e9ed`, padding: `1px 5px 6px` }}>
                    <h4 style={{ marginBottom: `0px` }}>{props.title}</h4>
                    <form className="form-inline">
                        <ul className="nav">
                            <li onClick={() => setMonth(state => ({ ...state, isOpen: true }))}>
                                <div className="input-group">
                                    <a className="icon-sub-header icon-fontawesome" title="Filtros" href="#" style={{ color: `#e6e9ed` }} onClick={(e) => e.preventDefault()}>
                                        <FontAwesomeIcon icon={faFilter} className="text-muted" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </form>
                </nav>
                {state.loading ?
                    <div style={{ minHeight: `36vh`, position: `relative` }}>
                        <div style={{ top: `30%`, left: `50%`, position: `absolute`, maxWidth: `30px` }}>
                            <Spinner color="warning" />
                        </div>
                    </div>
                    :
                    <div style={{ minHeight: `36vh`, position: `relative` }}>
                        <div style={{ top: `25%`, left: `24%`, position: `absolute` }}>
                            <Row style={{ minWidth: `15vw`, textAlign: `center` }}>
                                <Col>{state.title}</Col>
                                <Col>YTD</Col>
                            </Row>
                            <Row style={{ minWidth: `15vw`, textAlign: `center` }}>
                                <Col>
                                    <h1 style={{ fontSize: `4rem` }}>{state.value}</h1>
                                </Col>
                                <Col>
                                    <h1 style={{ fontSize: `4rem` }}>{state.ytd}</h1>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </Container>

            <Modal isOpen={month.isOpen} toggle={() => setMonth(state => ({ ...state, isOpen: !state.isOpen }))} size="sm">
                <ModalHeader toggle={() => setMonth(state => ({ ...state, isOpen: !state.isOpen }))}>Mês</ModalHeader>
                <ModalBody>
                    <Input type="select" value={month.value} onChange={(e) => setMonth({ isOpen: false, value: parseInt(e.target.value) })}>
                        <option value="1">{getStrMonth(1)}</option>
                        <option value="2">{getStrMonth(2)}</option>
                        <option value="3">{getStrMonth(3)}</option>
                        <option value="4">{getStrMonth(4)}</option>
                        <option value="5">{getStrMonth(5)}</option>
                        <option value="6">{getStrMonth(6)}</option>
                        <option value="7">{getStrMonth(7)}</option>
                        <option value="8">{getStrMonth(8)}</option>
                        <option value="9">{getStrMonth(9)}</option>
                        <option value="10">{getStrMonth(10)}</option>
                        <option value="11">{getStrMonth(11)}</option>
                        <option value="12">{getStrMonth(12)}</option>
                    </Input>
                </ModalBody>
            </Modal>
        </>
    );
}

const mapStateToProps = state => ({ profileId: state.main.profileId })
export default connect(mapStateToProps)(KPIFilter)