import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false, profileId: 0, alert: {
        show: false,
        text: "none",
        color: "primary",
        fixed: false
    },
    path: "/", access_token: null, menu: [], urlSearch: "", dependents: undefined, individualRecordError: undefined, hiredRedux: undefined
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        loading: (state, action) => {
            state.loading = action.payload;
        },
        addProfile: (state, action) => {
            state.profileId = action.payload;
        },
        alert: (state, action) => {
            state.loading = false;
            state.alert = action.payload;
        },
        unmountAlert: (state) => {
            state.alert = { ...state.alert, show: false };
        },
        path: (state, action) => {
            state.path = action.payload;
        },
        signOut: (state) => {
            state.login = "";
            state.access_token = null;
        },
        menu: (state, action) => {
            state.menu = action.payload.menu;
            state.profileId = action.payload.profileId;
        },
        accessToken: (state, action) => {
            state.access_token = action.payload;
        },
        urlSearch: (state, action) => {
            state.urlSearch = action.payload;
        },
        dependents: (state, action) => {
            state.dependents = action.payload;
        },
        individualRecordError: (state, action) => {
            state.individualRecordError = action.payload;
        },
        hiredRedux: (state, action) => {
            state.hiredRedux = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { loading, addProfile, alert, unmountAlert, path, signOut, menu, accessToken, urlSearch, dependents, individualRecordError, hiredRedux } = mainSlice.actions

export default mainSlice.reducer