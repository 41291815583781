import React, { useState, useEffect } from "react"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Input, Row, Col, Label } from 'reactstrap'
import { Loading, Toast, ToastTypes, formatDateHours } from "../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faInfoCircle, faPlus, faSave, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import McdTable from "../../global/Table"
import PhaseOptions from "../modals/PhaseOptions"
import MainForm from "./PhaseDetail/MainForm"

const Phase = (props) => {

    const [idCandidate] = useState(props.idCandidate);
    const [profileId] = useState(props.profileId);
    const [checkPhaseOptionsFields, setCheckPhaseOptionsFields] = useState(0);
    const [idWorkflow, setIdWorkflow] = useState(-1);
    const [grid, setGrid] = useState({
        table: {
            title: "",
            columns: { title: [], width: [], align: [], search: [] },
            rows: [],
            pagination: true,
            actions: []
        }
    });
    const [modal, setModal] = useState({ isOpen: false });
    const [modalDetail, setModalDetail] = useState({ isOpen: false });
    const [modalStates, setModalStates] = useState({ isOpen: false });
    const [States, setStates] = useState([]);
    const [Reasons, setReasons] = useState([]);
    const [showReason, setShowReason] = useState(false);
    const [error, setError] = useState(false);


    useEffect(() => {
        //LoadData()
        bindGrid()
    }, []);

    function LoadData() {
        var objState = []
        var objReason = []

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        props.dispatch(Loading(true))

        fetch("/api/candidates/reasonstate?idCandidate=" + props.idCandidate + "&newPhase=false", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                objState = json.states.map(result => {
                    return { value: result.idState, text: result.description, selected: result.selected }
                });

                setStates(objState)

                if (objState.find(a => a.selected) !== undefined && objState.find(a => a.selected).value === 3)
                    setShowReason(true)

                //Reasons
                objReason = json.reasons.map(result => {
                    return { value: result.idReason, text: result.description, selected: result.selected }
                });
                objReason.push({ value: -1, text: '', selected: (objReason.find(a => a.selected) === undefined), grouping: false })
                setReasons(objReason)
                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os registos dos estados e motivos", ToastTypes.Danger, false)))


    }

    function bindGrid() {

        props.dispatch(Loading(true))

        const requestOptions = { method: "GET", headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" } };

        fetch("/api/candidates/candidatestatelist/" + idCandidate, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); throw new Error(response.statusText); })
            .then(json => {

                setGrid({
                    table: {
                        title: "Fases",
                        columns: {
                            title: ["Data / Hora", "Fase", "Responsáveis pelo Processo", "Estado", ""],
                            width: [10, 20, 40, 20, 10],
                            align: ["left", "left", "left", "left", "right"],
                            search: [true, true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idWorkflow,
                                columns: [
                                    { column: formatDateHours(row.logDate) },
                                    { column: row.phaseName },
                                    { column: row.interviewers.join("; ") },
                                    { column: row.stateName }
                                ],
                                actions: [
                                    profileId === 1 || profileId === 101 ? { column: faEdit, title: "Alterar Estado", action: (e) => openEditState(e, row.idWorkflow) } : null,
                                     { column: faInfoCircle, title: "Visualizar", action: (e) => openInfo(e, row.idWorkflow) } 
                                ]
                            }
                        }),
                        actions: [
                            props.isHired ? null : { action: (e) => handleSetPhase(e), icon: faPlus, title: "Nova Fase" }
                        ],
                        filters: []
                    }
                })

                props.dispatch(Loading(false))
            })
            .catch(_ => props.dispatch(Toast("Não foi possivel obter os registos.", ToastTypes.Danger, false)))

    }

    const toggle = () => {
        setModal({ ...modal, isOpen: !modal.isOpen })
    }

    const handleSetPhase = (e) => {
        e.preventDefault();
        setCheckPhaseOptionsFields(0);
        setModal({ ...modal, isOpen: true })
    }

    const handleSave = () => {
        setCheckPhaseOptionsFields(checkPhaseOptionsFields + 1);
    }

    const handlerPhaseOptionsCheckFields = async (data) => {

        if (data === null) {
            return this.props.dispatch(Toast("Por favor adicione fases ao processo", ToastTypes.Warning, true))
        }

        var objSave = { PersonalData: null, Workflow: data.Workflow, email: null }

        if (data.IdEmailTemplate !== null && data.IdEmailTemplate !== -1) {

            let jsonEmail = await checkEmailFields(objSave, data)

            if (jsonEmail === null) {
                props.dispatch(Toast("Não foi possível validar o email", ToastTypes.Danger, true))
                return
            }
            else if (jsonEmail.tagsNotMatched.length > 0) {
                props.dispatch(Toast("Existem tags de email que não foram preenchidas. Por favor verifique no preview do email", ToastTypes.Danger, true))
                return
            }
            else {
                objSave = { ...objSave, email: jsonEmail.emailPreview }
            }
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objSave)
        }

        fetch("/api/candidates/save", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === true) {
                    props.dispatch(Toast("Registo inserido com sucesso", ToastTypes.Success, false))
                    bindGrid();
                }
                else {
                    props.dispatch(Toast("Não foi possível gravar", ToastTypes.Danger, false))
                }
            })
            .catch(() => { props.dispatch(Toast("Não foi possível gravar", ToastTypes.Danger, false)) })

        toggle()
    }

    async function checkEmailFields(objSave, data) {
        let json = null;

        try {

            //Check Email Tags
            var AuxName = objSave.PersonalData !== null ? objSave.PersonalData.Name : ''
            var AuxEmail = objSave.PersonalData !== null ? objSave.PersonalData.Email : ''
            var AuxPhone = objSave.PersonalData !== null ? objSave.PersonalData.PhoneNumber : ''
            var AuxIdCitizenship = objSave.PersonalData !== null ? objSave.PersonalData.IdCitizenship : -1
            var ArrPhases = []

            data.Workflow.forEach(a => {
                ArrPhases.push({ idPhase: a.IdPhase, idEvent: a.IdEvent })
            })

            var objEmail = { Phases: ArrPhases, Name: AuxName, Email: AuxEmail, Phone: AuxPhone, IdCitizenship: AuxIdCitizenship, IdCandidate: idCandidate, IdEmailTemplate: data.IdEmailTemplate }

            const requestOptionsEmail = {
                method: "PUT",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
                body: JSON.stringify(objEmail)
            }

            const response = await fetch("/api/candidates/checkemailtags", requestOptionsEmail)

            if (response.status === 200) {
                json = await response.json()
                return json;
            }
        }
        catch (e) {
            console.error(e);
            return null
        }

        return json;

    }

    const toggleDetail = () => {
        setModalDetail({ ...modalDetail, isOpen: !modalDetail.isOpen });
    }

    const toggleStates = () => {
        setModalStates({ ...modalStates, isOpen: !modalStates.isOpen });
    }

    const openInfo = (e, idWorkflow) => {
        e.preventDefault();
        setModalDetail({ ...modalDetail, isOpen: !modalDetail.isOpen });
        setIdWorkflow(idWorkflow);
    }

    const openEditState = (e, idWorkflow) => {
        e.preventDefault();

        var objState = []
        var objReason = []

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        props.dispatch(Loading(true))

        fetch("/api/candidates/reasonstate?idCandidate=" + props.idCandidate + "&idWorkflow=" + idWorkflow, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                objState = json.states.map(result => {
                    return { value: result.idState, text: result.description, selected: result.selected }
                });

                setStates(objState)

                if (objState.find(a => a.selected) !== undefined && objState.find(a => a.selected).value === 3)
                    setShowReason(true)
                else 
                    setShowReason(false)

                //Reasons
                objReason = json.reasons.map(result => {
                    return { value: result.idReason, text: result.description, selected: result.selected }
                });
                objReason.push({ value: -1, text: '', selected: (objReason.find(a => a.selected) === undefined), grouping: false })
                setReasons(objReason)
                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os registos dos estados e motivos", ToastTypes.Danger, false)))

            setModalStates({ ...modalStates, isOpen: true });
            setIdWorkflow(idWorkflow);
    }

    function handleChangeListCombo(e) {
        var id = e.target.id
        var obj = []
        const element = document.getElementById(e.target.id)

        if (id === 'lstState') {

            for (let i = 0; i < element.options.length; ++i) {
                var selected = false
                let objAux = States.find(x => parseInt(x.value) === parseInt(element[i].value))

                if (objAux !== undefined) {

                    if (parseInt(e.target.value) === parseInt(element[i].value))
                        selected = true

                    objAux.selected = selected
                    obj.push(objAux)
                }
            }
            setStates(obj)

            if (parseInt(e.target.value) === 3)
                setShowReason(true)
            else { 
                setShowReason(false)

                var objReason = []


                Reasons.forEach(a => {
                    a.selected = a.value === -1 ? true : false
                })

                setReasons(Reasons)
            }
        }
        else if (id === 'lstReason') {
            for (let i = 0; i < element.options.length; ++i) {
                let objAux = Reasons.find(x => parseInt(x.value) === parseInt(element[i].value))
                objAux.selected = element[i].selected
                obj.push(objAux)
            }
            if (parseInt(Reasons.find(a => a.selected).value) === -1 && parseInt(States.find(a => a.selected).value) === 3)
                setError(true)
            else
                setError(false)

            setReasons(obj)
        }
    }

    function SaveState() {
        var idState = parseInt(States.find(a => a.selected).value)
        var idReason = parseInt(Reasons.find(a => a.selected).value)

        if (idState === 3 && idReason === -1) {
            setError(true)
        }
        else {
            setError(false)

            const requestOptions = {
                method: "PUT",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
                body: JSON.stringify({
                    idWorkflow: idWorkflow,
                    idState: idState,
                    idReason: idReason
                })
            }
            props.dispatch(Loading(true))

            fetch("/api/candidates/state", requestOptions)
                .then(response => {
                    if (response.status === 200) {
                        toggleStates()
                        bindGrid()
                        props.dispatch(Toast("Registo gravado com sucesso!", ToastTypes.Success, true))
                    } else {
                        props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
                    }
                    props.dispatch(Loading(false))
                })
                .catch(error => {
                    console.log(error)
                    props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
                });
        }
    }

    return (
        <>
            <McdTable title={grid.table.title} columns={grid.table.columns} rows={grid.table.rows} pagination={grid.table.pagination} actions={grid.table.actions} />

            <Modal isOpen={modal.isOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Fase</ModalHeader>
                <ModalBody>
                    <PhaseOptions idCandidate={idCandidate} profileId={profileId} handlerPhaseOptionsCheckFields={handlerPhaseOptionsCheckFields}
                        checkPhaseOptionsFields={checkPhaseOptionsFields} PersonalData={null} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSave()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDetail.isOpen} toggle={toggleDetail} size="xl">
                <ModalHeader toggle={toggleDetail}>Detalhe</ModalHeader>
                <ModalBody>
                    <MainForm idCandidate={idCandidate} idWorkflow={idWorkflow} profileId={profileId} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalStates.isOpen} toggle={toggleStates} size="s">
                <ModalHeader toggle={toggleStates}>Alterar Estado</ModalHeader>
                <ModalBody>

                    <Row style={{ marginTop: 20 + 'px' }}>
                        <Col>
                            <Label>Estado</Label>
                            <Input type="select" id="lstState" value={States.length > 0 && States.find(a => a.selected) !== undefined ? States.find(a => a.selected).value : -1}
                                onChange={(e) => handleChangeListCombo(e)}>
                                {States.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                            </Input>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 + 'px' }}>
                        {showReason && Reasons.length > 0 ?
                            <Col>
                                <Label>Motivo</Label>
                                <Input type="select" value={Reasons.find(a => a.selected).value} id="lstReason" invalid={error}
                                    onChange={(e) => handleChangeListCombo(e)}>
                                    {Reasons.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                                </Input>
                            </Col>
                            : <Col></Col>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => SaveState()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Phase)