import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";

const KPITable = (props) => {
    const access_token = useSelector(state => state.main.access_token);
    const [state, setState] = useState({ loading: true, columns: [], rows: [] });
    const [profileId] = useState(props.profileId);

    useEffect(async () => {

        try {
            const requestOptions = { method: "GET", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" } };

            const response = await fetch(`/api/dashboard/${props.kpi}/columns`, requestOptions);
            const response2 = await fetch(`/api/dashboard/${props.kpi}?filter=table&ProfileId=` + profileId, requestOptions);

            if (!response.ok || !response2.ok)
                throw new error();

            const col = await response.json();

            const json = await response2.json()
            const row = json.map((a, idx) => ({
                id: idx,
                columns: [
                    { column: a.key },
                    { column: a.value }
                ]
            }));

            setState({ loading: false, columns: col, rows: row });
        } catch (e) {
            setState(state => ({ ...state, loading: false }));
        }

    }, []);

    if (state.loading)
        return (
            <Container style={{ color: `rgb(115, 135, 156)`, paddingLeft: `10px`, paddingRight: `10px` }}>
                <nav style={{ borderBottom: `2px solid #e6e9ed`, padding: `1px 5px 6px` }}>
                    <h4 style={{ marginBottom: `0px` }}>{props.title}</h4>
                </nav>
                <div style={{ minHeight: `36vh`, position: `relative` }}>
                    <div style={{ top: `30%`, left: `50%`, position: `absolute`, maxWidth: `30px` }}>
                        <Spinner color="warning" />
                    </div>
                </div>
            </Container>
        )

    return (
        <Container style={{ color: `rgb(115, 135, 156)`, paddingLeft: `10px`, paddingRight: `10px` }}>
            <nav style={{ borderBottom: `2px solid #e6e9ed`, padding: `1px 5px 6px` }}>
                <h4 style={{ marginBottom: `0px` }}>{props.title}</h4>
            </nav>
            <div style={{ minHeight: `36vh`, position: `relative` }}>
                <Row style={{ height: `32px` }}>
                    {state.columns.map((col, index) => <Col key={index} style={{ fontWeight: `bold`, textAlign: col.align, alignSelf: `center` }} xs={col.width}>{col.name}</Col>)}
                </Row>
                <div style={{ overflowY: `auto`, height: `34vh` }}>
                    {state.rows.map((row, index) =>
                        <Row key={index} style={{ height: `32px`, borderTop: `1px solid gainsboro` }}>
                            {row.columns.map((col, idx) =>
                                <Col key={idx} style={{ textAlign: state.columns[idx].align, alignSelf: `center` }} xs={state.columns[idx].width}>{col.column}</Col>
                            )}
                        </Row>
                    )}
                </div>
            </div>
        </Container>
    );
}

const mapStateToProps = state => ({ profileId: state.main.profileId })
export default connect(mapStateToProps)(KPITable)