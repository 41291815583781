import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faFilter, faEye, faEyeSlash, faEdit } from '@fortawesome/pro-solid-svg-icons'

class Interviewer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            lstState: [],
            modal: {
                Name: '',
                IdInterviewer: '',
                mobilePhone: '',
                isOpen: false,
                error: {
                    Name: false,
                    IdInterviewer: false,
                    mobilePhone: false
                }
            },
            ObjData: { users: [], Name: '', IdInterviewer: '', mobilePhone: '' },
            IdInterviewer: ''
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleNewInterview = this.handleNewInterview.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSaveInterviewer = this.handleSaveInterviewer.bind(this)
    }

    componentDidMount() {

        let objUsers;

        let objState = [
            { value: 1, text: "Ativo", selected: true },
            { value: 0, text: "Inativo", selected: false }
        ]

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/interviwerusers", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {

                objUsers = data.map(result => {
                    return { value: result.userIdentifier, text: result.name, selected: false }
                });

                this.setState({ lstState: objState, IdInterviewer: 0, ObjData: { ...this.state.ObjData, users: objUsers, IdInterviewer: '', mobilePhone: '' } })
                this.bindGrid(1)

            }).catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos dos utilizadores.", ToastTypes.Danger, false))
            });
    }

    bindGrid(State) {
        State = parseInt(State) === 1 ? true : false

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/interviewers?state=" + State, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Responsáveis pelo Processo",
                        columns: {
                            title: ["Nome", "Telefone", ""],
                            width: [70, 20, 10],
                            align: ["left", "right", "right"],
                            search: [true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idInterviewer,
                                columns: [
                                    { column: row.name },
                                    { column: row.mobilePhone }
                                ],
                                actions: [
                                    { column: row.active ? faEyeSlash : faEye, title: row.active ? "Inativar" : "Ativar", action: (e) => this.handleDeleteInterview(row.idInterviewer, row.name) },
                                    { column: faEdit, title: "Editar", action: (e) => this.handleEditInterview(row.idInterviewer, row.name, row.mobilePhone) }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewInterview(e), icon: faPlus, title: "Novo entrevistador" }
                        ],
                        filters: [
                            { id: "lstState", type: "list", data: this.state.lstState, label: "Estado", multiple: false }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possivel obter os responsáveis pelo processo.", ToastTypes.Danger, false))
            })
    }

    handleCloseModal() {
        this.setState({
            IdInterviewer: '',
            modal: {
                isOpen: false,
                error: { Name: false, IdInterviewer: false, mobilePhone: false }
            }
        });
    }

    handleFilter(data) {
        let dataValue = data[0]
        this.bindGrid(dataValue)
    }

    handleDeleteInterview(IdInterviewer, Name) {

        let Active = !this.state.lstState.find(x => x.selected === true).value

        if (!window.confirm('Tem a certeza que pretende ' + (Active ? 'ativar' : 'inativar') + ' o entrevistador com o nome: ' + Name + '?'))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdInterviewer: IdInterviewer,
                Active: Active
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/interviewers", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ IdInterviewer: '' })
                    this.props.dispatch(Toast("Entrevistador " + (Active ? 'ativado' : 'inativado') + " com sucesso", ToastTypes.Success, false))

                    var newArray = this.state.table.rows.filter(function (obj) { return obj.id !== IdInterviewer; });
                    this.setState({ table: { ...this.state.table, rows: newArray } })
                }
                else {
                    this.props.dispatch(Toast("Não foi possível concluir a operação. Por favor tente mais tarde!", ToastTypes.Danger, true))
                }
                this.props.dispatch(Loading(false))

            })
            .catch(() => this.props.dispatch(Toast("Não foi possível inativar o entrevistador!", ToastTypes.Danger, true)));
    }

    handleEditInterview(IdInterviewer, Name, MobilePhone) {

        this.setState({
            IdInterviewer: IdInterviewer,
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: { Name: false, IdInterviewer: false, mobilePhone: false }
            },
            ObjData: { ...this.state.ObjData, IdInterviewer: IdInterviewer, name: Name, mobilePhone: MobilePhone }
        })

    }

    handleNewInterview() {
        this.setState({
            IdInterviewer: undefined,
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: { Name: false, IdInterviewer: false, mobilePhone: false }
            },
            ObjData: { ...this.state.ObjData, IdInterviewer: '', name: '', mobilePhone: '' }
        })
    }



    handleChangeForm(e) {

        let dataValue = e.target.value
        let id = e.target.id

        let auxErrorObj = this.state.modal.error
        auxErrorObj[id] = false

        if (e.target.id === 'IdInterviewer') {
            const arrData = []
            var selected = false
            let userIdentifier = ''

            this.state.ObjData.users.forEach(a => {
                selected = false
                if (a.value === dataValue) {
                    selected = true
                    userIdentifier = a.value
                }
                arrData.push({ value: a.value, text: a.text, selected: selected })
            })

            this.setState({ ObjData: { ...this.state.ObjData, IdInterviewer: userIdentifier, users: arrData }, modal: { ...this.state.modal, error: auxErrorObj } })
        }
        else {
            this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, modal: { ...this.state.modal, error: auxErrorObj } })
        }

    }


    handleSaveInterviewer() {

        let modal = { ...this.state.modal }

        modal.error.IdInterviewer = (this.state.ObjData.IdInterviewer === 0 || this.state.ObjData.IdInterviewer === '')
        modal.error.mobilePhone = this.state.ObjData.mobilePhone == "";

        if (modal.error.IdInterviewer || modal.error.mobilePhone) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdInterviewer: this.state.ObjData.IdInterviewer,
                Active: true,
                mobilePhone: this.state.ObjData.mobilePhone
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/interviewers", requestOptions).
            then(response =>
            {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Responsável do processo gravado com sucesso.", ToastTypes.Success, false))

                    this.bindGrid(this.state.lstState.find(x => x.selected === true).value)

                    this.setState({
                        modal: {
                            isOpen: false,
                            error: { Name: false, IdInterviewer: false, mobilePhone: false }
                        },
                        IdInterviewer: ''
                    })
                }
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.handleCloseModal}>
                    <ModalHeader toggle={this.handleCloseModal}>Responsáveis pelo Processo</ModalHeader>
                    <ModalBody>

                        <>
                            <Row style={{ display: this.state.IdInterviewer === undefined ? "block" : "none" }}>
                                <Col xs="12">
                                    <Label>Nome</Label>
                                    <Input type="select" id="IdInterviewer" invalid={this.state.modal.error.IdInterviewer} onChange={(e) => this.handleChangeForm(e)}>
                                        <option key="-1" value=""></option>
                                        {this.state.ObjData.users.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                    </Input>
                                </Col>
                            </Row>

                            <Row style={{ display: this.state.IdInterviewer !== undefined  ? "block" : "none" }}>
                                <Col xs="12">
                                    <Label>Nome</Label>
                                        <Input type="text" id="Name" disabled value={this.state.ObjData.name}>
                                    </Input>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12">
                                    <Label>Telefone</Label>
                                    <Input type="number" id="mobilePhone" invalid={this.state.modal.error.mobilePhone} autoComplete="nope" 
                                        value={this.state.ObjData.mobilePhone} onChange={(e) => this.handleChangeForm(e)} />
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSaveInterviewer(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(Interviewer)
