import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { formatDate, Toast, ToastTypes } from "../../global/Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faPlusCircle, faMinusCircle, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dependents } from "../../../redux/MainSlice";

const Dependents = (props) => {

    const [stateDependents, setDependents] = useState({ localDependents: [], dependentsError: [] });
    const [idCandidate] = useState(props.idCandidate);
    const [modalIsOpen, setModalIsOpen] = useState(props.isOpen);

    const dispatch = useDispatch();
    const reduxDependents = useSelector(state => state.main.dependents);
    const reduxAccessToken = useSelector(state => state.main.access_token);

    useEffect(async () => {

        let localDepError = []
        let localDep = []

        if (reduxDependents != undefined) {
            reduxDependents.forEach(a => {
                localDep.push({ idDependent: a.idDependent, name: a.name, birthDate: a.birthDate, gender: a.gender });
                localDepError.push({ idDependent: a.idDependent, name: false, birthDate: false, gender: false });
            });

            return setDependents({ localDependents: localDep, dependentsError: localDepError });
        }

        try {
            const requestOptions = { method: "GET", headers: { authorization: "bearer " + reduxAccessToken, "Content-Type": "application/json" } }

            const response = await fetch("/api/candidates/dependents?idcandidate=" + idCandidate, requestOptions);
            if (!response.ok)
                throw new Error();

            const json = await response.json();

            json.forEach(a => {
                localDep.push({ idDependent: a.idDependent, name: a.name, birthDate: a.birthDate, gender: a.gender });
                localDepError.push({ idDependent: a.idDependent, name: false, birthDate: false, gender: false });
            });

            setDependents({ localDependents: localDep, dependentsError: localDepError });
        } catch {
            dispatch(Toast("Não foi possível obter os dependentes.", ToastTypes.Danger, false))
        }
    }, []);

    useEffect(() => setModalIsOpen(props.isOpen), [props.isOpen]);

    const handleChange = (e, dependentId, dateId) => {

        const dataValue = dateId !== undefined && dateId !== null ? e : e.target.value.toUpperCase();
        let id = dateId !== undefined && dateId !== null ? dateId : e.target.id;

        let erroAux = false;

        if (id.startsWith("name")) {
            id = "name";
            erroAux = dataValue === "";
        }
        else if (id.startsWith("birthDate")) {
            id = "birthDate";
            erroAux = dataValue === "";
        }
        else if (id.startsWith("lstGender")) {
            id = "gender";
            erroAux = parseInt(dataValue) === -1;
        }

        const localDep = [...stateDependents.localDependents];
        const dependent = localDep.find(a => a.idDependent === dependentId);
        dependent[id] = dataValue;

        var localDepError = [...stateDependents.dependentsError];
        var error = localDepError.find(a => a.idDependent === dependentId);
        error[id] = erroAux;

        setDependents({ localDependents: localDep, dependentsError: localDepError });
    }

    const handleAdd = () => {

        let localDep = [...stateDependents.localDependents];
        let localDepError = [...stateDependents.dependentsError];

        const maxId = localDep.length == 0 ? 1 : (localDep[localDep.length - 1].idDependent + 1);

        localDep.push({ idDependent: maxId, idCandidate: parseInt(idCandidate), name: "", birthDate: "", gender: -1 });
        localDepError.push({ idDependent: maxId, name: true, birthDate: true, gender: true });

        setDependents({ localDependents: localDep, dependentsError: localDepError });
    }

    const handleDelete = (id) => {

        const localDep = [...stateDependents.localDependents.filter(a => a.idDependent !== id)];
        const localDepError = [...stateDependents.dependentsError.filter(a => a.idDependent !== id)];

        setDependents({ localDependents: localDep, dependentsError: localDepError });
    }

    const handleConfirm = () => {

        if (stateDependents.dependentsError.filter(a => a.name || a.birthDate || a.gender).length != 0)
            return;

        dispatch(dependents(stateDependents.localDependents.map(a => ({...a, birthDate: formatDate(a.birthDate)}))));
    }

    return (
        <Modal isOpen={modalIsOpen} toggle={() => dispatch(dependents([...reduxDependents]))} size="lg">
            <ModalHeader toggle={() => dispatch(dependents([...reduxDependents]))}>Dependentes</ModalHeader>
            <ModalBody>
                {stateDependents.localDependents.map((item, key) =>
                    <Row key={key} style={{ marginTop: `10px` }}>
                        <Col xs="5">
                            <Label><b>Nome</b></Label>
                            <Input id={"name_" + item.idDependent} type="text" maxLength="50" autoComplete="nope" value={item.name}
                                onChange={(e) => handleChange(e, item.idDependent, undefined)} invalid={stateDependents.dependentsError.find(a => a.idDependent == item.idDependent).name} />
                        </Col>
                        <Col xs="3">
                            <Label><b>Data Nascimento</b></Label>
                            <DatePicker id={"birthDate_" + item.idDependent} showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje"
                                autoComplete="nope" className={stateDependents.dependentsError.find(a => a.idDependent == item.idDependent).birthDate ? "is-invalid form-control" : "form-control"}
                                selected={new Date(item.birthDate).getTime()} onChange={(e) => handleChange(e, item.idDependent, 'birthDate')} dateFormat="yyyy-MM-dd">
                            </DatePicker>
                        </Col>
                        <Col xs="3">
                            <Label><b>Género</b></Label>
                            <Input type="select" id={"lstGender_" + item.idDependent} value={item.gender != undefined && item.gender != -1 ? item.gender : -1}
                                invalid={stateDependents.dependentsError.find(a => a.idDependent == item.idDependent).gender} onChange={(e) => handleChange(e, item.idDependent, undefined)}>
                                <option value="-1"></option>
                                <option value="1">Masculino</option>
                                <option value="0">Feminino</option>
                            </Input>
                        </Col>
                        <Col xs="1">
                            <Label><b>&nbsp;</b></Label>
                            <div style={{ marginTop: 10 + 'px' }}><FontAwesomeIcon onClick={() => handleDelete(item.idDependent)} title="Eliminar"
                                style={{ verticalAlign: "baseline", cursor: "pointer", color: "#1b6ec2" }} id="add" icon={faMinusCircle} /></div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                        <div style={{ marginTop: 10 + 'px' }}>
                            <FontAwesomeIcon onClick={() => handleAdd()} title="Adicionar" style={{ verticalAlign: "baseline", cursor: "pointer", color: "#1b6ec2" }} id="add" icon={faPlusCircle} />
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => handleConfirm()}><FontAwesomeIcon icon={faUserCheck} /> Confirmar</Button>
            </ModalFooter>
        </Modal>
    )

}

export default Dependents
