import React from "react";
import { Col, Container, Row } from "reactstrap";
import KPITable from "./KPITable";
import KPISingle from "./KPISingle";
import KPIFilter from "./KPIFilter";

const Dashboard = () => {

    return (
        <Container>
            <Row style={{ minHeight: "40vh", maxHeight: "40vh", overflow: `hidden` }}>
                <Col style={{ padding: "10px" }}>
                    <KPISingle title="Pedidos abertos" kpi="open" />
                </Col>
                <Col style={{ padding: "10px" }}>
                    <KPITable title="Pedidos por restaurante" kpi="open.store" />
                </Col>
                <Col style={{ padding: "10px" }}>
                    <KPITable title="Pedidos por mercado" kpi="open.market" />
                </Col>
            </Row>
            <Row style={{ minHeight: "40vh", maxHeight: "40vh" }}>
                <Col style={{ padding: "10px" }}>
                    <KPIFilter title="Candidatos contratados" kpi="open.month" />
                </Col>
                <Col style={{ padding: "10px" }}>
                    <KPISingle title="Candidatos Formação Inicial" kpi="fima" />
                </Col>
                <Col style={{ padding: "10px" }}>
                    <KPITable title="Candidatos por fase" kpi="phase" />
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;
