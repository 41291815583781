import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSave, faFilter, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'

class RecruitmentSources extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            listState: [
                { value: 1, text: "Ativo", selected: true },
                { value: 0, text: "Inativo", selected: false }
            ],
            modal: {
                isOpen: false,
                Name: '',
                error: { Name: false }
            },
            ModalValues: { Name: '', SourcePaied: true },
            IdRecruitmentSourcesState: 0
        }

        this.handleNewSource = this.handleNewSource.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    componentDidMount() {
        this.bindGrid(this.state.listState.find(x => x.selected === true).value)
    }

    bindGrid(State) {

        State = parseInt(State) === 1 ? true : false

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/recruitmentsources?state=" + State, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                this.setState({
                    table: {
                        title: "Fontes Recrutamento",
                        columns: {
                            title: ["Fonte", "Paga?", ""],
                            width: [80, 10, 10],
                            align: ["left", "left", "right"],
                            search: [true, false, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idRecruitmentSources,
                                columns: [
                                    { column: row.name },
                                    { column: row.paied === true ? 'Sim' : 'Não' }
                                ],
                                actions: [
                                    { column: faEdit, title: "Editar", action: () => this.handleEditSource(row.idRecruitmentSources, row.name, row.paied) },
                                    { column: row.active ? faEyeSlash : faEye, title: row.active ? "Inativar" : "Ativar", action: () => this.handleDeleteSource(row.idRecruitmentSources, row.name, row.paied) }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewSource(e), icon: faPlus, title: "Nova Fonte de Recrutamento" }
                        ],
                        filters: [
                            {
                                id: "lstState",
                                type: "list",
                                data: this.state.listState,
                                label: "Estado",
                                multiple: false
                            }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possivel obter as fontes de recrutamento.", ToastTypes.Danger, false))
            })
    }

    handleEditSource(Id, Name, Status) {

        this.setState({
            modal: {
                isOpen: true,
                error: { Name: false }
            },
            ModalValues: { Name: Name, SourcePaied: Status },
            IdRecruitmentSourcesState: Id
        })
    }

    handleDeleteSource(Id, Name, Status) {

        let Active = !this.state.listState.find(x => x.selected === true).value

        if (!window.confirm('Tem a certeza que pretende ' + (Active ? 'ativar' : 'inativar') + ' a fonte com o nome: ' + Name + '?'))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                Name: Name,
                Paied: Status,
                Active: Active,
                IdRecruitmentSources: Id
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/recruitmentsources", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Fonte de recrutamento " + (Active ? 'ativada' : 'inativada') + " com sucesso", ToastTypes.Success, false))

                    var newArray = this.state.table.rows.filter(function (obj) { return obj.id !== Id; });
                    this.setState({ IdRecruitmentSourcesState: -1, table: { ...this.state.table, rows: newArray } })
                }
                this.props.dispatch(Loading(false))
            })
            .catch(_ => {
                this.props.dispatch(Toast("Não foi possível apagar a fonte de recrutamento!", ToastTypes.Danger, true))
            });
    }

    handleNewSource() {
        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: { Name: false }
            },
            IdRecruitmentSourcesState: 0,
            ModalValues: { Name: '', SourcePaied: true }
        })
    }

    handleCloseModal() {
        this.setState({
            modal: {
                isOpen: false,
                error: { Name: false }
            }
        });
    }

    handleOnChangeSource(e) {

        let id = e.target.id
        let value = e.target.value

        let auxErrorObj = this.state.modal.error
        auxErrorObj[id] = false

        if (e.target.type === "checkbox") {
            value = e.currentTarget.checked
        }

        this.setState({ ModalValues: { ...this.state.ModalValues, [id]: value }, modal: { ...this.state.modal, error: auxErrorObj } })
    }

    handleFilter(data) {
        this.bindGrid(data[0])
    }

    handleSaveSource() {

        let modal = { ...this.state.modal }

        modal.error.Name = this.state.ModalValues.Name === ''

        if (modal.error.Name) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                Name: this.state.ModalValues.Name,
                Paied: this.state.ModalValues.SourcePaied,
                Active: true,
                IdRecruitmentSources: this.state.IdRecruitmentSourcesState
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/recruitmentsources", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (json === 0) {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, false))
                }
                else if (json === -1) {
                    this.props.dispatch(Toast("O nome já se encontra atribuído numa fonte ativa / inativa", ToastTypes.Warning, false))
                    return
                }
                else {
                    this.props.dispatch(Toast("Fonte de recrutamento gravado com sucesso", ToastTypes.Success, false))

                    this.bindGrid(this.state.listState.find(x => x.selected === true).value)

                    this.setState({
                        modal: {
                            isOpen: false,
                            error: {
                                Name: false
                            }
                        },
                        IdRecruitmentSourcesState: 0
                    })
                }

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
            })
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.handleCloseModal}>
                    <ModalHeader toggle={this.handleCloseModal}>Fonte de Recrutamento</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label>Nome</Label>
                                <Input id="Name" type="text" defaultValue={this.state.ModalValues.Name} invalid={this.state.modal.error.Name} className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleOnChangeSource(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Paga?</Label>
                                <br />
                                <Input type="switch" id="SourcePaied" name="customSwitch" checked={this.state.ModalValues.SourcePaied} onChange={(e) => this.handleOnChangeSource(e)} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSaveSource(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(RecruitmentSources)