import React, { Component } from 'react';
import { Container, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import NavMenu from './NavMenu';
import { Loading } from './global/Loading';
import { connect } from "react-redux"
import '../css/Layout.css'
import Alerts from './global/Alerts';
import { path } from '../redux/MainSlice';

class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props)

        this.AppName = "Centro de Recrutamento"

        this.state = {
            showLeftMenu: true,
            pageTitle: ''
        }

        this.toggleLeftMenu = this.toggleLeftMenu.bind(this)
        this.handlePageTitle = this.handlePageTitle.bind(this)
    }

    toggleLeftMenu() {
        this.setState(prevState => {
            return { showLeftMenu: !prevState.showLeftMenu }
        })
    }

    handlePageTitle(title) {
        this.setState({ pageTitle: title })
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.props.path)
            this.props.dispatch(path(window.location.pathname));
    }

    render() {

        return (
            <div>
                <Loading show={this.props.loading} />
                <Alerts attr={this.props.alert} />
                <div id="background"></div>
                {
                    (this.props.showMenu !== undefined && this.props.showMenu === false) ?
                        null
                        :
                        <aside className={this.state.showLeftMenu ? 'left-panel hide-mobile' : 'left-panel'}>
                            <NavMenu onToggle={this.toggleLeftMenu} onHandlePageTitle={this.handlePageTitle} />
                        </aside>
                }

                <div className="right-panel">
                    <header className="header">
                        <div className="top-left">
                            <div id="menuToggle" onClick={this.toggleLeftMenu} className="menutoggle hide-desktop"><FontAwesomeIcon icon={faBars} /></div>
                            <NavLink className="navbar-brand hide-mobile" to="/" tag={Link}>
                                <img src={`${process.env.PUBLIC_URL}/images/McdLogo.png`} alt="Logo" width="40" height="40" />
                                <span style={{ paddingLeft: 15 + 'px', color: 'black', fontWeight: 'bold', fontSize: 20 + 'px' }}>{this.AppName}</span>
                            </NavLink>
                        </div>
                        <div className="top-center hide-desktop">{this.state.pageTitle}</div>
                        <div className="top-right">
                            <div style={{ float: 'right', fontSize: 15 + 'px' }}>
                            </div>
                        </div>
                    </header>
                    <Container className="content">
                        <div>
                            <div className={`card ${window.location.pathname !== "/" ? "" : " mobile"}`}>
                                <div className="card-body mobile">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </Container>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.main.loading,
        alert: state.main.alert,
        path: state.main.path
    };
}

export default connect(mapStateToProps)(Layout)
