import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading, formatDate } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSave, faFilter, faTrash } from '@fortawesome/pro-solid-svg-icons'
import DatePicker from "react-datepicker"

class RecruitmentSourceInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            profileId: props.profileId,
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            listYears: [],
            listMonths: [],
            listResourceInfo: [],
            listInternalCategory: [],
            listMarkets: [],
            listDepartments: [],
            modal: {
                isOpen: false,
                IdRecruitmentSources: -1,
                BeginDate: '',
                EndDate: '',
                Budget: '',
                IdInternalCategory: '',
                TotalEmployee: '',
                AdTitle: '',
                marketId: -1,
                departmentId: -1,
                error: {
                    IdRecruitmentSources: false,
                    BeginDate: false,
                    EndDate: false,
                    Budget: false,
                    IdInternalCategory: false,
                    TotalEmployee: false,
                    AdTitle: false,
                    marketId: false,
                    departmentId: false
                }
            },
            objData: { idRecruitmentSourcesInfo: 0, IdRecruitmentSources: -1, BeginDate: '', EndDate: '', Budget: '', IdInternalCategory: -1, TotalEmployee: '', AdTitle: '', marketId: -1, departmentId: -1 },
            openMarketDiv: 'none',
            openDepartmentDiv: 'none'
        }

        this.handleNewSourceInfo = this.handleNewSourceInfo.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleEditSourceInfo = this.handleEditSourceInfo.bind(this)
        this.handleSaveSourceInfo = this.handleSaveSourceInfo.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
    }

    componentDidMount() {

        var strRecruitmentSources = ''

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/recruitmentsourcesinfofilterdata?ProfileId=" + this.state.profileId, requestOptions)
            .then((response) => response.json())
            .then(data => {

                let years = data.years.map(result => {
                    return { value: result, text: result, selected: new Date().getFullYear() === result ? true : false }
                });

                let months = data.months.map((result, index) => {
                    return { value: index, text: result, selected: new Date().getMonth() === (index - 1) ? true : false }
                });

                let recruitmentSources = data.recruitmentSource.map(result => {
                    strRecruitmentSources += result.idRecruitmentSources.toString() + ','
                    return { value: result.idRecruitmentSources, text: result.name, selected: true }
                });

                let internalCategory = data.internalCategory.map(result => {
                    return {
                        value: result.idInternalCategory, text: result.officeFlag ? result.name + " (Office)" : result.name + " (Restaurante)", selected: false }
                });

                let markets = data.regions.map(result => {
                    return { value: result.key, text: result.value, selected: false }
                });
                markets = [{ value: -1, text: "Todos", selected: true }, ...markets];

                let departments = data.departments.map(result => {
                    return { value: result.number, text: result.name, selected: false }
                });
                departments = [{ value: -1, text: "Todos", selected: true }, ...departments];

                this.setState({ listYears: years, listResourceInfo: recruitmentSources, listInternalCategory: internalCategory, listMonths: months, listMarkets: markets, listDepartments: departments });

                this.bindGrid(years.find(x => x.selected === true).value, strRecruitmentSources.slice(0, -1),
                    months.find(x => x.selected === true).value, markets.find(x => x.selected === true).value, departments.find(x => x.selected === true).value)

                this.props.dispatch(Loading(false))
            }).catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os dados.", ToastTypes.Danger, false))
            });
    }

    bindGrid(year, recruitmentSources, month, marketId, departmentId) {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch(`/api/admin/sourceinfo?year=${year}&idRecruitmentSource=${recruitmentSources}&month=${month}&market=${marketId}&department=${departmentId}&status=${status}`, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
  
                const curDate = new Date();

                this.setState({
                    table: {
                        title: "Dados",
                        columns: {
                            title: ["Fonte", "Mercado/Dep.", "Função", "Anúncio", "Data de Início", "Data de Fim", "Custo", "Total", ""],
                            width: [10, 10, 15, 25, 10, 10, 5, 5, 10],
                            align: ["left", "left", "left", "left", "center", "center", "right", "right", "right"],
                            search: [true, true, true, true, true, true, true, true, false]
                        },
                        rows: json.map((row) => {

                            let marketName = this.state.listMarkets.find(f => f.value == row.marketId).text;
                            let departmentName = this.state.listDepartments.find(f => f.value == row.departmentId).text;

                            return {
                                id: row.idRecruitmentSourcesInfo,
                                columns: [
                                    { column: row.recruitmentSourcesName, color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },

                                    row.marketId !== -1
                                        ?
                                    { column: marketName, color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null }
                                        :
                                    { column: departmentName, color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },

                                    { column: row.internalCategoryName, color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },
                                    {
                                        column: row.adTitle !== null ? screen.width > 1600 ? row.adTitle.length > 30 ? row.adTitle.substr(0, 65) + "..." : row.adTitle : row.adTitle.length > 30 ? row.adTitle.substr(0, 30) + "..." : row.adTitle : null,
                                        tooltip: row.adTitle !== null ? row.adTitle : '',
                                        color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null
                                    },
                                    { column: formatDate(row.beginDate), color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },
                                    { column: formatDate(row.endDate), color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },
                                    { column: row.budget.toString() + '€', color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },
                                    { column: row.totalEmployees, color: !(curDate >= new Date(row.beginDate) && curDate <= new Date(row.endDate)) ? "grey" : null },
                                ],
                                actions: [
                                    {
                                        column: faEdit, title: "Editar", action: (e) => this.handleEditSourceInfo(e, row.idRecruitmentSourcesInfo, row.idRecruitmentSources,
                                            row.beginDate, row.endDate, row.budget, row.idInternalCategory, row.totalEmployees, row.adTitle, row.marketId, row.departmentId)
                                    },
                                    {
                                        column: faTrash, title: "Apagar", action: (e) => this.handleDeleteSourceInfo(row.idRecruitmentSourcesInfo, row.recruitmentSourcesName,
                                            row.beginDate, row.endDate, row.idRecruitmentSources, row.budget, row.idInternalCategory, row.totalEmployees)
                                    }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewSourceInfo(e), icon: faPlus, title: "Novos Dados" }
                        ],
                        filters: [
                            { id: "listYears", type: "list", data: this.state.listYears, label: "Ano", multiple: false },
                            { id: "listMonths", type: "list", data: this.state.listMonths, label: "Mês", multiple: false },
                            { id: "listMarkets", type: "list", data: this.state.listMarkets, label: "Mercado", multiple: false },
                            { id: "listDepartments", type: "list", data: this.state.listDepartments, label: "Departamento", multiple: false },
                            { id: "listResourceInfo", type: "list", data: this.state.listResourceInfo, label: "Fontes de Recrutamento", multiple: true }
                        ]
                    }
                })
            })
            .catch((error) => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possivel obter as fontes de recrutamento22.", ToastTypes.Danger, false))
            })
    }

    handleCloseModal() {
        this.setState({
            modal: {
                isOpen: false,
                error: {
                    IdRecruitmentSources: false,
                    BeginDate: false,
                    EndDate: false,
                    Budget: false,
                    IdInternalCategory: false,
                    TotalEmployee: false,
                    AdTitle: false,
                    marketId: false,
                    departmentId: false
                }
            }
        })
    }

    handleNewSourceInfo(e) {
        e.preventDefault();

        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: {
                    IdRecruitmentSources: false,
                    BeginDate: false,
                    EndDate: false,
                    Budget: false,
                    IdInternalCategory: false,
                    TotalEmployee: false,
                    AdTitle: false,
                    marketId: false,
                    departmentId: false
                }
            },
            objData: { idRecruitmentSourcesInfo: 0, IdRecruitmentSources: -1, Budget: '', BeginDate: '', EndDate: '', IdInternalCategory: -1, TotalEmployee: '', marketId: -1, departmentId: -1 }
        })
    }

    handleEditSourceInfo(e, idRecruitmentSourcesInfo, IdRecruitmentSources, BeginDate, EndDate, Budget, idInternalCategory, totalEmployees, AdTitle, marketId, departmentId) {
        e.preventDefault();

        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: {
                    IdRecruitmentSources: false,
                    BeginDate: false,
                    EndDate: false,
                    Budget: false,
                    IdInternalCategory: false,
                    TotalEmployee: false,
                    AdTitle: false,
                    marketId: false,
                    departmentId: false
                }
            },
            objData: {
                idRecruitmentSourcesInfo: idRecruitmentSourcesInfo, IdRecruitmentSources: IdRecruitmentSources, Budget: Budget, BeginDate: new Date(BeginDate).getTime(),
                EndDate: new Date(EndDate).getTime(), IdInternalCategory: idInternalCategory, TotalEmployee: totalEmployees, AdTitle: AdTitle, marketId: marketId, departmentId: departmentId
            },
            openMarketDiv: parseInt(marketId) === -1 ? 'none' : 'inline',
            openDepartmentDiv: parseInt(departmentId) === -1 ? 'none' : 'inline'
        })
    }

    handleDeleteSourceInfo(idRecruitmentSourcesInfo, RecruitmentSourcesName, BeginDate, EndDate, IdRecruitmentSources, Budget, idInternalCategory, totalEmployees) {

        if (!window.confirm('Tem a certeza que pretende apagar o registo para a fonte de recrutamento: ' + RecruitmentSourcesName + ' com as datas de ' +
            formatDate(BeginDate) + ' a ' + formatDate(EndDate)))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdRecruitmentSourcesInfo: idRecruitmentSourcesInfo,
                IdRecruitmentSources: IdRecruitmentSources,
                BeginDate: BeginDate,
                EndDate: EndDate,
                Budget: Budget,
                TotalEmployees: totalEmployees,
                IdInternalCategory: idInternalCategory
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/sourceinfo", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Registo apagado com sucesso!", ToastTypes.Success, false))

                    var newArray = this.state.table.rows.filter(function (obj) {
                        return obj.id !== idRecruitmentSourcesInfo;
                    });
                    this.setState({ table: { ...this.state.table, rows: newArray } })
                }
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível apagar !", ToastTypes.Danger, true))
            });
    }

    handleFilter(data) {

        var idRecruitmentSources = ''
        var selected = false
        var recruitmentSources = []

        let years = this.state.listYears.map(result => {
            selected = false
            if (parseInt(result.value) === parseInt(data[0]))
                selected = true
            return { value: result.value, text: result.text, selected: selected }
        });

        let months = this.state.listMonths.map(result => {
            selected = false
            if (parseInt(result.value) === parseInt(data[1]))
                selected = true
            return { value: result.value, text: result.text, selected: selected }
        });

        for (var i = 0; i <= data[4].length - 1; i++) {
            idRecruitmentSources += data[4][i] + ','
        }

        recruitmentSources = this.state.listResourceInfo.map(result => {
            return { value: result.value, text: result.text, selected: data[4].find(f => parseInt(f) == parseInt(result.value)) !== undefined }
        });

        let markets = this.state.listMarkets.map(result => {
            return { value: result.value, text: result.text, selected: parseInt(result.value) === parseInt(data[2]) }
        });

        let departments = this.state.listDepartments.map(result => {
            return { value: result.value, text: result.text, selected: parseInt(result.value) === parseInt(data[3]) }
        });

        this.bindGrid(parseInt(data[0]), idRecruitmentSources.slice(0, -1), parseInt(data[1]), parseInt(data[2]), parseInt(data[3]))
        this.setState({ listYears: years, listResourceInfo: recruitmentSources, listMonths: months, listMarkets: markets, listDepartments: departments });
    }

    handleChangeForm(e, isDate, id) {

        let dataValue = isDate ? e : e.target.value
        let auxid = isDate ? id : e.target.id

        let auxErrorObj = this.state.modal.error
        auxErrorObj[auxid] = false

        this.setState({
            objData: { ...this.state.objData, [auxid]: isDate ? Date.parse(dataValue) : dataValue }, 
            modal: { ...this.state.modal, error: auxErrorObj}
        })
            
    }

    handleChangeInternalCategory(e) {

        let dataValue = e.target.value
        let auxid = e.target.id

        var objData = {}

        let valueText = ''
        valueText = e.target.options[e.target.selectedIndex].text
        objData = { ...this.state.objData, 'IdInternalCategory': dataValue, 'departmentId': -1, 'marketId': -1 }

        let auxErrorObj = this.state.modal.error
        auxErrorObj[auxid] = false

        this.setState({
            objData: objData,
            modal: { ...this.state.modal, error: auxErrorObj },
            openMarketDiv: valueText.toLowerCase().indexOf("restaurante") >= 0 || auxid == 'marketId' ? 'inline' : 'none',
            openDepartmentDiv: valueText.toLowerCase().indexOf("office") >= 0 || auxid == 'departmentId' ? 'inline' : 'none'
        })

    }

    handleSaveSourceInfo() {

        let modal = { ...this.state.modal }

        modal.error.IdRecruitmentSources = this.state.objData.IdRecruitmentSources === -1
        modal.error.BeginDate = this.state.objData.BeginDate === ''
        modal.error.EndDate = this.state.objData.EndDate === ''
        modal.error.Budget = this.state.objData.Budget === ''
        modal.error.TotalEmployee = this.state.objData.TotalEmployee === ''
        modal.error.IdInternalCategory = this.state.objData.IdInternalCategory === -1
        modal.error.marketId = this.state.objData.marketId === -1 && this.state.openMarketDiv == 'inline'
        modal.error.departmentId = this.state.objData.departmentId === -1 && this.state.openDepartmentDiv == 'inline'

        var idRecruitmentSources = ''

        if (modal.error.IdRecruitmentSources || modal.error.BeginDate || modal.error.EndDate || modal.error.Budget || modal.error.TotalEmployee ||
            modal.error.IdInternalCategory || modal.error.marketId || modal.error.departmentId) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        if (new Date(this.state.objData.BeginDate) > new Date(this.state.objData.EndDate)) {
            this.props.dispatch(Toast("A data de início tem de ser inferior à data de fim", ToastTypes.Warning, true))
            return
        }

        this.state.listResourceInfo.filter(a => a.selected === true).map((row) => {
            return (
                idRecruitmentSources += row.value.toString() + ','
            )
        })

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdRecruitmentSourcesInfo: this.state.objData.idRecruitmentSourcesInfo,
                IdRecruitmentSources: this.state.objData.IdRecruitmentSources,
                BeginDate: formatDate(new Date(this.state.objData.BeginDate)),
                EndDate: formatDate(new Date(this.state.objData.EndDate)),
                Budget: this.state.objData.Budget,
                TotalEmployees: this.state.objData.TotalEmployee,
                IdInternalCategory: this.state.objData.IdInternalCategory,
                AdTitle: this.state.objData.AdTitle,
                marketId: this.state.objData.marketId,
                departmentId: this.state.objData.departmentId
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/sourceinfo", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (parseInt(json.id) === -1 && json.errorMessage === '') {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, false))
                }
                else if (json.errorMessage !== '') {
                    this.props.dispatch(Toast(json.errorMessage, ToastTypes.Warning, false))
                    return
                }
                else {

                    this.bindGrid(this.state.listYears.find(x => x.selected === true).value, idRecruitmentSources.slice(0, -1),
                        this.state.listMonths.find(x => x.selected === true).value, this.state.listMarkets.find(x => x.selected === true).value, this.state.listDepartments.find(x => x.selected === true).value)

                    this.setState({
                        objData: { idRecruitmentSourcesInfo: 0, IdRecruitmentSources: -1, Budget: '', BeginDate: '', EndDate: '', IdInternalCategory: -1, TotalEmployee: '', marketId: -1, departmentId: -1 },
                        modal: {
                            isOpen: !this.state.modal.isOpen,
                            error: {
                                IdRecruitmentSources: false,
                                BeginDate: false,
                                EndDate: false,
                                Budget: false,
                                IdInternalCategory: false,
                                TotalEmployee: false,
                                AdTitle: false,
                                marketId: false,
                                departmentId: false
                            }
                        }
                    })

                    this.props.dispatch(Toast("Informação gravada com sucesso", ToastTypes.Success, false))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true)));
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.handleCloseModal}>
                    <ModalHeader toggle={this.handleCloseModal}>Fonte de Recrutamento</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="6">
                                <Label>Fonte de Recrutamento</Label>
                                <Input type="select" id="IdRecruitmentSources" defaultValue={this.state.objData.IdRecruitmentSources} invalid={this.state.modal.error.IdRecruitmentSources}
                                    onChange={(e) => this.handleChangeForm(e, false)}>
                                    <option key="-1" value="-1"></option>
                                    {this.state.listResourceInfo.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                            <Col xs="6">
                                <Label>Custo</Label>
                                <Input id="Budget" defaultValue={this.state.objData.Budget} type="number" invalid={this.state.modal.error.Budget} className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Label>Data de Início</Label>
                                <DatePicker selected={this.state.objData.BeginDate} showMonthDropdown showYearDropdown id="BeginDate"
                                    showPopperArrow={false} dateFormat="yyyy-MM-dd" todayButton="Hoje" autoComplete="off" className={`form-control ${this.state.modal.error.BeginDate ? "is-invalid" : ""}`}
                                    onChange={(e) => this.handleChangeForm(e, true, 'BeginDate')}>
                                </DatePicker>
                            </Col>
                            <Col xs="6">
                                <Label>Data de Fim</Label>
                                <DatePicker selected={this.state.objData.EndDate} showMonthDropdown showYearDropdown id="EndDate"
                                    showPopperArrow={false} dateFormat="yyyy-MM-dd" todayButton="Hoje" autoComplete="off" className={`form-control ${this.state.modal.error.EndDate ? "is-invalid" : ""}`}
                                    onChange={(e) => this.handleChangeForm(e, true, 'EndDate')}>
                                </DatePicker>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Label>Função</Label>
                                <Input type="select" id="IdInternalCategory" defaultValue={this.state.objData.IdInternalCategory} invalid={this.state.modal.error.IdInternalCategory}
                                    onChange={(e) => this.handleChangeInternalCategory(e)}>
                                    <option key="-1" value="-1"></option>
                                    {
                                        this.state.listInternalCategory.map(v => {
                                            return (<option key={v.value} value={v.value}>{v.text}</option>)
                                        })
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Label>Total Candidaturas</Label>
                                <Input id="TotalEmployee" defaultValue={this.state.objData.TotalEmployee} invalid={this.state.modal.error.TotalEmployee} type="number" className="form-control"
                                    autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                            <Col xs="6" style={{ display: this.state.openMarketDiv  }}>
                                <Label>Mercado</Label>
                                <Input type="select" id="marketId" value={this.state.objData.marketId} invalid={this.state.modal.error.marketId}
                                    onChange={(e) => this.handleChangeForm(e, false)}>
                                    <option key="-1" value="-1"></option>
                                    {this.state.listMarkets.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                            <Col xs="6" style={{ display: this.state.openDepartmentDiv }}>
                                <Label>Departamento</Label>
                                <Input type="select" id="departmentId" value={this.state.objData.departmentId} invalid={this.state.modal.error.departmentId}
                                    onChange={(e) => this.handleChangeForm(e, false)}>
                                    <option key="-1" value="-1"></option>
                                    {this.state.listDepartments.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs="12">
                                <Label>Título do Anúncio</Label>
                                <textarea id="AdTitle" defaultValue={this.state.objData.AdTitle} type="text" className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSaveSourceInfo(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(RecruitmentSourceInfo)
