import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Loading, Toast, ToastTypes, formatDateHours } from "../../global/Utils"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Container, Input, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter, Row, Col } from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faMinus } from '@fortawesome/pro-solid-svg-icons'

const Notes = (props) => {

    const [idCandidate] = useState(props.idCandidate);
    const [ObjNotes, setObjNotes] = useState({});

    const [grid, setGrid] = useState({
        table: {
            title: "",
            columns: { title: [], width: [], align: [], search: [] },
            rows: [],
            pagination: true,
            actions: []
        }
    });

    const [modal, setModal] = useState({
        isOpen: false,
        idNote: 0,
        noteDesc: '',
        error: false
    });

    useEffect(() => {
        LoadNotes();
    }, []);

    function toggle() {
        setModal({ ...modal, isOpen: !modal.isOpen })
    }

    function LoadNotes() {

        props.dispatch(Loading(true))

        var arrNotes = []

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/notes?idcandidate=" + idCandidate, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                json.forEach(a => {
                    arrNotes.push({ idNote: a.idNote, idCandidate: a.idCandidate, description: a.description, logDate: a.logDate })
                })

                setObjNotes(arrNotes)

                props.dispatch(Loading(false))

            })
            .catch((error) => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel obter as notas do candidato.", ToastTypes.Danger, false))
            })

    }

    function handleEditNote(idNote, description) {

        setModal({
            isOpen: true,
            idNote: parseInt(idNote),
            noteDesc: description,
            error: false
        })

    }

    function handleDeleteNote(idNote) {

        if (!window.confirm('Tem a certeza que pretende eliminar a nota do candidato?'))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/notes?idNote=" + idNote, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                console.log(json)

                if (json) {

                    props.dispatch(Loading(false))

                    props.dispatch(Toast("Nota eliminada com sucesso.", ToastTypes.Success, false))

                    setModal({
                        isOpen: false,
                        idNote: 0,
                        noteDesc: '',
                        error: false
                    })

                    LoadNotes()
                }

            })
            .catch((error) => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel eliminar a nota do candidato.", ToastTypes.Danger, false))
            })

    }

    function handleChangeNote(e) {

        let dataValue = e.target.value
        let id = e.target.id

        setModal({
            isOpen: true,
            idNote: modal.idNote,
            noteDesc: dataValue,
            error: dataValue === '' ? true : false
        })
        
    }

    function handleNewNote() {

        setModal({
            isOpen: true,
            idNote: 0,
            noteDesc: '',
            error: false
        })

    }

    function handleSaveNote() {

        if (modal.error)
            return

        props.dispatch(Loading(true))

        var noteDesc = modal.noteDesc
        var idNote = modal.idNote

        if (noteDesc.length === 0) {
            props.dispatch(Toast("Nota vazia. Tente novamente.", ToastTypes.Danger, false))
            return
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/notes?idcandidate=" + idCandidate + "&idNote=" + idNote + "&NoteDesc=" + noteDesc, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (json) {

                    props.dispatch(Loading(false))

                    props.dispatch(Toast("Nota gravada com sucesso.", ToastTypes.Success, false))

                    setModal({
                        isOpen: false,
                        idNote: 0,
                        noteDesc: '',
                        error: false
                    })

                    LoadNotes()
                }

            })
            .catch((error) => {
                props.dispatch(Toast("Não foi possivel gravar a nota do candidato.", ToastTypes.Danger, false))
            })

    }

    if (ObjNotes === undefined || ObjNotes.length === undefined || ObjNotes.length === 0) {
        return (
            <>
                <Row>
                    <div style={{ paddingRight: 40 + 'px', textAlign: "right" }}>
                        <FontAwesomeIcon onClick={() => handleNewNote()} title="Inserir" className="text-muted" style={{ cursor: "pointer" }} id="add" icon={faPlus} />
                    </div>
                </Row>

                <Modal isOpen={modal.isOpen} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle}>Nota</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Input type="textarea" rows="5" id="noteDescription" maxLength="500" invalid={modal.error} value={modal.noteDesc} onChange={(e) => handleChangeNote(e)} />
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleSaveNote()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
    else {
        return (
            <>
                <Row>
                    <div style={{ paddingRight: 40 + 'px', textAlign: "right" }}>
                        <FontAwesomeIcon onClick={() => handleNewNote()} title="Inserir" className="text-muted" style={{ cursor: "pointer" }} id="add" icon={faPlus} />
                    </div>
                </Row>

                {ObjNotes.map((item, key) => {
                    return (

                        <Card id={"card_" + item.idNote} key={"card_" + item.idNote} style={{ marginTop: 10 + "px" }}>
                            <CardBody>
                                <CardTitle style={{ fontWeight: "bold" }}> {formatDateHours(item.logDate)} </CardTitle>
                                <CardText>
                                    {item.description}
                                </CardText>
                            </CardBody>
                            <CardFooter>
                                <div style={{ paddingRight: 10 + 'px', textAlign: "right" }}>
                                    <FontAwesomeIcon onClick={() => handleDeleteNote(item.idNote)} title="Eliminar" className="text-muted" style={{ cursor: "pointer" }} id="add" icon={faMinus} />
                                </div>
                            </CardFooter>
                        </Card>
                    );

                })}

                <Modal isOpen={modal.isOpen} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle}>Nota</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Input type="textarea" rows="5" id="noteDescription" maxLength="500" invalid={modal.error} value={modal.noteDesc} onChange={(e) => handleChangeNote(e)} />
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleSaveNote()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }


}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Notes)