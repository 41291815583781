import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleEvent } from "../../redux/AgendaSlice";
import { formatDate } from "../global/Utils";

export const DayHeader = (props) => {
    const dispatch = useDispatch();

    const [currentDay, setCurrentDay] = useState();
    const size = props.source == "month" ? 24 : 40;

    useEffect(() => {
        const curDay = (new Date()).getDate();
        const curMonth = (new Date()).getMonth();

        if (curDay === props.day && curMonth === props.month)
            setCurrentDay(
                <div style={{ backgroundColor: "#3181ea", width: size + "px", height: size + "px", borderRadius: 50 + "%", lineHeight: size + "px", color: "white" }}>
                    {props.day}
                </div>)
        else
            setCurrentDay(<div style={{ width: size + "px", height: size + "px", lineHeight: size + "px" }}>{props.day}</div>)
    }, [props.day, props.month]);

    const handleNewEvent = () => {
        dispatch(handleEvent({ isOpen: true, eventId: 0, beginDate: formatDate(props.date), endDate: formatDate(props.date) }));
    }

    return (
        <div className={`agenda-${props.source}ly-day-header`} title="Novo evento" onClick={() => handleNewEvent()}>
            {currentDay}
        </div>
    )
}