import React from 'react'
import { useDispatch } from "react-redux"
import { Alert } from 'reactstrap'
import { unmountAlert } from "../../redux/MainSlice"

const Alerts = (props) => {
    const dispatch = useDispatch();

    if (!props.attr.show)
        return (<></>)

    if (!props.attr.fixed)
        setTimeout(function () {
            dispatch(unmountAlert())
        }, 5000)

    return (
        <div style={{ position: "fixed", right: 15 + "px", bottom: 5 + "px", width: 100 + "%", zIndex: 2050, display: "block" }}>
            {props.attr.fixed ?
                <Alert color={props.attr.color} fade={true} style={{ minWidth: 15 + "%", maxWidth: 30 + "%", float: "right", paddingTop: 20 + "px", paddingBottom: 20 + "px" }}
                    toggle={() => dispatch(unmountAlert())}>{props.attr.text}</Alert>
                :
                <Alert color={props.attr.color} fade={true} style={{ minWidth: 15 + "%", maxWidth: 30 + "%", float: "right", paddingTop: 20 + "px", paddingBottom: 20 + "px" }}>{props.attr.text}</Alert>
            }
        </div >
    );
}

export default Alerts