import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSave, faFilter, faEye, faEyeSlash, faFileWord, faFilePdf, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class Emails extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            listState: [
                { value: 1, text: "Ativo", selected: true },
                { value: 0, text: "Inativo", selected: false }
            ],
            modal: {
                isOpen: false,
                emailTags: '',
                name: '',
                subject: '',
                body: '',
                error: {
                    name: false,
                    subject: false,
                    body: false,
                    emailTypes: false
                }

            },
            ObjData: { emailTags: [], name: '', idEmail: 0, subject: '', body: '', state: true, emailTypes: [] },
            ObjFiles: [{ base64File: '', fileName: '', idFile: -1, extention: 'pdf', newFile: true, addedFile: false, deletedFile: false, changed: false }],
            imagePath: '/images/Plus.png'
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleNewEmail = this.handleNewEmail.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSaveEmail = this.handleSaveEmail.bind(this)
        this.setDivFiles = this.setDivFiles.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.handleEditEmail = this.handleEditEmail.bind(this)

        this.reactQuillRef = null
    }

    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']                                         // remove formatting button
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color', 'header', 'font', 'size', 'strike'
    ]

    componentDidMount() {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/emaildata", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {
                let objEmailTags = data.emailTag.map(result => { return { value: result.tagName, text: result.tagName, selected: false } });

                let objEmailTypes = data.emailType.map(result => { return { value: result.idEmailType, text: result.description, selected: false } });

                this.setState({ ObjData: { ...this.state.ObjData, emailTags: objEmailTags, emailTypes: objEmailTypes } })
            }).catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os dados.", ToastTypes.Danger, false))
            });

        this.bindGrid(this.state.listState.find(x => x.selected === true).value)
    }

    handleCloseModal() {
        this.setState({
            modal: {
                isOpen: false,
                error: { name: false, subject: false, body: false, emailTypes: false }
            }
        });
    }

    handleFilter(data) {
        this.bindGrid(parseInt(data[0]))
    }

    bindGrid(State) {

        State = parseInt(State) === 1 ? true : false

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/emailconfig?state=" + State, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Emails",
                        columns: {
                            title: ["Name", "Assunto", "Tipo de Email", ""],
                            width: [40, 30, 25, 5],
                            align: ["left", "left", "left", "right"],
                            search: [true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.email.idEmail,
                                columns: [
                                    { column: row.email.name },
                                    { column: row.email.subject },
                                    { column: row.email.emailTypeDescription }
                                ],
                                actions: [
                                    {
                                        column: faEdit, title: "Editar", action: () => this.handleEditEmail(row.email.idEmail, row.email.name, row.email.subject, row.email.body, row.email.state,
                                            row.emailAttaches, row.email.idEmailType)
                                    },
                                    {
                                        column: row.email.active ? faEyeSlash : faEye, title: row.email.active ? "Inativar" : "Ativar",
                                        action: () => this.handleDeleteEmail(row.email.idEmail, row.email.name, row.email.subject, row.email.body, row.email.idEmailType)
                                    }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewEmail(e), icon: faPlus, title: "Novo Email" }
                        ],
                        filters: [
                            { id: "lstState", type: "list", data: this.state.listState, label: "Estado", multiple: false }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possivel obter os dados.", ToastTypes.Danger, false)))
    }

    handleEditEmail(idEmail, name, subject, body, state, arrFiles, idEmailType) {

        var listFiles = arrFiles
        if (arrFiles.find(x => x.newFile === true) === undefined)
            listFiles.push({ base64File: '', fileName: '', idFile: 0, extention: '', newFile: true, addedFile: false, deletedFile: false, changed: false })

        var ObjEmailTypes = []
        this.state.ObjData.emailTypes.forEach(item => {
            ObjEmailTypes.push({ value: item.value, text: item.text, selected: parseInt(item.value) === idEmailType ? true : false })
        })
        this.setState({
            ObjFiles: listFiles,
            ObjData: { ...this.state.ObjData, name: name, idEmail: idEmail, subject: subject, body: body, state: state, emailTypes: ObjEmailTypes },
            modal: {
                isOpen: true,
                error: { name: false, subject: false, body: false, emailTypes: false }
            }
        })
    }

    handleDeleteEmail(idEmail, name, subject, body, idEmailType) {
        let Active = !this.state.listState.find(x => x.selected === true).value

        if (!window.confirm('Tem a certeza que pretende ' + (Active ? 'ativar' : 'inativar') + ' o email com o nome: ' + name))
            return;

        const requestOptions = {

            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdEmail: idEmail,
                Name: name,
                Subject: subject,
                Body: body,
                Active: Active,
                IdEmailType: idEmailType
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/emailconfig", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.bindGrid(this.state.listState.find(x => x.selected === true).value)

                    this.setState({ idEmail: 0, ObjData: { ...this.state.ObjData, idEmail: 0, subject: '', body: '' } })

                    this.props.dispatch(Toast("Email " + (Active ? 'ativado' : 'inativado') + " com sucesso", ToastTypes.Success, false))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível inativar o email!", ToastTypes.Danger, true)))
    }

    handleNewEmail() {

        var ObjEmailTypes = []
        this.state.ObjData.emailTypes.forEach(item => { ObjEmailTypes.push({ value: item.value, text: item.text, selected: false }) })

        this.setState({
            ObjFiles: [{ base64File: '', fileName: '', idFile: -1, extention: 'pdf', newFile: true, addedFile: false, deletedFile: false, changed: false }],
            ObjData: { ...this.state.ObjData, name: '', idEmail: 0, subject: '', body: '', state: true, emailTypes: ObjEmailTypes },
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: { name: false, subject: false, body: false, emailTypes: false }
            }
        })
    }

    handleChangeBody(e) {
        this.setState({ ObjData: { ...this.state.ObjData, body: e } })
    }

    handleChangeForm(e) {
        let dataValue = e.target.value
        let id = e.target.id

        let auxErrorObj = this.state.modal.error
        auxErrorObj[id] = false

        if (e.target.id === 'tag') {
            const editor = this.reactQuillRef.getEditor();
            const index = editor.getSelection() !== null ? editor.getSelection().index : null;

            if (index === null) {
                this.props.dispatch(Toast("Por favor indique uma localização para inserção da tag na caixa body", ToastTypes.Danger, true));
                return
            }

            editor.insertText(index, dataValue);
        }
        else if (e.target.id === 'emailTypes') {
            var ObjEmailTypes = []
            const element = document.getElementById(e.target.id)

            for (var i = 0; i < element.options.length; ++i) {
                if (parseInt(element[i].value) !== -1)
                    ObjEmailTypes.push({ value: parseInt(element[i].value), text: element[i].text, selected: parseInt(element[i].value) === parseInt(dataValue) ? true : false })
            }
            this.setState({ ObjData: { ...this.state.ObjData, emailTypes: ObjEmailTypes }, modal: { ...this.state.modal, error: auxErrorObj } })
        }
        else {
            this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, modal: { ...this.state.modal, error: auxErrorObj } })
        }
    }

    handleSaveEmail() {

        let modal = { ...this.state.modal }

        modal.error.name = this.state.ObjData.name === ''
        modal.error.subject = this.state.ObjData.subject === ''
        modal.error.body = this.state.ObjData.body === ''
        modal.error.emailTypes = this.state.ObjData.emailTypes.find(x => x.selected) === undefined

        if (modal.error.name || modal.error.subject || modal.error.emailTypes) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        if (modal.error.body) {
            this.props.dispatch(Toast("Por favor, preencha o campo de texto do email.", ToastTypes.Danger, false))
            return
        }

        var objEmail = {
            IdEmail: this.state.ObjData.idEmail,
            Name: this.state.ObjData.name,
            Subject: this.state.ObjData.subject,
            Body: this.state.ObjData.body,
            IdEmailType: this.state.ObjData.emailTypes.find(x => x.selected).value,
            Active: true
        }
        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                Email: objEmail,
                EmailAttaches: this.state.ObjFiles.filter(x => x.changed === true)
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/emailconfig", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (json === 0) {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, false))
                }
                else if (json === -1) {
                    this.props.dispatch(Toast("Já foi configurado um email para esta fase", ToastTypes.Warning, false))
                    return
                }
                else {
                    this.props.dispatch(Toast("Email gravado com sucesso", ToastTypes.Success, false))

                    this.bindGrid(this.state.listState.find(x => x.selected === true).value)

                    this.setState({
                        modal: {
                            isOpen: false,
                            error: { name: false, subject: false, body: false, emailTypes: false }
                        },
                        ObjData: { ...this.state.ObjData, idEmail: 0, Name: '', subject: '', body: '' }
                    })
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true)));
    }

    handleSendMail() {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/mail", requestOptions)
            .catch(_ => { this.props.dispatch(Toast("Não foi possível enviar o email!", ToastTypes.Danger, true)) })
    }

    handleChangeFile = (e) => {
        const file = e.target.files[0]

        if (file.type !== "application/msword" && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== "application/pdf") {
            this.props.dispatch(Toast("Só é permitido selecionar word ou pdf!", ToastTypes.Warning, false))
            return
        }
        if (file.size > 3145728) { // 3MB
            this.props.dispatch(Toast("O tamanho máximo do ficheiro são 3MB!", ToastTypes.Warning, false))
            return
        }

        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = (e) => {
            var base64File = fileReader.result.split(",")[1]
            var fileName = file.name
            var idFile = -1
            var extention = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'doc' : 'pdf'
            var arrFiles = this.state.ObjFiles.filter(a => a.newFile === false)

            arrFiles.forEach(x => {
                if (x.idFile < 0)
                    idFile = idFile - 1
            })

            arrFiles.push({ base64File: base64File, fileName: fileName, idFile: idFile, extention: extention, newFile: false, addedFile: true, deletedFile: false, changed: true })
            arrFiles.push({ base64File: '', fileName: '', idFile: 0, extention: '', newFile: true, addedFile: false, deletedFile: false, changed: false })

            this.setState({ ObjFiles: arrFiles })
        }
    }

    downloadFile(e, idFile) {
        e.preventDefault();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/emailfile?idFile=" + idFile, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '') {
                    this.props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
                    return
                }
                window.open(JSON.parse(json)[0].filePath);

                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
            })
    }

    deleteFile(e, idFile) {
        e.preventDefault();

        var arrFiles = []

        this.state.ObjFiles.filter(a => a.newFile === false).forEach(x => {
            if (x.idFile === idFile)
                arrFiles.push({ base64File: x.base64File, fileName: x.fileName, idFile: x.idFile, extention: x.extention, newFile: false, addedFile: false, deletedFile: true, changed: true })
            else
                arrFiles.push({ base64File: x.base64File, fileName: x.fileName, idFile: x.idFile, extention: x.extention, newFile: x.newFile, addedFile: x.addedFile, deletedFile: x.deletedFile, changed: x.changed })

        })
        arrFiles.push({ base64File: '', fileName: '', idFile: 0, extention: '', newFile: true, addedFile: false, deletedFile: false, changed: false })

        this.setState({ ObjFiles: arrFiles })
    }

    setDivFiles(indexAux) {
        var counter = -1
        return this.state.ObjFiles.filter(x => x.deletedFile === false).map((result, index) => {
            counter = counter + 1

            return (
                index >= indexAux && index <= indexAux + 5 ?
                    result.newFile ?
                        <div key={index} className="col-lg-2 image-upload">
                            <label id="lblFile" htmlFor="newFile" key={"lblFile" + result.idFile + '_' + indexAux} style={{ cursor: "pointer" }}>
                                <img src={this.state.imagePath} key={"img" + result.idFile + '_' + indexAux} alt="Adicionar Ficheiro" style={{ border: "1px solid #ced4da", height: 60 + 'px', width: 60 + 'px' }} />
                            </label>
                            <input id="newFile" type="file" key={"newFile" + result.idFile + '_' + indexAux} onChange={(e) => this.handleChangeFile(e)} />
                        </div>
                        :
                        <div key={index} className="col-lg-2">
                            <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                                <a href="#top" className="boxclose" onClick={(e) => this.deleteFile(e, result.idFile)} key={"closeBox" + result.idFile + "_" + indexAux} id={"closeBox" + result.idFile}>
                                    <FontAwesomeIcon icon={faTimesCircle} /></a>
                                <FontAwesomeIcon title={result.fileName} onClick={(e) =>
                                    this.downloadFile(e, result.idFile)} key={"fileDownload" + result.idFile + '_' + indexAux} id={"fileDownload" + result.idFile} style={{
                                        height: 36 + 'px', width: 57 + 'px',
                                        color: result.extention === 'pdf' ? 'red' : 'blue', paddingTop: 3 + 'px', cursor: 'pointer'
                                    }} icon={result.extention === 'pdf' ? faFilePdf : faFileWord} />
                            </div>
                        </div>
                    : null
            );
        });
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal size="xl" isOpen={this.state.modal.isOpen} toggle={this.handleCloseModal}>
                    <ModalHeader toggle={this.handleCloseModal}>Email</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label>Nome</Label>
                                <Input id="name" type="text" maxLength="50" defaultValue={this.state.ObjData.name} invalid={this.state.modal.error.name} className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Assunto</Label>
                                <Input id="subject" type="text" maxLength="50" defaultValue={this.state.ObjData.subject} invalid={this.state.modal.error.subject} className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Tag</Label>
                                <Input type="select" id="tag" defaultValue={-1} onChange={(e) => this.handleChangeForm(e)}>
                                    <option key="-1" value="-1"></option>
                                    {this.state.ObjData.emailTags.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                            <Col>
                                <Label>Tipos de Email</Label>
                                <Input type="select" id="emailTypes" defaultValue={this.state.ObjData.emailTypes.find(a => a.selected) !== undefined ? this.state.ObjData.emailTypes.find(a => a.selected).value : -1} invalid={this.state.modal.error.emailTypes} onChange={(e) => this.handleChangeForm(e)}>
                                    <option key="-1" value="-1"></option>
                                    {this.state.ObjData.emailTypes.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Body</Label>
                                <ReactQuill ref={(el) => { this.reactQuillRef = el }} id="body" value={this.state.ObjData.body}
                                    onChange={(e) => this.handleChangeBody(e)} modules={this.modules}
                                    formats={this.formats} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Ficheiros</Label>
                                {
                                    this.state.ObjFiles.filter(x => x.deletedFile === false).map((result, index) => {

                                        return (
                                            index % 6 === 0 ?
                                                <div className="row" key={"rowFile" + result.idFile}>{this.setDivFiles(index)}</div>
                                                :
                                                null
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSaveEmail(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(Emails)
