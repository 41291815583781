import React, { useState, useEffect } from "react"
import { Button, Input, Row, Col, Label, Container, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import { formatDateHours, Loading, Toast, ToastTypes } from "../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const AttemdanceDeclaration = (props) => {

    const [BeginDate, setBeginDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Location, setLocation] = useState([]);
    const [Emails, setEmails] = useState([]);
    const [ObjDataError, setObjDataError] = useState({ BeginDate: false, EndDate: false, Location: false, Emails: false });
    const [modalAttendance, setModalAttendance] = useState({ isOpen: props.isOpen });

    useEffect(() => {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        if (Emails.length === 0 || Location.length === 0) {
            props.dispatch(Loading(true))
        }

        if (Emails.length === 0)
            fetch("/api/candidates/emails", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    var objEmail = json.map(result => {
                        return { value: result.idEmail, text: result.name, selected: false }
                    });
                    setEmails(objEmail)
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))


        if (Location.length === 0)
            fetch("/api/candidates/places", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    var objLocation = json.map(result => {
                        return { value: result.idPlace, text: result.name, selected: false }
                    });
                    setLocation(objLocation)
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

    }, []);

    function handleChangeForm(e, DateId) {

        let dataValue
        if (DateId === 'BeginDate' || DateId === 'EndDate') {
            dataValue = e
            if (DateId === 'BeginDate') {
                setBeginDate(Date.parse(dataValue))
                setObjDataError({ ...ObjDataError, BeginDate: false })
            }
            else if (DateId === 'EndDate') {
                setEndDate(Date.parse(dataValue))
                setObjDataError({ ...ObjDataError, EndDate: false })
            }
        } else {

            const element = document.getElementById(e.target.id)
            var obj = []
            var objAux
            if (e.target.id === 'Location') {
                for (var i = 0; i < element.options.length; ++i) {
                    let ii = i;
                    objAux = Location.find(x => parseInt(x.value) === parseInt(element[ii].value))
                    if (objAux !== undefined) {
                        objAux.selected = element[ii].selected
                        obj.push(objAux)
                    }
                }
                setLocation(obj)
                setObjDataError({ ...ObjDataError, Location: false })
            }
            else {
                for (var b = 0; b < element.options.length; b++) {
                    let bb = b;
                    objAux = Emails.find(x => parseInt(x.value) === parseInt(element[bb].value))
                    if (objAux !== undefined) {
                        objAux.selected = element[bb].selected
                        obj.push(objAux)
                    }
                }
                setEmails(obj)
                setObjDataError({ ...ObjDataError, Emails: false })
            }
        }
    }

    function handleGenerateDeclaration() {

        let modal = { BeginDate: false, EndDate: false, Location: false, Emails: false }
        modal.BeginDate = BeginDate === ''
        modal.EndDate = EndDate === ''
        modal.Location = Location.find(a => a.selected) === undefined
        modal.Emails = Emails.find(a => a.selected) === undefined

        if (modal.BeginDate || modal.EndDate || modal.Location || modal.Emails)
            return setObjDataError(modal)

        if (new Date(BeginDate) > new Date(EndDate))
            return props.dispatch(Toast("A data de início tem de ser inferior à de fim", ToastTypes.Warning, true))

        var beginDate = formatDateHours(new Date(BeginDate))
        var endDate = formatDateHours(new Date(EndDate))
        var place = Location.find(a => a.selected).value

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                name: props.name,
                email: props.email,
                beginDate: beginDate,
                endDate: endDate,
                idPlace: place,
                idTemplateEmail: Emails.find(a => a.selected).value
            })
        }

        fetch("/api/candidates/attendancedeclaration/" + props.idCandidate, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '')
                    return props.dispatch(Toast("Não foi possível enviar a declaração de presença!", ToastTypes.Danger, true))

                props.dispatch(Toast("Declaração de presença enviada com sucesso!", ToastTypes.Success, true))
                props.handlerCheckFieldsAttendance(true)
            })
            .catch(() => props.dispatch(Toast("Não foi possível enviar a declaração de presença", ToastTypes.Danger, false)))
    }

    function toggleAttendance() {
        setModalAttendance({ isOpen: !modalAttendance.isOpen })
        props.toggle(!modalAttendance.isOpen)
    }

    return (
        <Modal isOpen={props.isOpen} toggle={toggleAttendance} size="ml">
            <ModalHeader toggle={toggleAttendance}></ModalHeader>
            <ModalBody>
                <Container>
                    <Row>
                        <Col>
                            <Label>Data de Início</Label>
                            <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={5} invalid={true} className={ObjDataError.BeginDate ? "is-invalid form-control" : "form-control"}
                                showMonthDropdown showYearDropdown id="BeginDate" showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off"
                                selected={BeginDate} onChange={(e) => handleChangeForm(e, "BeginDate")}>
                            </DatePicker>
                        </Col>
                        <Col>
                            <Label>Data de Fim</Label>
                            <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={5} className={ObjDataError.EndDate ? "is-invalid form-control" : "form-control"} showMonthDropdown
                                showYearDropdown id="EndDate" showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off" selected={EndDate}
                                onChange={(e) => handleChangeForm(e, "EndDate")}>
                            </DatePicker>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Template Email</Label>
                            <Input type="select" id="lstEmail" invalid={ObjDataError.Emails}
                                onChange={(e) => handleChangeForm(e)}>
                                <option key={-1} value={-1}></option>
                                {Emails.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                            </Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Local</Label>
                            <Input type="select" id="Location" invalid={ObjDataError.Location}
                                onChange={(e) => handleChangeForm(e)}>
                                <option key={-1} value={-1}></option>
                                {Location.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                            </Input>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" style={{ float: "right" }} onClick={() => handleGenerateDeclaration()}><FontAwesomeIcon icon={faEnvelope} /> Enviar</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(AttemdanceDeclaration)