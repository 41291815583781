import React, { useState, useEffect } from "react"
import { Button, Row, Col, Label, ButtonGroup, ButtonToolbar } from 'reactstrap'
import { Toast, ToastTypes, Loading } from "../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"

const Uniforms = (props) => {

    const [UniformData, setUniformData] = useState([]);
    useEffect(() => {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/uniforms?idCandidate=" + parseInt(props.idCandidate), requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                setUniformData(json)
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter a informação relativa às fardas.", ToastTypes.Danger, false)))

        props.dispatch(Loading(false))
        
    }, []);


    function buildSizes(objUniform) {

        return (
            <ButtonToolbar>
                {
                    objUniform.sizes.map((result, index) => {
                        return (
                            <ButtonGroup key={"ButtonGroup_" + result.id} className="me-2" style={{ marginLeft: 10 + 'px', minWidth: 50 + 'px', marginTop: 10 + 'px' }}>
                                <Button disabled outline key={"btnSize_" + result.id} className={result.selected ? 'UniformsButtonSelected' : null} >{result.size}</Button>
                            </ButtonGroup>
                        )
                    })
                }
            </ButtonToolbar>
        )
    }

    return (
        <>
            {
                UniformData.map((result, index) => {
                    return (
                        <div key={"dvUniform_" + result.idUniform}>
                            <Row key={"rowUnifor_" + result.idUniform} style={{ marginTop: 30 + 'px' }}>
                                <Col key={"colUnifor_" + result.idUniform} xs="12" sm="12" md="12" lg="12">
                                    <Label key={"lblDesc_" + result.idUniform}>
                                        <b>{result.description}</b>
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {buildSizes(result)}
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }
        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })
export default connect(mapStateToProps)(Uniforms)