import React, { useState, useEffect } from "react"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label, Container } from 'reactstrap'
import { Loading, Toast, ToastTypes, maskPostalCode, CheckPostalCode, CheckEmail, IsValidNIF, CalcWeekHoursPercentage, CalcMonthlyHoursPersonalData,DateUtc } from "../../global/Utils"
import { connect, useSelector, useDispatch } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faSave } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Dependents from "../CandidatesDetail/Dependents"
import { dependents, individualRecordError, hiredRedux } from "../../../redux/MainSlice";
import { costCenter } from "../../../redux/CandidateSlice";

const PersonalData = (props) => {

    const [ObjPersonalData, setObjPersonalData] = useState({});
    const [ObjPersonalDataError, setObjPersonalDataError] = useState({
        address: false,
        birthDate: false,
        bornIn: false,
        deficientInFamily: false,
        disability: false,
        disabilityDegree: false,
        documentExpirationDate: false,
        documentNumber: false,
        email: false,
        fiscalNumber: false,
        gender: false,
        iban: false,
        idCandidate: 1,
        idCitizenship: false,
        idCivilState: false,
        idDocumentType: false,
        idLiteraryQualification: false,
        idRegionalCenter: false,
        idStudyArea: false,
        name: false,
        niss: false,
        numberOfDependents: false,
        numberOfHolders: false,
        phoneNumber: false,
        postalCode: false,
        postalLocation: false,
        spouseBirthDate: false,
        spouseName: false,
        swift: false,
        syndicate: false
    });
    const [ObjPersonalDataDetailError, setObjPersonalDataDetailError] = useState({
        admissionDate: false,
        contractEndDate: false,
        contractNumOfDays: false,
        contractNumOfMonths: false,
        costCenter: false,
        idCompanyPosition: false,
        idContractJustification: false,
        idInternalCategory: false,
        idProfessionalCategory: false,
        idCppCategory: false,
        idRegime: false,
        salary: false,
        workload: false,
        workloadSalary: false,
        idCppCategory: false,
        weekHoursPercentage: false,
        weekDays: false,
        monthlyHours: false
    });
    const [ObjWorkFlowDetailData, setObjWorkFlowDetailData] = useState({});
    const [idCandidate] = useState(props.idCandidate);
    const [profileId] = useState(props.profileId);
    const [ObjCombos, setObjCombos] = useState({
        documentTypes: [],
        citizenShips: [],
        genders: [],
        syndicates: [],
        numberOfHolders: [],
        civilStates: [],
        studyAreas: [],
        literayHabilitations: [],
        companyPositions: [],
        regimes: [],
        costCenters: [],
        regionalCenters: [],
        internalCategories: [],
        professionalCategories: [],
        internalJoinProfissionalCategories: [],
        finalProfessionalCategories: [],
        companyPositionMonths: [],
        companyPositionDays: [],
        companyPositionJustification: [],
        waitLoad: true,
        cppCategories: [],
        internalJoinCppCategories: [],
        finalCppCategories: []
    })
    const [FinalProfessionalCategory, setFinalProfessionalCategory] = useState([]);
    const [FinalCppCategory, setFinalCppCategory] = useState([]);
    const [modalJustification, setModalJustification] = useState({ isOpen: false });
    const [modalDependents, setModalDependents] = useState(false);
    const [openSpouseDiv, setopenSpouseDiv] = useState("none")
    const [openContractDiv, setopenContractDiv] = useState("none")
    const dispatch = useDispatch()
    const reduxDependents = useSelector(state => state.main.dependents)
    const reduxStateClose = useSelector(state => state.main.individualRecordError)
    const [hired, setHired] = useState(false)
    const [checkFieldsHired, setCheckFieldsHired] = useState(false)
    const reduxHired = useSelector(state => state.main.hiredRedux);
    const localCostCenter = useSelector(state => state.candidate.costCenter);
    const localCostCenters = useSelector(state => state.candidate.lstCostCenter);

    useEffect(() => {
        LoadComboData();
    }, []);

    useEffect(() => {
        if (ObjCombos.waitLoad)
            return;

        LoadData();
        LoadDependents();
    }, [ObjCombos.waitLoad]);

    useEffect(() => {
        if (reduxStateClose !== undefined) {
            setObjPersonalDataError(reduxStateClose.personalData)
            setObjPersonalDataDetailError(reduxStateClose.workflow)
            setCheckFieldsHired(true)
            dispatch(individualRecordError(undefined));
        }
    }, [reduxStateClose]);

    useEffect(() => {
        if (reduxHired !== undefined) {
            setHired(reduxHired)
            dispatch(hiredRedux(undefined));
        }
    }, [reduxHired]);

    useEffect(() => {
        if (reduxDependents == undefined)
            return;

        setModalDependents(false);
    }, [reduxDependents]);

    function LoadData() {

        props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/candidatepersonaldata?idCandidate=" + idCandidate, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                setHired(json.generalState === 2)

                //if (json.personalData.iban === '')
                //    json.personalData.iban = 'PT50'

                if (json.personalData.postalCode !== null && json.personalData.postalCode !== undefined)
                    json.personalData.postalCode = maskPostalCode(json.personalData.postalCode)

                if (json.personalData.documentEmissionDate == null)
                    json.personalData.documentEmissionDate = "";
                if (json.personalData.documentExpirationDate == null)
                    json.personalData.documentExpirationDate = "";
                if (json.personalData.drivingLicenseDate == null)
                    json.personalData.drivingLicenseDate = "";
                if (json.personalData.spouseBirthDate == null)
                    json.personalData.spouseBirthDate = "";
                if (json.personalData.idDocumentType == null)
                    json.personalData.idDocumentType = "";

                json.personalData.gender = json.personalData.gender == null ? "" : json.personalData.gender * 1;

                setObjPersonalData(json.personalData);

                if (parseInt(json.personalData.idCivilState) === 1 || parseInt(json.personalData.idCivilState) === 7)
                    setopenSpouseDiv('inline');
                else
                    setopenSpouseDiv('none');

                setObjWorkFlowDetailData(json.workflow);

                if (json.workflow.length > 0) {
                    //setHired(json.workflow.find(a => a.idState === 2) !== undefined ? true : false);

                    if (parseInt(json.workflow[0].detail.idCompanyPosition) === 1203 && json.workflow[0].detail.admissionDate !== null)
                        setopenContractDiv('inline');
                    else
                        setopenContractDiv('none');

                    const ObjFinalProfessionalCategory = LoadComboProfessionalCategory(parseInt(json.workflow[0].detail.idInternalCategory));
                    setFinalProfessionalCategory(ObjFinalProfessionalCategory);

                    const ObjFinalCppCategory = LoadComboCppCategory(parseInt(json.workflow[0].detail.idInternalCategory));
                    setFinalCppCategory(ObjFinalCppCategory);
                }

                props.dispatch(Loading(false));
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter a informação relativa ao candidato.", ToastTypes.Danger, false)))
    }

    function LoadDependents() {

        var arrDependents = []

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/dependents?idcandidate=" + idCandidate, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                json.forEach(a => {
                    arrDependents.push({ idDependent: a.idDependent, name: a.name, birthDate: a.birthDate, gender: a.gender })
                })

                dispatch(dependents(arrDependents));
            })
            .catch(() => dispatch(Toast("Não foi possível obter os dependentes.", ToastTypes.Danger, false)))
    }

    function LoadComboData() {

        props.dispatch(Loading(true));

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([
            fetch("/api/candidates/documenttypes", requestOptions),
            fetch("/api/candidates/citizenship", requestOptions),
            fetch("/api/candidates/civilstate", requestOptions),
            fetch("/api/candidates/regionalcenter", requestOptions),
            fetch("/api/candidates/literayhabilitations", requestOptions),
            fetch("/api/candidates/companyposition", requestOptions),
            fetch("/api/candidates/regime", requestOptions),
            fetch("/api/candidates/internalcategory", requestOptions),
            fetch("/api/candidates/professionalcategory", requestOptions),
            fetch("/api/candidates/internaljoinprofessionalcategories", requestOptions),
            fetch("/api/candidates/companypositionmonths", requestOptions),
            fetch("/api/candidates/companypositiondays", requestOptions),
            fetch("/api/candidates/companypositionjustification", requestOptions),
            fetch("/api/candidates/studyareas", requestOptions),
            fetch("/api/candidates/cppcategory", requestOptions),
            fetch("/api/candidates/internaljoincppcategories", requestOptions),
        ])
            .then(allResponses => {

                if (allResponses[0].status === 200 && allResponses[1].status === 200 && allResponses[2].status === 200 && allResponses[3].status === 200 && allResponses[4].status === 200
                    && allResponses[5].status === 200 && allResponses[6].status === 200 && allResponses[7].status === 200 && allResponses[8].status === 200
                    && allResponses[9].status === 200 && allResponses[10].status === 200 && allResponses[11].status === 200 && allResponses[12].status === 200 && allResponses[13].status === 200 && allResponses[14].status === 200 && allResponses[15].status === 200) {

                    Promise.all([allResponses[0].json(), allResponses[1].json(), allResponses[2].json(), allResponses[3].json(), allResponses[4].json(), allResponses[5].json(),
                    allResponses[6].json(), allResponses[7].json(), allResponses[8].json(), allResponses[9].json(), allResponses[10].json(), allResponses[11].json(),
                    allResponses[12].json(), allResponses[13].json(), allResponses[14].json(), allResponses[15].json()])
                        .then(allJson => {
                            setObjCombos({
                                documentTypes: allJson[0], citizenShips: allJson[1], civilStates: allJson[2], regionalCenters: allJson[3], literayHabilitations: allJson[4], companyPositions: allJson[5],
                                regimes: allJson[6], costCenters: localCostCenters, internalCategories: allJson[7], professionalCategories: allJson[8], internalJoinProfissionalCategories: allJson[9],
                                companyPositionMonths: allJson[10], companyPositionDays: allJson[11], companyPositionJustification: allJson[12], studyAreas: allJson[13], waitLoad: false, cppCategories: allJson[14], internalJoinCppCategories: allJson[15]
                            })
                        })
                        .catch(() => props.dispatch(Toast("Não foi possível obter as informações de preenchimento das combos.", ToastTypes.Danger, false)))
                }
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter as informações de preenchimento das combos.", ToastTypes.Danger, false)))
    }

    function handleChangeForm(e, DateId, isDetail, isBool) {

        var id = DateId !== undefined && DateId !== null ? DateId : e.target.id
        var value = DateId !== undefined && DateId !== null ? e : e.target.value.toUpperCase()

        var objWorkflow = []
        var objDetail = {};

        if (isDetail) {

            var contractEndDate = null

            if (id === 'admissionDate') {

                if (value !== null) {
                    setopenContractDiv('inline')
                } else {
                    setopenContractDiv('none')
                }

                contractEndDate = setLoadTermDate(value, ObjWorkFlowDetailData.length > 0 ? ObjWorkFlowDetailData[0].detail.contractNumOfMonths : 0, ObjWorkFlowDetailData.length > 0 ?
                    ObjWorkFlowDetailData[0].detail.contractNumOfDays : 0)
            }

            if (id === 'contractNumOfMonths') {
                contractEndDate = setLoadTermDate(ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.admissionDate !== null ?
                    ObjWorkFlowDetailData[0].detail.admissionDate : null, value, ObjWorkFlowDetailData.length > 0 ? ObjWorkFlowDetailData[0].detail.contractNumOfDays : 0);

                setObjPersonalDataDetailError(state => ({ ...state, 'contractNumOfMonths': false }));
            }

            if (id === 'contractNumOfDays') {
                contractEndDate = setLoadTermDate(ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.admissionDate !== null ?
                    ObjWorkFlowDetailData[0].detail.admissionDate : null, ObjWorkFlowDetailData.length > 0 ? ObjWorkFlowDetailData[0].detail.contractNumOfMonths : 0, value);

                setObjPersonalDataDetailError(state => ({ ...state, 'contractNumOfDays': false }));
            }

            if (id === 'idContractJustification')
                setObjPersonalDataDetailError(state => ({ ...state, 'idContractJustification': parseInt(value) == 0 }));

            if (id === 'idCompanyPosition') {

                if (parseInt(value) === 1203) {
                    setopenContractDiv('inline');

                    setObjPersonalDataDetailError(state => ({ ...state, 'contractNumOfMonths': true, 'contractNumOfDays': true, 'idContractJustification': true }))
                } else {

                    // delete contract fields
                    ObjWorkFlowDetailData.forEach(a => {
                        var aux = a
                        objDetail = a.detail;
                        objDetail = { ...objDetail, 'idCompanyPosition': value, 'contractNumOfDays': null, 'contractNumOfMonths': null, 'contractEndDate': null, 'idContractJustification': null }
                        aux = { ...aux, detail: objDetail }
                        objWorkflow.push(aux)
                    });

                    setopenContractDiv('none');
                    setObjPersonalDataDetailError(state => ({ ...state, 'contractNumOfMonths': false, 'contractNumOfDays': false, 'idContractJustification': false, 'contractEndDate': false }));
                }
            }

            if (id === 'idInternalCategory') {

                var ObjCombo = [...ObjCombos.internalCategories]

                var ObjFinalProfessionalCategory = LoadComboProfessionalCategory(parseInt(value))
                var ObjFinalCppCategory = LoadComboCppCategory(parseInt(value))

                ObjCombo.forEach(item => {
                    item.selected = false
                    if (parseInt(item.key) === parseInt(value)) {
                        item.selected = true
                    }
                });

                if (parseInt(value) === -1 && ObjFinalProfessionalCategory.length === 0 && ObjFinalCppCategory.length === 0) {

                    ObjWorkFlowDetailData.forEach(a => {
                        var aux = a
                        objDetail = a.detail;
                        objDetail = { ...objDetail, 'idInternalCategory': -1, 'idProfessionalCategory': -1, 'idCppCategory': -1 }
                        aux = { ...aux, detail: objDetail }
                        objWorkflow.push(aux)
                    })

                    setObjWorkFlowDetailData(objWorkflow)
                }

                setFinalProfessionalCategory(ObjFinalProfessionalCategory)
                setFinalCppCategory(ObjFinalCppCategory)
            }

            if (id === 'weekDays') {

                if (value === '')
                    value = null
                else
                    value = parseInt(value)

                var MonthlyHours = value !== null ? CalcMonthlyHoursPersonalData(parseInt(value)) : null

                ObjWorkFlowDetailData.forEach(a => {
                    var aux = a
                    objDetail = a.detail;
                    objDetail = { ...objDetail, 'weekDays': value, 'monthlyHours': MonthlyHours }
                    aux = { ...aux, detail: objDetail }
                    objWorkflow.push(aux)
                })
                setObjWorkFlowDetailData(objWorkflow);
                setObjPersonalDataDetailError(state => ({ ...state, 'monthlyHours': value === null && MonthlyHours === null && checkFieldsHired === true, 'weekDays': value === null && checkFieldsHired === true }));
            }

            if (id === 'salary' || id === 'workload') {

                var weekHoursPercentage = null;

                var salary = ObjWorkFlowDetailData.length > 0 ? id === 'salary' ? value === '' ? 0 : value : ObjWorkFlowDetailData !== undefined && ObjWorkFlowDetailData[0].detail.salary === null ?
                    0 : ObjWorkFlowDetailData[0].detail.salary : 0
                var workload = ObjWorkFlowDetailData.length > 0 ? id === 'workload' ? value === '' ? 0 : value : ObjWorkFlowDetailData !== undefined && ObjWorkFlowDetailData[0].detail.workload === null ?
                    0 : ObjWorkFlowDetailData[0].detail.workload : 0

                if (parseInt(workload) > 0) {
                    weekHoursPercentage = CalcWeekHoursPercentage(parseInt(workload));
                }

                if (parseInt(salary) > 0 && parseInt(workload) > 0) {

                    const requestOptions = {
                        method: "GET",
                        headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
                    }

                    fetch("/api/candidates/workloadsalary?workload=" + workload + "&salary=" + salary, requestOptions)
                        .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                        .then(json => {

                            ObjWorkFlowDetailData.forEach(a => {
                                var aux = a
                                objDetail = a.detail;
                                objDetail = { ...objDetail, 'workloadSalary': parseFloat(json).toFixed(2), [id]: value, 'weekHoursPercentage': weekHoursPercentage }
                                aux = { ...aux, detail: objDetail }
                                objWorkflow.push(aux)
                            })

                            setObjPersonalDataDetailError(state => ({ ...state, 'workloadSalary': false, 'weekHoursPercentage': !parseInt(workload) > 0 && checkFieldsHired === true }));
                            setObjWorkFlowDetailData(objWorkflow);

                            props.dispatch(Loading(false))
                        })
                        .catch(() => props.dispatch(Toast("Não foi possível obter o vencimento da carga horária.", ToastTypes.Danger, false)))
                }
                else {

                    ObjWorkFlowDetailData.forEach(a => {
                        var aux = a
                        objDetail = a.detail;
                        objDetail = { ...objDetail, [id]: value, 'weekHoursPercentage': weekHoursPercentage, 'workloadSalary': null }
                        aux = { ...aux, detail: objDetail }
                        objWorkflow.push(aux)
                    })

                    setObjWorkFlowDetailData(objWorkflow);
                    setObjPersonalDataDetailError(state => ({ ...state, 'workloadSalary': checkFieldsHired, 'weekHoursPercentage': checkFieldsHired && (objDetail.workload === null || objDetail.workload === '') }));
                }
            }
            else {
                ObjWorkFlowDetailData.forEach(a => {
                    var aux = a
                    objDetail = a.detail;

                    if (contractEndDate !== null) {
                        objDetail = { ...objDetail, [id]: value, 'contractEndDate': contractEndDate }
                    } else {
                        objDetail = { ...objDetail, [id]: value }
                    }

                    aux = { ...aux, detail: objDetail }
                    objWorkflow.push(aux)
                })

                setObjWorkFlowDetailData(objWorkflow)
            }

            if (checkFieldsHired === true || id === 'idInternalCategory' || id === 'idProfessionalCategory' || id === 'idCppCategory') {
                setObjPersonalDataDetailError(state => ({ ...state, [id]: value === undefined || value === null || value === '' || parseInt(value) === -1 ? true : false }))
            }
        }
        else {

            // validações
            if (id === 'postalCode') {
                value = maskPostalCode(value)
                setObjPersonalDataError({ ...ObjPersonalDataError, [id]: CheckPostalCode(value, true) ? false : true })
            }
            else if (id === 'email') {
                setObjPersonalDataError({ ...ObjPersonalDataError, [id]: CheckEmail(value) ? false : true })
            }
            else if (id === 'fiscalNumber') {
                setObjPersonalDataError({ ...ObjPersonalDataError, [id]: IsValidNIF(value) ? false : true })
            }
            else if (id === 'niss') {
                setObjPersonalDataError({ ...ObjPersonalDataError, [id]: value.length === 11 ? false : true })
            }
            else if (id === 'birthDate') {

                if (value !== null) {

                    var DataInserted = new Date(value.getFullYear() + 16, value.getMonth(), value.getDate(), 0, 0, 0)
                    var myToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

                    setObjPersonalDataError({ ...ObjPersonalDataError, [id]: DataInserted <= myToday ? false : true })
                }
                else {
                    setObjPersonalDataError({ ...ObjPersonalDataError, [id]: true })
                }
            }
            else {
                if (checkFieldsHired === true || id === 'gender')
                    setObjPersonalDataError({ ...ObjPersonalDataError, [id]: value === undefined || value === null || value === '' || value === -1 ? true : false })
            }

            if (id === 'iban') {

                var valueIban = value

                setObjPersonalDataError(state => ({ ...state, 'iban': (valueIban === '' || (valueIban != '' && valueIban.length === 25 && valueIban.substring(0, 4).toUpperCase() === 'PT50')) ? false : true }));

                if (valueIban.length === 8 || valueIban.length === 25) {

                    var codnib = ''
                    if (valueIban.length >= 8) {
                        codnib = valueIban.substr(4, 4);
                    }

                    const requestOptions = {
                        method: "GET",
                        headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
                    }

                    fetch("/api/candidates/swift?codnib=" + codnib, requestOptions)
                        .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                        .then(json => {
                            if (json.length > 0) {
                                if (json[0].value !== undefined) {
                                    setObjPersonalData(item => { return { ...item, 'swift': json[0].value, [id]: value } });
                                    setObjPersonalDataError(state => ({ ...state, 'swift': false }));
                                }

                            } else {
                                setObjPersonalData(item => { return { ...item, 'swift': '', [id]: '' } });
                                setObjPersonalDataError(state => ({ ...state, 'swift': true }));
                            }

                            props.dispatch(Loading(false))
                        })
                        .catch(() => props.dispatch(Toast("Não foi possível obter o Swift.", ToastTypes.Danger, false)))
                } else if (valueIban.length < 8) {
                    setObjPersonalData(item => { return { ...item, 'swift': '', [id]: value } })
                } else {
                    setObjPersonalData({ ...ObjPersonalData, [id]: value })
                }
            }
            else if (id === 'idCivilState') {
                if (parseInt(value) === 1 || parseInt(value) === 7) {
                    setopenSpouseDiv('inline')
                    setObjPersonalData({ ...ObjPersonalData, [id]: value })
                } else {
                    setObjPersonalData(item => { return { ...item, [id]: value, 'spouseName': '', 'spouseBirthDate': null } })
                    setopenSpouseDiv('none')
                }
            }
            else if (isBool) {
                if (!isNaN(parseInt(value)))
                    value = parseInt(value) === 1 ? true : false

                setObjPersonalData({ ...ObjPersonalData, [id]: value })
            }
            else {
                setObjPersonalData({ ...ObjPersonalData, [id]: value })
            }
        }
    }

    function LoadComboProfessionalCategory(idInternalCategory) {

        let ObjFinalProfessionalCategory = [];

        const lstIdProfs = ObjCombos.internalJoinProfissionalCategories.filter(a => parseInt(a.key) === parseInt(idInternalCategory));

        if (!ObjCombos.waitLoad && parseInt(idInternalCategory) > 0) {

            if (lstIdProfs.length > 0)
                ObjCombos.professionalCategories.forEach(IdProf => {
                    if (lstIdProfs.find(a => parseInt(a.value) === parseInt(IdProf.key)) !== undefined)
                        ObjFinalProfessionalCategory.push(IdProf);
                });
            else
                props.dispatch(Toast("Esta categoria interna não tem categorias profissionais associadas!", ToastTypes.Warning, false));
        } 

        return ObjFinalProfessionalCategory
    }

    function LoadComboCppCategory(idInternalCategory) {

        let ObjFinalCppCategory = [];

        const lstIdCpps = ObjCombos.internalJoinCppCategories.filter(a => parseInt(a.key) === parseInt(idInternalCategory));

        if (!ObjCombos.waitLoad && parseInt(idInternalCategory) > 0) {

            if (lstIdCpps.length > 0) {
                ObjCombos.cppCategories.forEach(IdCpp => {
                    if (lstIdCpps.find(a => parseInt(a.value) === parseInt(IdCpp.key)) !== undefined)
                        ObjFinalCppCategory.push(IdCpp);
                });
            }
            else {
                props.dispatch(Toast("Esta categoria interna não tem categorias cpp associadas!", ToastTypes.Warning, false));
            }
        }

        return ObjFinalCppCategory
    }

    function setLoadTermDate(admissionDate, numMonths, numDays) {
        if (numMonths == null || numDays == null || numMonths == "" || numDays == "")
            return "";

        var contractEndDate = new Date(admissionDate);

        contractEndDate.setMonth(contractEndDate.getMonth() + parseInt(numMonths - 1));
        contractEndDate.setDate(contractEndDate.getDate() - 1);
        contractEndDate.setDate(contractEndDate.getDate() + parseInt(numDays - 1));

        return contractEndDate;
    }

    function toggleJustification() {
        setModalJustification({ ...modalJustification, isOpen: !modalJustification.isOpen })
    }

    function handleSave() {

        var error = { ...ObjPersonalDataError }
        var errorDetail = { ...ObjPersonalDataDetailError }

        // 15-09-2022 - categories and gender as required fields
        error.gender = ObjPersonalData.gender === '' || ObjPersonalData.gender === null
        errorDetail.idInternalCategory = parseInt(ObjWorkFlowDetailData[0].detail.idInternalCategory) === -1 || ObjWorkFlowDetailData[0].detail.idInternalCategory === null || ObjWorkFlowDetailData[0].detail.idInternalCategory === ''
        errorDetail.idProfessionalCategory = parseInt(ObjWorkFlowDetailData[0].detail.idProfessionalCategory) === -1 || ObjWorkFlowDetailData[0].detail.idProfessionalCategory === null || ObjWorkFlowDetailData[0].detail.idProfessionalCategory === ''
        errorDetail.idCppCategory = parseInt(ObjWorkFlowDetailData[0].detail.idCppCategory) === -1 || ObjWorkFlowDetailData[0].detail.idCppCategory === null || ObjWorkFlowDetailData[0].detail.idCppCategory === ''

        if (checkFieldsHired) {
            error.postalCode = CheckPostalCode(ObjPersonalData.postalCode, true) === false ? true : false
            error.fiscalNumber = ObjPersonalData.fiscalNumber === '' || ObjPersonalData.fiscalNumber === null || !IsValidNIF(ObjPersonalData.fiscalNumber)
            //error.iban = (ObjPersonalData.iban.length === 25 && ObjPersonalData.iban.substring(0, 4).toUpperCase() === 'PT50') ? false : true //WITH pt50
        }

        if (ObjPersonalData.birthDate !== null && ObjPersonalData.birthDate !== '') {
            var DataInserted = new Date(new Date(ObjPersonalData.birthDate).getFullYear() + 16, new Date(ObjPersonalData.birthDate).getMonth(), new Date(ObjPersonalData.birthDate).getDate(), 0, 0, 0)
            var myToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
            error.birthDate = DataInserted <= myToday ? false : true
        }

        error.email = ObjPersonalData.email === '' || ObjPersonalData.email === null || !CheckEmail(ObjPersonalData.email)
        
        let hasError = false

        for (var propt in error)
            if (error[propt] === true)
                hasError = true

        for (var propt in errorDetail)
            if (errorDetail[propt] === true)
                hasError = true

        setObjPersonalDataError(error)
        setObjPersonalDataDetailError(errorDetail)

        if (hasError)
            return;

        var objPersonalData = {
            isPersonalData: true,
            IdCandidate: parseInt(idCandidate),
            IdWorkflow: -1,
            Name: ObjPersonalData.name,
            PhoneNumber: ObjPersonalData.phoneNumber,
            HomePhoneNumber: ObjPersonalData.homePhoneNumber,
            EmergencyContact: ObjPersonalData.emergencyContact,
            Email: ObjPersonalData.email,
            BirthDate: ObjPersonalData.birthDate !== undefined || ObjPersonalData.birthDate !== null ? DateUtc(ObjPersonalData.birthDate) : null,
            Address: ObjPersonalData.address,
            PostalCode: ObjPersonalData.postalCode.toString().replace('-', ''),
            PostalLocation: ObjPersonalData.postalLocation,
            IdCitizenship: ObjPersonalData.idCitizenship,
            FiscalNumber: ObjPersonalData.fiscalNumber,
            Niss: ObjPersonalData.niss,
            Syndicate: ObjPersonalData.syndicate === null || parseInt(ObjPersonalData.syndicate) === -1 ? null : ObjPersonalData.syndicate,
            NumberOfHolders: ObjPersonalData.numberOfHolders === -1 ? null : ObjPersonalData.numberOfHolders,
            NumberOfDependents: ObjPersonalData.numberOfDependents,
            DeficientInFamily: ObjPersonalData.deficientInFamily === null || parseInt(ObjPersonalData.deficientInFamily) === -1 ? null : ObjPersonalData.deficientInFamily,
            Disability: ObjPersonalData.disability === null || parseInt(ObjPersonalData.disability) === -1 ? null : ObjPersonalData.disability,
            DisabilityDegree: parseInt(ObjPersonalData.disabilityDegree),
            IdRegionalCenter: ObjPersonalData.idRegionalCenter !== null ? parseInt(ObjPersonalData.idRegionalCenter) : null,
            IdDocumentType: ObjPersonalData.idDocumentType != "" ? ObjPersonalData.idDocumentType : -1,
            DocumentNumber: ObjPersonalData.documentNumber,
            DocumentExpirationDate: ObjPersonalData.documentExpirationDate !== undefined && ObjPersonalData.documentExpirationDate !== null ? DateUtc(ObjPersonalData.documentExpirationDate) : null,
            DocumentEmissionDate: ObjPersonalData.documentEmissionDate !== undefined && ObjPersonalData.documentEmissionDate !== null ? DateUtc(ObjPersonalData.documentEmissionDate) : null,
            IdLiteraryQualification: ObjPersonalData.idLiteraryQualification,
            IdStudyArea: ObjPersonalData.idStudyArea,
            Archive: ObjPersonalData.archive,
            Gender: isNaN(parseInt(ObjPersonalData.gender)) ? null : parseInt(ObjPersonalData.gender) == 1,
            BornIn: ObjPersonalData.bornIn,
            IdCivilState: ObjPersonalData.idCivilState,
            SpouseName: ObjPersonalData.spouseName,
            SpouseBirthDate: ObjPersonalData.spouseBirthDate !== undefined && ObjPersonalData.spouseBirthDate !== null ? DateUtc(ObjPersonalData.spouseBirthDate) : null,
            DrivingLicense: ObjPersonalData.drivingLicense,
            DrivingLicenseDate: ObjPersonalData.drivingLicenseDate !== undefined && ObjPersonalData.drivingLicenseDate !== null ? DateUtc(ObjPersonalData.drivingLicenseDate) : null,
            Iban: ObjPersonalData.iban,
            Swift: ObjPersonalData.swift,
            CandidatesDependent: reduxDependents,
        }

        

        var workflowDetailInfo = {
            CostCenter: parseInt(ObjWorkFlowDetailData[0].detail.costCenter),
            //AdmissionDate: ObjWorkFlowDetailData[0].detail.admissionDate !== undefined && ObjWorkFlowDetailData[0].detail.admissionDate !== null ? new Date(ObjWorkFlowDetailData[0].detail.admissionDate) : null,
            AdmissionDate: ObjWorkFlowDetailData[0].detail.admissionDate !== undefined && ObjWorkFlowDetailData[0].detail.admissionDate !== null ? DateUtc(ObjWorkFlowDetailData[0].detail.admissionDate) : null,
            IdCompanyPosition: ObjWorkFlowDetailData[0].detail.idCompanyPosition,
            ContractJustification: ObjWorkFlowDetailData[0].detail.contractJustification,
            IdRegime: ObjWorkFlowDetailData[0].detail.idRegime,
            EfectiveDate: ObjWorkFlowDetailData[0].detail.efectiveDate !== undefined && ObjWorkFlowDetailData[0].detail.efectiveDate !== null ? DateUtc(ObjWorkFlowDetailData[0].detail.efectiveDate) : null,
            IdInternalCategory: ObjWorkFlowDetailData[0].detail.idInternalCategory,
            IdProfessionalCategory: FinalProfessionalCategory.length > 0 ? ObjWorkFlowDetailData[0].detail.idProfessionalCategory : -1,
            Salary: ObjWorkFlowDetailData[0].detail.salary !== undefined && ObjWorkFlowDetailData[0].detail.salary !== '' ? ObjWorkFlowDetailData[0].detail.salary : null,
            MealAllowance: ObjWorkFlowDetailData[0].detail.mealAllowance !== undefined && ObjWorkFlowDetailData[0].detail.mealAllowance !== '' ? ObjWorkFlowDetailData[0].detail.mealAllowance : null,
            Workload: ObjWorkFlowDetailData[0].detail.workload !== undefined && ObjWorkFlowDetailData[0].detail.workload !== '' ? ObjWorkFlowDetailData[0].detail.workload : null,
            WorkloadSalary: ObjWorkFlowDetailData[0].detail.workloadSalary !== undefined && ObjWorkFlowDetailData[0].detail.workloadSalary !== '' ? ObjWorkFlowDetailData[0].detail.workloadSalary : null,
            ContractNumOfDays: ObjWorkFlowDetailData[0].detail.contractNumOfDays,
            ContractNumOfMonths: ObjWorkFlowDetailData[0].detail.contractNumOfMonths,
            ContractEndDate: ObjWorkFlowDetailData[0].detail.contractEndDate !== undefined && ObjWorkFlowDetailData[0].detail.contractEndDate !== null ? DateUtc(ObjWorkFlowDetailData[0].detail.contractEndDate) : null,
            IdContractJustification: ObjWorkFlowDetailData[0].detail.idContractJustification,
            IdCppCategory: FinalCppCategory.length > 0 ? ObjWorkFlowDetailData[0].detail.idCppCategory : -1,
            WeekHoursPercentage: ObjWorkFlowDetailData[0].detail.weekHoursPercentage,
            WeekDays: ObjWorkFlowDetailData[0].detail.weekDays,
            MonthlyHours: ObjWorkFlowDetailData[0].detail.monthlyHours,
            SourceId: parseInt(ObjWorkFlowDetailData[0].detail.sourceId)
        }

        var objWorkflow = [];

        ObjWorkFlowDetailData.forEach(a => {
            var objDataWorkflow = {
                IdWorkflow: a.idWorkflow,
                IdCandidate: parseInt(idCandidate),
                IdPhase: -1,
                IdEvent: -1,
                IdReason: -1,
                IdState: -1,
                UpdateOnlyDetail: true,
                Detail: workflowDetailInfo
            }
            objWorkflow.push(objDataWorkflow)
        })

        var save = { PersonalData: objPersonalData, Workflow: objWorkflow, CandidateToken: null, GeneralState: checkFieldsHired ? 2 : 0 }

        // check if fiscalnumber or email already exists in our BD
        if ((ObjPersonalData.fiscalNumber !== undefined && ObjPersonalData.fiscalNumber !== '') || (ObjPersonalData.email !== undefined && ObjPersonalData.email !== '')) {

            const requestOptions = {
                method: "GET",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
            }

            props.dispatch(Loading(true));

            fetch("/api/candidates/checkcandidaterepeatedfields?idcandidate=" + idCandidate + '&fiscalnumber=' + ObjPersonalData.fiscalNumber + '&email=' + ObjPersonalData.email, requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {

                    if (json)
                        return props.dispatch(Toast("Já existe um NIF/Email inserido na Base de Dados. Reveja os campos!", ToastTypes.Danger, false));

                    const requestOptions = {
                        method: "PUT",
                        headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
                        body: JSON.stringify(save)
                    }

                    fetch("/api/candidates/save", requestOptions)
                        .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                        .then(json => {
                            if (!json)
                                return props.dispatch(Toast("Não foi possível gravar. Tente mais tarde", ToastTypes.Danger, false));

                            dispatch(costCenter(workflowDetailInfo.CostCenter));
                            //if (save.GeneralState === 2) {
                            //    setHired(true)
                            //}
                            props.dispatch(Toast("Gravado com sucesso!", ToastTypes.Success, false));
                        })
                        .catch(_ => props.dispatch(Toast("Não foi possível gravar. Tente mais tarde", ToastTypes.Danger, false)))
                })
                .catch(_ => props.dispatch(Toast("Não foi possível verificar os campos repetidos do candidato.", ToastTypes.Danger, false)))
        }
    }

    useEffect(() => {
        if (ObjWorkFlowDetailData.length == undefined)
            return;

        const temp = [...ObjWorkFlowDetailData];
        temp[0].detail.costCenter = localCostCenter;

        setObjWorkFlowDetailData(temp);

    }, [localCostCenter]);

    return (
        <>
            <Row>
                <Col xs="2">
                    <Label><b>Data de Admissão</b></Label>
                    <DatePicker id="admissionDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.admissionDate !== null ? new Date(ObjWorkFlowDetailData[0].detail.admissionDate).getTime() : null}
                        onChange={(e) => handleChangeForm(e, 'admissionDate', true)} dateFormat="yyyy-MM-dd"
                        className={ObjPersonalDataDetailError.admissionDate === true ? "is-invalid form-control" : "form-control"}>
                    </DatePicker>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="10">
                    <Label><b>Nome</b></Label>
                    <Input id="name" type="text" className="form-control" value={ObjPersonalData.name !== undefined ? ObjPersonalData.name : ''} autoComplete="off" disabled={hired}
                        invalid={ObjPersonalDataError.name} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Data de Nascimento</b></Label>
                    <DatePicker id="birthDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={ObjPersonalData.birthDate !== undefined ? new Date(ObjPersonalData.birthDate).getTime() : null} onChange={(e) => handleChangeForm(e, 'birthDate')} dateFormat="yyyy-MM-dd"
                        className={ObjPersonalDataError.birthDate === true ? "is-invalid form-control" : "form-control"}>
                    </DatePicker>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="6">
                    <Label><b>Morada</b></Label>
                    <Input id="address" type="text" className="form-control" autoComplete="off" value={ObjPersonalData.address !== undefined ? ObjPersonalData.address : ''} disabled={hired}
                        invalid={ObjPersonalDataError.address}
                        onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Código Postal</b></Label>
                    <Input id="postalCode" type="text" maxLength="8" className="form-control" autoComplete="off" value={ObjPersonalData.postalCode !== undefined ? ObjPersonalData.postalCode : ''}
                        invalid={ObjPersonalDataError.postalCode} disabled={hired}
                        onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Localidade</b></Label>
                    <Input id="postalLocation" type="text" className="form-control" autoComplete="off" value={ObjPersonalData.postalLocation !== undefined ? ObjPersonalData.postalLocation : ''} disabled={hired}
                        invalid={ObjPersonalDataError.postalLocation} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Natural de</b></Label>
                    <Input id="bornIn" type="text" className="form-control" autoComplete="off" value={ObjPersonalData.bornIn !== undefined ? ObjPersonalData.bornIn : ''} disabled={hired}
                        invalid={ObjPersonalDataError.bornIn} onChange={(e) => handleChangeForm(e)} />
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="6">
                    <Label><b>Email</b></Label>
                    <Input id="email" type="text" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.email !== undefined ? ObjPersonalData.email : ''}
                        invalid={ObjPersonalDataError.email} disabled={hired}
                        onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Telefone</b></Label>
                    <Input id="homePhoneNumber" type="number" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.homePhoneNumber !== undefined ? ObjPersonalData.homePhoneNumber : ''}
                        disabled={hired} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Telemóvel</b></Label>
                    <Input id="phoneNumber" type="number" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.phoneNumber !== undefined ? ObjPersonalData.phoneNumber : ''} disabled={hired}
                        invalid={ObjPersonalDataError.phoneNumber} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Contacto de Urgência</b></Label>
                    <Input id="emergencyContact" type="number" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.emergencyContact !== undefined ? ObjPersonalData.emergencyContact : ''}
                        disabled={hired} onChange={(e) => handleChangeForm(e)} />
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="4">
                    <Label><b>Tipo de Cartão</b></Label>
                    <Input type="select" id="idDocumentType" value={ObjPersonalData.idDocumentType !== undefined && ObjPersonalData.idDocumentType !== null ? ObjPersonalData.idDocumentType : -1} disabled={hired}
                        invalid={ObjPersonalDataError.idDocumentType} onChange={(e) => handleChangeForm(e)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.documentTypes.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Número</b></Label>
                    <Input id="documentNumber" type="text" maxLength="50" className="form-control" autoComplete="nope" value={ObjPersonalData.documentNumber !== undefined ? ObjPersonalData.documentNumber : ''}
                        disabled={hired} invalid={ObjPersonalDataError.documentNumber} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Arquivo</b></Label>
                    <Input id="archive" type="text" maxLength="50" className="form-control" autoComplete="nope" value={ObjPersonalData.archive !== undefined ? ObjPersonalData.archive : ''} disabled={hired}
                        onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Data de Emissão</b></Label>
                    <DatePicker id="documentEmissionDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={ObjPersonalData.documentEmissionDate !== undefined ? new Date(ObjPersonalData.documentEmissionDate).getTime() : null} onChange={(e) => handleChangeForm(e, 'documentEmissionDate')}
                        dateFormat="yyyy-MM-dd" className="form-control">
                    </DatePicker>
                </Col>
                <Col xs="2">
                    <Label><b>Data de Validade</b></Label>
                    <DatePicker id="documentExpirationDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={ObjPersonalData.documentExpirationDate !== undefined ? new Date(ObjPersonalData.documentExpirationDate).getTime() : null} onChange={(e) => handleChangeForm(e, 'documentExpirationDate')}
                        dateFormat="yyyy-MM-dd" className={ObjPersonalDataError.documentExpirationDate === true ? "is-invalid form-control" : "form-control"}>
                    </DatePicker>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="4">
                    <Label><b>Nacionalidade</b></Label>
                    <Input type="select" id="idCitizenship" value={ObjPersonalData.idCitizenship !== undefined && ObjPersonalData.idCitizenship !== null ? ObjPersonalData.idCitizenship : -1} disabled={hired}
                        invalid={ObjPersonalDataError.idCitizenship} onChange={(e) => handleChangeForm(e)}>
                        {ObjCombos.citizenShips.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Nº Contribuinte</b></Label>
                    <Input id="fiscalNumber" type="number" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.fiscalNumber !== undefined ? ObjPersonalData.fiscalNumber : ''}
                        invalid={ObjPersonalDataError.fiscalNumber} disabled={hired} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Sindicato</b></Label>
                    <Input type="select" id="syndicate"
                        value={ObjPersonalData.syndicate === undefined || ObjPersonalData.syndicate === null || parseInt(ObjPersonalData.syndicate) === -1 ? -1 : ObjPersonalData.syndicate ? 1 : 0}
                        disabled={hired} invalid={ObjPersonalDataError.syndicate} onChange={(e) => handleChangeForm(e, null, false, true)}>
                        <option value="-1"></option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </Input>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="2">
                    <Label><b>Género</b></Label>
                    <Input type="select" id="gender" value={isNaN(parseInt(ObjPersonalData.gender)) ? "" : parseInt(ObjPersonalData.gender)}
                        disabled={hired} invalid={ObjPersonalDataError.gender} onChange={(e) => handleChangeForm(e, null, false, false)}>
                        <option value=""></option>
                        <option value="1">Masculino</option>
                        <option value="0">Feminino</option>
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Estado Civil</b></Label>
                    <Input type="select" id="idCivilState" value={ObjPersonalData.idCivilState !== undefined && ObjPersonalData.idCivilState !== null ? ObjPersonalData.idCivilState : -1} disabled={hired}
                        invalid={ObjPersonalDataError.idCivilState} onChange={(e) => handleChangeForm(e)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.civilStates.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Titulares</b></Label>
                    <Input type="select" id="numberOfHolders" value={ObjPersonalData.numberOfHolders !== undefined && ObjPersonalData.numberOfHolders !== null ? ObjPersonalData.numberOfHolders : -1} disabled={hired}
                        invalid={ObjPersonalDataError.numberOfHolders}  onChange={(e) => handleChangeForm(e)}>
                        <option value="-1"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </Input>
                </Col>
            </Row>

            <div id="divSpouse" style={{ display: openSpouseDiv }}>
                <Row style={{ marginTop: "10px" }}>
                    <Col xs="4">
                        <Label><b>Nome Cônjugue</b></Label>
                        <Input id="spouseName" type="text" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.spouseName !== undefined ? ObjPersonalData.spouseName : ''} disabled={hired}
                            invalid={ObjPersonalDataError.spouseName} onChange={(e) => handleChangeForm(e)} />
                    </Col>
                    <Col xs="2">
                        <Label><b>Data Nasc. Cônjugue</b></Label>
                        <DatePicker id="spouseBirthDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                            selected={ObjPersonalData.spouseBirthDate !== undefined ? new Date(ObjPersonalData.spouseBirthDate).getTime() : ''} onChange={(e) => handleChangeForm(e, 'spouseBirthDate')} dateFormat="yyyy-MM-dd"
                            className={ObjPersonalData.spouseBirthDate === true ? "is-invalid form-control" : "form-control"}>
                        </DatePicker>
                    </Col>
                </Row>
            </div>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="2">
                    <Label><b>Nº Dependentes</b></Label>
                    <Input id="numberOfDependents" type="number" readOnly
                        value={reduxDependents === undefined ? ObjPersonalData.numberOfDependents !== undefined ? ObjPersonalData.numberOfDependents : '' : reduxDependents.length} maxLength="50"
                        readOnly onClick={() => setModalDependents(true)} className={hired ? '' : "form-controlDisableClick"} autoComplete="off" disabled={hired}
                        onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Def. Agregado Familiar</b></Label>
                    <Input type="select" id="deficientInFamily"
                        value={ObjPersonalData.deficientInFamily === undefined || ObjPersonalData.deficientInFamily === null || parseInt(ObjPersonalData.deficientInFamily) === -1 ? -1 : ObjPersonalData.deficientInFamily ? 1 : 0}
                        disabled={hired} invalid={ObjPersonalDataError.deficientInFamily} onChange={(e) => handleChangeForm(e, null, false, true)}>
                        <option value="-1"></option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Deficiente</b></Label>
                    <Input type="select" id="disability"
                        value={ObjPersonalData.disability === undefined || ObjPersonalData.disability === null || parseInt(ObjPersonalData.disability) === -1 ? -1 : ObjPersonalData.disability ? 1 : 0}
                        disabled={hired} invalid={ObjPersonalDataError.disability} onChange={(e) => handleChangeForm(e, null, false, true)}>
                        <option value="-1"></option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Grau de Deficiência</b></Label>
                    <Input id="disabilityDegree" type="number" className="form-control" autoComplete="off" value={ObjPersonalData.disabilityDegree !== undefined ? ObjPersonalData.disabilityDegree : ''}
                        disabled={hired} invalid={ObjPersonalDataError.disabilityDegree} onChange={(e) => handleChangeForm(e)} />
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="2">
                    <Label><b>Nº Seg. Social</b></Label>
                    <Input id="niss" type="number" maxLength="11" className="form-control" autoComplete="off" value={ObjPersonalData.niss !== undefined ? ObjPersonalData.niss : ''} disabled={hired}
                        invalid={ObjPersonalDataError.niss} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Centro Regional</b></Label>
                    <Input type="select" id="idRegionalCenter" value={ObjPersonalData.idRegionalCenter !== undefined && ObjPersonalData.idRegionalCenter !== null ? ObjPersonalData.idRegionalCenter : -1}
                        disabled={hired} invalid={ObjPersonalDataError.idRegionalCenter} onChange={(e) => handleChangeForm(e)}>
                        {ObjCombos.regionalCenters.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="6">
                    <Label><b>IBAN</b></Label>
                    <Input id="iban" type="text" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.iban !== undefined ? ObjPersonalData.iban : ''} disabled={hired}
                        invalid={ObjPersonalDataError.iban} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="2">
                    <Label><b>Swift</b></Label>
                    <Input id="swift" type="text" maxLength="50" className="form-control" readOnly autoComplete="off" value={ObjPersonalData.swift !== undefined ? ObjPersonalData.swift : ''} disabled={hired}
                        invalid={ObjPersonalDataError.swift} onChange={(e) => handleChangeForm(e)} />
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="3">
                    <Label><b>Área de Estudo</b></Label>
                    <Input type="select" id="idStudyArea" value={ObjPersonalData.idStudyArea !== undefined && ObjPersonalData.idStudyArea !== null ? ObjPersonalData.idStudyArea : -1} disabled={hired}
                        invalid={ObjPersonalDataError.idStudyArea} onChange={(e) => handleChangeForm(e)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.studyAreas.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="3">
                    <Label><b>Habilitações Literárias</b></Label>
                    <Input type="select" id="idLiteraryQualification"
                        value={ObjPersonalData.idLiteraryQualification !== undefined && ObjPersonalData.idLiteraryQualification !== null ? ObjPersonalData.idLiteraryQualification : -1} disabled={hired}
                        invalid={ObjPersonalDataError.idLiteraryQualification} onChange={(e) => handleChangeForm(e)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.literayHabilitations.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="3">
                    <Label><b>Carta de Condução</b></Label>
                    <Input id="drivingLicense" type="text" maxLength="50" className="form-control" autoComplete="off" value={ObjPersonalData.drivingLicense !== undefined ? ObjPersonalData.drivingLicense : ''}
                        disabled={hired} onChange={(e) => handleChangeForm(e)} />
                </Col>
                <Col xs="3">
                    <Label><b>Data da Carta</b></Label>
                    <DatePicker id="drivingLicenseDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={new Date(ObjPersonalData.drivingLicenseDate).getTime()} onChange={(e) => handleChangeForm(e, 'drivingLicenseDate')} dateFormat="yyyy-MM-dd" className="form-control">
                    </DatePicker>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="3">
                    <Label><b>Contrato</b></Label>
                    <Input type="select" id="idCompanyPosition" disabled={(ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.admissionDate === null) || hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idCompanyPosition !== null && ObjWorkFlowDetailData[0].detail.admissionDate !== null ?
                            ObjWorkFlowDetailData[0].detail.idCompanyPosition : -1} invalid={ObjPersonalDataDetailError.idCompanyPosition} onChange={(e) => handleChangeForm(e, null, true, false)}
                        title={(ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.admissionDate === null) || hired ? "Inserir a data de admissão" : ""}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.companyPositions.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="3">
                    <Label><b>Justificação</b></Label>
                    <Input type="text" className={hired ? '' : "form-controlDisableClick"} id="contractJustification"
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.contractJustification !== null ? ObjWorkFlowDetailData[0].detail.contractJustification : ''} readOnly
                        onClick={() => toggleJustification()} onChange={(e) => handleChangeForm(e, null, true)} disabled={hired} />
                </Col>
                <Col xs="3">
                    <Label><b>Regime</b></Label>
                    <Input type="select" id="idRegime" value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idRegime !== null ? ObjWorkFlowDetailData[0].detail.idRegime : -1}
                        disabled={hired}
                        invalid={ObjPersonalDataDetailError.idRegime} onChange={(e) => handleChangeForm(e, null, true, false)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.regimes.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="3">
                    <Label><b>Data de Efectivação</b></Label>
                    <DatePicker id="efectiveDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled={hired}
                        selected={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.efectiveDate !== null ? new Date(ObjWorkFlowDetailData[0].detail.efectiveDate) : null}
                        onChange={(e) => handleChangeForm(e, 'efectiveDate', true)} dateFormat="yyyy-MM-dd" className="form-control">
                    </DatePicker>
                </Col>
            </Row>

            <div id="divContract" style={{ display: openContractDiv }}>
                <Row style={{ marginTop: "10px" }}>
                    <Col xs="2">
                        <Label><b>Mês</b></Label>
                        <Input type="select" id="contractNumOfMonths" disabled={hired}
                            value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.contractNumOfMonths !== null ? ObjWorkFlowDetailData[0].detail.contractNumOfMonths : -1}
                            invalid={ObjPersonalDataDetailError.contractNumOfMonths} onChange={(e) => handleChangeForm(e, null, true, false)}>
                            <option value=""></option>
                            {ObjCombos.companyPositionMonths.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                    <Col xs="2">
                        <Label><b>Dias</b></Label>
                        <Input type="select" id="contractNumOfDays" disabled={hired}
                            value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.contractNumOfDays !== null ? ObjWorkFlowDetailData[0].detail.contractNumOfDays : -1}
                            invalid={ObjPersonalDataDetailError.contractNumOfDays} onChange={(e) => handleChangeForm(e, null, true, false)}>
                            <option value=""></option>
                            {ObjCombos.companyPositionDays.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                    <Col xs="2">
                        <Label><b>Data de Termo</b></Label>
                        <DatePicker id="contractEndDate" showPopperArrow={false} showMonthDropdown showYearDropdown todayButton="Hoje" autoComplete="off" disabled
                            selected={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.contractEndDate !== null ? new Date(ObjWorkFlowDetailData[0].detail.contractEndDate).getTime() : null}
                            onChange={(e) => handleChangeForm(e, 'contractEndDate', true)} dateFormat="yyyy-MM-dd"
                            className={ObjPersonalDataError.contractEndDate === true ? "is-invalid form-control" : "form-control"}>
                        </DatePicker>
                    </Col>
                    <Col xs="6">
                        <Label><b>Justificação</b></Label>
                        <Input type="select" id="idContractJustification" disabled={hired}
                            value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idContractJustification !== null ? ObjWorkFlowDetailData[0].detail.idContractJustification : -1}
                            onChange={(e) => handleChangeForm(e, null, true, false)} invalid={ObjPersonalDataDetailError.idContractJustification}>
                            {ObjCombos.companyPositionJustification.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                </Row>
            </div>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="6">
                    <Label><b>Categoria Interna</b></Label>
                    <Input type="select" id="idInternalCategory" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idInternalCategory !== null ? ObjWorkFlowDetailData[0].detail.idInternalCategory : -1}
                        invalid={ObjPersonalDataDetailError.idInternalCategory} onChange={(e) => handleChangeForm(e, null, true, false)}>
                        {ObjCombos.internalCategories.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="6">
                    <Label><b>Categoria Profissional</b></Label>
                    <Input type="select" id="idProfessionalCategory" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idProfessionalCategory !== null ? ObjWorkFlowDetailData[0].detail.idProfessionalCategory : -1}
                        invalid={ObjPersonalDataDetailError.idProfessionalCategory} onChange={(e) => handleChangeForm(e, null, true, false)}>
                        <option key={-1} value={-1}></option>
                        {FinalProfessionalCategory.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
                <Col xs="6">
                    <Label><b>Categoria CPP</b></Label>
                    <Input type="select" id="idCppCategory" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.idCppCategory !== null ? ObjWorkFlowDetailData[0].detail.idCppCategory : -1}
                        invalid={ObjPersonalDataDetailError.idCppCategory} onChange={(e) => handleChangeForm(e, null, true, false)}>
                        <option key={-1} value={-1}></option>
                        {FinalCppCategory.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col xs="4">
                    <Label><b>Centro de Custo</b></Label>
                    <Input type="select" id="costCenter" value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.costCenter !== null ? ObjWorkFlowDetailData[0].detail.costCenter : -1}
                        disabled={hired} invalid={ObjPersonalDataDetailError.costCenter} onChange={(e) => handleChangeForm(e, null, true, false)}>
                        <option key={-1} value={-1}></option>
                        {ObjCombos.costCenters.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col xs="2">
                    <Label><b>Venc. Base Mensal</b></Label>
                    <Input id="salary" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.salary !== null && ObjWorkFlowDetailData[0].detail.salary !== 0 ? ObjWorkFlowDetailData[0].detail.salary : ''}
                        invalid={ObjPersonalDataDetailError.salary} onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>
                <Col xs="2">
                    <Label><b>Sub. Alimentação</b></Label>
                    <Input id="mealAllowance" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.mealAllowance !== null && ObjWorkFlowDetailData[0].detail.mealAllowance !== 0 ?
                            ObjWorkFlowDetailData[0].detail.mealAllowance : ''} onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>
                <Col xs="2">
                    <Label><b>Carga Horária</b></Label>
                    <Input id="workload" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.workload !== null && ObjWorkFlowDetailData[0].detail.workload !== 0 ? ObjWorkFlowDetailData[0].detail.workload : ''}
                        invalid={ObjPersonalDataDetailError.workload} onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>
                <Col xs="2">
                    <Label><b>Venc. Carga Horária</b></Label>
                    <Input id="workloadSalary" type="number" maxLength="50" className="form-control" autoComplete="off" disabled="disabled"
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.workloadSalary !== null && ObjWorkFlowDetailData[0].detail.workloadSalary !== 0 ?
                            ObjWorkFlowDetailData[0].detail.workloadSalary : ''} invalid={ObjPersonalDataDetailError.workloadSalary} onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>

                <Col xs="4">
                    <Label><b>Nº Dias Trabalho por Semana</b></Label>
                    <Input id="weekDays" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.weekDays !== null && ObjWorkFlowDetailData[0].detail.weekDays !== 0 ? ObjWorkFlowDetailData[0].detail.weekDays : ''}
                        invalid={ObjPersonalDataDetailError.weekDays} onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>

                <Col xs="2">
                    <Label><b>% Horas Semanais</b></Label>
                    <Input id="weekHoursPercentage" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.weekHoursPercentage !== null && ObjWorkFlowDetailData[0].detail.weekHoursPercentage !== 0 ? ObjWorkFlowDetailData[0].detail.weekHoursPercentage : ''}
                        invalid={ObjPersonalDataDetailError.weekHoursPercentage} readOnly onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>

                <Col xs="2">
                    <Label><b>Nº Dias Mês</b></Label>
                    <Input id="monthlyHours" type="number" maxLength="50" className="form-control" autoComplete="off" disabled={hired}
                        value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.monthlyHours !== null && ObjWorkFlowDetailData[0].detail.monthlyHours !== 0 ? ObjWorkFlowDetailData[0].detail.monthlyHours : ''}
                        invalid={ObjPersonalDataDetailError.monthlyHours} readOnly onChange={(e) => handleChangeForm(e, null, true, false)} />
                </Col>

            </Row>

            <Row style={{ marginTop: "10px" }}>
                <Col style={{ float: "right" }}>
                    <Button color="primary" style={{ float: "right", display: hired ? "none" : "inline-block" }} onClick={(e) => handleSave(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                </Col>
            </Row>

            <Modal isOpen={modalJustification.isOpen} toggle={toggleJustification} size="ml">
                <ModalHeader toggle={toggleJustification}>Justificação</ModalHeader>
                <ModalBody>
                    <Container>
                        <Input type="textarea" rows="5" id="contractJustification"
                            value={ObjWorkFlowDetailData.length > 0 && ObjWorkFlowDetailData[0].detail.contractJustification !== null ? ObjWorkFlowDetailData[0].detail.contractJustification : ''}
                            onChange={(e) => handleChangeForm(e, null, true)} />
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => toggleJustification()}><FontAwesomeIcon icon={faSave} /> Fechar</Button>
                </ModalFooter>
            </Modal>

            <Dependents idCandidate={idCandidate} isOpen={modalDependents} />
        </>
    )
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(PersonalData)
