import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'
import { connect } from "react-redux"
import Menu from "./components/global/Menu"
import PageNotFound from './components/global/PageNotFound'
import './custom.css'
import { GetParameter } from './components/global/Utils'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFlag, faHome, faSignOutAlt, faFileAlt, faMobile, faCopyright, faList, faCalculator, faUpload, faCloudUploadAlt, faFile, faDownload, faCog, faTruck, faFileCsv,
    faParachuteBox, faEuroSign, faHamburger, faTh, faClock, faUsers, faCalendar, faEnvelope, faThumbtack, faUser, faEdit, faMale, faStream, faStoreAlt, faDatabase,faRotate
} from '@fortawesome/pro-solid-svg-icons'
import { accessToken, menu, signOut } from './redux/MainSlice'
import Dashboard from './components/dashboard/Dashboard';
import Candidates from './components/candidates/Candidates';

let access_token = null

class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props)
        this.state = {
            Layout: <></>,
            isAuth: false,
            signOut: false
        }

        this.subItems = []
    }

    componentDidMount() {

        /* Auto Refresh */
        var _this = this;
        setInterval(function () {
            // Get access_token with refresh_token
            fetch("/auth/refresh?gas=1")
                .then(response => {
                    if (response.status === 200)
                        return response.json()

                    // don't have refresh token, need authentication
                    alert("O tempo da sessao expirou! Vai ser necessario nova autenticacao para renovar a sessao.")
                    if (response.status === 302)
                        response.text().then(url => document.location.href = url)
                    return null
                })
                .then(json => {
                    if (json === null)
                        return

                    _this.props.dispatch(accessToken(json));
                })
                .catch(e => console.error(e))
        }, 13 * 60 * 1000 /* 13 minutes*/);

        library.add(faFlag, faHome, faFileAlt, faMobile, faCopyright, faList, faFileAlt, faSignOutAlt, faCalculator, faUpload, faCloudUploadAlt, faFile, faDownload,
            faCog, faTruck, faFileCsv, faParachuteBox, faEuroSign, faHamburger, faTh, faClock, faUsers, faCalendar, faEnvelope, faThumbtack, faUser, faEdit, faMale,
            faStream, faStoreAlt, faDatabase, faRotate)

        // check if access_token exists
        if (this.props.access_token === null) {

            if (document.location.pathname.toLowerCase().startsWith("/auth")) {
                const code = GetParameter("code")
                const logout = GetParameter("logout")

                if (logout !== null) {
                    // Remove refresh token
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(logout)
                    }

                    fetch("/auth/logout", requestOptions)
                        .then(response => {
                            if (response.status === 200) {
                                this.props.dispatch(signOut(true));
                                this.setState({ isAuth: false, signOut: true })

                                // Remove the code from url
                                window.history.replaceState(null, null, "/signout?true")
                            }
                        })
                        .catch(e => console.log("app-fetch-01", e))

                    return
                }

                if (code !== null) {
                    // Get access_token with refresh_token
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(code)
                    }

                    fetch("/auth/authorize", requestOptions).then(response => response.json())
                        .then(json => {
                            if (json === null)
                                return

                            // Remove the code from url
                            window.history.replaceState(null, null, "/")

                            access_token = json
                            this.props.dispatch(accessToken(json));
                            this._componentDidMount()
                        })
                        .catch(e => console.log("app-fetch-01", JSON.parse(JSON.stringify(e))))
                }
            }
            else {
                // Get access_token with refresh_token
                fetch("/auth/refresh?gas=1")
                    .then(response => {
                        if (response.status === 200)
                            return response.json()

                        // don't have refresh token, need authentication
                        if (response.status === 302)
                            response.text().then(url => document.location.href = url)
                        return null
                    })
                    .then(json => {
                        if (json === null)
                            return

                        access_token = json
                        this.props.dispatch(accessToken(json));
                        this._componentDidMount();
                    })
                    .catch(e => console.log("app-fetch-02", JSON.parse(JSON.stringify(e))))
            }

            return
        }

        // all ok
        access_token = this.props.access_token

        this._componentDidMount()
    }

    _componentDidMount() {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        fetch("/auth/authorization", requestOptions)
            .then(response => {
                if (response.status === 200)
                    return response.json();

                if (response.status === 302)
                    response.text().then(url => document.location.href = url)

                throw Error(response.statusText)
            })
            .then(json => {
                if (json === null)
                    return;

                this.props.dispatch(menu({ menu: JSON.parse(json.menu), profileId: json.profileId }));
                this.setState({ menu: JSON.parse(json.menu), profileId: json.profileId, isAuth: true })

                if (JSON.parse(json.menu).find(e => e.path === '/Home' && e.profile.includes(json.profileId)) !== undefined) {
                    window.history.replaceState(null, null, "/Home")
                }
            })
            .catch(error => console.log(error))
    }

    render() {

        if (this.state.signOut)
            return (<h2 style={{ textAlign: "center" }}>Pode fechar a janela</h2>)

        if (!this.state.isAuth)
            return (<></>)

        return (
            <Layout>
                <Routes>
                    {
                        this.props.menu
                            .filter(filter => filter.profile.find(prof => this.props.profileId === prof) !== undefined)
                            .filter(filter => Menu[filter.component] != undefined || filter.subMenu != undefined)
                            .map(item => {
                                if (item.subMenu !== undefined) {
                                    item.subMenu
                                        .filter(filter => filter.profile.find(prof => this.props.profileId === prof) !== undefined)
                                        .map(subitem => {
                                            if (subitem.subMenu !== undefined) {
                                                subitem.subMenu
                                                    .filter(filter => filter.profile.find(prof => this.props.profileId === prof) !== undefined)
                                                    .map(sub2item => this.subItems.push(sub2item))
                                            }
                                            return this.subItems.push(subitem)
                                        })
                                }

                                return (
                                    <Route key={item.path} path={item.path} element={Menu[item.component]} />
                                )
                            })
                    }
                    {
                        this.subItems.map(item => <Route key={item.path} path={item.path} element={Menu[item.component]} />)
                    }
                    <Route path="/" element={this.state.profileId === 1 || this.state.profileId === 101 ? <Dashboard/> : <Candidates/>} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.main.access_token,
        menu: state.main.menu,
        profileId: state.main.profileId
    }
}

export default connect(mapStateToProps)(App)