import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { faEdit, faPlus, faFilter, faSave } from '@fortawesome/pro-solid-svg-icons'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input, Label, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Places extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            query: "",
            modal: {
                isOpen: false,
                idPlace: 0,
                name: "",
                type: "",
                url: "",
                urlId: "",
                urlPass: "",
                address: "",
                active: true,
                error: {
                    name: false,
                    type: false,
                    url: false,
                    urlId: false,
                    urlPass: false,
                    address: false
                }
            },
            places: []
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleNewPlace = this.handleNewPlace.bind(this)
        this.handleEditPlace = this.handleEditPlace.bind(this)
        this.handleSavePlace = this.handleSavePlace.bind(this)
        this.handleStatePlace = this.handleStatePlace.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        let query = "type=all&state=active"

        fetch("/api/admin/places?" + query, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                const states = [{ value: "active", text: "Ativo", selected: true, init: true }, { value: "inactive", text: "Inativo", selected: false, init: false }]

                this.setState({
                    table: {
                        title: "Locais",
                        columns: {
                            title: ["Nome", "Tipo", ""],
                            width: [60, 35, 5],
                            align: ["left", "left", "right"],
                            search: [true, true, false]
                        },
                        rows: this.buildGridRows(json),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewPlace(e), icon: faPlus, title: "Novo local" }
                        ],
                        filters: [
                            { id: "lstTypes", type: "list", data: types, label: "Tipos", multiple: false },
                            { id: "lstStates", type: "list", data: states, label: "Estado", multiple: false }
                        ]
                    },
                    query: query,
                    places: json
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json) {
        return json.map((row) => {
            return {
                id: row.idPlace,
                columns: [
                    { column: row.name },
                    { column: types[row.idPlaceType ? 2 : 1].text }
                ],
                actions: [
                    { column: faEdit, title: "Editar", action: () => this.handleEditPlace(row.idPlace) }
                ]
            }
        })
    }

    handleFilter(data) {

        const filter = {
            type: data[0],
            state: data[1]
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        let query = 'type=' + filter.type + '&state=' + filter.state

        this.props.dispatch(Loading(true))

        fetch("/api/admin/places?" + query, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        ...this.state.table,
                        rows: this.buildGridRows(json)
                    },
                    query: query,
                    places: json
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    handleNewPlace(e) {
        e.preventDefault()

        this.setState({
            modal: {
                isOpen: true, idPlace: 0, name: "", type: "", url: "", urlId: "", urlPass: "", address: "", active: true,
                error: { name: false, type: false, url: false, urlId: false, urlPass: false, address: false }
            }
        })
    }

    handleEditPlace(idPlace) {
        const place = this.state.places.find(f => f.idPlace === idPlace)
        place.type = place.idPlaceType ? "presential" : "digital"

        this.setState({
            modal: {
                ...this.state.modal, isOpen: true, idPlace: idPlace, name: place.name, type: place.type,
                url: (place.link === null || place.link === undefined) ? "" : place.link, urlId: (place.linkId === null || place.linkId === undefined) ? "" : place.linkId,
                urlPass: (place.linkPass === null || place.linkPass === undefined) ? "" : place.linkPass, address: (place.address === null || place.address === undefined) ? "" : place.address,
                active: place.active
            }
        })
    }

    handleSavePlace() {
        let modal = { ...this.state.modal }

        modal.error.name = modal.name === ""
        modal.error.type = modal.type === ""
        modal.error.url = modal.type === "digital" && modal.url === ""
        modal.error.address = modal.type === "presential" && modal.address === ""

        if (modal.error.name || modal.error.type || modal.error.url || modal.error.urlId || modal.error.urlPass || modal.error.address) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const data = {
            idPlace: modal.idPlace, idPlaceType: modal.type === "presential", name: modal.name, address: modal.address !== "" ? modal.address : null,
            link: modal.url !== "" ? modal.url : null, linkId: modal.urlId !== "" ? modal.urlId : null, linkPass: modal.urlPass !== "" ? modal.urlPass : null, active: modal.active
        }

        this.props.dispatch(Loading(true))

        let requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        fetch("/api/admin/places", requestOptions)
            .then(response => {
                return response.status === 200 ? "" : response.then(Promise.reject.bind(Promise))
            })
            .then(() => {
                let requestOptions = {
                    method: "GET",
                    headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
                }

                fetch("/api/admin/places?" + this.state.query, requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        this.setState({
                            table: { ...this.state.table, rows: this.buildGridRows(json) },
                            places: json,
                            modal: { ...this.state.modal, isOpen: false }
                        })

                        this.props.dispatch(Toast("Local gravado", ToastTypes.Success, false))
                    })
                    .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    handleStatePlace() {
        this.setState({ modal: { ...this.state.modal, active: !this.state.modal.active } })
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Local</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Label>Nome</Label>
                                    <Input id="PlaceName" type="text" value={this.state.modal.name} autoComplete="nope" invalid={this.state.modal.error.name}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, name: e.target.value,
                                                    error: { ...this.state.modal.error, name: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Tipo</Label>
                                    <Input id="PlaceType" type="select" value={this.state.modal.type} invalid={this.state.modal.error.type} disabled={this.state.modal.idPlace !== 0}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, type: e.target.value,
                                                    error: { ...this.state.modal.error, type: e.target.value === "" }
                                                }
                                            })
                                        }}>
                                        <option value=""></option>
                                        {types.filter(f => f.value !== "all").map(t => <option key={t.value} value={t.value}>{t.text}</option>)}
                                    </Input>
                                </Col>
                                <Col>
                                    <Label>Ativo</Label><br />
                                    <Input type="switch" id="PlaceActive" label={!this.state.modal.active ? "Inativo" : "Ativo"}
                                        checked={this.state.modal.active} onChange={(e) => this.setState({ modal: { ...this.state.modal, active: e.target.checked } })} />
                                </Col>
                            </Row>
                            <div style={{ display: this.state.modal.type === "digital" ? "" : "none" }}>
                                <Row>
                                    <Col>
                                        <Label>URL</Label>
                                        <Input id="PlaceUrl" type="text" value={this.state.modal.url} autoComplete="nope" invalid={this.state.modal.error.url}
                                            onChange={(e) => {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal, url: e.target.value,
                                                        error: { ...this.state.modal.error, url: e.target.value === "" }
                                                    }
                                                })
                                            }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>Id</Label>
                                        <Input id="PlaceUrlId" type="text" value={this.state.modal.urlId} autoComplete="nope" invalid={this.state.modal.error.urlId}
                                            onChange={(e) => {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal, urlId: e.target.value,
                                                        error: { ...this.state.modal.error, urlId: e.target.value === "" }
                                                    }
                                                })
                                            }} />
                                    </Col>
                                    <Col>
                                        <Label>Pass</Label>
                                        <Input id="PlaceUrlPass" type="text" value={this.state.modal.urlPass} autoComplete="nope" invalid={this.state.modal.error.urlPass}
                                            onChange={(e) => {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal, urlPass: e.target.value,
                                                        error: { ...this.state.modal.error, urlPass: e.target.value === "" }
                                                    }
                                                })
                                            }} />
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ display: this.state.modal.type === "presential" ? "" : "none" }}>
                                <Row>
                                    <Col>
                                        <Label>Morada</Label>
                                        <Input id="PlaceAddress" type="textarea" value={this.state.modal.address} autoComplete="nope" rows="4" invalid={this.state.modal.error.address}
                                            onChange={(e) => {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal, address: e.target.value,
                                                        error: { ...this.state.modal.error, address: e.target.value === "" }
                                                    }
                                                })
                                            }} />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </ModalBody>
                    <ModalFooter>

                        <Button color="primary" onClick={this.handleSavePlace}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const types = [
    { value: "all", text: "Todos", selected: true, init: true },
    { value: "digital", text: "Digital", selected: false, init: false },
    { value: "presential", text: "Presencial", selected: false, init: false }]
const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(Places)
