import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    costCenter: 0, lstCostCenter: []
}

export const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        costCenter: (state, action) => {
            state.costCenter = action.payload;
        },
        lstCostCenter: (state, action) => {
            state.lstCostCenter = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { costCenter, lstCostCenter } = candidateSlice.actions

export default candidateSlice.reducer