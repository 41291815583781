import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faEye, faInboxOut, faSave, faTrash, faUserCheck, faUserXmark } from '@fortawesome/pro-solid-svg-icons'
import { formatDate, formatDateTime, Loading, Toast, ToastTypes } from "../global/Utils";
import { agendaIntervirwers, agendaPhases, agendaPlaces, handleEmail, handleEvent } from "../../redux/AgendaSlice";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import McdTable from "../global/Table";
import { Email } from "./Email";

export const Event = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.agenda.handle_event);
    const access_token = useSelector(state => state.main.access_token);
    const phases = useSelector(state => state.agenda.phases);
    const places = useSelector(state => state.agenda.places);
    const interviewers = useSelector(state => state.agenda.interviewers);
    const handle_email = useSelector(state => state.agenda.handle_email);

    const [event, setEvent] = useState({ description: "", lotation: "", phaseId: "", placeId: "", beginDate: "", endDate: "", interviewers: [], phaseTypeId: 0 });
    const [eventErr, setEventError] = useState({ description: false, beginDate: false, endDate: false });
    const [activeTab, setTab] = useState("1");
    const [candidates, setCandidates] = useState({ columns: { title: [], width: [], align: [], search: [] }, rows: [] });

    const handleToggle = () => {
        dispatch(handleEvent({ ...state, isOpen: !state.isOpen }));
    }

    useEffect(() => {

        if (!state.isOpen)
            return;

        setEventError({ description: false, beginDate: false, endDate: false });

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        if (phases.length === 0) {
            fetch("/api/agenda/phases", requestOptions).then(response => response.json())
                .then(data => dispatch(agendaPhases(data)))
                .catch(_ => dispatch(Toast("Não foi possível obter as fases.", ToastTypes.Danger, false)));
        }

        if (places.length === 0) {
            fetch("/api/agenda/places", requestOptions).then(response => response.json())
                .then(data => dispatch(agendaPlaces(data)))
                .catch(_ => dispatch(Toast("Não foi possível obter os locais.", ToastTypes.Danger, false)));
        }

        if (interviewers.length === 0) {
            fetch("/api/agenda/interviewers", requestOptions).then(response => response.json())
                .then(data => dispatch(agendaIntervirwers(data)))
                .catch(_ => dispatch(Toast("Não foi possível obter os responsáveis pelo processo.", ToastTypes.Danger, false)));
        }

        if (state.eventId == 0)
            return setEvent({
                description: "", lotation: "", phaseId: "", placeId: "", beginDate: state.beginDate + "T00:00", endDate: state.endDate + "T00:00",
                interviewers: [], phaseTypeId: 0
            });

        fetch("/api/agenda/event/" + state.eventId, requestOptions).then(response => response.json())
            .then(data => {
                data.lotation = data.lotation === -1 ? "" : data.lotation;
                data.phaseId = data.phaseId ?? "";
                data.placeId = data.placeId ?? "";
                data.beginDate = data.beginDate;
                data.endDate = data.endDate;
                data.phaseTypeId = data.phaseTypeId;

                setEvent(data);

                fetch("/api/agenda/event/" + state.eventId + "/candidates", requestOptions).then(response => response.json())
                    .then(dataC => {
                        const candidates = { columns: {}, rows: [] };

                        candidates.columns = {
                            title: ["Nome", "Data Nasc.", "Função", "Restaurante", ""],
                            width: [20, 20, 20, 15, 5],
                            align: ["left", "left", "left", "left", "right"],
                            search: [false, false, false, false, false]
                        };
                        candidates.rows = dataC.map(a => ({
                            id: a.candidateId,
                            columns: [
                                { column: a.name },
                                { column: formatDate(a.birthDate) },
                                { column: a.category },
                                { column: a.costCenter }
                            ],
                            actions: [
                                { column: !a.attended ? faUserXmark : faUserCheck, color: !a.attended ? "red" : "rgb(79, 165, 112)", tooltip: "", action: () => handleAttendance(state.eventId, a.candidateId) }
                            ]
                        }))

                        setCandidates(candidates);
                    });
            })
            .catch(_ => dispatch(Toast("Não foi possível obter o evento.", ToastTypes.Danger, false)));

    }, [state]);

    const handleChange = (e, id) => {

        switch (e.type) {
            case "select-multiple":
                setEvent(prev => ({ ...prev, [e.id]: Array.from(e.selectedOptions, (option) => option.value) }));
                break;
            case undefined: // date
                setEvent(prev => ({ ...prev, [id]: e }));

                break;
            default:
                setEvent(prev => ({ ...prev, [e.id]: e.value }));
        }

        if (id == undefined && e.id == "description" && e.value != "")
            return setEventError(prev => ({ ...prev, description: false }));
    }

    const handleSaveEvent = () => {

        let err = {};
        err.description = event.description === "";
        err.beginDate = event.beginDate === "";
        err.endDate = event.endDate === "";

        if (!err.beginDate && !err.endDate) {
            err.beginDate = new Date(event.beginDate) > new Date(event.endDate);
            err.endDate = err.beginDate;
        }

        if (err.description || err.beginDate || err.endDate)
            return setEventError(err);

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                eventId: state.eventId,
                Description: event.description,
                phaseId: event.phaseId == "" ? null : parseInt(event.phaseId),
                placeId: event.placeId == "" ? null : parseInt(event.placeId),
                BeginDate: formatDateTime(event.beginDate),
                EndDate: formatDateTime(event.endDate),
                Lotation: event.lotation == "" ? -1 : parseInt(event.lotation),
                Interviewers: event.interviewers,
                Active: true
            })
        }

        dispatch(Loading(true));

        fetch("/api/agenda/event", requestOptions)
            .then(response => {
                if (response.status == 200) {
                    dispatch(handleEvent({ isOpen: false, needRefresh: true }));

                    return dispatch(Toast("Evento gravado com sucesso", ToastTypes.Success, false));
                }

                dispatch(Toast("Não foi possível gravar o evento.", ToastTypes.Danger, true));
            })
            .catch(_ => dispatch(Toast("Não foi possível gravar o evento.", ToastTypes.Danger, true)));
    }

    const handleRemoveEvent = () => {

        if (!window.confirm("Tem a certeza que pretende eliminar o evento?"))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        dispatch(Loading(true));

        fetch("/api/agenda/event/" + event.eventId, requestOptions)
            .then(response => {
                if (response.status == 200) {
                    dispatch(handleEvent({ isOpen: false, needRefresh: true }));

                    return dispatch(Toast("Evento eliminado", ToastTypes.Success, false));
                }

                dispatch(Toast("Não foi possível eliminar o evento.", ToastTypes.Danger, true));
            })
            .catch(_ => dispatch(Toast("Não foi possível eliminar o evento.", ToastTypes.Danger, true)));
    }

    const handleSendNotification = () => {
        dispatch(handleEmail({ isOpen: true }));
    }

    const handleAttendance = async (eventId, candidateId) => {

        try {
            const requestOptions = { method: "PATCH", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" } }

            dispatch(Loading(true));

            const response = await fetch(`/api/agenda/event?eventid=${eventId}&candidateid=${candidateId}`, requestOptions);
            if (!response.ok)
                throw new Error();

            requestOptions.method = "GET";
            const response2 = await fetch("/api/agenda/event/" + state.eventId + "/candidates", requestOptions);
            if (!response2.ok)
                throw new Error();

            const dataC = await response2.json();

            const candidates = { columns: {}, rows: [] };

            candidates.columns = {
                title: ["Nome", "Data Nasc.", "Função", "Restaurante", ""],
                width: [20, 20, 20, 15, 5],
                align: ["left", "left", "left", "left", "right"],
                search: [false, false, false, false, false]
            };
            candidates.rows = dataC.map(a => ({
                id: a.candidateId,
                columns: [
                    { column: a.name },
                    { column: formatDate(a.birthDate) },
                    { column: a.category },
                    { column: a.costCenter }
                ],
                actions: [
                    { column: !a.attended ? faUserXmark : faUserCheck, color: !a.attended ? "red" : "rgb(79, 165, 112)", tooltip: "", action: () => handleAttendance(state.eventId, a.candidateId) }
                ]
            }))

            setCandidates(candidates);
        } catch (e) {
            console.error(e);
            dispatch(Toast("Não foi possível alterar o estado.", ToastTypes.Danger, false));
        }
        finally {
            dispatch(Loading(false));
        }
    }

    return (
        <>
            <Modal size="xl" isOpen={state.isOpen} toggle={handleToggle}>
                <ModalHeader toggle={handleToggle}>Evento</ModalHeader>
                <ModalBody>
                    {candidates.rows.length != 0 && state.eventId != 0 ?
                        <>
                            <Nav tabs style={{ flexWrap: `inherit` }}>
                                <NavItem>
                                    <NavLink onClick={() => setTab("1")} className={classnames({ active: activeTab == "1" })}>
                                        <span style={{ paddingLeft: `6px` }}>Evento</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => setTab("2")} className={classnames({ active: activeTab == "2" })}>
                                        <span style={{ paddingLeft: `6px` }}>Candidatos</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col>
                                            <Label>Nome</Label>
                                            <Input id="description" type="text" value={event.description} invalid={eventErr.description} maxLength="50"
                                                autoComplete="nope" onChange={(e) => handleChange(e.target)} />
                                        </Col>
                                        <Col>
                                            <Label>Lotação</Label>
                                            <Input id="lotation" type="number" value={event.lotation} maxLength="3" onChange={(e) => handleChange(e.target)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Fase</Label>
                                            <Input id="phaseId" type="select" value={event.phaseId} onChange={(e) => handleChange(e.target)}>
                                                <option value=""></option>
                                                {phases.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Label>Local</Label>
                                            <Input id="placeId" type="select" value={event.placeId} onChange={(e) => handleChange(e.target)}>
                                                <option value=""></option>
                                                {places.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Data de início</Label>
                                            <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={15} showMonthDropdown showYearDropdown id="beginDate"
                                                showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off"
                                                selected={(new Date(event.beginDate)).getTime()} className={`form-control ${eventErr.beginDate ? "is-invalid" : ""}`}
                                                onChange={(e) => handleChange(e, "beginDate")}>
                                            </DatePicker>
                                        </Col>
                                        <Col>
                                            <Label>Data de fim</Label>
                                            <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={15} showMonthDropdown showYearDropdown id="endDate"
                                                showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off"
                                                selected={(new Date(event.endDate)).getTime()} className={`form-control ${eventErr.endDate ? "is-invalid" : ""}`}
                                                onChange={(e) => handleChange(e, "endDate")}>
                                            </DatePicker>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6">
                                            <Label>Responsáveis pelo Processo <span onClick={_ => setEvent(prev => ({ ...prev, interviewers: [] }))} className="remove-interviewer"
                                                title="Remover seleção">
                                                <FontAwesomeIcon icon={faEraser} /></span></Label>
                                            <Input id="interviewers" type="select" multiple value={event.interviewers} onChange={(e) => handleChange(e.target)}>
                                                {interviewers.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                            </Input>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col>
                                            <McdTable title={`Vagas: ${event.lotation - candidates.rows.filter(f => f.actions[0].color !== "red").length}`}
                                                columns={candidates.columns} rows={candidates.rows} pagination={false} actions={[]} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Label>Nome</Label>
                                    <Input id="description" type="text" value={event.description} invalid={eventErr.description} maxLength="50"
                                        autoComplete="nope" onChange={(e) => handleChange(e.target)} />
                                </Col>
                                <Col>
                                    <Label>Lotação</Label>
                                    <Input id="lotation" type="number" value={event.lotation} maxLength="3" onChange={(e) => handleChange(e.target)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Fase</Label>
                                    <Input id="phaseId" type="select" value={event.phaseId} onChange={(e) => handleChange(e.target)}>
                                        <option value=""></option>
                                        {phases.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                    </Input>
                                </Col>
                                <Col>
                                    <Label>Local</Label>
                                    <Input id="placeId" type="select" value={event.placeId} onChange={(e) => handleChange(e.target)}>
                                        <option value=""></option>
                                        {places.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Data de início</Label>
                                    <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={15} showMonthDropdown showYearDropdown id="beginDate"
                                        showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off"
                                        selected={(new Date(event.beginDate)).getTime()} className={`form-control ${eventErr.beginDate ? "is-invalid" : ""}`}
                                        onChange={(e) => handleChange(e, "beginDate")}>
                                    </DatePicker>
                                </Col>
                                <Col>
                                    <Label>Data de fim</Label>
                                    <DatePicker showTimeSelect timeFormat="HH:mm" timeIntervals={15} showMonthDropdown showYearDropdown id="endDate"
                                        showPopperArrow={false} dateFormat="yyyy-MM-dd HH:mm" timeCaption="Horas" todayButton="Hoje" autoComplete="off"
                                        selected={(new Date(event.endDate)).getTime()} className={`form-control ${eventErr.endDate ? "is-invalid" : ""}`}
                                        onChange={(e) => handleChange(e, "endDate")}>
                                    </DatePicker>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Label>Responsáveis pelo Processo <span onClick={_ => setEvent(prev => ({ ...prev, interviewers: [] }))} className="remove-interviewer" title="Remover seleção">
                                        <FontAwesomeIcon icon={faEraser} /></span></Label>
                                    <Input id="interviewers" type="select" multiple value={event.interviewers} onChange={(e) => handleChange(e.target)}>
                                        {interviewers.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                                    </Input>
                                </Col>
                            </Row>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    {event.phaseTypeId == 1 ? <Button color="info" outline onClick={_ => handleSendNotification()}><FontAwesomeIcon icon={faInboxOut} /> Email</Button> : null}
                    {state.eventId != 0 ? <Button color="danger" outline onClick={_ => handleRemoveEvent()}><FontAwesomeIcon icon={faTrash} /> Eliminar</Button> : null}
                    <Button color="primary" onClick={_ => handleSaveEvent()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                </ModalFooter>
            </Modal>
            <Email eventId={event.eventId} />
        </>
    )
}