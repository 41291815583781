import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col } from "reactstrap";
import { handleEvent } from "../../../redux/AgendaSlice";
import { formatDate } from "../../global/Utils";

export const WeekEvent = (props) => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (props.events.length == 0)
            return setEvents([]);

        let evts = [];
        let total = props.events.length;
        let prevHeight = 0;
        props.events.forEach((evt, pos) => {
            if (formatDate(evt.begin) != formatDate(evt.end))
                evt.end = formatDate(new Date(evt.end)) + " 00:00";

            const dtBegin = new Date(evt.begin);
            const dtEnd = new Date(evt.end);

            const diff = dtEnd - dtBegin;

            let h = (diff / 1000 / 60 / 15) * 25;
            let t = (-1 * prevHeight - (2 * pos)) + (dtBegin.getMinutes() == 15 ? 25 : dtBegin.getMinutes() == 30 ? 50 : dtBegin.getMinutes() == 45 ? 75 : 0);
            let l = total == 1 || pos == 0 ? (props.events[0].position * 5) : (100 / total) * pos;
            let w = 100 - l;

            evts.push({
                eventId: evt.eventId, description: evt.description, begin: evt.begin, end: evt.end, width: w, height: h, top: t, left: l,
                color: evt.color, extraHeight: dtEnd.getMinutes() == 0 || Math.floor(h / 100) > 3 ? Math.floor(h / 100) - 2 : 0,
                email: evt.email
            });

            prevHeight += h;
        });

        setEvents(evts);

    }, [props.events]);

    const handleEventById = (id) => {
        dispatch(handleEvent({ isOpen: true, eventId: id }));
    }

    if (events.length == 0)
        return (<Col></Col>)

    return (
        <Col style={{ height: 100 + "%" }}>
            {events.map(evt =>
                <div key={evt.eventId} className="agenda-weekly-event" onClick={() => handleEventById(evt.eventId)}
                    style={{ width: `${evt.width}%`, height: `calc(${evt.height}% + ${evt.extraHeight}px)`, top: `${evt.top}%`, left: `${evt.left}%`, backgroundColor: evt.color }}
                    title={`${evt.description} ${evt.begin.substring(10, 16)}-${evt.end.substring(11, 16)}`}>
                    <div><b>{evt.description}</b></div>
                    <div>
                        {`${evt.begin.substring(10, 16)} - ${evt.end.substring(11, 16)}`}
                        {evt.email ? <FontAwesomeIcon icon={faCheck} style={{ marginLeft: `5px`, color: "#53bdeb", marginTop: `4px` }} /> : null}
                    </div>
                </div>
            )}
        </Col>
    )
}