import React from 'react'
import { connect } from "react-redux"
import McdTable from "../../global/Table"
import { Toast, ToastTypes, Loading, formatDate } from "../../global/Utils"
import { faEdit, faPlus, faFilter, faSave, faList } from '@fortawesome/pro-solid-svg-icons'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input, Label, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SurveysAdm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            query: "",
            modal: {
                isOpen: false,
                surveyId: 0,
                title: "",
                fromDate: "",
                toDate: "",
                error: {
                    title: false,
                    fromDate: false,
                    toDate: false
                }
            },
            categories: [],
            surveys: [],
            categoryId: 0
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleNewSurvey = this.handleNewSurvey.bind(this)
        this.handleEditSurvey = this.handleEditSurvey.bind(this)
        this.handleSaveSurvey = this.handleSaveSurvey.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([fetch("/api/admin/surveys/categories", requestOptions)])
            .then(allResponses => {
                if (allResponses[0].status !== 200)
                    throw new Error("Error loading categories")

                Promise.all([allResponses[0].json()])
                    .then(json => {
                        const categories = json[0].map((a, index) => { return { value: a.categoryId, text: a.title, selected: index === 0, init: index === 0 } })

                        let query = "category=1&state=active"

                        fetch("/api/admin/surveys?" + query, requestOptions)
                            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
                            .then(json => {
                                const states = [{ value: "active", text: "Ativo", selected: true, init: true }, { value: "inactive", text: "Inativo", selected: false, init: false }]

                                this.setState({
                                    table: {
                                        title: "Questionários",
                                        columns: {
                                            title: ["Nome", "De", "A", "Questions", ""],
                                            width: [45, 20, 20, 5, 10],
                                            align: ["left", "center", "center", "right", "right"],
                                            search: [true, true, true, false, false]
                                        },
                                        rows: this.buildGridRows(json),
                                        actions: [
                                            { action: "filter", icon: faFilter },
                                            { action: (e) => this.handleNewSurvey(e), icon: faPlus, title: "Novo questionário" }
                                        ],
                                        filters: [
                                            { id: "lstCategories", type: "list", data: categories, label: "Modelos", multiple: false },
                                            { id: "lstStates", type: "list", data: states, label: "Estado", multiple: false }
                                        ]
                                    },
                                    query: query,
                                    surveys: json,
                                    categories: categories,
                                    categoryId: parseInt(categories[0].value)
                                })

                                this.props.dispatch(Loading(false))
                            })
                    })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json) {
        return json.map((row) => {
            return {
                id: row.id,
                columns: [
                    { column: row.title },
                    { column: formatDate(row.fromDate) },
                    { column: formatDate(row.toDate) === "" ? "-" : formatDate(row.toDate) },
                    { column: row.totalQuestions }
                ],
                actions: [
                    { column: faEdit, tooltip: "Editar", action: () => this.handleEditSurvey(row.id) },
                    { column: faList, tooltip: "Questões", color: "#4fa570", action: "./" + (row.haveAnswers ? "preview" : "edit") + "?id=" + row.id }
                ]
            }
        })
    }

    handleFilter(data) {

        const filter = {
            category: parseInt(data[0]),
            state: data[1]
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        let query = 'category=' + filter.category + '&state=' + filter.state

        this.props.dispatch(Loading(true))

        fetch("/api/admin/surveys?" + query, requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
                this.setState({
                    table: {
                        ...this.state.table,
                        rows: this.buildGridRows(json)
                    },
                    query: query,
                    surveys: json,
                    categoryId: filter.category
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    handleNewSurvey(e) {
        e.preventDefault()

        this.setState({
            modal: {
                isOpen: true, surveyId: 0, title: "", fromDate: "", toDate: "",
                error: { title: false, fromDate: false, toDate: false }
            }
        })
    }

    handleEditSurvey(surveyId) {
        const survey = this.state.surveys.find(f => f.id === surveyId)

        this.setState({
            modal: {
                ...this.state.modal, error: { title: false, fromDate: false, toDate: false }, isOpen: true, surveyId: surveyId, title: survey.title,
                fromDate: formatDate(survey.fromDate), toDate: formatDate(survey.toDate)
            }
        })
    }

    handleSaveSurvey() {
        let modal = { ...this.state.modal }

        modal.error.title = modal.title === ""
        modal.error.fromDate = modal.fromDate === ""
        if (modal.fromDate !== "" && modal.toDate !== "") {
            let from = new Date(modal.fromDate)
            let to = new Date(modal.toDate)

            modal.error.toDate = from >= to
        }

        if (modal.error.title || modal.error.fromDate || modal.error.toDate) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const data = {
            id: modal.surveyId, title: modal.title, fromDate: modal.fromDate, toDate: modal.toDate === "" ? null : modal.toDate, categoryId: this.state.categoryId
        }

        this.props.dispatch(Loading(true))

        let requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        fetch("/api/admin/surveys", requestOptions)
            .then(response => {
                if (response.status === 409) {
                    this.props.dispatch(Toast("Tem de corrigir as datas", ToastTypes.Warning, false))
                    return
                }

                if (response.status === 200) {
                    let requestOptions = {
                        method: "GET",
                        headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
                    }

                    fetch("/api/admin/surveys?" + this.state.query, requestOptions)
                        .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
                        .then(json => {
                            this.setState({
                                table: { ...this.state.table, rows: this.buildGridRows(json) },
                                surveys: json,
                                modal: { ...this.state.modal, isOpen: false }
                            })

                            this.props.dispatch(Toast("Questionário gravado", ToastTypes.Success, false))
                        })
                }
                else {
                    return response.then(Promise.reject.bind(Promise))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Questionário</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Label>Nome</Label>
                                    <Input id="SurveyName" type="text" value={this.state.modal.title} autoComplete="nope" invalid={this.state.modal.error.title}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, title: e.target.value,
                                                    error: { ...this.state.modal.error, title: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Inicio a</Label>
                                    <Input id="FromDate" type="date" value={this.state.modal.fromDate} autoComplete="nope" invalid={this.state.modal.error.fromDate}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, fromDate: e.target.value,
                                                    error: { ...this.state.modal.error, fromDate: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Em vigor até</Label>
                                    <Input id="ToDate" type="date" value={this.state.modal.toDate} autoComplete="nope" invalid={this.state.modal.error.toDate}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, toDate: e.target.value,
                                                    error: { ...this.state.modal.error, toDate: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSaveSurvey}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(SurveysAdm)
