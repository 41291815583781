import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { handleEvent } from "../../../redux/AgendaSlice";

export const Events = (props) => {
    const dispatch = useDispatch();

    const handleEventById = (id) => {
        dispatch(handleEvent({ isOpen: true, eventId: id }));
    }

    return (
        <div className="agenda-monthly-day-events invisible-scrollbar">
            {props.events.map(event =>
                <div key={event.eventId} className={"agenda-monthly-events" + ((new Date(event.end)) < (new Date()) ? " older-event" : "")}
                    onClick={() => handleEventById(event.eventId)}>
                    <div className="agenda-monthly-events-dot" style={{ backgroundColor: event.color }}></div>
                    <div>{event.time}</div>
                    <div className="agenda-monthly-events-text">
                        {event.description}
                        {event.email ? <FontAwesomeIcon icon={faCheck} style={{ float: "right", color: "#53bdeb", marginTop: `4px` }} /> : null}
                    </div>
                </div>
            )}
        </div>
    )
}