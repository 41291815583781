import { loading, alert } from "../../redux/MainSlice"

export const Loading = (show) => loading(show);

export const Toast = (text, type, fixed) => {
    if (type === undefined) type = ToastTypes.Info
    if (fixed === undefined) fixed = false

    return alert({ show: true, text: text, color: type, fixed: fixed });
}

export const ToastTypes = {
    Success: "success",
    Danger: "danger",
    Warning: "warning",
    Info: "info"
}

export const GetParameter = (parameter) => {
    const urlParams = new URLSearchParams(document.location.search)
    return urlParams.get(parameter)
}

export function b64EncodeUnicode(str) {
    return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function formatDate(date) {
    if (date === null || date === undefined)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateHours(date) {
    if (date === null || date === undefined)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return [year, month, day].join('-') + ' ' + [hours, minutes].join(':');
}

export function addDays(date, days) {
    let someDate = date === "" || date === null ? new Date() : date
    let copiedDate = new Date(someDate.getTime())

    copiedDate.setDate(copiedDate.getDate() + days)

    return copiedDate
}

export function zeroPad(num, places) {
    return String(num).padStart(places, '0')
}

export function formatDisplayHour(hour) {

    var hasPlus = false;

    if (hour.toString().replace('+', '').length >= 3) {

        if (hour.toString().indexOf('+') >= 0)
            hasPlus = true;

        var hours = zeroPad(hour.toString().replace('+', '').toString().slice(0, hour.toString().replace('+', '').toString().length - 2).toString(), 2);
        var minutes = zeroPad(hour.toString().replace('+', '').toString().slice(hour.toString().replace('+', '').toString().length - 2).toString(), 2)

        hour = (hasPlus ? '+' : '') + (hours + ':' + minutes)
    }
    return hour
}

export function formatHour(hour) {

    var hasPlus = false;

    if (hour.toString().replace('+', '').length === 4) {

        if (hour.toString().indexOf('+') >= 0)
            hasPlus = true;

        var hours = zeroPad(hour.toString().replace('+', '').toString().slice(0, hour.toString().replace('+', '').toString().length - 2).toString(), 2);
        var minutes = zeroPad(hour.toString().replace('+', '').toString().slice(hour.toString().replace('+', '').toString().length - 2).toString(), 2)

        hour = (hasPlus ? '+' : '') + (hours + ':' + minutes)
    }
    return hour
}

export function formatHour2(hour) {

    let hasPlus = hour.toString().indexOf('+') >= 0;

    let hours = zeroPad(hour.toString().replace('+', '').toString().slice(0, hour.toString().replace('+', '').toString().length - 2).toString(), 2);
    let minutes = zeroPad(hour.toString().replace('+', '').toString().slice(hour.toString().replace('+', '').toString().length - 2).toString(), 2)

    hour = (hasPlus ? '+' : '') + (hours + ':' + minutes)

    return hour
}

export function ValidateEmail(inputText) {
    return inputText.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
}

export function getStrWeek(intWeek) {

    var strWeek;

    switch (intWeek) {
        case 1:
            strWeek = 'Segunda'
            break;
        case 2:
            strWeek = 'Terca'
            break;
        case 3:
            strWeek = 'Quarta'
            break;
        case 4:
            strWeek = 'Quinta'
            break;
        case 5:
            strWeek = 'Sexta'
            break;
        case 6:
            strWeek = 'Sabado'
            break;
        default:
            strWeek = 'Domingo'
    }
    return strWeek;
}

export function getStrMonth(month) {
    switch (month) {
        case 1:
            return "Janeiro";
        case 2:
            return "Fevereiro";
        case 3:
            return "Março";
        case 4:
            return "Abril";
        case 5:
            return "Maio";
        case 6:
            return "Junho";
        case 7:
            return "Julho";
        case 8:
            return "Agosto";
        case 9:
            return "Setembro";
        case 10:
            return "Outubro";
        case 11:
            return "Novembro";
        case 12:
            return "Dezembro";
        default:
            return "";
    }
}

export const APPCONFIG = {
    FileServiceUrl: "http://pt-intranet.corp.pri:9008/api/uploadfiles",
    HashAppId: "405808F8-0493-49E0-BC56-9BA91F381032",
    FileUrl: "http://pt-intranet.corp.pri:9008/OUT/FilesExport/"
}

export function maskPostalCode(PostalCode) {

    if (PostalCode === undefined || PostalCode == null)
        return ''

    if (PostalCode.length > 4) {
        if (PostalCode.charAt(PostalCode.length - 1).trim() === '-')
            return PostalCode.toString()
        else {
            PostalCode = PostalCode.replace('-', '')
            return PostalCode.substring(0, 4) + '-' + PostalCode.substring(4)
        }
    }
    else if (PostalCode.length <= 4 && isNaN(PostalCode) === false) {
        return PostalCode
    }
}

export function CheckPostalCode(postalCode, FullPostalCode) {
    if (FullPostalCode)
        return /^\d{4}-\d{3}?$/.test(postalCode)

    return /^\d{4}(-\d{3})?$/.test(postalCode)
}

export function CheckEmail(email) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
}

export function IsNumeric(ObjVal) {
    return /^\d+$/.test(ObjVal);
}

export function IsValidNIF(nif) {
    var c;
    var checkDigit = 0;
    //Verifica se é null, se é numérico e se tem 9 dígitos
    if (nif !== null && IsNumeric(nif) && nif.length === 9 && nif !== '123456789') {

        //Obtem o primeiro número do NIF
        c = nif.charAt(0);
        //Verifica se o primeiro número é (1, 2, 5, 6, 8 ou 9)
        if (c.trim() === '1' || c.trim() === '2' || c.trim() === '3') {
            //Calculo do Digito de Controle
            checkDigit = c * 9;
            var i = 0;
            for (i = 2; i <= 8; i++) {
                checkDigit += nif.charAt(i - 1) * (10 - i);
            }
            checkDigit = 11 - (checkDigit % 11);

            //Se o digito de controle é maior que dez, coloca-o a zero
            if (checkDigit >= 10)
                checkDigit = 0;

            //Compara o digito de controle com o último numero do NIF
            //Se igual, o NIF é válido.
            if (parseInt(checkDigit) === parseInt(nif.charAt(8)))
                return true;
        }
    }
    return false;
}

export function formatDateTime(date) {
    if (date === null || date === undefined)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return [year, month, day].join('-') + 'T' + [hours, minutes].join(':');
}

export function CalcWeekHoursPercentage(workload) {
    return ((workload * 100) / 40).toFixed(2);
}

export function CalcMonthlyHoursPersonalData(WeekDays) {
    var calc = (WeekDays * 30) / 5

    if (calc > 30)
        return 30
    else
        return calc

}

export function Convert60to100(value) {

    var result;

    if (value >= 10000)
        return 0;

    if (value.toString().length > 2) {
        result = (Math.floor(value / 100) * 100) + ((value % 100) * 100) / 60;
    }
    else {

        result = (value * 100) / 60;
    }
    return result;
}

export function Convert100to60(value) {

    var result;

    //if (value >= 1000)
    //    return 0;

    var minutes;
    minutes = Math.round((value * 60) / 100.0, 0);

    result = Math.floor(minutes / 60.0) * 100;
    result = result + (minutes - Math.floor(minutes / 60.0) * 60);

    return result;

}

export function DateUtc(value) {
    return new Date(Date.UTC(new Date(value).getFullYear(), new Date(value).getMonth(), new Date(value).getDate()))
}

export function LoadLocalStorage(id, type, defaultValue, defaultSelected) {
    let _id = localStorage.getItem(id) ?? "default";

    if (_id == "default")
        return type != "list" ? defaultValue : defaultSelected;

    if (type != "list") {
        if (type == "date")
            return _id == "" ? null : new Date(_id);

        return _id;
    }

    return _id.split(",").find(a => parseInt(a) == defaultValue) != undefined;
}