import RecruitmentSource from '../Maintenance/RecruitmentSource';
import Interviewer from '../Maintenance/Interviewer';
import Places from '../Maintenance/Places';
import Emails from '../Maintenance/Emails';
import RecruitmentSourceInfo from '../Maintenance/RecruitmentSourceInfo';
import EmployeeRequest from '../EmployeeRequest/EmployeeRequest';
import EmployeeRequestSchedule from '../EmployeeRequest/EmployeeRequestSchedule';
import Uniform from '../Maintenance/Uniform';
import Phase from '../Maintenance/Phase';
import SignOut from '../SignOut';
import SurveysAdm from "../Maintenance/surveys/Surveys";
import SurveysAdmEdit from "../Maintenance/surveys/SurveysEdit";
import SurveysAdmPreview from "../Maintenance/surveys/SurveysPreview";
import Candidates from '../candidates/Candidates';
import CandidateDetail from '../candidates/CandidateDetail';
import Surveys from "../surveys/Surveys";
import { Month } from "../agenda/Month";
import { Week } from '../agenda/Week';
import Dashboard from '../dashboard/Dashboard';

const menu = {
    "Dashboard": <Dashboard />,
    "RecruitmentSource": <RecruitmentSource />,
    "Interviewer": <Interviewer />,
    "Places": <Places />,
    "Emails": <Emails />,
    "Uniform": <Uniform />,
    "Phase": <Phase />,
    "RecruitmentSourceInfo": <RecruitmentSourceInfo />,
    "EmployeeRequest": <EmployeeRequest />,
    "EmployeeRequestSchedule": <EmployeeRequestSchedule />,
    "SignOut": <SignOut />,
    "SurveysAdm": <SurveysAdm />,
    "SurveysAdmEdit": <SurveysAdmEdit />,
    "SurveysAdmPreview": <SurveysAdmPreview />,
    "Candidates": <Candidates />,
    "CandidateDetail": <CandidateDetail />,
    "Surveys": <Surveys />,
    "AgendaMonth": <Month />,
    "AgendaWeek": <Week />
};

export default menu;