import React, { useState, useEffect } from "react"
import { Loading, Toast, ToastTypes, formatDateHours } from "../../global/Utils"
import { Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Label } from 'reactstrap'
import { faFileWord, faFilePdf, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import McdTable from "../../global/Table"
import ReactHtmlParser from "react-html-parser";

const Emails = (props) => {

    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [emailTo, setEmailTo] = useState('');
    const [emailCc, setEmailCc] = useState('');
    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState(true);
    const [idCandidate] = useState(props.idCandidate);
    const [grid, setGrid] = useState({
        table: {
            title: "",
            columns: { title: [], width: [], align: [], search: [] },
            rows: [],
            pagination: true,
            actions: []
        }
    });
    const [modal, setModal] = useState({
        isOpen: false
    });

    useEffect(() => {
        BindGrid();
    }, []);

    const BindGrid = () => {
        props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/emailresume/" + idCandidate, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                setGrid({
                    table: {
                        title: "Emails",
                        columns: {
                            title: ["Nome", "Data de Inserção", "Enviado", "Data de Envio", ""],
                            width: [30, 20, 20, 20, 10],
                            align: ["left", "left", "left", "left", "right"],
                            search: [true, true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idEmailQueue,
                                columns: [
                                    { column: row.emailName },
                                    { column: formatDateHours(row.insertionDate) },
                                    { column: row.sent },
                                    { column: row.sentDate !== null ? formatDateHours(row.sentDate) : '' }
                                ],
                                actions: [
                                    { column: faEnvelope, title: "Visualizar", action: (e) => openInfo(e, row.idEmailQueue) }
                                ]
                            }
                        }),
                        actions: [
                        ],
                        filters: []
                    }
                })
                props.dispatch(Loading(false))
            })
            .catch((error) => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel obter os registos.", ToastTypes.Danger, false))
            })
    }

    const toggle = () => {
        setModal({ ...modal, isOpen: !modal.isOpen })
    }

    const openInfo = (e, IdEmailQueue) => {
        e.preventDefault();
        loadEmailData(IdEmailQueue);
    }

    const loadEmailData = (IdEmailQueue) => {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/detailemailsent?idCandidate=" + idCandidate + "&idWorkflow=-1&IdEmailQueue=" + IdEmailQueue, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json.body === null || json.to === null) {
                    setFileData(false)
                }
                else {
                    setEmailBody(json.body)
                    setEmailSubject(json.subject)
                    setEmailTo(json.to)
                    setEmailCc(json.cc)
                    setModal({ ...modal, isOpen: !modal.isOpen })
                    if (json.emailAttach !== null)
                        setFiles(json.emailAttach)
                }
            })
            .catch(() => props.dispatch(Toast("Não foi possível carregar os dados do email", ToastTypes.Danger, false)))

    }

    const setDivFiles = (indexAux) => {
        var counter = -1
        return files.filter(x => x.deletedFile === false).map((result, index) => {
            counter = counter + 1

            return (
                index >= indexAux && index <= indexAux + 5 ?
                    result.newFile ?
                        <div key={index} className="col-lg-2 image-upload">
                            <label id="lblFile" htmlFor="newFile" key={"lblFile" + result.idFile + '_' + indexAux} style={{ cursor: "pointer" }}>
                                <img src={this.state.imagePath} key={"img" + result.idFile + '_' + indexAux} alt="Adicionar Ficheiro" style={{ border: "1px solid #ced4da", height: 60 + 'px', width: 60 + 'px' }} />
                            </label>
                            <input id="newFile" type="file" key={"newFile" + result.idFile + '_' + indexAux} onChange={(e) => this.handleChangeFile(e)} />
                        </div>
                        :
                        <div key={index} className="col-lg-2">
                            <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                                <FontAwesomeIcon title={result.fileName} onClick={(e) =>
                                    downloadFile(e, result.idFile)} key={"fileDownload" + result.idFile + '_' + indexAux} id={"fileDownload" + result.idFile} style={{
                                        height: 42 + 'px', width: 57 + 'px',
                                        color: result.extention === 'pdf' ? 'red' : 'blue', paddingTop: 13 + 'px', cursor: 'pointer'
                                    }} icon={result.extention === 'pdf' ? faFilePdf : faFileWord} />
                            </div>
                        </div>
                    : null
            );
        });
    }

    const downloadFile = (e, idFile) => {
        e.preventDefault();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        props.dispatch(Loading(true))

        fetch("/api/admin/emailfile?idFile=" + idFile, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '') {
                    props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
                    return
                }
                window.open(JSON.parse(json)[0].filePath);
                props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
            })
    }

    return (
        <>
            <McdTable title={grid.table.title} columns={grid.table.columns} rows={grid.table.rows} pagination={grid.table.pagination}
                actions={grid.table.actions} />

            <Modal isOpen={modal.isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Detalhe</ModalHeader>
                <ModalBody>

                    <div style={{ paddingLeft: 5 + 'px', paddingRight: 5 + 'px' }}>
                        {fileData === false ? <Row><Col><Label>Sem Registos</Label></Col></Row> :
                            <div>
                                <Row style={{ marginTop: 5 + 'px' }}>
                                    <Col>
                                        <Label style={{ marginBottom: 0 + 'px' }}><b>Para</b></Label>
                                        <div>
                                            {emailTo}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 5 + 'px' }}>
                                    <Col>
                                        <Label style={{ marginBottom: 0 + 'px' }}><b>CC</b></Label>
                                        <div>
                                            {emailCc}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 + 'px' }}>
                                    <Col>
                                        <Label style={{ marginBottom: 0 + 'px' }}><b>Assunto</b></Label>
                                        <div>
                                            {emailSubject}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 + 'px' }}>
                                    <Col>
                                        <Label style={{ marginBottom: 0 + 'px' }}><b>Mensagem</b></Label>
                                        <div className="emailBody">
                                            {ReactHtmlParser(emailBody)}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 + 'px' }}>
                                    <Col>
                                        <Label><b>Ficheiros</b></Label>
                                        {
                                            files.filter(x => x.deletedFile === false).map((result, index) => {

                                                return (
                                                    index % 6 === 0 ?
                                                        <div className="row" key={"rowFile_" + index + '_' + result.idFile}>
                                                            {setDivFiles(index)}
                                                        </div>
                                                        :
                                                        null
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>

                </ModalBody>
            </Modal>

        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Emails)