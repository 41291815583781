import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading, formatDate } from "../global/Utils"
import { faEye, faFilter, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import { Modal, ModalBody, ModalHeader, Row, Col, Container } from 'reactstrap'
import store from '../../Store'

class Surveys extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            query: "",
            categories: [],
            stores: [],
            surveys: [],
            modal: {
                isOpen: false
            },
            answers: []
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleViewSurvey = this.handleViewSurvey.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([fetch("/api/surveys/categories", requestOptions), fetch("/api/surveys/stores", requestOptions)])
            .then(allResponses => {
                if (allResponses[0].status !== 200 || allResponses[1].status !== 200)
                    throw new Error("Error loading data")

                Promise.all([allResponses[0].json(), allResponses[1].json()])
                    .then(json => {
                        let categories = json[0].map(a => { return { value: a.categoryId, text: a.title, selected: false, init: false } })
                        categories.splice(0, 0, { value: 0, text: "Todos", selected: true, init: true })

                        let stores = json[1].map(a => { return { value: a.key, text: a.value, selected: json[1].length === 1 ? true : false, init: false } })
                        if (stores.length > 1)
                            stores.splice(0, 0, { value: 0, text: "Todos", selected: true, init: true })

                        let query = "name=&store=0&category=0&state=open"

                        fetch("/api/surveys?" + query, requestOptions)
                            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
                            .then(json => {
                                const states = [{ value: "open", text: "Abertos", selected: true, init: true }, { value: "close", text: "Fechados", selected: false, init: false }]
                                
                                this.setState({
                                    table: {
                                        title: "Questionários",
                                        columns: {
                                            title: ["Nome", "Restaurante", "Modelo", "Data", ""],
                                            width: [30, 30, 20, 5, 10],
                                            align: ["left", "left", "left", "center", "right"],
                                            search: [true, true, true, false, false]
                                        },
                                        rows: this.buildGridRows(json, "open"),
                                        actions: [
                                            { action: "filter", icon: faFilter }
                                        ],
                                        filters: [
                                            { id: "txtName", type: "text", label: "Nome" },
                                            { id: "lstStores", type: "list", data: stores, label: "Restaurantes", multiple: true },
                                            { id: "lstCategories", type: "list", data: categories, label: "Modelos", multiple: false },
                                            { id: "lstStates", type: "list", data: states, label: "Estado", multiple: false }
                                        ]
                                    },
                                    query: query,
                                    surveys: json,
                                    categories: categories,
                                    stores: stores
                                })

                                this.props.dispatch(Loading(false))
                            })
                            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
                    })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json, state) {

        return json.map((row, index) => {
            return {
                id: index,
                columns: [
                    { column: row.name },
                    { column: row.storeName },
                    { column: row.categoryName },
                    { column: formatDate(row.date) }
                ],
                actions: [
                    state === "open" ?
                        { column: faWindowClose, tooltip: "Fechar questionário", action: () => this.handleCloseSurvey(index) }
                        :
                        { column: faEye, tooltip: "Visualizar", action: () => this.handleViewSurvey(index) }
                ]
            }
        })
    }

    handleFilter(data) {

        const filter = {
            name: encodeURIComponent(data[0]),
            store: data[1],
            category: parseInt(data[2]),
            state: data[3]
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        let query = "name=" + filter.name + "&store=" + filter.store + "&category=" + filter.category + "&state=" + filter.state

        this.props.dispatch(Loading(true))

        fetch("/api/surveys?" + query, requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
 
                this.setState({
                    table: {
                        ...this.state.table,
                        rows: this.buildGridRows(json, filter.state)
                    },
                    query: query,
                    surveys: json
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    handleCloseSurvey(index) {
        if (!window.confirm("Concorda que o questionário seja finalizado sem respostas?"))
            return;

        let survey = this.state.surveys[index]

        let data = {
            surveyId: survey.surveyId,
            candidateId: survey.candidateId,
            releaseDate: survey.releaseDate
        }

        this.props.dispatch(Loading(true))

        let requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        fetch("/api/surveys/", requestOptions)
            .then(response => {
                if (response.status !== 200)
                    throw response.statusText;

                requestOptions = {
                    method: "GET",
                    headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
                }

                fetch("/api/surveys?" + this.state.query, requestOptions)
                    .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
                    .then(json => {
                        this.setState({
                            table: {
                                ...this.state.table,
                                rows: this.buildGridRows(json, "open")
                            },
                            surveys: json
                        })

                        this.props.dispatch(Loading(false))
                    })
                    .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível fechar o questionário", ToastTypes.Danger, false)))
    }

    handleViewSurvey(index) {
        let survey = this.state.surveys[index]

        this.props.dispatch(Loading(true))

        let requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/surveys/answers?sid=" + survey.surveyId + "&cid=" + survey.candidateId + "&rdt=" + encodeURIComponent(survey.releaseDate), requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
                if (!json) {
                    this.props.dispatch(Toast("Não há respostas para o questionário em questão", ToastTypes.Danger, false))
                    return
                }

                this.setState({
                    answers: json,
                    modal: { ...this.state.modal, isOpen: true }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter o questionário", ToastTypes.Danger, false)))
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Respostas</ModalHeader>
                    <ModalBody>
                        <Container>
                            {
                                this.state.answers.map(a => {
                                    return (<div style={{ marginBottom: 10 + "px" }}>
                                        {
                                            a.answer === "GROUP" ?
                                                <><Row style={{ marginBottom: 2 + "px" }}><Col><b>{a.question}</b></Col></Row></>
                                                :
                                                a.grouping ?
                                                    <><Row style={{ marginBottom: 2 + "px", paddingLeft: 15 + "px" }}><Col><b>P: </b>{a.question}</Col></Row>
                                                        <Row style={{ paddingLeft: 15 + "px" }}><Col><b>R: </b>{a.answer}</Col></Row>
                                                    </>
                                                    :
                                                    <><Row style={{ marginBottom: 2 + "px" }}><Col><b>P: </b>{a.question}</Col></Row>
                                                        <Row><Col><b>R: </b>{a.answer}</Col></Row>
                                                    </>
                                        }
                                    </div>)
                                })
                            }
                        </Container>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(Surveys)
