import React from 'react'
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading, GetParameter } from "../../global/Utils"
import { Row, Col, Input, Label, Container, Button } from 'reactstrap'
import "./SurveysPreview.css"
import { faBackspace } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

class SurveysAdmPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingPage: true,
            surveyId: 0,
            surveyTitle: "",
            surveySummary: "",
            surveyQuestions: []
        }

    }

    componentDidMount() {
        let surveyId = parseInt(GetParameter("id"))
        if (surveyId === undefined) {
            this.props.dispatch(Toast("Não foi possível obter o detalhe", ToastTypes.Danger, true))
            return
        }

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/surveys/" + surveyId, requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
                this.setState({
                    surveyId: surveyId,
                    surveyTitle: json.title,
                    surveySummary: json.summary,
                    surveyQuestions: json.questions,
                    isLoadingPage: false
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter o detalhe", ToastTypes.Danger, false)))
    }

    buildAnswers(question) {

        switch (question.family) {
            case "group":
                return <></>;
            case STRING:
                return <Input type="textarea" style={{ height: 100 + "px" }} />
            case SINGLE_CHOICE:
                return (
                    <Container className="preview-question">
                        <Row className="preview-hide-mobile">{question.answers.map((a, index) => <Col key={index} className="text-center"><Label>{a.text}</Label></Col>)}</Row>
                        <Row className="preview-row-margin">{question.answers.map((a, index) =>
                            <Col key={index} data-id={"answer-" + a.id} className="text-center preview-col-normal preview-mouse-hover" xs="12"
                                onClick={(e) => {
                                    let id = e.target.getAttribute("data-id")
                                    document.getElementById(id + "-option").checked = true
                                }}>
                                <Input type="radio" id={"answer-" + a.id + "-option"} name={"single_choice" + question.id} data-id={"answer-" + a.id} style={{ position: "unset" }} />
                                <Label data-id={"answer-" + a.id} className="preview-show-mobile" style={{ paddingLeft: 5 + "px" }}>{a.text}</Label>
                            </Col>)}
                        </Row>
                    </Container>
                )
            default:
        }
    }

    buildQuestions(questions) {
        if (questions === null)
            return (<></>)

        return questions.map((question, index) => {
            return (
                <div key={index} className="preview-question">
                    <div className="preview-heading">
                        {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                        {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                    </div>
                    {this.buildAnswers(question)}
                </div>
            )
        })
    }

    render() {

        if (this.state.isLoadingPage) return (<></>)

        return (
            <Container>
                <Row>
                    <Col className="text-right">
                        <Link to={-1}>
                            <Button color="secondary"><FontAwesomeIcon icon={faBackspace} /> Voltar</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6">
                        <b>NOME:</b><Label style={{ paddingLeft: 5 + "px" }}>Nome do trabalhador</Label>
                    </Col>
                    <Col>
                        <b>RESTAURANTE:</b><Label style={{ paddingLeft: 5 + "px" }}>Nome do restaurante</Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="preview-question preview-summary" dangerouslySetInnerHTML={{ __html: this.state.surveySummary }} />
                    </Col>
                </Row>
                <div className="preview-height50" />
                {this.state.surveyQuestions.map((question, index) => {
                    return (
                        <Row key={index} className="preview-question-main">
                            <Col className={"preview-padding-bottom10"}>
                                <div>
                                    <div className="preview-question preview-heading" style={{ fontWeight: question.family === "group" ? "bold" : "" }}>
                                        {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                                        {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                                    </div>
                                    {this.buildAnswers(question)}
                                </div>
                                {question.family === "group" ? this.buildQuestions(question.questions) : null}
                            </Col>
                        </Row>
                    )
                })}
            </Container>
        )
    }
}

const STRING = "string"
const SINGLE_CHOICE = "single_choice"

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(SurveysAdmPreview)
