import React, { useState, useEffect } from "react"
import { Row, Col, Label } from 'reactstrap'
import { Loading, Toast, ToastTypes, formatDateHours } from "../../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"

const Event = (props) => {

    const [info,setInfo] = useState({
        name: '',
        place: '',
        beginDate: '',
        endDate: '',
        interviewers: ''
    });

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {


        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/phasedetailevent/" + props.idWorkflow, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json.length > 0) {
                    setInfo({
                        name: json[0].eventName,
                        place: json[0].event,
                        beginDate: formatDateHours(json[0].beginDate),
                        endDate: formatDateHours(json[0].endDate),
                        interviewers: json[0].interviewrs,
                    })
                }
                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os dados do evento", ToastTypes.Danger, false)))

    }

    return (
        <div style={{ paddingLeft: 5 + 'px', paddingRight: 5 + 'px' }}>
            <Row style={{ marginTop: 5 + 'px' }}>
                <Col>
                    <Label style={{marginBottom: 0 + 'px'}}><b>Nome</b></Label>
                    <div>
                        {info.name}
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: 20 + 'px' }}>
                <Col>
                    <Label style={{ marginBottom: 0 + 'px' }}><b>Local</b></Label>
                    <div>
                        {info.place}
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: 20 + 'px' }}>
                <Col>
                    <Label style={{ marginBottom: 0 + 'px' }}><b>Data de Início</b></Label>
                    <div>
                        {info.beginDate}
                    </div>
                </Col>
                <Col>
                    <Label style={{ marginBottom: 0 + 'px' }}><b>Data de Fim</b></Label>
                    <div>
                        {info.endDate}
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: 20 + 'px' }}>
                <Col>
                    <Label style={{ marginBottom: 0 + 'px' }}><b>Responsáveis pelo Processo</b></Label>
                    <div>
                        {info.interviewers}
                    </div>
                </Col>
            </Row>
        </div>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Event)