import React, { useState, useEffect } from "react"
import {Accordion, AccordionItem, AccordionHeader } from 'reactstrap'
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import Event from "./Event"
import Email from "./Email"

const MainForm = (props) => {

    const [tabInfo, setTabInfo] = useState({
        profileId: props.profileId,
        activeTab: "1",
        idCandidate: props.idCandidate,
        idWorkflow: props.idWorkflow
    });

    useEffect(() => {
    }, []);

    function toggle(tab) {
        setTabInfo({ ...tabInfo, activeTab: tab })
    }

    return (

        <div>

            <Accordion open={tabInfo.activeTab} toggle={function noRefCheck() { }}>
                <AccordionItem onClick={(e) => toggle("1")}>
                    <AccordionHeader targetId="1">
                        Evento
                    </AccordionHeader>
                    <AccordionItem accordionid="1" style={{ border: "none",  display: tabInfo.activeTab === "1" ? "inline" : "none" }}>
                        <Event idWorkflow={tabInfo.idWorkflow} />
                    </AccordionItem>
                </AccordionItem>

                <AccordionItem onClick={(e) => toggle("2")}>
                    <AccordionHeader targetId="2">
                        Email
                    </AccordionHeader>
                    <AccordionItem accordionid="2" style={{ border: "none", display: tabInfo.activeTab === "2" ? "inline" : "none" }}>
                        <Email idCandidate={tabInfo.idCandidate} idWorkflow={tabInfo.idWorkflow} />
                    </AccordionItem>
                </AccordionItem>
            </Accordion>

            {/*<TabContent activeTab={'1'}>*/}
            {/*    <TabPane tabId="1">*/}
            {/*        <Row>*/}
            {/*            <Col sm="6">*/}
            {/*                <Card body>*/}
            {/*                    <CardTitle style={{ borderBottomStyle: "solid", borderBottomColor: "#00000020", borderBottomWidth: 1 + 'px' }}><b>Evento</b></CardTitle>*/}
            {/*                    <CardText>*/}
            {/*                        <Event idWorkflow={tabInfo.idWorkflow} />*/}
            {/*                    </CardText>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}
            {/*            <Col sm="6">*/}
            {/*                <Card body>*/}
            {/*                    <CardTitle style={{ borderBottomStyle: "solid", borderBottomColor: "#00000020", borderBottomWidth: 1 + 'px' }}><b>Email</b></CardTitle>*/}
            {/*                    <CardText>*/}
            {/*                        <Email idCandidate={tabInfo.idCandidate} idWorkflow={tabInfo.idWorkflow} />*/}
            {/*                    </CardText>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </TabPane>*/}
            {/*</TabContent>*/}

        </div>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(MainForm)