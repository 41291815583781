import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave, faFilter } from '@fortawesome/pro-solid-svg-icons'

class EmployeeRequest extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            profileId: props.profileId,
            editFields: { month1: false, month2: false, month3: false },
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: [],
                subTotal: []
            },
            listYears: [],
            listStores: [],
            types: [],
            modal: {
                isOpen: false
            },
            quarterDescription: '',
            intQuarter: -1,
            ObjData: { intQuarter: -1, year: -1, month1: 0, idEmployeeRequest1: -1, month2: 0, idEmployeeRequest2: -1, month3: 0, idEmployeeRequest3: -1 },
            canEdit: false,
            AvailableEditQuarter: false
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleSaveRequest = this.handleSaveRequest.bind(this)
        this.handleChangeForm = this.handleChangeForm.bind(this)
    }

    componentDidMount() {

        var yearSelected = new Date(new Date().setMonth(new Date().getMonth() + 1)).getFullYear();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/request/employeerequestfilters?ProfileId=" + this.state.profileId, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {

                let years = data.years.map(result => {
                    var valueSelected = false

                    if (result === yearSelected) {
                        valueSelected = true;
                    }

                    return { value: result, text: result, selected: valueSelected }
                });

                let stores = data.costCenter.map((result, index) => {

                    var valueSelected = false

                    if (index === 0) {
                        valueSelected = true;
                    }

                    return { value: result.number, text: result.name, selected: valueSelected }
                });

                this.setState({ listYears: years, listStores: stores, types: types })
                this.BindGrid(yearSelected, stores.find(x => x.selected).value, types.find(x => x.selected).value)
            }).catch(error => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível obter os dados dos filtros.", ToastTypes.Danger, false))
            });
    }

    

    BindGrid(year, store, type) {

        this.props.dispatch(Loading(true))
        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/request/employeerequest?year=" + year + "&storeNumber=" + store + "&profileId=" + parseInt(this.state.profileId) + "&type=" + type, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                this.setState({
                    table: {
                        title: "Requisição de Colaboradores",
                        columns: {
                            title: ["Centro de Custo", "Trimestre", "Ano", "1º Mês", "2º Mês", "3º Mês", "Total", ""],
                            width: [20, 20, 20, 10, 10, 10, 10, 10],
                            align: ["left", "left", "left", "left", "left", "left", "left", "right"],
                            search: [true, true, true, true, true, true, true, false]
                        },
                        rows: json.map(row => {
                            return {
                                id: row.storeNumber.toString() + '_' + row.intQuarter.toString(),
                                columns: [
                                    { column: row.storeName },
                                    { column: row.quarterDescription },
                                    { column: row.year },
                                    { column: row.month1 },
                                    { column: row.month2 },
                                    { column: row.month3 },
                                    { column: row.total }
                                ],
                                actions: [
                                    row.availableEditQuarter || this.state.profileId === 1 ?
                                    {
                                        column: faEdit, title: "Editar", action: (e) =>
                                            this.handleEdit(row.intQuarter, row.year, row.month1, row.idEmployeeRequest1, row.month2, row.idEmployeeRequest2, row.month3,
                                                row.idEmployeeRequest3, row.storeNumber)
                                    }
                                    : null
                                ]
                            }
                        }),
                        subTotal: [false, false, false, true, true, true, true, false],
                        actions: [
                            { action: "filter", icon: faFilter }
                        ],
                        filters: [
                            {
                                id: "lstYears",
                                type: "list",
                                data: this.state.listYears,
                                label: "Ano",
                                multiple: false
                            },
                            {
                                id: "lstStores",
                                type: "list",
                                data: this.state.listStores,
                                label: "Centro de Custo",
                                multiple: false
                            },
                            {
                                id: "lstDados",
                                type: "list",
                                data: types,
                                label: "Dados",
                                multiple: false
                            }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(_ => this.props.dispatch(Toast("Não foi possivel obter os dados", ToastTypes.Danger, false)))
    }

    handleFilter(data) {
        var year = parseInt(data[0])
        var storeNumber = parseInt(data[1])
        var type = parseInt(data[2])

        this.BindGrid(year, storeNumber, type)
    }

    handleCloseModal() {
        this.setState({ modal: { isOpen: false } });
    }

    handleEdit(intQuarter, year, month1, idEmployeeRequest1, month2, idEmployeeRequest2, month3, idEmployeeRequest3, storeNumber) {

        let objEdit;
        const Year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();

        if (this.state.profileId === 1 || this.state.profileId === 101) {
            objEdit = { month1: false, month2: false, month3: false }
        }
        else {
            let monthday = parseInt(month) * 100 + parseInt(day);
            if (monthday >= 1206)
                monthday = 0;

            const monthTable = [{ month: 4, begin: 0, end: 305 }, { month: 7, begin: 306, end: 605 }, { month: 10, begin: 606, end: 905 }, { month: 1, begin: 906, end: 1205 }];
            const validMonth = monthTable.find(a => monthday >= a.begin && monthday <= a.end).month;
            const nextQuarter = (Year + (monthday == 0 ? 1 : 0)) * 100 + validMonth;

            const canEdit = nextQuarter > (year * 100 + this.getIntMonth(intQuarter, 1));

            objEdit = { month1: canEdit, month2: canEdit, month3: canEdit };
        }

        this.setState({
            modal: { isOpen: !this.state.modal.isOpen },
            ObjData: {
                intQuarter: intQuarter, year: year, month1: month1, idEmployeeRequest1: idEmployeeRequest1, month2: month2, idEmployeeRequest2: idEmployeeRequest2,
                month3: month3, idEmployeeRequest3: idEmployeeRequest3, storeNumber: storeNumber
            },
            quarterDescription: 'T' + intQuarter + '/' + year,
            editFields: objEdit,
            canEdit: !objEdit.month1 || !objEdit.month2 || !objEdit.month3
        })
    }



    handleChangeForm(e) {
        var txtId = e.target.id
        var txtValue = e.target.value === '' ? -1 : parseInt(e.target.value)
        this.setState({ ObjData: { ...this.state.ObjData, [txtId]: txtValue } })
    }

    getMonth(intMonth) {
        var intQuarter = this.state.ObjData.intQuarter;
        var month = '';

        if (intQuarter === 1 && intMonth === 1)
            month = 'Janeiro'
        else if (intQuarter === 1 && intMonth === 2)
            month = 'Fevereiro'
        else if (intQuarter === 1 && intMonth === 3)
            month = 'Março'
        else if (intQuarter === 2 && intMonth === 1)
            month = 'Abril'
        else if (intQuarter === 2 && intMonth === 2)
            month = 'Maio'
        else if (intQuarter === 2 && intMonth === 3)
            month = 'Junho'
        else if (intQuarter === 3 && intMonth === 1)
            month = 'Julho'
        else if (intQuarter === 3 && intMonth === 2)
            month = 'Agosto'
        else if (intQuarter === 3 && intMonth === 3)
            month = 'Setembro'
        else if (intQuarter === 4 && intMonth === 1)
            month = 'Outubro'
        else if (intQuarter === 4 && intMonth === 2)
            month = 'Novembro'
        else if (intQuarter === 4 && intMonth === 3)
            month = 'Dezembro'

        return month
    }

    getIntMonth(intQuarter, monthPosition) {

        var month;

        if (intQuarter === 1 && monthPosition === 1)
            month = 1
        else if (intQuarter === 1 && monthPosition === 2)
            month = 2
        else if (intQuarter === 1 && monthPosition === 3)
            month = 3
        else if (intQuarter === 2 && monthPosition === 1)
            month = 4
        else if (intQuarter === 2 && monthPosition === 2)
            month = 5
        else if (intQuarter === 2 && monthPosition === 3)
            month = 6
        else if (intQuarter === 3 && monthPosition === 1)
            month = 7
        else if (intQuarter === 3 && monthPosition === 2)
            month = 8
        else if (intQuarter === 3 && monthPosition === 3)
            month = 9
        else if (intQuarter === 4 && monthPosition === 1)
            month = 10
        else if (intQuarter === 4 && monthPosition === 2)
            month = 11
        else if (intQuarter === 4 && monthPosition === 3)
            month = 12

        return month
    }

    handleSaveRequest(e) {

        if (this.state.ObjData.month1 === -1 || this.state.ObjData.month2 === -1 || this.state.ObjData.month3 === -1) {
            this.props.dispatch(Toast("Preencha todos os campos", ToastTypes.Warning, true))
            return
        }

        var store = this.state.listStores.find(x => x.selected).value

        var intQuarter = this.state.ObjData.intQuarter;
        var year = this.state.ObjData.year
        var storeNumber = this.state.ObjData.storeNumber
        var type = this.state.types.find(x => x.selected).value

        var obj = [
            {
                IdEmployeeRequest: this.state.ObjData.idEmployeeRequest1,
                StoreNumber: this.state.ObjData.storeNumber,
                Year: this.state.ObjData.year,
                Month: this.getIntMonth(intQuarter, 1),
                NumberOfEmployees: this.state.ObjData.month1
            },
            {
                IdEmployeeRequest: this.state.ObjData.idEmployeeRequest2,
                StoreNumber: this.state.ObjData.storeNumber,
                Year: this.state.ObjData.year,
                Month: this.getIntMonth(intQuarter, 2),
                NumberOfEmployees: this.state.ObjData.month2
            },
            {
                IdEmployeeRequest: this.state.ObjData.idEmployeeRequest3,
                StoreNumber: this.state.ObjData.storeNumber,
                Year: this.state.ObjData.year,
                Month: this.getIntMonth(intQuarter, 3),
                NumberOfEmployees: this.state.ObjData.month3
            }
        ]

        var objSave = { profileId: this.state.profileId, employeesRequest: obj }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objSave)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/request/employeerequest", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === 0)
                    return this.props.dispatch(Toast("Não foi possível gravar. Tente mais tarde!", ToastTypes.Danger, true))

                this.props.dispatch(Toast("Requisições gravadas com sucesso", ToastTypes.Success, false))

                this.BindGrid(year, store, type)

                this.setState({
                    modal: { isOpen: !this.state.modal.isOpen },
                    ObjData: { intQuarter: -1, year: -1, month1: 0, idEmployeeRequest1: -1, month2: 0, idEmployeeRequest2: -1, month3: 0, idEmployeeRequest3: -1 }
                })
            })
            .catch(_ => this.props.dispatch(Toast("Não foi possível gravar. Tente mais tarde!", ToastTypes.Danger, true)));
    }

    render() {

        return (

            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} subTotal={this.state.table.subTotal} />

                <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.handleCloseModal}>{this.state.quarterDescription}</ModalHeader>
                    <ModalBody>

                        <Form>
                            <FormGroup>
                                <div className="row">
                                    <div className="col-lg-12" style={{ textAlign: "left" }}>
                                        <label htmlFor="IdPhaseType"><b>Resumo</b></label>

                                        <div className="row">
                                            <div className="col-lg-4" style={{ textAlign: "left" }}>
                                                <label htmlFor="name">{this.getMonth(1)}</label>
                                                <input id="month1" disabled={this.state.editFields.month1} defaultValue={this.state.ObjData.month1} type="number" maxLength="50"
                                                    className="form-control" autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                                            </div>

                                            <div className="col-lg-4" style={{ textAlign: "left" }}>
                                                <label htmlFor="name">{this.getMonth(2)}</label>
                                                <input id="month2" disabled={this.state.editFields.month2} defaultValue={this.state.ObjData.month2} type="number" maxLength="50"
                                                    className="form-control" autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                                            </div>

                                            <div className="col-lg-4" style={{ textAlign: "left" }}>
                                                <label htmlFor="name">{this.getMonth(3)}</label>
                                                <input id="month3" disabled={this.state.editFields.month3} defaultValue={this.state.ObjData.month3} type="number" maxLength="50"
                                                    className="form-control" autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </Form>

                    </ModalBody>
                    {this.state.canEdit ?
                        <ModalFooter>
                            <Button color="primary" onClick={(e) => this.handleSaveRequest(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </ModalFooter>
                        : null
                    }
                </Modal>
            </>
        )
    }
}

const types = [
    { value: -1, text: "Todos", selected: true, init: true },
    { value: 0, text: "Com Valor", selected: false, init: false }]

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(EmployeeRequest)
