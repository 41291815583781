import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    handle_event: false, phases: [], places: [], interviewers: [], filter: { phases: [], interviewers: [] }, handle_email: false
}

export const agendaSlice = createSlice({
    name: 'agenda',
    initialState,
    reducers: {
        handleEvent: (state, action) => {
            state.handle_event = action.payload;
        },
        agendaPhases: (state, action) => {
            state.phases = action.payload;
        },
        agendaPlaces: (state, action) => {
            state.places = action.payload;
        },
        agendaIntervirwers: (state, action) => {
            state.interviewers = action.payload;
        },
        filtersPhases: (state, action) => {
            state.filter.phases = action.payload;
        },
        filtersInterviewers: (state, action) => {
            state.filter.interviewers = action.payload;
        },
        handleEmail: (state, action) => {
            state.handle_email = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const { handleEvent, agendaPhases, agendaPlaces, agendaIntervirwers, filtersPhases, filtersInterviewers, handleEmail } = agendaSlice.actions

export default agendaSlice.reducer