import { configureStore } from '@reduxjs/toolkit'
import mainSlice from "./redux/MainSlice"
import agendaSlice from "./redux/AgendaSlice"
import candidateSlice from "./redux/CandidateSlice"

const store = configureStore({
    reducer: {
        main: mainSlice,
        agenda: agendaSlice,
        candidate: candidateSlice
    }
});

export default store;
