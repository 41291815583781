import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label, Modal, ModalHeader, ModalBody, Container } from 'reactstrap'
import { formatDateHours, Loading, Toast, ToastTypes } from "../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faTrash, faPlusCircle, faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EmailPreview from "./EmailPreview"
import McdTable from "../../global/Table"

const PhaseOptions = (props) => {

    const StateInProcess = 1;
    const [IdCandidate] = useState(props.idCandidate);
    const [Phases, setPhases] = useState([]);
    const [Events, setEvents] = useState([]);
    const [Emails, setEmails] = useState([]);
    const [States, setStates] = useState([]);
    const [Reasons, setReasons] = useState([]);
    const [modal, setModal] = useState({
        isOpen: false,
        phaseObj: [],
        state: -1,
        email: -1,
        reason: -1,
        error: {
            phase: false,
            event: false,
            state: false,
            email: false,
            reason: false
        }
    });
    const [listPhases, setListPhases] = useState([]);
    const [showReason, setShowReason] = useState(false);
    const [modalPreviewEmail, setModalPreviewEmail] = useState({ isOpen: false });
    const [errorEmailTag, seErrorEmailTag] = useState(false);

    useEffect(() => {
        LoadComboData();
    }, []);

    useEffect(() => {
        if (props.checkPhaseOptionsFields === 0)
            return;

        var hasError = listPhases.length === 0

        var objWorkflow = []
        listPhases.forEach(a => {
            objWorkflow.push({ IdWorkflow: 0, IdCandidate: IdCandidate, IdPhase: a.idPhase, IdEvent: a.idEvent, IdState: modal.state, IdReason: modal.reason, Active: true, Detail: null })
        })


        var objSave = {
            Workflow: objWorkflow,
            IdEmailTemplate : Emails.find(a => a.selected) !== null ? Emails.find(a => a.selected).value : -1
        }
        props.handlerPhaseOptionsCheckFields(hasError ? null : objSave)
    }, [props.checkPhaseOptionsFields]);

    function LoadComboData() {
        var objPhase = []
        var objState = []
        var objReason = []

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        if (Phases.length === 0 || Emails.length === 0 || States.length === 0) {
            props.dispatch(Loading(true))
        }

        if (Phases.length === 0)
            fetch("/api/candidates/phase", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {

                    objPhase = json.map(result => {
                        return { value: result.idPhase, text: result.phaseName, selected: false, grouping: result.grouping }
                    });
                    objPhase.push({ value: -1, text: '', selected: true, grouping: false })

                    setPhases(objPhase)
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os registos da fase", ToastTypes.Danger, false)))

        if (Emails.length === 0)
            fetch("/api/candidates/emails", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    var objEmail = json.map(result => {
                        return { value: result.idEmail, text: result.name, selected: false }
                    });
                    objEmail.push({ value: -1, text: '', selected: true })
                    setEmails(objEmail)
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os registos dos emails", ToastTypes.Danger, false)))

        if (States.length === 0) {
            fetch("/api/candidates/reasonstate?idCandidate=" + props.idCandidate + "&idWorkflow=-1", requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    
                    objState = json.states.map(result => {
                        return { value: result.idState, text: result.description, selected: result.selected }
                    });

                    setStates(objState)

                    if (objState.find(a => a.selected) !== undefined && objState.find(a => a.selected).value === 3)
                        setShowReason(true)

                    //Reasons
                    objReason = json.reasons.map(result => {
                        return { value: result.idReason, text: result.description, selected: result.selected }
                    });
                    objReason.push({ value: -1, text: '', selected: (objReason.find(a => a.selected) === undefined), grouping: false })
                    setReasons(objReason)

                    setModal({ ...modal, state: objState.find(a => a.selected) !== undefined ? objState.find(a => a.selected).value : StateInProcess, reason: objReason.find(a => a.selected).value })
                    props.dispatch(Loading(false))
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter os registos dos estados e motivos", ToastTypes.Danger, false)))
        }
    }

    function handleChangeListCombo(e) {
        var id = e.target.id
        var obj = []
        const element = document.getElementById(e.target.id)
        var error = modal.error

        if (id === 'lstEvent') {
            for (let i = 0; i < element.options.length; ++i) {
                var objAux = Events.find(x => parseInt(x.value) === parseInt(element[i].value))
                objAux.selected = element[i].selected
                obj.push(objAux)
            }
            setEvents(obj)
            error.event = parseInt(e.target.value) === -1
            setModal({ ...modal, event: parseInt(e.target.value), error: error })
        }
        else if (id === 'lstState') {

            for (let i = 0; i < element.options.length; ++i) {
                var selected = false
                let objAux = States.find(x => parseInt(x.value) === parseInt(element[i].value))

                //porque esta escondido
                if (objAux !== undefined) {

                    if (parseInt(e.target.value) === parseInt(element[i].value))
                        selected = true

                    objAux.selected = selected
                    obj.push(objAux)
                }
            }

            setStates(obj)
            error.state = parseInt(e.target.value) === -1
            setModal({ ...modal, state: parseInt(e.target.value), error: error })

            if (parseInt(e.target.value) === 3)
                setShowReason(true)
            else
                setShowReason(false)

        }
        else if (id === 'lstEmail') {

            for (let i = 0; i < element.options.length; ++i) {
                let objAux = Emails.find(x => parseInt(x.value) === parseInt(element[i].value))
                objAux.selected = element[i].selected
                obj.push(objAux)
            }
            setEmails(obj)
            error.email = e.target.value === -1
        }

        else if (id === 'lstReason') {
            for (let i = 0; i < element.options.length; ++i) {
                let objAux = Reasons.find(x => parseInt(x.value) === parseInt(element[i].value))
                objAux.selected = element[i].selected
                obj.push(objAux)
            }
            setReasons(obj)
            error.reason = parseInt(e.target.value) === -1
            setModal({ ...modal, reason: parseInt(e.target.value), error: error })
        }
    }

    function CheckEmailTags(idEmail) {
        if (idEmail > 0) {

            const requestOptions = {
                method: "GET",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
            }

            fetch("/api/candidates/checkemailtags?idCandidate=" + props.idCandidate + "&idIdEmailTemplate=" + idEmail, requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {

                    if (json === null) {
                        seErrorEmailTag(true)
                        props.dispatch(Toast("Não foi possível verificar o email", ToastTypes.Warning, true))
                        return
                    }
                    else if (json.length > 0) {
                        var strMessage = 'As seguintes tags do template de email ainda não se encontram preenchidas: '
                        for (var i = 0; i <= json.length - 1; ++i) {
                            strMessage += json[i] + ';'
                        }
                        seErrorEmailTag(true)
                        props.dispatch(Toast(strMessage.slice(0, -1), ToastTypes.Warning, true))
                        return
                    }
                    else {
                        seErrorEmailTag(false)
                    }

                })
                .catch(() => props.dispatch(Toast("Não foi possível verificar o email", ToastTypes.Danger, false)))
        }
        else {
            seErrorEmailTag(false)
        }
    }

    function handleChangeListComboPhase(e) {

        var objPhase = []
        var strPhase = ''
        setEvents([])

        var error = modal.error
        error.phase = e.target.value === -1
        setModal({ ...modal, phase: e.target.value, error: error })

        const element = document.getElementById(e.target.id)
        for (let i = 0; i < element.options.length; ++i) {
            var obj = Phases.find(x => parseInt(x.value) === parseInt(element[i].value))
            obj.selected = element[i].selected
            if (element[i].selected)
                strPhase += element[i].value + ','

            objPhase.push(obj)
        }
        var grouped = objPhase.filter(x => x.grouping && x.selected)
        var notGrouped = objPhase.filter(x => x.grouping === false && x.selected)

        if (grouped.length > 0 && notGrouped.length > 0) {
            objPhase = objPhase.map(i => { return { ...i, selected: false } })
            strPhase = '-1'
            setEvents([])
            return
        }
        else {
            strPhase = strPhase.slice(0, -1)
        }

       

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/events?Phases=" + strPhase, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                console.log(json)
                var objEvents = json.map(result => {
                    return { value: result.idEvent, text: result.description + " (" + formatDateHours(result.beginDate) + ")", selected: false }
                });
                objEvents.push({ value: -1, text: '', selected: true })
                setPhases(objPhase)
                setEvents(objEvents)

                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    function handleAddPhase() {

        var idPhase = Phases.find(a => a.selected) !== undefined ? Phases.find(a => a.selected).value : -1
        var idEvent = Events.find(a => a.selected) !== undefined ? Events.find(a => a.selected).value : -1
        var phaseName = Phases.find(a => a.selected) !== undefined ? Phases.find(a => a.selected).text : ''
        var eventName = Events.find(a => a.selected) !== undefined ? Events.find(a => a.selected).text : ''

        if (idPhase === -1) {
            props.dispatch(Toast("Por favor selecione uma fase", ToastTypes.Warning, true))
            return
        }

        if (listPhases.find(a => a.idPhase === idPhase) !== undefined) {
            props.dispatch(Toast("A fase " + phaseName + " já se encontra adicionada", ToastTypes.Warning, true))
            return
        }

        if (listPhases.filter(a => a.grouping === false).length > 0) {
            props.dispatch(Toast("Já existem fases não agrupáveis adicionadas.", ToastTypes.Warning, true))
            return
        }

        var grouping = Phases.find(a => a.selected).grouping
        var aux = []
        var phaseObj = modal.phaseObj
        phaseObj.push({ idPhase: idPhase, idEvent: idEvent })
        aux.push(...listPhases)
        aux.push({ idPhase: idPhase, idEvent: idEvent, phaseName: phaseName, eventName: eventName, grouping: grouping })
        setListPhases(aux)
        setModal({ ...modal, phaseObj: phaseObj })
    }

    const handleDeletePhase = (idPhase) => {
        const aux = listPhases.filter(a => a.idPhase !== idPhase);
        const phaseObj = modal.phaseObj.filter(a => a.idPhase !== idPhase);
        setListPhases(aux);
        setModal({ ...modal, phaseObj: phaseObj });
    }

    function togglePreviewEmail() {
        setModalPreviewEmail({ ...modalPreviewEmail, isOpen: !modalPreviewEmail.isOpen })
    }

    function handlePreviewEmail() {
        if (Emails.find(a => a.selected).value === -1) {
            props.dispatch(Toast("Por favor selecione um email", ToastTypes.Warning, false))
            return
        }

        setModalPreviewEmail({ ...modalPreviewEmail, isOpen: !modalPreviewEmail.isOpen })
    }

    return (
        <>
            <Row>
                <Col>
                    <Label>Fase</Label>
                    <Input type="select" id="lstPhase" value={Phases !== undefined && Phases.length > 0 ? Phases.filter(a => a.selected).map((item) => item.value) : ""} invalid={modal.error.phase}
                        onChange={(e) => handleChangeListComboPhase(e)}>
                        {Phases.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                    </Input>
                </Col>

                <Col>
                    <Label>Eventos</Label>
                    <Input type="select" id="lstEvent" value={Events !== undefined && Events.length > 0 ? Events.filter(a => a.selected).map((item) => item.value) : ""} invalid={modal.error.event}
                        onChange={(e) => handleChangeListCombo(e)}>
                        {Events.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                    </Input>
                </Col>
                <Col xs="1">
                    <div style={{ marginTop: `32px`, fontSize: `25px` }}>
                        <FontAwesomeIcon onClick={() => handleAddPhase()} title="Adicionar" style={{ verticalAlign: "baseline", cursor: "pointer", color: "#1b6ec2" }} icon={faPlusCircle} />
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: 10 + 'px' }}>
                <Col>
                    <McdTable title="-" columns={{ title: ["Fase", "Evento", ""], width: [40, 40, 10], align: ["left", "left", "right"], search: [false, false, false] }}
                        rows={listPhases.map((a, idx) => ({
                            id: idx,
                            columns: [
                                { column: a.phaseName },
                                { column: a.eventName }
                            ],
                            actions: [{ column: faTrash, title: "Remover", action: () => handleDeletePhase(a.idPhase) }]
                        }))} pagination={false} actions={[]} />
                </Col>
            </Row>

            <Row style={{ marginTop: 20 + 'px' }}>
                <Col>
                    <Label>Estado</Label>
                    <Input type="select" id="lstState" value={States.length > 0 && States.find(a => a.selected) !== undefined ? States.find(a => a.selected).value : StateInProcess} invalid={modal.error.state}
                        onChange={(e) => handleChangeListCombo(e)}>
                        {States.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                    </Input>
                </Col>

                <Col>
                    <Label>Template de Email</Label>
                    <Input type="select" id="lstEmail" invalid={modal.error.email} value={Emails.length > 0 ? Emails.find(a => a.selected).value : -1}
                        onChange={(e) => handleChangeListCombo(e)}>
                        {Emails.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                    </Input>
                </Col>
                <Col xs="1">
                    <div style={{ marginTop: `32px`, fontSize: `25px` }}>
                        {errorEmailTag === false && Emails.length > 0 && Emails.find(a => a.selected).value > 0 ?
                            < FontAwesomeIcon onClick={() => handlePreviewEmail()} style={{ verticalAlign: "baseline", cursor: "pointer", color: "#1b6ec2" }} title="Pre-visualizar email" icon={faEnvelopeOpen} />
                            :
                            < FontAwesomeIcon style={{ verticalAlign: "baseline", color: "#808080" }} title="Pre-visualizar email" icon={faEnvelopeOpen} />
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                {showReason && Reasons.length > 0 ?
                    <Col>
                        <Label>Motivo</Label>
                        <Input type="select" value={Reasons.find(a => a.selected).value} id="lstReason" invalid={modal.error.reason}
                            onChange={(e) => handleChangeListCombo(e)}>
                            {Reasons.map(a => <option key={a.value} value={a.value}>{a.text}</option>)}
                        </Input>
                    </Col>
                    : <Col></Col>
                }
                <Col></Col>
                <Col></Col>
            </Row>

            <Modal isOpen={modalPreviewEmail.isOpen} toggle={togglePreviewEmail} size="xl">
                <ModalHeader toggle={togglePreviewEmail}>Pré Visualização</ModalHeader>
                <ModalBody>
                    <Container>
                        <EmailPreview idEmail={Emails.length > 0 ? Emails.find(a => a.selected).value : -1} idCandidate={props.idCandidate} PersonalData={props.PersonalData} PhaseObj={modal.phaseObj} />
                    </Container>
                </ModalBody>
            </Modal>
        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(PhaseOptions)