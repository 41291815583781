import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Container, Row, Col, Label } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faFilter, faEye, faEyeSlash, faEdit } from '@fortawesome/pro-solid-svg-icons'

class Phase extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            modal: {
                isOpen: false,
                PhaseName: '',
                IdPhaseType: -1,
                error: {
                    PhaseName: false,
                    IdPhaseType: false
                }
            },
            lstPhaseTypes: [],
            lstState: [],
            objData: { IdPhase: 0, PhaseName: '', IdPhaseType: -1, Grouping: false, Active: true }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSavePhase = this.handleSavePhase.bind(this)
    }

    componentDidMount() {

        let ObjPhaseTypes;

        let objState = [
            { value: 1, text: "Ativo", selected: true },
            { value: 0, text: "Inativo", selected: false }
        ]

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/phasefilters", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {

                ObjPhaseTypes = data.phaseTypes.map(result => { return { value: result.idPhaseType, text: result.name, selected: true } });

                this.setState({ lstState: objState, lstPhaseTypes: ObjPhaseTypes })
                this.bindGrid(1)

            }).catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os filtros.", ToastTypes.Danger, false))
            });
    }

    bindGrid(state) {

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/phase?state=" + state, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Fases",
                        columns: {
                            title: ["Nome", "Tipo de Fase", "Agrupamento", ""],
                            width: [30, 30, 30, 10],
                            align: ["left", "left", "left", "right"],
                            search: [true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idPhase,
                                columns: [
                                    { column: row.phaseName },
                                    { column: row.idPhaseTypeName },
                                    { column: row.grouping === true ? 'Sim' : 'Não' },
                                ],
                                actions: [
                                    { column: faEdit, title: "Editar", color: row.lock ? "grey" : "#4FA570", action: (e) => row.lock ? null : this.handleEditPhase(row) },
                                    { column: row.active ? faEyeSlash : faEye, title: row.active ? "Inativar" : "Ativar", color: row.lock ? "grey" : "#4FA570", action: (e) => row.lock ? null : this.handleDeletePhase(row.idPhase, row.phaseName, row.active) }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewPhase(), icon: faPlus, title: "Nova Fase" }
                        ],
                        filters: [{ id: "lstState", type: "list", data: this.state.lstState, label: "Estado", multiple: false }]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível obter os dados das fases", ToastTypes.Danger, true))
            });
    }

    handleFilter(data) {
        this.bindGrid(data[0])
    }

    handleNewPhase() {
        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: { PhaseName: false, IdPhaseType: false }
            },
            objData: { IdPhase: 0, PhaseName: '', IdPhaseType: -1, IdEmail: -1, Grouping: false, Active: true }
        })
    }

    handleCloseModal() {
        this.setState({
            modal: {
                isOpen: false,
                error: { PhaseName: false, IdPhaseType: false }
            }
        });
    }

    handleChangeForm(e) {
        let dataValue = e.target.value
        let id = e.target.id

        if (e.target.type === "checkbox") {
            dataValue = e.currentTarget.checked
        }

        let auxErrorObj = this.state.modal.error
        auxErrorObj[id] = false

        this.setState({ objData: { ...this.state.objData, [id]: dataValue }, modal: { ...this.state.modal, error: auxErrorObj } })
    }

    handleEditPhase(obj) {

        this.setState({
            modal: { isOpen: !this.state.modal.isOpen, error: { PhaseName: false, IdPhaseType: false } },
            objData: { IdPhase: obj.idPhase, PhaseName: obj.phaseName, IdPhaseType: obj.idPhaseType, Grouping: obj.grouping, Active: obj.active }
        })
    }

    handleDeletePhase(idPhase, PhaseName, active) {

        if (!window.confirm('Tem a certeza que pretende ' + (active ? 'inativar' : 'ativar') + ' a fase com a descrição: ' + PhaseName + '?'))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdPhase: idPhase,
                Active: active ? false : true
            })
        }
        this.props.dispatch(Loading(true))

        fetch("/api/admin/phase", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Fase " + (active ? 'inativada' : 'ativada') + " com sucesso", ToastTypes.Success, false))

                    var newArray = this.state.table.rows.filter(function (obj) {
                        return obj.id !== idPhase;
                    });
                    this.setState({ table: { ...this.state.table, rows: newArray } })
                } else {
                    this.props.dispatch(Toast("Não foi possível concluir a operação. Por favor tente mais tarde!", ToastTypes.Danger, true))
                }
                this.props.dispatch(Loading(false))
            })

            .catch(error => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível concluir a operação. Por favor tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    handleSavePhase() {

        let modal = { ...this.state.modal }

        modal.error.PhaseName = this.state.objData.PhaseName === ''
        modal.error.IdPhaseType = (this.state.objData.IdPhaseType === -1 || this.state.objData.IdPhaseType === '')

        if (modal.error.PhaseName || modal.error.IdPhaseType) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdPhase: this.state.objData.IdPhase,
                PhaseName: this.state.objData.PhaseName,
                IdPhaseType: this.state.objData.IdPhaseType,
                Grouping: this.state.objData.Grouping,
                Active: true
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/phase", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.bindGrid(this.state.lstState.find(a => a.selected).value)
                    this.props.dispatch(Toast("Fase gravada com sucesso", ToastTypes.Success, false))
                    this.setState({
                        modal: {
                            isOpen: !this.state.modal.isOpen,
                            error: { PhaseName: false, IdPhaseType: false }
                        }
                    })
                } else {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
                }
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.handleCloseModal}>
                    <ModalHeader toggle={this.handleCloseModal}>Fases</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Label>Descrição</Label>
                                    <Input id="PhaseName" type="text" maxLength="50" defaultValue={this.state.objData.PhaseName} invalid={this.state.modal.error.PhaseName} autoComplete="off"
                                        onChange={(e) => this.handleChangeForm(e)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <Label>Tipo de Fase</Label>
                                    <Input type="select" id="IdPhaseType" value={this.state.objData.IdPhaseType} invalid={this.state.modal.error.IdPhaseType} onChange={(e) => this.handleChangeForm(e)}>
                                        <option key="-1" value=""></option>
                                        {this.state.lstPhaseTypes.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                    </Input>
                                </Col>
                                <Col>
                                    <Label htmlFor="Grouping">Agrupar</Label><br/>
                                    <Input type="switch" id="Grouping" checked={this.state.objData.Grouping} onChange={(e) => this.handleChangeForm(e)} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSavePhase()}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })
export default connect(mapStateToProps)(Phase)