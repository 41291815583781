import { faChevronRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import "./Agenda.css";
import { DayHeader } from "./DayHeader";
import { Events } from "./monthly/Events";
import { Event } from "./Event";
import { formatDate, getStrMonth, Loading, Toast, ToastTypes } from "../global/Utils";
import { useDispatch, useSelector } from "react-redux";
import { agendaIntervirwers, agendaPhases, agendaPlaces, handleEvent } from "../../redux/AgendaSlice";
import { useNavigate } from "react-router-dom";
import { Filters } from "./Filters";

export const Month = () => {
    const dispatch = useDispatch();
    const access_token = useSelector(state => state.main.access_token);
    const handle_event = useSelector(state => state.agenda.handle_event);
    const selPhases = useSelector(state => state.agenda.filter.phases);
    const selInterviewers = useSelector(state => state.agenda.filter.interviewers);

    const navigate = useNavigate();

    const [state, setState] = useState({ year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1 })
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        dispatch(agendaPhases([]));
        dispatch(agendaPlaces([]));
        dispatch(agendaIntervirwers([]));
    }, []);

    const refreshMonth = (year, month) => {
        const curDate = new Date();
        const date = new Date(year, month - 1, 1);
        const nextMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(nextMonth.setDate(nextMonth.getDate() - 1));

        let firstDate = new Date(date.setDate(date.getDate() - date.getDay() + 1));

        while (lastDayOfMonth.getDay() != 0) {
            lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);
        }

        let days = [];

        do {
            days.push({ date: new Date(firstDate), day: (new Date(firstDate)).getDate(), month: (new Date(firstDate)).getMonth() });
            firstDate.setDate(firstDate.getDate() + 1);
        } while (firstDate <= lastDayOfMonth);

        setDaysOfMonth(days);

        // add events
        loadEvents(days);
    }

    const loadEvents = (days) => {

        const d = days ?? daysOfMonth;

        const beginDate = formatDate(d[0].date);
        const endDate = formatDate(d[d.length - 1].date);

        dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        fetch(`/api/agenda?begin=${beginDate}&end=${endDate}&phases=${selPhases.toString()}&interviewers=${selInterviewers.toString()}`, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                setEvents(json);

                dispatch(Loading(false));
            })
            .catch(_ => dispatch(Toast("Não foi possivel obter os eventos.", ToastTypes.Danger, false)))
    }

    const handleMonthPrev = () => {
        let m = state.month - 1;
        let y = state.year;
        if (m === 0) {
            m = 12;
            y--;
        }

        setState({ month: m, year: y });
    }

    const handleMonthNext = () => {
        let m = state.month + 1;
        let y = state.year;
        if (m === 13) {
            m = 1;
            y++;
        }

        setState({ month: m, year: y });
    }

    useEffect(() => {
        setEvents([]);

        refreshMonth(state.year, state.month);
    }, [state.year, state.month]);

    useEffect(() => {
        if (handle_event.isOpen || !handle_event.needRefresh)
            return;

        dispatch(handleEvent({ isOpen: false, needRefresh: false }));

        loadEvents();
    }, [handle_event]);

    if (daysOfMonth.length === 0)
        return (<></>);

    return (
        <>
            <Container className="agenda">
                <Row className="agenda-header">
                    <Col className="agenda-header-col">
                        <div className="agenda-header-col-left">
                            <Button color="secondary" outline size="sm" className="border-0 agenda-header-col-change-view" onClick={handleMonthPrev}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Button>
                            <Button color="secondary" outline size="sm" className="border-0 agenda-header-col-change-view" onClick={handleMonthNext}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                            <h3>{getStrMonth(state.month) + " " + state.year}</h3>
                        </div>
                        <div className="agenda-header-col-right">
                            <Filters />
                            <Button color="secondary" outline size="sm" onClick={_ => navigate("/agenda/week")}>Semanal</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="agenda-monthly-weekdays">
                    <Col>SEG</Col>
                    <Col>TER</Col>
                    <Col>QUA</Col>
                    <Col>QUI</Col>
                    <Col>SEX</Col>
                    <Col>SAB</Col>
                    <Col>DOM</Col>
                </Row>
                <div className="agenda-monthly-weeks">
                    <Row>
                        {daysOfMonth.filter((_, i) => i >= 0 && i <= 6).map(d =>
                            <Col key={d.day}>
                                <DayHeader day={d.day} month={d.month} date={d.date} source="month" />
                                <Events events={events.filter(f => new Date((new Date(f.begin)).setHours(0, 0, 0, 0)).toString() === d.date.toString())} />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {daysOfMonth.filter((_, i) => i >= 7 && i <= 13).map(d =>
                            <Col key={d.day}>
                                <DayHeader day={d.day} month={d.month} date={d.date} source="month" />
                                <Events events={events.filter(f => new Date((new Date(f.begin)).setHours(0, 0, 0, 0)).toString() === d.date.toString())} />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {daysOfMonth.filter((_, i) => i >= 14 && i <= 20).map(d =>
                            <Col key={d.day}>
                                <DayHeader day={d.day} month={d.month} date={d.date} source="month" />
                                <Events events={events.filter(f => new Date((new Date(f.begin)).setHours(0, 0, 0, 0)).toString() === d.date.toString())} />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {daysOfMonth.filter((_, i) => i >= 21 && i <= 27).map(d =>
                            <Col key={d.day}>
                                <DayHeader day={d.day} month={d.month} date={d.date} source="month" />
                                <Events events={events.filter(f => new Date((new Date(f.begin)).setHours(0, 0, 0, 0)).toString() === d.date.toString())} />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {daysOfMonth.filter((_, i) => i >= 28 && i <= 34).map(d =>
                            <Col key={d.day}>
                                <DayHeader day={d.day} month={d.month} date={d.date} source="month" />
                                <Events events={events.filter(f => new Date((new Date(f.begin)).setHours(0, 0, 0, 0)).toString() === d.date.toString())} />
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
            <Event isOpen={handle_event} />
        </>
    )
}