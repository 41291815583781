import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux"

class NavMenu extends Component {
    constructor(props) {
        super(props)

        this.changePath = this.changePath.bind(this)
        this.ActiveMenuCheckChildren = this.ActiveMenuCheckChildren.bind(this)
    }

    getLabel(label) {
        const res = this.props.menu.find(item => {
            if (item.path === label) return true

            if (item.subMenu !== undefined) return item.subMenu.find(subItem => subItem.path === label)

            return false
        })

        if (res === undefined)
            return ""

        if (res.path === label)
            return res.label

        return res.subMenu.find(subItem => subItem.path === label).label
    }

    componentDidMount() {
        this.props.onHandlePageTitle(this.getLabel(this.props.path))
    }

    changePath(e) {
        this.setState({ path: e.target.getAttribute('href') })
        this.props.onToggle()
        this.props.onHandlePageTitle(this.getLabel(e.target.getAttribute("href")))
    }

    ActiveMenuCheckChildren(item) {
        let isActive = this.props.path === item.path

        if (!isActive && item.subMenu !== undefined) {
            item.subMenu.forEach(x => {
                if (x.path === this.props.path && x.label === "")
                    isActive = true
            })
        }

        if (item.path == "/home" && this.props.path == "/")
            return true;

        return isActive;
    }

    render() {

        if (this.props.menu === [])
            return (<></>)

        return (
            <Nav vertical className="main-menu">
                {this.props.menu
                    .filter(item => { return item.profile.find(prof => this.props.profileId === prof) !== undefined })
                    .filter(e => e.unprofile === undefined || !e.unprofile.includes(this.props.profileId))
                    .map(item => {

                        let subitem

                        if (item.subMenu !== undefined) {
                            subitem = item.subMenu.filter(item => item.label !== "").filter(item => { return item.profile.find(prof => this.props.profileId === prof) !== undefined }).map(subitem => {
                                let sub2menu

                                if (subitem.subMenu !== undefined) {

                                    sub2menu = subitem.subMenu.filter(item => item.label !== "").filter(item => { return item.profile.find(prof => this.props.profileId === prof) !== undefined }).map(sub2item => {

                                        return (
                                            <NavItem key={sub2item.path} className={this.props.path === sub2item.path ? "active sub2-menu" : "sub2-menu"}>
                                                <NavLink tag={Link} to={sub2item.path} onClick={this.changePath} >
                                                    <FontAwesomeIcon icon={sub2item.icon} className="menu-icon" />{sub2item.label}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }

                                return (

                                    <div key={subitem.path} style={{ width: "100%" }}>
                                        <NavItem className={(this.props.path === subitem.path || (subitem.subMenu !== undefined && subitem.subMenu.find(x => x.path === this.props.path && x.label === ''))) && subitem.enable ? "active sub-menu" : "sub-menu"}>
                                            <NavLink tag={Link} to={subitem.path} onClick={this.changePath}
                                                className={sub2menu !== undefined && sub2menu.find(x => x.label !== "") !== undefined ? "sub-menu-main" : ""} disabled={!subitem.enable}>
                                                <FontAwesomeIcon icon={subitem.icon} className="menu-icon" />{subitem.label}
                                            </NavLink>
                                        </NavItem>
                                        {sub2menu}
                                    </div>
                                )
                            })
                        }

                        return (
                            <div key={item.path} style={{ display: "contents" }}>

                                <NavItem style={{ display: item.label === '' ? "none" : "inline" }} key={item.path} className={this.ActiveMenuCheckChildren(item) && item.enable ? "active" : ""}>
                                    <NavLink tag={Link} to={item.path} onClick={this.changePath}
                                        className={subitem !== undefined && subitem.find(x => x.label !== "") !== undefined ? "sub-menu-main" : ""} disabled={!item.enable}>
                                        <FontAwesomeIcon icon={item.icon} className="menu-icon" />{item.label}
                                    </NavLink>
                                </NavItem>
                                {subitem}
                            </div>
                        )
                    })}
            </Nav>
        );
    }
}
const mapStateToProps = state => {
    return { path: state.main.path, menu: state.main.menu, profileId: state.main.profileId }
}

export default connect(mapStateToProps)(NavMenu)