import React, { useState, useEffect } from "react";
import "./Agenda.css";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { formatDate, formatHour2, getStrMonth, Toast, ToastTypes, Loading } from "../global/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Event } from "./Event";
import { useNavigate } from "react-router-dom";
import { DayHeader } from "./DayHeader";
import { WeekEvent } from "./weekly/Event";
import { handleEvent } from "../../redux/AgendaSlice";
import { Filters } from "./Filters";

export const Week = () => {
    const dispatch = useDispatch();
    const handle_event = useSelector(state => state.agenda.handle_event);
    const access_token = useSelector(state => state.main.access_token);
    const selPhases = useSelector(state => state.agenda.filter.phases);
    const selInterviewers = useSelector(state => state.agenda.filter.interviewers);
    const navigate = useNavigate();

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const [days, setDays] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const dt = new Date();
        let first = new Date(dt.setDate(dt.getDate() - dt.getDay()));

        let days = [];
        for (var i = 0; i < 5; i++)
            days.push(new Date(first.setDate(first.getDate() + 1)));

        setDays(days);
    }, []);

    useEffect(async () => {
        if (days.length == 0)
            return;

        loadEvents();
    }, [days]);

    const loadEvents = async () => {
        const beginDate = formatDate(days[0]);
        const endDate = formatDate(days[4]);

        dispatch(Loading(true));

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        try {
            const response = await fetch(`/api/agenda?begin=${beginDate}&end=${endDate}&phases=${selPhases.toString()}&interviewers=${selInterviewers.toString()}`, requestOptions);
            if (!response.ok) throw "";
            const json = await response.json();

            // more than 1 day
            const dif = json.filter(f => formatDate(f.begin) != formatDate(f.end))
            dif.forEach(evt => json.push({ ...evt, begin: formatDate(evt.end) + " 00:00" }));

            let prevId = 0;
            json.forEach(evt => {
                let have = json.find(child => new Date(evt.begin) >= new Date(child.begin) && new Date(evt.begin) <= new Date(child.end) && child.eventId == prevId);

                let pos = 0;
                if (have != undefined)
                    pos = have.position + 1;

                evt.position = pos;

                prevId = evt.eventId;
            });

            setEvents(json);
        } catch {
            dispatch(Toast("Não foi possivel obter os eventos.", ToastTypes.Danger, false))
        } finally {
            dispatch(Loading(false));
        }
    }

    const handleWeekPrev = () => {
        const dt = new Date(days[0]);

        let first = new Date(dt.setDate(dt.getDate() - 8));

        let xDays = [];
        for (var i = 0; i < 5; i++)
            xDays.push(new Date(first.setDate(first.getDate() + 1)));

        setDays(xDays);
    }

    const handleWeekNext = () => {
        const dt = new Date(days[0]);

        let first = new Date(dt.setDate(dt.getDate() + 6));

        let xDays = [];
        for (var i = 0; i < 5; i++)
            xDays.push(new Date(first.setDate(first.getDate() + 1)));

        setDays(xDays);
    }

    useEffect(() => {
        if (handle_event.isOpen || !handle_event.needRefresh)
            return;

        dispatch(handleEvent({ isOpen: false, needRefresh: false }));

        loadEvents();
    }, [handle_event]);

    const filterEvents = (event, day, h) => {
        const curDate = new Date(formatDate(day) + " " + formatHour2(h * 100));

        return formatDate(curDate) == formatDate(new Date(event.begin)) && curDate.getHours() == new Date(event.begin).getHours();
    }

    const getTitle = () => {
        let result = getStrMonth(days[0].getMonth() + 1) + " ";

        if (days[0].getFullYear() != days[4].getFullYear())
            result += days[0].getFullYear();
        if (days[0].getMonth() != days[4].getMonth())
            result += " - " + getStrMonth(days[4].getMonth() + 1)

        return result + " " + days[4].getFullYear();
    }

    const getWeekTitle = () => {
        let currentdate = days[0];

        const oneJan = new Date(currentdate.getFullYear(), 0, 1);
        const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        return `Semana ${Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7)}`;
    }

    if (days.length == 0)
        return (<></>);

    return (
        <>
            <Container className="agenda">
                <Row className="agenda-header">
                    <Col className="agenda-header-col">
                        <div className="agenda-header-col-left">
                            <Button color="secondary" outline size="sm" className="border-0 agenda-header-col-change-view" onClick={handleWeekPrev}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Button>
                            <Button color="secondary" outline size="sm" className="border-0 agenda-header-col-change-view" onClick={handleWeekNext}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                            <h3>{getTitle()}</h3>
                            <Badge color="light" pill style={{ marginLeft: `10px`, color: `black`, fontWeight: `normal` }}>{getWeekTitle()}</Badge>
                        </div>
                        <div className="agenda-header-col-right">
                            <Filters />
                            <Button color="secondary" outline size="sm" onClick={_ => navigate("/agenda/month")}>Mensal</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="agenda-weekly-weekdays">
                    <Col style={{ maxWidth: 60 + "px" }}></Col>
                    <Col>SEG</Col>
                    <Col>TER</Col>
                    <Col>QUA</Col>
                    <Col>QUI</Col>
                    <Col>SEX</Col>
                </Row>
                <Row className="agenda-weekly-weekdays-day">
                    <Col style={{ maxWidth: 60 + "px" }}></Col>
                    {days.map(day => <Col key={day.getDate()}><DayHeader day={day.getDate()} month={day.getMonth()} date={formatDate(day)} source="week" /></Col>)}
                </Row>
                <div style={{ height: "calc(100vh - 230px)", overflow: "auto" }}>
                    {hours.map(h =>
                        <Row key={h} className="agenda-weekly-hours">
                            <Col className="agenda-weekly-hours-hour"><span>{formatHour2(h * 100)}</span></Col>
                            {days.map(day =>
                                <WeekEvent key={day.getDate()} hour={h} events={events.filter(f => filterEvents(f, day, h))} />
                            )}
                        </Row>)}
                </div>
            </Container>
            <Event isOpen={handle_event} />
        </>
    )
}