import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label } from 'reactstrap'
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading, formatDate, CheckEmail, DateUtc } from "../../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const StartForm = (props) => {

    const [profileId] = useState(props.profileId);
    const [modal, setModal] = useState({
        isOpen: false,
        isNew: false,
        source: -1,
        name: "",
        mobile: "",
        email: "",
        birthDate: "",
        nationality: -1,
        literaryAbility: -1,
        studyArea: -1,
        region: -1,
        store: -1,
        favoritStores: [],
        scheduleType: -1,
        fileCV: null,
        externalAPI: null,
        error: {
            source: false,
            name: false,
            mobile: false,
            email: false,
            birthDate: false,
            nationality: false,
            literaryAbility: false,
            studyArea: false,
            region: false,
            scheduleType: false,
            favoritStores: false
        }
    });
    const [sources, setsources] = useState([]);
    const [literaryAbilities, setliteraryAbilities] = useState([]);
    const [studyAreas, setstudyAreas] = useState([]);
    const [nationalities, setnationalities] = useState([]);
    const [regions, setregions] = useState([]);
    const [stores, setstores] = useState([]);
    const [scheduleTypes, setscheduleTypes] = useState([]);
    const [favoritStores, setfavoritStores] = useState([]);

    useEffect(() => {
        handleNewCandidate();
    }, []);

    useEffect(() => {

        if (props.checkFields === false)
            return

        var error = {
            source: false,
            name: false,
            mobile: false,
            email: false,
            birthDate: false,
            nationality: false,
            literaryAbility: false,
            studyArea: false,
            region: false,
            scheduleType: false,
            favoritStores: false
        }
        
        if (modal.birthDate !== null && modal.birthDate !== '') {
            var DataInserted = new Date(modal.birthDate.getFullYear() + 16, modal.birthDate.getMonth(), modal.birthDate.getDate(), 0, 0, 0)
            var myToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
            error.birthDate = DataInserted <= myToday ? false : true
        }
        else {
            error.birthDate = true
        }

        error.email = modal.email === '' || !CheckEmail(modal.email)
        error.source = modal.source === -1
        error.name = modal.name === ''
        error.studyArea = modal.studyArea === -1
        error.region = modal.region === -1
        error.scheduleType = modal.scheduleType === -1
        error.mobile = modal.mobile === ''
        error.favoritStores = modal.favoritStores !== undefined && modal.favoritStores.length === 0

        var hasError = false
        for (var propt in error) {
            if (error[propt])
                hasError = true
        }

        if (hasError) {
            setModal({ ...modal, error: error })
        }

        var arrFavStores = []

        var objFavoritStore = modal.favoritStores.filter(a => a.selected)
        for (var i = 0; i < objFavoritStore.length; ++i) {
            arrFavStores.push(parseInt(objFavoritStore[i].Key))
        }

        var objWorkflowDetailInfo = {
            CostCenter: modal.store,
            IdMarket: modal.region,
            IdScheduleType: modal.scheduleType,
            IdFavouriteStores: arrFavStores,
            sourceId: modal.source
        }

        var objSaveCandidate = {
            IdCandidate: 0,
            IdWorkflow: 0,
            Name: modal.name,
            PhoneNumber: modal.mobile,
            Email: modal.email,
            BirthDate: modal.birthDate,
            IdCitizenship: modal.nationality,
            IdLiteraryQualification: modal.literaryAbility,
            IdStudyArea: modal.studyArea,
            Documents: modal.fileCV === null ? null : [{ Base64File: modal.fileCV.Base64File, FileName: modal.fileCV.FileName, FileExtension: modal.fileCV.FileExtension, IdFileType: 4 }],
            Detail: objWorkflowDetailInfo
        }

        //check if candidate is already in database
        if (hasError) {
            props.handlerCheckFields(null)
            return
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/checkCandidate?email=" + modal.email + '&birthDate=' + formatDate(modal.birthDate), requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                props.handlerCheckFields(json === false ? (hasError ? null : objSaveCandidate) : true)
            })
            .catch(() => props.dispatch(Toast("Não foi possível verificar o candidato", ToastTypes.Danger, false)))
    }, [props.checkFields]);

    function handleNewCandidate() {

        var arrFavoritStores = []

        if (sources.length === 0 || literaryAbilities.length === 0 || studyAreas.length === 0 || nationalities.length === 0 || regions.length === 0) {
            props.dispatch(Loading(true))

            const requestOptions = {
                method: "GET",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
            }

            if (sources.length === 0)
                fetch("/api/candidates/sources", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setsources(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

            if (literaryAbilities.length === 0)
                fetch("/api/candidates/literaryabilities", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setliteraryAbilities(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

            if (studyAreas.length === 0)
                fetch("/api/candidates/studyareas", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setstudyAreas(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

            if (nationalities.length === 0)
                fetch("/api/candidates/nationalities", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setnationalities(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

            if (regions.length === 0)
                fetch("/api/candidates/regions", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setregions(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))


            if (stores.length === 0)
                fetch("/api/candidates/stores?ProfileId=" + profileId, requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {

                        json.forEach(item => {
                            arrFavoritStores.push({ Key: item.key, value: item.value, selected: false })
                        })
                        setfavoritStores(arrFavoritStores)
                        setstores(arrFavoritStores)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))

            if (regions.length === 0)
                fetch("/api/candidates/scheduletypes", requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setscheduleTypes(json)

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
        }

        setModal({
            isOpen: true, isNew: true, source: -1, name: "", mobile: "", email: "", birthDate: "", nationality: -1, literaryAbility: -1, studyArea: -1, region: -1, fileCV: null, store: -1, favoritStores: [], scheduleType: -1,
            externalAPI: null,
            error: { source: false, name: false, mobile: false, email: false, birthDate: false, nationality: false, literaryAbility: false, studyArea: false, region: false, scheduleType: false, favoritStores: false }
        })
    }

    function handleSource(e) {
        const idSource = parseInt(e.target.value)

        if (sources.findIndex(f => f.id === idSource && f.external) !== -1) {
            let id = window.prompt("Indicar código")

            if (id !== null && id !== "") {
                props.dispatch(Loading(true))

                const requestOptions = {
                    method: "GET",
                    headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
                }

                fetch("/api/candidates/source/" + id, requestOptions)
                    .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                    .then(json => {
                        setModal({
                            ...modal, source: idSource, name: json.name, mobile: json.mobile, email: json.email, birthDate: new Date(json.birthDate), fileCV: { Base64File: json.base64String, FileName: json.fileCV.substring(json.fileCV.lastIndexOf("/") + 1), FileExtension: json.fileCV.substring(json.fileCV.lastIndexOf(".") + 1) },
                            externalAPI: { nationality: json.nationality, literaryAbility: json.literaryAbility, studyArea: json.studyArea, region: json.region, fileCV: json.fileCV, favoritStore1: json.favoritStore1, favoritStore2: json.favoritStore2, favoritStore3: json.favoritStore3 },
                            error: { source: false, name: false, mobile: false, email: false, birthDate: false, nationality: false, literaryAbility: false, studyArea: false, region: false, fileCV: json.fileCV !== null ? false : true }
                        })

                        props.dispatch(Loading(false))
                    })
                    .catch(() => props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
            } else {
                /* 2023-08-08 user can cancel the code id and still insert on BD */
                setModal({ ...modal, source: idSource, error: { ...modal.error, source: false } })
            }
        }
        else {
            setModal({ ...modal, source: idSource, error: { ...modal.error, source: false } })
        }
    }

    function handleChangeFileCV(e) {
        const file = e.target.files[0]

        if (file === undefined)
            return;

        if (file.type !== "application/pdf" && file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            props.dispatch(Toast("Só é permitido selecionar PDF ou DOCX!", ToastTypes.Warning, false))
            return
        }
        if (file.size > 3145728) { // 3MB
            props.dispatch(Toast("Só é permitido documentos com tamanho até 3MB!", ToastTypes.Warning, false))
            return
        }

        const fileExtension = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'docx' : 'pdf'

        //upload file
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = (e) => {
            setModal({ ...modal, fileCV: { Base64File: fileReader.result.split(",")[1], FileName: file.name, FileExtension: fileExtension } })
        }
    }

    function handleChangeListCombo(e) {
        var ObjStores = []
        const element = document.getElementById(e.target.id)
        let counterStore = 0
        let selected = false
        for (var i = 0; i < element.options.length; ++i) {
            if (element[i].selected)
                counterStore += 1

            if (element[i].selected && counterStore <= 3)
                selected = true
            else
                selected = false
            ObjStores.push({ Key: element[i].value, value: element[i].text, selected: selected })
        }

        var storeSelected = ObjStores.filter(a => a.selected)
        setfavoritStores(ObjStores)
        setModal({ ...modal, favoritStores: storeSelected, error: { ...modal.error, favoritStores: false } })
    }

    function handleChangeBirthDay(e) {

        var value = e
        var birthDateError = false
        if (value !== null) {

            var DataInserted = new Date(value.getFullYear() + 16, value.getMonth(), value.getDate(), 0, 0, 0)
            var myToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
            birthDateError = DataInserted <= myToday ? false : true
        }
        else {
            birthDateError = true
        }
        setModal({ ...modal, birthDate: DateUtc(value), error: { ...modal.error, birthDate: birthDateError } })
    }

    return (

        <>
            <Row>
                <Col style={{ textAlign: "left" }}>
                    <Label>Fonte</Label>
                    <Input type="select" id="lstSources" invalid={modal.error.source} onChange={handleSource}>
                        <option value="-1"></option>
                        {sources.map(source => <option key={source.id} value={source.id}>{source.name}</option>)}
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Nome</Label>
                    <Input id="txtName" type="text" maxLength="200" autoComplete="off" value={modal.name} invalid={modal.error.name}
                        onChange={(e) => {
                            setModal({ ...modal, name: e.target.value.toUpperCase(), error: { ...modal.error, name: false } })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Contato</Label>
                    <Input id="txtMobile" type="number" maxLength="9" autoComplete="off" value={modal.mobile} invalid={modal.error.mobile}
                        onChange={(e) => setModal({ ...modal, mobile: e.target.value, error: { ...modal.error, mobile: false } })} />
                </Col>
                <Col>
                    <Label>E-mail</Label>
                    <Input id="txtEmail" type="email" maxLength="200" autoComplete="off" value={modal.email} invalid={modal.error.email}
                        onChange={(e) => setModal({ ...modal, email: e.target.value.toUpperCase(), error: { ...modal.error, email: false } })} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Data nascimento</Label>
                    <DatePicker showYearDropdown id="txtBirthDate" isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd"
                        selected={modal.birthDate} autoComplete="off" 
                        className={modal.error.birthDate ? "is-invalid form-control" : "form-control"}
                        onChange={(e) => handleChangeBirthDay(e)} dateFormat="yyyy-MM-dd"
                    >
                    </DatePicker>
                </Col>
                <Col>
                    <Label>Nacionalidade</Label>
                    {modal.externalAPI !== null ? <span style={{ fontSize: 12 + "px", marginLeft: 5 + "px" }}>({modal.externalAPI.nationality})</span> : null}
                    <Input type="select" id="lstNationalities" value={modal.nationality} invalid={modal.error.nationality}
                        onChange={(e) => setModal({ ...modal, nationality: e.target.value })}>
                        <option value="-1"></option>
                        {nationalities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Habilitações</Label>
                    {modal.externalAPI !== null ? <span style={{ fontSize: 12 + "px", marginLeft: 5 + "px" }}>({modal.externalAPI.literaryAbility})</span> : null}
                    <Input type="select" id="lstLiteraryAbilities" value={modal.literaryAbility} invalid={modal.error.literaryAbility}
                        onChange={(e) => setModal({ ...modal, literaryAbility: e.target.value })}>
                        <option value="-1"></option>
                        {literaryAbilities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col>
                    <Label>Área de estudo</Label>
                    {modal.externalAPI !== null ?
                        <span style={{ fontSize: 12 + "px", marginLeft: 5 + "px" }}>({modal.externalAPI.studyArea})</span> : null}
                    <Input type="select" id="lstStudyAreas" value={modal.studyArea} invalid={modal.error.studyArea}
                        onChange={(e) => setModal({ ...modal, studyArea: e.target.value, error: { ...modal.error, studyArea: false } })}>
                        <option value="-1"></option>
                        {studyAreas.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Mercado geográfico</Label>
                    {modal.externalAPI !== null ? <span style={{ fontSize: 12 + "px", marginLeft: 5 + "px" }}>({modal.externalAPI.region})</span> : null}
                    <Input type="select" id="lstRegions" value={modal.region} invalid={modal.error.region}
                        onChange={(e) => setModal({ ...modal, region: e.target.value, error: { ...modal.error, region: false } })}>
                        <option value="-1"></option>
                        {regions.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col>
                    <Label>CV</Label>
                    <div style={{ maxHeight: 70 + "px" }}>
                        {modal.externalAPI !== null && modal.externalAPI.fileCV !== "" ?
                            <a href={modal.externalAPI.fileCV} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 36 + "px" }} className="text-primary" />
                            </a>
                            :
                            <>
                                <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 36 + "px" }}
                                    className={modal.fileCV === null ? "text-secondary" : "text-primary"} />
                                <input type="file" id="txtCV" style={{ position: "relative", top: -19 + "px", left: -30 + "px", width: 40 + "px", height: 45 + "px", opacity: 0, cursor: "pointer" }}
                                    title="" onChange={handleChangeFileCV} />
                            </>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Restaurante de Destino</Label>
                    <Input type="select" id="lstDestenyStore" value={modal.store} onChange={(e) => setModal({ ...modal, store: e.target.value })}>
                        <option value="-1"></option>
                        {stores.map(a => <option key={a.Key} value={a.Key}>{a.value}</option>)}
                    </Input>
                </Col>

                <Col>
                    <Label>Horário Tipo</Label>
                    <Input type="select" id="lstScheduleType" value={modal.scheduleType} invalid={modal.error.scheduleType}
                        onChange={(e) => setModal({ ...modal, scheduleType: e.target.value, error: { ...modal.error, scheduleType: false } })}>
                        <option value="-1"></option>
                        {scheduleTypes.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Restaurantes Preferidos</Label>
                    {modal.externalAPI !== null ? <span style={{ fontSize: 12 + "px", marginLeft: 5 + "px" }}>({modal.externalAPI.favoritStore1} / {modal.externalAPI.favoritStore2} / {modal.externalAPI.favoritStore3})</span> : null}
                    <Input type="select" multiple id="lstFavoritStores" invalid={modal.error.favoritStores} value={favoritStores !== undefined && favoritStores.length > 0 ? favoritStores.filter(a => a.selected).map((item) => item.Key) : []}
                        onChange={(e) => handleChangeListCombo(e)}>
                        {favoritStores.map(a => <option key={a.Key} value={a.Key}>{a.value}</option>)}
                    </Input>
                </Col>
                <Col></Col>
            </Row>
        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(StartForm)