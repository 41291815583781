import React from 'react'
import { connect } from "react-redux"
import { GetParameter } from "../global/Utils"
import classnames from "classnames"
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap'
import General from "./CandidatesDetail/General"
import PersonalData from "./CandidatesDetail/PersonalData"
import Phases from "./CandidatesDetail/Phases"
import Documents from "./CandidatesDetail/Documents"
import Notes from "./CandidatesDetail/Notes"
import Emails from "./CandidatesDetail/Emails"
import Uniforms from "./CandidatesDetail/Uniforms"
import { lstCostCenter } from '../../redux/CandidateSlice'

class CandidateDetail extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            profileId: props.profileId,
            activeTab: "1",
            idCandidate: GetParameter("idcandidate") === null ? -1 : GetParameter("idcandidate"),
            isHired: false,
            loading: true
        }

        this.toggle = this.toggle.bind(this)
    }

    async componentDidMount() {
        let hired = false;

        try {
            const requestOptions = { method: "GET", headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" } };

            let response = await fetch(`/api/candidates/${this.state.idCandidate}/hired`, requestOptions);
            if (!response.ok)
                throw new Error("");

            hired = await response.json();

            response = await fetch(`/api/candidates/costcenters?profileid=${this.state.profileId}`, requestOptions);
            if (!response.ok)
                throw new Error("");

            this.props.dispatch(lstCostCenter(await response.json()));
        } catch {
        }
        finally {
            this.setState(prev => ({ ...prev, loading: false, isHired: hired }));
        }
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
    }

    render() {

        if (this.state.loading)
            return (<></>);

        return (

            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={(e) => this.toggle("1")}>
                            Geral
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={(e) => this.toggle("2")}>
                            Dados Pessoais
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ display: this.state.profileId !== 5 ? "block" : "none" }}>
                        <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={(e) => this.toggle("3")}>
                            Fases
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={(e) => this.toggle("4")}>
                            Documentos
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ display: this.state.profileId !== 5 ? "block" : "none" }}>
                        <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={(e) => this.toggle("5")}>
                            Notas
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ display: this.state.profileId !== 5 ? "block" : "none" }}>
                        <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={(e) => this.toggle("6")}>
                            Emails
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={(e) => this.toggle("7")}>
                            Fardas
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <General profileId={this.state.profileId} idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="2">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <PersonalData idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="3">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <Phases idCandidate={this.state.idCandidate} isHired={this.state.isHired} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="4">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <Documents idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="5">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <Notes idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="6">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <Emails idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="7">
                        <Row style={{ marginTop: 15 + "px" }}>
                            <Col sm="12">
                                <Uniforms idCandidate={this.state.idCandidate} />
                            </Col>
                        </Row>
                    </TabPane>


                </TabContent>
                <Container>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(CandidateDetail)