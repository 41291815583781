import React, { useState, useEffect } from "react"
import { Row, Col, Label, Button } from 'reactstrap'
import { Loading, Toast, ToastTypes } from "../../../global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faFileWord, faFilePdf, faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactHtmlParser from "react-html-parser";

const Email = (props) => {

    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [emailTo, setEmailTo] = useState('');
    const [emailCc, setEmailCc] = useState('');
    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState(true);
    const [idEmailQueue, SetIdEmailQueue] = useState(-1);

    useEffect(() => {
        loadEmailData();
    }, []);

    function loadEmailData() {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/detailemailsent?idCandidate=" + parseInt(props.idCandidate) + "&idWorkflow=" + parseInt(props.idWorkflow) + "&IdEmailQueue=-1", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json.body === null || json.to === null) {
                    setFileData(false)
                }
                else {
                    SetIdEmailQueue(json.idEmailQueue)
                    setEmailBody(json.body)
                    setEmailSubject(json.subject)
                    setEmailTo(json.to)
                    setEmailCc(json.cc)
                    if (json.emailAttach !== null)
                        setFiles(json.emailAttach)
                }
            })
            .catch(() => props.dispatch(Toast("Não foi possível carregar os dados do email", ToastTypes.Danger, false)))

    }

    function setDivFiles(indexAux) {
        var counter = -1
        return files.filter(x => x.deletedFile === false).map((result, index) => {
            counter = counter + 1

            return (
                index >= indexAux && index <= indexAux + 5 ?
                    result.newFile ?
                        <div key={index} className="col-lg-2 image-upload">
                            <label id="lblFile" htmlFor="newFile" key={"lblFile" + result.idFile + '_' + indexAux} style={{ cursor: "pointer" }}>
                                <img src={this.state.imagePath} key={"img" + result.idFile + '_' + indexAux} alt="Adicionar Ficheiro" style={{ border: "1px solid #ced4da", height: 60 + 'px', width: 60 + 'px' }} />
                            </label>
                            <input id="newFile" type="file" key={"newFile" + result.idFile + '_' + indexAux} onChange={(e) => this.handleChangeFile(e)} />
                        </div>
                        :
                        <div key={index} className="col-lg-2">
                            <div style={{ height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                                <FontAwesomeIcon title={result.fileName} onClick={(e) =>
                                    downloadFile(e, result.idFile)} key={"fileDownload" + result.idFile + '_' + indexAux} id={"fileDownload" + result.idFile} style={{
                                        height: 42 + 'px', width: 57 + 'px',
                                        color: result.extention === 'pdf' ? 'red' : 'blue', paddingTop: 13 + 'px', cursor: 'pointer'
                                    }} icon={result.extention === 'pdf' ? faFilePdf : faFileWord} />
                            </div>
                        </div>
                    : null
            );
        });
    }

    function downloadFile(e, idFile) {
        e.preventDefault();

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }
        props.dispatch(Loading(true))

        fetch("/api/admin/emailfile?idFile=" + idFile, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === '') {
                    props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
                    return
                }
                window.open(JSON.parse(json)[0].filePath);
                props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                props.dispatch(Toast("Não foi possivel efetuar o download.", ToastTypes.Danger, false))
            })
    }

    function handleResendEmail(e) {

        if (!window.confirm('Tem a certeza que pretende reenviar o email?'))
            return;

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/resendEmail?IdEmailQueue=" + idEmailQueue, requestOptions)
            .then(response => {
                if (response.status === 200)
                    props.dispatch(Toast("Email enviado com sucesso", ToastTypes.Success, false))
                else
                    props.dispatch(Toast("Não foi possível enviar o email", ToastTypes.Danger, false))

            })
            .catch(() => props.dispatch(Toast("Não foi possível enviar o email", ToastTypes.Danger, false)))
    }

    return (
        <div style={{ paddingLeft: 5 + 'px', paddingRight: 5 + 'px' }}>
            {fileData === false ? <Row><Col><Label>Sem Registos</Label></Col></Row> :
                <div>
                    <Row style={{ marginTop: 5 + 'px' }}>
                        <Col>
                            <Label style={{ marginBottom: 0 + 'px' }}><b>Para</b></Label>
                            <div>
                                {emailTo}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 + 'px' }}>
                        <Col>
                            <Label style={{ marginBottom: 0 + 'px' }}><b>CC</b></Label>
                            <div>
                                {emailCc}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 + 'px' }}>
                        <Col>
                            <Label style={{ marginBottom: 0 + 'px' }}><b>Assunto</b></Label>
                            <div>
                                {emailSubject}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 + 'px' }}>
                        <Col>
                            <Label style={{ marginBottom: 0 + 'px' }}><b>Mensagem</b></Label>
                            <div className="emailBody">
                                {ReactHtmlParser(emailBody)}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 + 'px' }}>
                        <Col>
                            <Label><b>Ficheiros</b></Label>
                            {
                                files.filter(x => x.deletedFile === false).map((result, index) => {

                                    return (
                                        index % 6 === 0 ?
                                            <div className="row" key={"rowFile_" + index + '_' + result.idFile}>
                                                {setDivFiles(index)}
                                            </div>
                                            :
                                            null
                                    )
                                })
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="7"></Col>
                        <Col sm="5" style={{ textAlign: "right" }}>
                            <Button color="primary" onClick={(e) => handleResendEmail(e)}><FontAwesomeIcon icon={faEnvelopeOpen} /> Reenviar Email</Button>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Email)