import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label } from 'reactstrap'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faFilter, faEye, faEyeSlash, faInfoCircle, faEdit } from '@fortawesome/pro-solid-svg-icons'

class Uniforms extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            modal: {
                isOpen: false,
                Description: '',
                Cost: '',
                Gender: -1,
                UniformSizes: '',
                IdInternalCategory: -1,
                error: {
                    Description: false,
                    Cost: false,
                    Gender: false,
                    UniformSizes: false,
                    lstInternalCategoryEdit: false
                }
            },
            lstInternalCategory: [],
            lstGender: [],
            lstState: [],
            CostFrom: '',
            CostTo: '',
            objData: { IdUniform: 0, Description: '', Cost: '', Gender: -1, UniformSizes: '', IdInternalCategory: [] }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSaveUniform = this.handleSaveUniform.bind(this)
    }

    componentDidMount() {

        let objInternalCategory;
        let objGender;
        let strInternalCategory = '';
        let strGender = '';

        let objState = [
            { value: 1, text: "Ativo", selected: true },
            { value: 0, text: "Inativo", selected: false }
        ]

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/uniformfilter", requestOptions)
            .then((response) => response.json())
            .then(data => {
                objInternalCategory = data.internalCategory.map(result => {
                    strInternalCategory += result.idInternalCategory.toString() + ','
                    return { value: result.idInternalCategory, text: result.name, selected: true }
                });

                strInternalCategory = strInternalCategory.replace(/,\s*$/, "");

                objGender = data.gender.map(result => {
                    strGender += result.idGender.toString() + ','
                    return { value: result.idGender, text: result.description, selected: true }
                });

                strGender = strGender.replace(/,\s*$/, "");

                console.log(strGender)

                this.setState({ lstState: objState, lstInternalCategory: objInternalCategory, lstGender: objGender })
                this.bindGrid(strInternalCategory, strGender, this.state.CostFrom, this.state.CostTo, 1)
            }).catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os filtros.", ToastTypes.Danger, false))
            });
    }

    bindGrid(internalcategory, gender, costFrom, costTo, state) {

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }
        costFrom = costFrom === '' ? -1 : costFrom
        costTo = costTo === '' ? -1 : costTo

        fetch("/api/admin/uniform?Gender=" + gender + "&IdInternalCategory=" + internalcategory + "&CostFrom=" + costFrom + "&CostTo=" + costTo + "&state=" + state, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Fardas",
                        columns: {
                            title: ["Descrição", "Preço", "Género", "Tamanhos", ""],
                            width: [30, 20, 20, 20, 10],
                            align: ["left", "left", "left", "left", "right"],
                            search: [true, true, true, true, false]
                        },
                        rows: json.map((row) => {
                            return {
                                id: row.idUniform,
                                columns: [
                                    { column: row.description },
                                    { column: row.cost.toString() + '€' },
                                    { column: row.genderDescription },
                                    { column: row.uniformSizes.replace(/,\s*$/, "").replace("[", "").replace("]", "").replace(/\"/g, "").replace(/,/g, ", ")}
                                ],
                                actions: [
                                    { column: faEdit, title: "Editar", action: () => this.handleEditUniform(row) },
                                    {
                                        column: row.active ? faEyeSlash : faEye, title: row.active ? "Inativar" : "Ativar",
                                        action: () => this.handleDeleteUniform(row.idUniform, row.description, row.active)
                                    }
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            { action: (e) => this.handleNewUniform(e), icon: faPlus, title: "Nova Farda" }
                        ],
                        filters: [
                            { id: "txtCostFrom", type: "text", data: this.state.CostFrom, label: "Preço de" },
                            { id: "txtCostTo", type: "text", data: this.state.CostTo, label: "Preço a" },
                            { id: "lstState", type: "list", data: this.state.lstState, label: "Estado", multiple: false },
                            { id: "lstGender", type: "list", data: this.state.lstGender, label: "Género", multiple: true },
                            { id: "lstInternalCategory", type: "list", data: this.state.lstInternalCategory, label: "Função", multiple: true }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível obter os dados das fardas", ToastTypes.Danger, true))
            });
    }

    handleFilter(data) {
        var strInternalCategory = ''
        var strGender = ''

        data[3].forEach(a => { strGender += a.toString() + ',' })
        strGender = strGender.replace(/,\s*$/, "");

        data[4].forEach(a => { strInternalCategory += a.toString() + ',' })
        strInternalCategory = strInternalCategory.replace(/,\s*$/, "");

        this.bindGrid(strInternalCategory, strGender, data[0], data[1], data[2])
    }

    handleNewUniform() {
        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: {
                    Description: false,
                    Cost: false,
                    Gender: false,
                    UniformSizes: false,
                    lstInternalCategoryEdit: false
                }
            },
            objData: { IdUniform: 0, Description: '', Cost: '', Gender: -1, UniformSizes: '', IdInternalCategory: [] }
        })
    }

    handleCloseModal() {
        this.setState({
            modal: {
                isOpen: false,
                error: {
                    Description: false,
                    Cost: false,
                    Gender: false,
                    UniformSizes: false,
                    lstInternalCategoryEdit: false
                }
            }
        });
    }

    handleChangeForm(e) {
        let dataValue = e.target.value
        let id = e.target.id
        var arrIdInternalCategory = []

        if (id === 'UniformSizes')
            dataValue = dataValue.toUpperCase();

        let auxErrorObj = this.state.modal.error
        auxErrorObj[id] = false

        if (id === 'lstInternalCategoryEdit') {

            const element = document.getElementById(e.target.id)
            for (var i = 0; i < element.selectedOptions.length; i++) {
                arrIdInternalCategory.push(element.selectedOptions[i].value)
            }
            this.setState({ objData: { ...this.state.objData, IdInternalCategory: arrIdInternalCategory }, modal: { ...this.state.modal, error: auxErrorObj } })
        }
        else {
            this.setState({ objData: { ...this.state.objData, [id]: dataValue }, modal: { ...this.state.modal, error: auxErrorObj } })
        }
    }

    handleSaveUniform() {

        var strGenderFilter = ''
        var strInternalCategoryFilter = ''
        var active = true

        this.state.lstGender.forEach(a => {
            strGenderFilter += a.value.toString() + ','
        })
        strGenderFilter = strGenderFilter.replace(/,\s*$/, "");

        this.state.lstInternalCategory.forEach(a => {
            strInternalCategoryFilter += a.value.toString() + ','
        })
        strInternalCategoryFilter = strInternalCategoryFilter.replace(/,\s*$/, "");

        let modal = { ...this.state.modal }

        modal.error.Description = this.state.objData.Description === ''
        modal.error.Cost = this.state.objData.Cost === ''
        modal.error.Gender = (this.state.objData.Gender === -1 || this.state.objData.Gender === "")
        modal.error.UniformSizes = this.state.objData.UniformSizes === ""
        modal.error.lstInternalCategoryEdit = this.state.objData.IdInternalCategory.length === 0

        if (modal.error.Description || modal.error.Cost || modal.error.Gender || modal.error.UniformSizes || modal.error.lstInternalCategoryEdit) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdUniform: this.state.objData.IdUniform,
                Description: this.state.objData.Description,
                Cost: this.state.objData.Cost,
                Gender: this.state.objData.Gender,
                GenderDescription: '',
                UniformSizes: this.state.objData.UniformSizes,
                Active: true,
                UniformsCategories: this.state.objData.IdInternalCategory
            })
        }
        this.props.dispatch(Loading(true))

        fetch("/api/admin/uniform", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.bindGrid(strInternalCategoryFilter, strGenderFilter, this.state.CostFrom, this.state.CostTo, active ? 1 : 0)
                    this.props.dispatch(Toast("Farda gravada com sucesso", ToastTypes.Success, false))

                    this.setState({
                        modal: {
                            isOpen: !this.state.modal.isOpen,
                            error: {
                                Description: false,
                                Cost: false,
                                Gender: false,
                                UniformSizes: false,
                                lstInternalCategoryEdit: false
                            }
                        }
                    })
                }
                else {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
                }
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    handleDeleteUniform(idUniform, description, active) {

        if (!window.confirm('Tem a certeza que pretende ' + (active ? 'inativar' : 'ativar') + ' a farda com a descrição: ' + description + '?'))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify({
                IdUniform: idUniform,
                Active: active ? false : true,
                UniformsCategories: []
            })
        }

        this.props.dispatch(Loading(true))

        fetch("/api/admin/uniform", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Farda " + (active ? 'inativada' : 'ativada') + " com sucesso", ToastTypes.Success, false))

                    var newArray = this.state.table.rows.filter(function (obj) {
                        return obj.id !== idUniform;
                    });
                    this.setState({ table: { ...this.state.table, rows: newArray } })
                }
                else {
                    this.props.dispatch(Toast("Não foi possível concluir a operação. Por favor tente mais tarde!", ToastTypes.Danger, true))
                }
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível concluir a operação. Por favor tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    handleEditUniform(obj) {
        var strUniformSizes = ''

        JSON.parse(obj.uniformSizes).forEach(a => {
            strUniformSizes += a.toString() + ','
        })
        strUniformSizes = strUniformSizes.replace(/,\s*$/, "");

        this.setState({
            modal: {
                isOpen: !this.state.modal.isOpen,
                error: {
                    Description: false,
                    Cost: false,
                    Gender: false,
                    UniformSizes: false,
                    IdInternalCategory: false
                }
            },
            objData: { IdUniform: obj.idUniform, Description: obj.description, Cost: obj.cost, Gender: obj.gender, UniformSizes: strUniformSizes, IdInternalCategory: obj.uniformsCategories }
        })
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.handleCloseModal}>Fardas</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <Label>Descrição</Label>
                                <Input id="Description" type="text" maxLength="50" defaultValue={this.state.objData.Description} invalid={this.state.modal.error.Description} className="form-control"
                                    autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Label>Género</Label>
                                <Input type="select" id="Gender" value={this.state.objData.Gender} invalid={this.state.modal.error.Gender} onChange={(e) => this.handleChangeForm(e)}>
                                    <option key="-1" value=""></option>
                                    {this.state.lstGender.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                            <Col xs="6">
                                <Label>Preço</Label>
                                <Input id="Cost" type="number" maxLength="50" defaultValue={this.state.objData.Cost} invalid={this.state.modal.error.Cost} className="form-control" autoComplete="off"
                                    onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Label>Tamanhos</Label> <FontAwesomeIcon style={{ color: 'red' }} title="Preencha os tamanhos separados por virgula (Ex: M,L)" icon={faInfoCircle} />
                                <Input id="UniformSizes" type="text" defaultValue={this.state.objData.UniformSizes} invalid={this.state.modal.error.UniformSizes} className="form-control"
                                    autoComplete="off" onChange={(e) => this.handleChangeForm(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Label>Funções</Label>
                                <Input type="select" multiple id="lstInternalCategoryEdit" value={this.state.objData.IdInternalCategory.map((item) => item)}
                                    invalid={this.state.modal.error.lstInternalCategoryEdit} onChange={(e) => this.handleChangeForm(e)}>
                                    {this.state.lstInternalCategory.map(v => <option key={v.value} value={v.value}>{v.text}</option>)}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(e) => this.handleSaveUniform(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(Uniforms)