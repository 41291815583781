import React, { useEffect } from "react";
import { faInboxOut } from "@fortawesome/pro-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { handleEmail } from "../../redux/AgendaSlice";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Toast, ToastTypes, Loading } from "../global/Utils";

export const Email = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.agenda.handle_email);
    const access_token = useSelector(state => state.main.access_token);

    const [data, setData] = useState({ emailAddress: "", emailAddressCC: "", subject: "", templateId: 0 });
    const [templates, setTemplates] = useState([]);
    const [err, setErr] = useState({ emailAddress: false, emailAddressCC: false, subject: false, templateId: false });

    const handleToggle = () => {
        dispatch(handleEmail({ ...state, isOpen: !state.isOpen }));
    }

    useEffect(() => {
        if (!state.isOpen)
            return;

        setErr({ emailAddress: false, emailAddressCC: false, subject: false, templateId: false });
        setData({ emailAddress: "", emailAddressCC: "", subject: "", templateId: 0 });

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        fetch("/api/agenda/email", requestOptions).then(response => response.json())
            .then(data => setTemplates(data))
            .catch(_ => dispatch(Toast("Não foi possível obter os templates.", ToastTypes.Danger, false)));

    }, [state]);

    const validateEmail = (email, checkEmpty) => {
        if (!checkEmpty && email == "")
            return true;

        return String(email).toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const handleChange = (e) => {

        if (e.type == "text")
            return setData(d => ({ ...d, [e.id]: e.value }));

        setData(d => ({ ...d, templateId: parseInt(e.value) }));
    }

    const handleEmailQueue = async () => {

        setErr({
            emailAddress: !validateEmail(data.emailAddress, true), emailAddressCC: !validateEmail(data.emailAddressCC, false),
            subject: data.subject == "", templateId: data.templateId == 0
        });

        if (!validateEmail(data.emailAddress, true) || !validateEmail(data.emailAddressCC, false) || data.subject == "" || data.templateId == 0)
            return;

        try {
            dispatch(Loading(true));

            const requestOptions = {
                method: "POST", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" },
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/agenda/event/${props.eventId}/email`, requestOptions);
            if (!response.ok)
                throw response.statusText;

            handleToggle();
            dispatch(Toast("Email agendado para envio", ToastTypes.Info, false));
        } catch (e) {
            console.error(e);
            return dispatch(Toast("Não foi possível agendar o envio do email.", ToastTypes.Danger, false));
        }
    }

    return (
        <Modal isOpen={state.isOpen} toggle={handleToggle}>
            <ModalHeader toggle={handleToggle}>Envio de email</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label>Endereço de email</Label>
                        <Input id="emailAddress" type="text" autoComplete="nope" value={data.emailAddress} invalid={err.emailAddress} onChange={e => handleChange(e.target)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Com Cópia</Label>
                        <Input id="emailAddressCC" type="text" autoComplete="nope" value={data.emailAddressCC} invalid={err.emailAddressCC} onChange={e => handleChange(e.target)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Assunto</Label>
                        <Input id="subject" type="text" autoComplete="nope" value={data.subject} invalid={err.subject} onChange={e => handleChange(e.target)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Template</Label>
                        <Input type="select" value={data.templateId} invalid={err.templateId} onChange={e => handleChange(e.target)}>
                            <option value="0"></option>
                            {templates.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
                        </Input>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="info" outline onClick={_ => handleEmailQueue()}><FontAwesomeIcon icon={faInboxOut} /> Enviar</Button>
            </ModalFooter>
        </Modal>
    )
}