import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading, zeroPad, formatHour, getStrWeek, Convert60to100, Convert100to60 } from "../global/Utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave, faPlus, faFilter, faTrash, faRotate } from '@fortawesome/pro-solid-svg-icons'

class EmployeeRequestSchedule extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modal: {
                isOpen: false
            },
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            profileId: props.profileId,
            listYears: [],
            listMonths: [],
            listStores: [],
            filterHired: [{ value: -1, text: 'Todos', selected: true }, { value: 1, text: 'Sim', selected: false }, { value: 0, text: 'Não', selected: false }],
            objData: [],
            listInternalCategoryFull: [],
            listInternalCategory: [],
            listScheduleTypes: [],
            scheduleData: {
                years: [], months: [], stores: [], IdEmployeeRequestScheduleDetail: -1, IdEmployeeRequest: -1, IdEmployeeRequestSchedule: -1, IdInternalCategory: -1, IdScheduleType: -1, Urgent: false, objData: [], objError: []
            },
            canSave: false,
            lockForm: false,
            lockPopUpComboData: false
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.editSchedule = this.editSchedule.bind(this)
        this.handleNewSchedule = this.handleNewSchedule.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleChangeFormPopUp = this.handleChangeFormPopUp.bind(this)
        this.handleChangeSchedules = this.handleChangeSchedules.bind(this)
    }

    componentDidMount() {
        var year
        var month

        //Mes corrente 
        var yearNext = new Date(new Date().setMonth(new Date().getMonth())).getFullYear();
        var currentMonth = new Date(new Date().setMonth(new Date().getMonth())).getMonth() + 1;

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/request/employeerequestschedulefilters?ProfileId=" + this.state.profileId, requestOptions)
            .then((response) => { return response.json(); })
            .then(data => {
                let years = data.years.map(result => {
                    var valueSelected = false

                    if (result === yearNext) {
                        valueSelected = true;
                        year = result;
                    }

                    return { value: result, text: result, selected: valueSelected }
                });

                let months = data.months.map(result => {
                    var valueSelected = false

                    if (result.intMonth === currentMonth) {
                        valueSelected = true;
                        month = result.intMonth
                    }

                    return { value: result.intMonth, text: result.strMonth, selected: valueSelected }
                });

                var store = -1;
                let stores = data.costCenters.map((result, index) => {

                    var valueSelected = false
                    if (index === 0) {
                        valueSelected = true;
                        store = result.number
                    }
                    return { value: result.number, text: result.name, selected: valueSelected }
                });

                this.setState({ listYears: years, listStores: stores, listMonths: months })
                this.bindGrid(year, month, store, -1)
            }).catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os dados dos filtros.", ToastTypes.Danger, false))
            });

    }


    handleFilter(data) {

        var selected = false
        const arrYears = []
        const arrMonths = []
        const arrStores = []
        var month;

        this.state.listYears.forEach(a => {
            selected = false
            if (a.value === parseInt(data[0])) {
                selected = true
            }
            arrYears.push({ value: a.value, text: a.text, selected: selected })
        })

        this.state.listMonths.forEach(a => {
            selected = false
            if (a.value === parseInt(data[1])) {
                month = a.value;
                selected = true
            }
            arrMonths.push({ value: a.value, text: a.text, selected: selected })
        })

        this.state.listStores.forEach(a => {
            selected = false
            if (a.value === parseInt(data[2])) {
                selected = true
            }
            arrStores.push({ value: a.value, text: a.text, selected: selected })
        })

        this.setState({ listYears: arrYears, listMonths: arrMonths, listStores: arrStores })

        var year = data[0]
        month = data[1]
        var store = data[2]
        var hired = parseInt(data[3])

        this.bindGrid(year, month, store, hired)
    }

    bindGrid(year, month, storeNumber, hired) {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/request/employeerequestschedule?year=" + year + "&month=" + month + "&storeNumber=" + storeNumber + "&hired=" + hired + "&ProfileId=" + this.state.profileId, requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Horários",
                        columns: {
                            title: ["Centro de Custo", "Código", "Função", "2ª Feira", "3ª Feira", "4ª Feira", "5ª Feira", "6ª Feira", "Sabado", "Domingo", "Urgente", "CH", "VCH", ""],
                            width: [8, 2, 12, 8, 8, 8, 8, 8, 8, 8, 4, 8, 8, 10],
                            align: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "right"],
                            search: [true, true, true, true, true, true, true, true, true, true, false, false, false, false]
                        },
                        rows: json.map((row, index) => {
                            return {
                                id: row.idEmployeeRequestSchedule,
                                columns: [
                                    { column: row.storeName, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.scheduleCode, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.internalCategoryName, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[0].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[1].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[2].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[3].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[4].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[5].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.employeeRequestScheduleDataWeek[6].formatedSchedule, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.urgent ? 'Sim' : 'Não', color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.estimatedWorkLoad == null ? '--' : row.estimatedWorkLoad, color: (row.idWorkflow !== null ? "#568EDB" : null) },
                                    { column: row.estimatedSalary == null ? '--' : row.estimatedSalary + '€', color: (row.idWorkflow !== null ? "#568EDB" : null) }
                                ],
                                actions: [
                                    this.state.profileId === 1 ? { column: faEdit, title: "Editar", action: (e) => this.editSchedule(e, row.idInternalCategory, row.urgent, row.idEmployeeRequestSchedule, row.employeeRequestScheduleDataWeek, row.idEmployeeRequest, row.idScheduleType, row.idWorkflow, row.year, row.month) } : null,
                                    (row.idWorkflow === null) ? { column: faTrash, title: "Apagar", action: (e) => this.deleteSchedule(e, row.idEmployeeRequestSchedule) } : null
                                ]
                            }
                        }),
                        actions: [
                            { action: "filter", icon: faFilter },
                            this.state.profileId === 1 ? { action: (e) => this.handleRefreshWorkLoadSalary(), icon: faRotate, title: "Atualiza Vencimento Carga Horária" } : null,
                            { action: (e) => this.handleNewSchedule(e), icon: faPlus, title: "Novo Horário" }
                        ],
                        filters: [

                            {
                                id: "lstYear",
                                type: "list",
                                data: this.state.listYears,
                                label: "Ano",
                                multiple: false
                            },
                            {
                                id: "lstMonth",
                                type: "list",
                                data: this.state.listMonths,
                                label: "Mês",
                                multiple: false
                            },
                            {
                                id: "lstStore",
                                type: "list",
                                data: this.state.listStores,
                                label: "Centro de Custo",
                                multiple: false
                            },
                            {
                                id: "lstHired",
                                type: "list",
                                data: this.state.filterHired,
                                label: "Contratado",
                                multiple: false
                            }

                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possivel obter os horários.", ToastTypes.Danger, false))
            })
    }

    handleNewSchedule(e) {
        e.preventDefault();

        this.fillPopUpData(-1, false, -1, null, -1, -1, null, -1, -1);

        let year = this.state.listYears.find(a => a.selected).value;
        let month = this.state.listMonths.find(a => a.selected).value;

        let canSave = (year * 100 + month) >= NextMonth();

        if (this.state.profileId === 1 || this.state.profileId === 101)
            canSave = true;

        this.setState({ modal: { isOpen: !this.state.modal.isOpen }, canSave: canSave, lockForm: false });
    }

    handleCloseModal() {
        this.setState({
            modal: { isOpen: false }
        });
    }

    getObjId(boxid) {
        var objId = '';

        if (boxid === 'E1')
            objId = 'beginSchedule1'
        else if (boxid === 'S1')
            objId = 'endSchedule1'
        else if (boxid === 'E2')
            objId = 'beginSchedule2'
        else if (boxid === 'S2')
            objId = 'endSchedule2'
        else if (boxid === 'E3')
            objId = 'beginSchedule3'
        else if (boxid === 'S3')
            objId = 'endSchedule3'
        else if (boxid === 'E4')
            objId = 'beginSchedule4'
        else if (boxid === 'S4')
            objId = 'endSchedule4'

        return objId
    }

    checkHour(timeInput) {

        var hour;
        var minute;

        timeInput = timeInput.replace('+', '').replace(':', '');

        if (timeInput.toLowerCase() === 'do' || timeInput.toLowerCase() === 'dc' || timeInput.toLowerCase() === 'ttp')
            return ''

        if (timeInput === '')
            return ''

        if (timeInput.length > 4)
            return 'Horário Inválida'

        if (timeInput.length < 4) {
            return 'Horário Inválida'
        }

        if (timeInput.length === 4) {
            hour = zeroPad(timeInput.substring(0, 2), 2)
            minute = zeroPad(timeInput.substring(2, 4), 2)
        }

        if (hour >= 24 && timeInput >= 0)
            return 'Hora Inválida'
        if (minute >= 60 && timeInput >= 0)
            return 'Minutos Inválidos'

        if (timeInput !== -1)
            return ''

    }

    editSchedule(e, idInternalCategory, urgent, idEmployeeRequestSchedule, employeeRequestScheduleDataWeek, idEmployeeRequest, IdScheduleType, idWorkflow, year, month) {
        e.preventDefault();

        this.fillPopUpData(idInternalCategory, urgent, idEmployeeRequestSchedule, employeeRequestScheduleDataWeek, idEmployeeRequest, IdScheduleType, idWorkflow, year, month);

        let canSave = (year * 100 + month) >= NextMonth();

        if (this.state.profileId === 1 || this.state.profileId === 101)
            canSave = true;

        this.setState({ canSave: canSave });
    }

    deleteSchedule(e, idEmployeeRequestSchedule) {
        e.preventDefault()

        if (!window.confirm('Tem a certeza que pretende apagar o horário?'))
            return;
        const requestOptions = {

            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/request/employeerequestschedule?idEmployeeRequestSchedule=" + idEmployeeRequestSchedule, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (json === 0) {
                    this.props.dispatch(Toast("Não foi possível apagar", ToastTypes.Danger, false))
                }
                else {
                    this.props.dispatch(Toast("Horário apagado com sucesso", ToastTypes.Success, false))
                    this.bindGrid(this.state.listYears.find(a => a.selected).value, this.state.listMonths.find(a => a.selected).value, this.state.listStores.find(a => a.selected).value,
                        this.state.filterHired.find(a => a.selected).value)
                }

                this.props.dispatch(Loading(false));
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível apagar", ToastTypes.Danger, true))
            });
    }

    filterInternalCategory(CostCenter, lstinternalCategory) {
        var listInternalCategory = lstinternalCategory.filter(a => a.office === CostCenter > 1000)
        this.setState({
            listInternalCategory: listInternalCategory
        })
    }

    fillPopUpData(idInternalCategory, urgent, idEmployeeRequestSchedule, employeeRequestScheduleDataWeek, idEmployeeRequest, IdScheduleType, idWorkflow, year, month) {

        var objData = []
        var objError = []

        var yearFiler;
        var monthFiler;

        if (idEmployeeRequestSchedule === -1) {
            yearFiler = new Date(new Date().setMonth(new Date().getMonth())).getFullYear();
            monthFiler = new Date(new Date().setMonth(new Date().getMonth())).getMonth() + 1;
        }
        else {
            yearFiler = year
            monthFiler = month
        }

        var storeFilter = this.state.listStores.find(x => x.selected).value

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/request/employeerequestscheduledetailfilter?ProfileId=" + this.state.profileId + "&idEmployeeRequestSchedule=" + idEmployeeRequestSchedule, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {

                let listInternalCategory = []
                listInternalCategory.push({ value: -1, text: '', selected: idInternalCategory === -1, office: false })
                data.tblInternalCategory.map(result => {
                    var valueSelected = false

                    if (result.idInternalCategory === idInternalCategory) {
                        valueSelected = true;
                    }
                    listInternalCategory.push({ value: result.idInternalCategory, text: result.name, selected: valueSelected, office: result.officeFlag })
                });

                let years = data.years.map(result => {
                    var valueSelected = false

                    if (result === yearFiler) {
                        valueSelected = true;
                    }

                    return { value: result, text: result, selected: valueSelected }
                });

                let months = data.months.map(result => {
                    var valueSelected = false

                    if (result.intMonth === monthFiler) {
                        valueSelected = true;
                    }

                    return { value: result.intMonth, text: result.strMonth, selected: valueSelected }
                });

                let stores = data.costCenters.map(result => {
                    var valueSelected = false
                    if (result.number === storeFilter) {
                        valueSelected = true;
                        this.filterInternalCategory(result.number, listInternalCategory)
                    }
                    return { value: result.number, text: result.name, selected: valueSelected }
                });

                let ScheduleTypes = []

                ScheduleTypes.push({ value: -1, text: '', selected: IdScheduleType === -1 })
                data.scheduleType.map(result => {
                    var valueSelected = false

                    if (result.idScheduleType === IdScheduleType) {
                        valueSelected = true;
                    }
                    ScheduleTypes.push({ value: result.idScheduleType, text: result.scheduleName, selected: valueSelected })
                });

                if (employeeRequestScheduleDataWeek != null) {
                    employeeRequestScheduleDataWeek.forEach(item => {
                        objData.push({ weekDay: item.weekDay, beginSchedule1: item.beginSchedule1, endSchedule1: item.endSchedule1, beginSchedule2: item.beginSchedule2, endSchedule2: item.endSchedule2, beginSchedule3: item.beginSchedule3, endSchedule3: item.endSchedule3, beginSchedule4: item.beginSchedule4, endSchedule4: item.endSchedule4 })
                        objError.push({ weekDay: item.weekDay, beginSchedule1: '', endSchedule1: '', beginSchedule2: '', endSchedule2: '', beginSchedule3: '', endSchedule3: '', beginSchedule4: '', endSchedule4: '' })
                    })
                }
                else {
                    for (let i = 1; i <= 7; i++) {
                        objData.push({ weekDay: i, beginSchedule1: null, endSchedule1: null, beginSchedule2: null, endSchedule2: null, beginSchedule3: null, endSchedule3: null, beginSchedule4: null, endSchedule4: null })
                        objError.push({ weekDay: i, beginSchedule1: '', endSchedule1: '', beginSchedule2: '', endSchedule2: '', beginSchedule3: '', endSchedule3: '', beginSchedule4: '', endSchedule4: '' })
                    }
                }

                let year = this.state.listYears.find(a => a.selected).value;
                let month = this.state.listMonths.find(a => a.selected).value;

                let canSave = (year * 100 + month) >= NextMonth();

                if (this.state.profileId === 1 || this.state.profileId === 101)
                    canSave = true;

                this.setState({
                    listInternalCategoryFull: listInternalCategory,
                    listScheduleTypes: ScheduleTypes,
                    scheduleData: { years: years, months: months, stores: stores, IdInternalCategory: idInternalCategory, IdEmployeeRequest: idEmployeeRequest, IdEmployeeRequestSchedule: idEmployeeRequestSchedule, IdScheduleType: IdScheduleType, Urgent: urgent, objData: objData, objError: objError },
                    modal: { isOpen: true },
                    lockPopUpComboData: idEmployeeRequestSchedule > 0 ? true : this.state.profileId === 1 ? false : true,
                    lockForm: idWorkflow !== null || (!canSave && idEmployeeRequestSchedule != -1) ? true : false
                })


            }).catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os dados dos filtros.", ToastTypes.Danger, false))
            });

    }

    handleChangeForm(e) {
        let dataValue = e.target.value
        let id = e.target.id
        var lstState

        var year = this.state.listYears.find(a => a.selected).value
        var month = this.state.listMonths.find(a => a.selected).value
        var store = this.state.listStores.find(a => a.selected).value
        var hired = this.state.filterHired.find(a => a.selected).value

        var list = []
        const arrData = []
        var selected = false

        if (id === 'year') {
            list = this.state.listYears
            lstState = 'listYears'
        }
        else if (id === 'month') {
            list = this.state.listMonths
            lstState = 'listMonths'
        }
        else if (id === 'store') {
            list = this.state.listStores
            lstState = 'listStores'
        }

        list.forEach(a => {
            selected = false
            if (parseInt(a.value) === parseInt(dataValue)) {
                selected = true
                if (id === 'year')
                    year = a.value
                else if (id === 'month')
                    month = a.value
                else if (id === 'store')
                    store = a.value
            }
            arrData.push({ value: a.value, text: a.text, selected: selected })
        })

        this.setState({ [lstState]: arrData })

        this.bindGrid(year, month, store, hired)

    }

    handleChangeSchedules(e) {
        var dataValue = e.target.value
        var id = e.target.id
        const splitId = id.split('_');
        var week = splitId[1];
        var idObj = splitId[0];
        let data = this.state.scheduleData.objData
        let dataError = this.state.scheduleData.objError
        const index = data.findIndex(f => f.weekDay === parseInt(week))

        var IdWithoutlast3 = id.slice(0, -3);
        var boxNumber = parseInt(id.substr(id.length - 3, 1));
        if (idObj !== 'beginSchedule1') {
            var idAux = ''
            var dayValue = null;
            if (IdWithoutlast3 == 'endSchedule') {
                idAux = 'beginSchedule' + boxNumber.toString();
            }
            else {
                idAux = 'endSchedule' + (boxNumber - 1).toString();
            }

            dayValue = this.state.scheduleData.objData.find(a => a.weekDay === parseInt(week))[idAux]

            if (dayValue !== undefined && dayValue !== null && dayValue !== '' && (dayValue.indexOf("+") >= 0 || dataValue.replace('+', '') < dayValue) && dataValue.indexOf("+") < 0 && dataValue.length === 4) {
                if (dataValue !== '') {
                    dataValue = '+' + dataValue
                }
            }
        }

        if (dataValue.toLowerCase() === 'do' || dataValue.toLowerCase() === 'dc' || dataValue.toLowerCase() === 'ttp') {
            dataError[index][idObj] = ''
        }
        else {
            dataError[index][idObj] = this.checkHour(dataValue)
        }
        data[index][idObj] = dataValue === '' ? null : dataValue.replace(':', '')
        this.setState({ scheduleData: { ...this.state.scheduleData, objData: data, objError: dataError } })
    }

    handleChangeFormPopUp(e) {
        var dataValue = e.target.value
        var id = e.target.id
        const arrData = []

        if (e.target.type === "checkbox") {
            dataValue = e.currentTarget.checked
            this.setState({ scheduleData: { ...this.state.scheduleData, Urgent: dataValue }})
            return
        }

        if (id === 'years' || id === 'months' || id === 'stores') {
            var list = []

            var selected = false

            if (id === 'years') {
                list = this.state.scheduleData.years
            }
            else if (id === 'months') {
                list = this.state.scheduleData.months
            }
            else if (id === 'stores') {
                list = this.state.scheduleData.stores
                this.filterInternalCategory(dataValue, this.state.listInternalCategoryFull)
            }

            list.forEach(a => {
                selected = false
                if (parseInt(a.value) === parseInt(dataValue)) {
                    selected = true
                }
                arrData.push({ value: a.value, text: a.text, selected: selected })
            })

            let canSave = false;

            if (id === "years" || id === "months") {
                let year = id === "years" ? arrData.find(a => a.selected).value : this.state.scheduleData.years.find(a => a.selected).value;
                let month = id === "months" ? arrData.find(a => a.selected).value : this.state.scheduleData.months.find(a => a.selected).value;

                canSave = (year * 100 + month) >= NextMonth();

            }

            if (this.state.profileId === 1 || this.state.profileId === 101)
                canSave = true;

            this.setState({ scheduleData: { ...this.state.scheduleData, [id]: arrData }, canSave: canSave })
        } else {

            var auxId = ''
            var idSelected = -1

            if (id === 'IdInternalCategory') {
                list = this.state.listInternalCategory
                auxId = 'listInternalCategory'
            }
            else if (id === 'IdScheduleType') {
                list = this.state.listScheduleTypes
                auxId = 'listScheduleTypes'
            }

            list.forEach(a => {
                selected = false
                if (parseInt(a.value) === parseInt(dataValue)) {
                    idSelected = parseInt(a.value)
                    selected = true
                }
                arrData.push({ value: a.value, text: a.text, selected: selected })
            })

            if (id === 'IdInternalCategory') {
                this.setState({ listInternalCategory: arrData })
            }
            else if (id === 'IdScheduleType') {
                this.setState({ listScheduleTypes: arrData })
            }

            this.setState({ scheduleData: { ...this.state.scheduleData, [id]: idSelected } })
        }
    }

    checkObjError(obj) {

        var wrongSchedule = false

        //Horas mal introduzidas
        for (let i = 1; i <= 7; i++) {
            Object.keys(obj[i - 1]).forEach(a => {
                if (a !== 'weekDay') {
                    if (obj.find(x => x.weekDay === i)[a] !== '') {
                        wrongSchedule = true
                        return;
                    }
                }
            })
        }

        return wrongSchedule
    }

    formatDataToSave(value) {
        if (value !== null) {

            if (value.substring(0, 1) === '+')
                value = parseInt(value) + 2400
            else if (value.toString().toLowerCase() === 'do')
                value = 10001
            else if (value.toString().toLowerCase() === 'dc')
                value = 10002
            else if (value.toString().toLowerCase() === 'ttp')
                value = 10005
            else
                value = parseInt(value)
        }
        return value
    }

    handleSaveSchedule(e) {
        const year = this.state.scheduleData.years.find(a => a.selected).value;
        const month = this.state.scheduleData.months.find(a => a.selected).value;
        const nextMonth = NextMonth();

        let wrongSchedule = false
        var objSaveAux = this.state.scheduleData.objData

        for (let i = 1; i <= 7; i++) {
            Object.keys(objSaveAux[i - 1]).forEach(a => {

                if (a !== 'weekDay') {
                    if (objSaveAux.find(x => x.weekDay === i)[a] !== null && objSaveAux.find(x => x.weekDay === i)[a] === '')
                        objSaveAux[i - 1][a] = null
                }
            })
        }

        //Horas mal introduzidas
        wrongSchedule = this.checkObjError(this.state.scheduleData.objError)

        var arrValidation = []
        var totalHours = 0
        var allEmpty = true
        var followedSchedule = true

        var totalHoursAux = 0

        //se o fim é maior que o inicio
        let dataError = this.state.scheduleData.objError
        if (wrongSchedule === false) {
            for (let i = 1; i <= 7; i++) {

                var obj = objSaveAux.find(x => x.weekDay === i)

                var hoursDay = 0

                for (let a = 1; a <= 4; a++) {

                    var idBegin = 'beginSchedule' + a.toString()
                    var idEnd = 'endSchedule' + a.toString()
                    var beginSchedule = 0
                    var endSchedule = 0

                    if (obj[idBegin] !== null)
                        allEmpty = false

                    if (a >= 2) {
                        var idBeginPrevious = 'beginSchedule' + (a - 1).toString()
                        if (obj[idBeginPrevious] === null && obj[idBegin] !== null)
                            followedSchedule = false
                    }


                    if (obj[idBegin] !== null || obj[idEnd] !== null) {

                        const index = dataError.findIndex(f => f.weekDay === i)
                        if ((obj[idBegin].toLowerCase() === 'do' || obj[idBegin].toLowerCase() === 'dc' || obj[idBegin].toLowerCase() === 'ttp') && obj[idEnd] !== null) {
                            dataError[index][idEnd] = 'Horário inválido'
                            wrongSchedule = true
                        }
                        else if (obj[idBegin].toLowerCase() !== 'do' && obj[idBegin].toLowerCase() !== 'dc' && obj[idBegin].toLowerCase() !== 'ttp') {
                            beginSchedule = obj[idBegin] === null ? 0 : obj[idBegin].substring(0, 1) === '+' ? parseInt(obj[idBegin]) + 2400 : parseInt(obj[idBegin])
                            endSchedule = obj[idEnd] === null ? 0 : obj[idEnd].substring(0, 1) === '+' ? parseInt(obj[idEnd]) + 2400 : parseInt(obj[idEnd])

                            if (beginSchedule >= endSchedule && beginSchedule < 10001) {
                                dataError[index][idBegin] = 'Hora de Inicio maior que a de fim'
                                dataError[index][idEnd] = ''
                                wrongSchedule = true
                            }
                            else {
                                hoursDay += Convert60to100(endSchedule) - Convert60to100(beginSchedule)
                            }
                        }
                    }
                }

                totalHoursAux += hoursDay

                arrValidation.push({ weekDay: 1, totalHours: hoursDay })
                totalHours += hoursDay
            }

            totalHours = Convert100to60(totalHours)

            this.setState({ scheduleData: { ...this.state.scheduleData, objError: dataError } })
        }

        if (wrongSchedule) {
            this.props.dispatch(Toast("Existem horas mal introduzidas. Verifique as caixas a vermelho", ToastTypes.Danger, false))
            return
        }

        if (this.state.scheduleData.IdInternalCategory === -1 || this.state.scheduleData.IdScheduleType === -1 || this.state.scheduleData.years.find(a => a.selected).value === -1 || this.state.scheduleData.months.find(a => a.selected).value === -1 || this.state.scheduleData.stores.find(a => a.selected).value === -1) {
            this.props.dispatch(Toast("Preencha todos os campos (Ano / Mês / centro de custo / Tipo de horário)", ToastTypes.Danger, false))
            return
        }

        if (allEmpty) {
            this.props.dispatch(Toast("Preencha o horário", ToastTypes.Danger, false))
            return
        }

        if (followedSchedule === false) {
            this.props.dispatch(Toast("Os horários têm de ser seguidos", ToastTypes.Danger, false))
            return
        }

        //save
        var objDetail = []

        var objEmployeeRequestSchedule = { IdEmployeeRequestSchedule: this.state.scheduleData.IdEmployeeRequestSchedule, IdInternalCategory: this.state.scheduleData.IdInternalCategory, IdScheduleType: this.state.scheduleData.IdScheduleType, Urgent: this.state.scheduleData.Urgent }

        objSaveAux.map(a => {
            return (
                objDetail.push({ IdEmployeeRequestScheduleDetail: 0, IdEmployeeRequestSchedule: this.state.scheduleData.IdEmployeeRequestSchedule, WeekDay: a.weekDay, BeginSchedule1: this.formatDataToSave(a.beginSchedule1), EndSchedule1: this.formatDataToSave(a.endSchedule1), BeginSchedule2: this.formatDataToSave(a.beginSchedule2), EndSchedule2: this.formatDataToSave(a.endSchedule2), BeginSchedule3: this.formatDataToSave(a.beginSchedule3), EndSchedule3: this.formatDataToSave(a.endSchedule3), BeginSchedule4: this.formatDataToSave(a.beginSchedule4), EndSchedule4: this.formatDataToSave(a.endSchedule4) })
            )
        })

        var objSave = { employeesRequestScheduleDetail: objDetail, employeeRequestSchedule: objEmployeeRequestSchedule, year: this.state.scheduleData.years.find(a => a.selected).value, month: this.state.scheduleData.months.find(a => a.selected).value, storeNumber: this.state.scheduleData.stores.find(a => a.selected).value, totalHours: totalHours, idEmployeeRequestSchedule: this.state.scheduleData.IdEmployeeRequestSchedule }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objSave)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/request/employeerequestscheduledetail", requestOptions).then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                if (json.length > 0 && json[0].weekDay === -1) {
                    this.props.dispatch(Toast("Não foi possível gravar. Tente mais tarde!", ToastTypes.Danger, true))
                }
                else if (json.length > 0 && this.checkObjError(json) === true) {
                    this.setObjErrorSave(json)
                    this.props.dispatch(Toast("Corrija os erros encontrados", ToastTypes.Danger, false))
                }
                else {
                    this.props.dispatch(Toast("Horário gravado com sucesso", ToastTypes.Success, false))

                    this.bindGrid(this.state.listYears.find(a => a.selected).value, this.state.listMonths.find(a => a.selected).value, this.state.listStores.find(a => a.selected).value, this.state.filterHired.find(a => a.selected).value)

                    this.setState({
                        modal: { isOpen: !this.state.modal.isOpen },
                        scheduleData: { ...this.state.scheduleData, IdEmployeeRequestScheduleDetail: -1, IdEmployeeRequest: -1, IdEmployeeRequestSchedule: -1, IdInternalCategory: -1, IdScheduleType: -1, Urgent: false }
                    })
                }

                this.props.dispatch(Loading(false))

            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível gravar. Tente mais tarde!", ToastTypes.Danger, true))
            });
    }

    setObjErrorSave(objError) {


        let dataError = [];

        objError.forEach(result => {
            dataError.push({ weekDay: result.weekDay, beginSchedule1: result.beginSchedule1, endSchedule1: result.endSchedule1, beginSchedule2: result.beginSchedule2, endSchedule2: result.endSchedule2, beginSchedule3: result.beginSchedule3, endSchedule3: result.endSchedule3, beginSchedule4: result.beginSchedule4, endSchedule4: result.endSchedule4 })
        });

        this.setState({ scheduleData: { ...this.state.scheduleData, objError: dataError } })
    }

    setReturnInput(objData, objError, weekDay, objProperty) {

        var html = ""
        var value = '';
        var error = "";
        if (objData !== undefined) {

            if (objData.find(x => parseInt(x.weekDay) === weekDay)[objProperty] === null)
                value = ''
            else if (objData.find(x => parseInt(x.weekDay) === weekDay)[objProperty] !== null && !isNaN(parseInt(objData.find(x => parseInt(x.weekDay) === weekDay)[objProperty])))
                value = formatHour(objData.find(x => x.weekDay === weekDay)[objProperty])
            else
                value = objData.find(x => parseInt(x.weekDay) === weekDay)[objProperty]
        }

        if (objError !== undefined)
            error = objError.find(x => parseInt(x.weekDay) === weekDay)[objProperty]

        html = <input title={error} disabled={this.state.lockForm} value={value} style={{ minWidth: 60 + "px", borderColor: error !== '' ? "red" : "transparent", textAlign: "center", color: value.indexOf('+') >= 0 ? 'gray' : '', fontStyle: value.indexOf('+') >= 0 ? 'italic' : '' }} key={objProperty + '_' + weekDay} id={objProperty + '_' + weekDay} type="text" placeholder="hh:mm" onChange={(e) => this.handleChangeSchedules(e)} maxLength="6" className="form-control" autoComplete="off" />

        return html
    }

    handleRefreshWorkLoadSalary() {

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/request/refreshschedules", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(Toast("Dados atualizados com sucesso!", ToastTypes.Success, true))
                    this.bindGrid(this.state.listYears.find(a => a.selected).value, this.state.listMonths.find(a => a.selected).value, this.state.listStores.find(a => a.selected).value, this.state.filterHired.find(a => a.selected).value)
                }
                this.props.dispatch(Loading(false))
            })
            .catch(_ => {
                this.props.dispatch(Toast("Não foi possível apagar atualizar os dados!", ToastTypes.Danger, true))
            });
    }

    render() {

        return (

            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal size="lg" style={{ maxWidth: '900px', width: '100%' }} isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.handleCloseModal}>Horário</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <div>

                                    <div className="row">
                                        <div className="col-lg-4" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label htmlFor="years">Ano</label>
                                            <Input type="select" key="years" id="years" disabled={this.state.lockForm}
                                                value={this.state.scheduleData.years.length > 0 && this.state.scheduleData.years.find(a => a.selected) !== undefined ?
                                                    this.state.scheduleData.years.find(a => a.selected).value : -1} onChange={(e) => this.handleChangeFormPopUp(e)}>
                                                <option key="-1" value="-1"></option>
                                                {
                                                    this.state.scheduleData.years.map((v, index) => {
                                                        return (<option key={index} value={v.value}>{v.text}</option>)
                                                    })
                                                }
                                            </Input>
                                        </div>

                                        <div className="col-lg-4" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label htmlFor="months">Mês</label>
                                            <Input type="select" key="months" id="months" disabled={this.state.lockForm} value={this.state.scheduleData.months.length > 0 && this.state.scheduleData.months.find(a => a.selected) !== undefined ? this.state.scheduleData.months.find(a => a.selected).value : -1} onChange={(e) => this.handleChangeFormPopUp(e)}>
                                                <option key="-1" value="-1"></option>
                                                {
                                                    this.state.scheduleData.months.map((v, index) => {
                                                        return (<option key={index} value={v.value}>{v.text}</option>)
                                                    })
                                                }
                                            </Input>
                                        </div>

                                        <div className="col-lg-4" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label htmlFor="stores">Centro de Custo</label>
                                            <Input type="select" key="stores" id="stores" disabled={this.state.lockForm} value={this.state.scheduleData.stores.length > 0 && this.state.scheduleData.stores.find(a => a.selected) !== undefined ? this.state.scheduleData.stores.find(a => a.selected).value : -1}
                                                onChange={(e) => this.handleChangeFormPopUp(e)}>
                                                <option key="-1" value="-1"></option>
                                                {
                                                    this.state.scheduleData.stores.map((v, index) => {
                                                        return (<option key={index} value={v.value}>{v.text}</option>)
                                                    })
                                                }
                                            </Input>
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-lg-6" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label htmlFor="IdInternalCategory">Função</label>
                                            <Input type="select" key="IdInternalCategory" disabled={this.state.lockForm} id="IdInternalCategory"
                                                value={this.state.listInternalCategory.length > 0 && this.state.listInternalCategory.find(a => a.selected) !== undefined ? this.state.listInternalCategory.find(a => a.selected).value : -1}
                                                onChange={(e) => this.handleChangeFormPopUp(e)}>
                                                {
                                                    this.state.listInternalCategory.map((v, index) => {
                                                        return (<option key={index} value={v.value}>{v.text}</option>)
                                                    })
                                                }
                                            </Input>
                                        </div>

                                        <div className="col-lg-4" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label htmlFor="IdScheduleType">Tipo de Horário</label>
                                            <Input type="select" key="IdScheduleType" disabled={this.state.lockForm} id="IdScheduleType"
                                                value={this.state.listScheduleTypes.length > 0 && this.state.listScheduleTypes.find(a => a.selected) !== undefined ? this.state.listScheduleTypes.find(a => a.selected).value : -1}
                                                onChange={(e) => this.handleChangeFormPopUp(e)}>
                                                {
                                                    this.state.listScheduleTypes.map((v, index) => {
                                                        return (<option key={index} value={v.value}>{v.text}</option>)
                                                    })
                                                }
                                            </Input>
                                        </div>

                                        <div className="col-lg-2" style={{ marginTop: 10 + 'px', fontSize: 12 + 'px' }}>
                                            <label style={{ marginBottom: 0 + 'px' }} htmlFor="active">Urgente</label>
                                            <Input type="switch" id="Urgent" disabled={this.state.lockForm} checked={this.state.scheduleData === undefined ? false : this.state.scheduleData.Urgent} onChange={(e) => this.handleChangeFormPopUp(e)} />
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12" style={{ textAlign: "left" }}>

                                            <table id="tbl" style={{ fontSize: 12 + 'px', marginLeft: 0 + 'px' }}>

                                                <thead>
                                                    <tr>
                                                        <td className="weekday-separator"></td>
                                                        <td className="weekday-separator">E</td>
                                                        <td className="weekday-separator">S</td>
                                                        <td className="weekday-separator">E</td>
                                                        <td className="weekday-separator">S</td>
                                                        <td className="weekday-separator">E</td>
                                                        <td className="weekday-separator">S</td>
                                                        <td className="weekday-separator">E</td>
                                                        <td className="weekday-separator">S</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.scheduleData.objData !== undefined && this.state.scheduleData.objData.length > 0 ?

                                                            this.state.scheduleData.objData.map(result => {
                                                                return (
                                                                    <tr key={result.weekDay}>
                                                                        <td className="weekday-separator">{getStrWeek(result.weekDay)}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'beginSchedule1')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'endSchedule1')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'beginSchedule2')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'endSchedule2')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'beginSchedule3')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'endSchedule3')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'beginSchedule4')}</td>
                                                                        <td className="weekday-separator">{this.setReturnInput(this.state.scheduleData.objData, this.state.scheduleData.objError, result.weekDay, 'endSchedule4')}</td>
                                                                    </tr>

                                                                )
                                                            })
                                                            :
                                                            <tr><td></td></tr>

                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </FormGroup>
                        </Form>

                    </ModalBody>
                    {this.state.canSave ?
                        <ModalFooter>
                            <Button color="primary" style={{ display: (this.state.lockForm === false) ? 'inline' : 'none' }} onClick={(e) => this.handleSaveSchedule(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </ModalFooter> :
                        null
                    }
                </Modal>
            </>
        )
    }
}

function NextMonth() {
    const now = new Date();
    const next = now.getDate() <= 5 ? 1 : 2;

    let result;

    if (now.getMonth() == 11)
        result = new Date(now.getFullYear() + 1, (next - 1), 1);
    else
        result = new Date(now.getFullYear(), now.getMonth() + next, 1);

    return result.getFullYear() * 100 + result.getMonth() + 1;
}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(EmployeeRequestSchedule)