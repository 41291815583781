import React from 'react'
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading, GetParameter } from "../../global/Utils"
import { faPaperPlane, faPlus, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input, Label, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./SurveysEdit.css"
import { Link, Navigate } from 'react-router-dom'

class SurveysAdmEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingPage: true,
            readOnly: false,
            surveyId: 0,
            surveyTitle: "",
            surveySummary: "",
            surveyQuestions: [],
            modal: {
                isOpen: false,
                questionId: 0,
                heading: "",
                family: "",
                familyText: "",
                numering: "",
                numeringText: "",
                position: 0,
                subQuestionId: 0,
                error: {
                    heading: false,
                    family: false,
                    familyText: false,
                    numering: false,
                    numeringText: false,
                    position: false
                }
            },
            modalSummary: {
                isOpen: false,
                summary: ""
            }
        }

        this.handleAddQuestion = this.handleAddQuestion.bind(this)
        this.handleSetQuestion = this.handleSetQuestion.bind(this)
        this.handleSaveQuestion = this.handleSaveQuestion.bind(this)
        this.handleSaveSummary = this.handleSaveSummary.bind(this)
        this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    toggleSummary = () => {
        this.setState(prevState => { return { modalSummary: { ...prevState.modalSummary, isOpen: !prevState.modalSummary.isOpen } } })
    }

    componentDidMount() {
        let surveyId = parseInt(GetParameter("id"))
        if (surveyId === undefined) {
            this.props.dispatch(Toast("Não foi possível obter o detalhe", ToastTypes.Danger, true))
            return
        }

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/admin/surveys/" + surveyId, requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
                this.props.dispatch(Loading(false))

                if (json.haveAnswers) {
                    this.setState({ readOnly: true, surveyId: surveyId, isLoadingPage: false })
                    return
                }

                this.setState({
                    surveyId: surveyId,
                    surveyTitle: json.title,
                    surveySummary: json.summary,
                    surveyQuestions: json.questions,
                    isLoadingPage: false
                })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter o detalhe", ToastTypes.Danger, false)))
    }

    handleAddQuestion(e, questionId) {
        e.preventDefault()

        let questionTotal = 0
        let subQuestionId

        if (questionId === null) {
            questionTotal = this.state.surveyQuestions.length + 1
            subQuestionId = undefined
        }
        else {
            questionTotal = this.state.surveyQuestions.find(f => f.id === parseInt(questionId)).questions.length + 1
            subQuestionId = 0
        }

        this.setState({
            modal: {
                isOpen: true, questionId: questionId, heading: "", family: "group", familyText: "", numering: "none", numeringText: "", position: questionTotal, subQuestionId: subQuestionId,
                error: { heading: false, family: false, familyText: false, numering: false, numeringText: false, position: false }
            }
        })
    }

    handleSetQuestion(e, questionId, subQuestionId) {
        e.preventDefault()

        let question = {}

        if (subQuestionId === undefined) {
            question = this.state.surveyQuestions.find(f => f.id === parseInt(questionId))
        }
        else {
            question = this.state.surveyQuestions.find(f => f.id === parseInt(questionId)).questions.find(f => f.id === parseInt(subQuestionId))
        }

        if (question.family === SINGLE_CHOICE) {
            question.familyText = question.answers.map(a => a.text).toString();
        }

        this.setState({
            modal: {
                isOpen: true, questionId: questionId, heading: question.heading, family: question.family, familyText: question.familyText === undefined ? "" : question.familyText,
                numering: question.numering, numeringText: question.numeringText, position: question.position, subQuestionId: subQuestionId,
                error: { heading: false, family: false, familyText: false, numering: false, numeringText: false, position: false }
            }
        })
    }

    handleSetSummary(e) {
        e.preventDefault()

        let sum = this.state.surveySummary
        sum = sum === null ? "" : sum
        sum = sum.replaceAll("</br>", "\n")
        sum = sum.substring(0, sum.lastIndexOf("\n"))

        this.setState({
            modalSummary: {
                isOpen: true, summary: sum, error: { summary: false }
            }
        })
    }

    async handleSaveQuestion() {

        // check
        let modal = this.state.modal

        modal.error.heading = modal.heading === ""
        modal.error.position = modal.position === "" || parseInt(modal.position) < 1
        modal.error.numeringText = modal.numering === "number" && (modal.numeringText === "" || modal.numeringText === null || modal.numeringText === undefined)
        modal.error.familyText = modal.family === SINGLE_CHOICE && (modal.familyText === "" || modal.familyText === null || modal.familyText === undefined)
        modal.error.family = modal.family === "group" && modal.subQuestionId === 0

        if (modal.error.heading || modal.error.position || modal.error.numeringText || modal.error.family || modal.error.familyText) {
            this.setState({ modal: { ...this.state.modal, error: modal.error } })
            return;
        }

        let questions = this.state.surveyQuestions
        let index = questions.findIndex(f => f.id === parseInt(modal.questionId))

        if (index === -1) {
            let newId = parseInt(await this.getNewSequence())
            index = questions.length

            questions.push({ id: newId, heading: "", position: 0, family: "", numering: "", numeringText: "", questions: [], answers: [] })
        }

        if (modal.subQuestionId === 0) {
            let newId = parseInt(await this.getNewSequence())

            questions[index].questions.push({ id: newId, heading: "", position: 0, family: "", numering: "", numeringText: "", answers: [] })
            modal.subQuestionId = newId
        }

        if (modal.subQuestionId !== undefined) {
            let subIndex = questions[index].questions.findIndex(f => f.id === parseInt(modal.subQuestionId))

            // fix position if greater than max
            modal.position = modal.position > questions[index].questions.length ? questions[index].questions.length + 1 : modal.position

            // sort questions
            let questionsToSort = questions[index].questions.filter(f => f.position >= modal.position)
            questionsToSort.forEach(item => item.position += 1)

            questions[index].questions[subIndex].heading = modal.heading
            questions[index].questions[subIndex].position = parseInt(modal.position)
            questions[index].questions[subIndex].family = modal.family
            questions[index].questions[subIndex].numering = modal.numering
            questions[index].questions[subIndex].numeringText = modal.numeringText

            if (modal.family === SINGLE_CHOICE) {
                questions[index].questions[subIndex].answers = await Promise.all(modal.familyText.split(",").map(async (m) => {
                    let id = parseInt(await this.getNewSequence())
                    return { "id": id, "text": m }
                }))
            }

            questions[index].questions.sort((a, b) => { return a.position - b.position })
            questions[index].questions.forEach((item, index) => item.position = index + 1)
        }
        else {
            // fix position if greater than max
            modal.position = modal.position > questions.length ? questions.length + 1 : modal.position

            // sort questions
            let questionsToSort = questions.filter(f => f.position >= modal.position)
            questionsToSort.forEach(item => item.position += 1)

            questions[index].heading = modal.heading
            questions[index].position = parseInt(modal.position)
            questions[index].family = modal.family
            questions[index].numering = modal.numering
            questions[index].numeringText = modal.numeringText

            if (modal.family === SINGLE_CHOICE) {
                questions[index].answers = await Promise.all(modal.familyText.split(",").map(async (m) => {
                    const id = parseInt(await this.getNewSequence())
                    return { "id": id, "text": m }
                }))
            }

            questions.sort((a, b) => { return a.position - b.position })
            questions.forEach((item, index) => item.position = index + 1)
        }

        this.saveQuestions(questions)
    }

    handleDeleteQuestion() {
        let modal = this.state.modal;
        let questions = []

        if (modal.subQuestionId !== undefined) {
            questions = this.state.surveyQuestions.map(a => {
                if (a.questions === null)
                    return a

                return {
                    ...a, questions: a.questions.filter(f => f.id !== parseInt(modal.subQuestionId)).map((sub, index) => { return { ...sub, position: (index + 1) } })
                }
            })
        }
        else {
            questions = this.state.surveyQuestions.filter(f => f.id !== parseInt(modal.questionId)).map((a, index) => { return { ...a, position: (index + 1) } })
        }

        this.saveQuestions(questions)
    }

    saveQuestions(questions) {
        this.props.dispatch(Loading(true))

        // remove answer's property if null
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].answers === null)
                delete questions[i].answers
        }

        let requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(questions)
        }

        fetch("/api/admin/surveys/" + this.state.surveyId + "/questions", requestOptions)
            .then(response => {
                this.props.dispatch(Loading(false))

                if (response.status === 200) {
                    this.setState({ surveyQuestions: questions, modal: { ...this.state.modal, isOpen: false } })
                    return;
                }

                return response.then(Promise.reject.bind(Promise))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar o questionário", ToastTypes.Danger, false)))
    }

    handleSaveSummary() {
        let modal = this.state.modalSummary

        let sum = ""
        modal.summary.split("\n").forEach(line => sum += line + "</br>")
        modal.isOpen = false

        this.props.dispatch(Loading(true))

        let requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(sum)
        }

        fetch("/api/admin/surveys/" + this.state.surveyId, requestOptions)
            .then(response => {
                this.props.dispatch(Loading(false))

                if (response.status === 200) {
                    this.setState({ surveySummary: sum, modalSummary: modal })
                    return;
                }

                return response.then(Promise.reject.bind(Promise))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar a introdução", ToastTypes.Danger, false)))
    }

    buildAnswers(question) {

        switch (question.family) {
            case "group":
                return <></>;
            case STRING:
                return <div className="form-control" style={{ height: 100 + "px" }} />
            case SINGLE_CHOICE:
                return (
                    <Container className="question question-hover">
                        <Row>{question.answers.map((a, index) => <Col key={index} className="text-center"><Label>{a.text}</Label></Col>)}</Row>
                        <Row>{question.answers.map((a, index) => <Col key={index} className="text-center"><Input type="radio" style={{ position: "unset" }} /></Col>)}</Row>
                    </Container>
                )
            default:
        }
    }

    buildQuestions(questionId, questions) {
        if (questions === null)
            return (<></>)

        let mapQuestions = questions.map((question, index) => {
            return (
                <div key={index} className="question question-hover sub-question" onClick={(e) => this.handleSetQuestion(e, questionId, question.id)}>
                    <div className="heading">
                        {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                        {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                    </div>
                    {this.buildAnswers(question)}
                </div>
            )
        })

        return (
            <>
                {mapQuestions}
                { }
                <Row className="margin-top20">
                    <Col className="text-center"><Button color="primary" size="sm" onClick={(e) => this.handleAddQuestion(e, questionId)}><FontAwesomeIcon icon={faPlus} /></Button></Col>
                </Row>
            </>
        )
    }

    async getNewSequence() {
        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        const response = await fetch("/api/admin/surveys/sequence", requestOptions);
        return await response.text();
    }

    render() {

        if (this.state.isLoadingPage) return (<></>)
        if (this.state.readOnly) return (<Navigate to={"/maintenance/surveys/preview?id=" + this.state.surveyId} />)

        return (
            <>
                <Container>
                    <Row className="margin-bottom20">
                        <Col xs="9">
                            <h3 className="title">{this.state.surveyTitle}</h3>
                        </Col>
                        <Col className="text-right">
                            <Link to={"/maintenance/surveys/preview?id=" + this.state.surveyId}>
                                <Button color="secondary"><FontAwesomeIcon icon={faPaperPlane} /> Visualisar</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="question summary question-hover" dangerouslySetInnerHTML={{ __html: this.state.surveySummary }}
                                onClick={(e) => this.handleSetSummary(e)}
                            />
                        </Col>
                    </Row>
                    <div className="height50" />
                    {this.state.surveyQuestions.map((question, index) => {
                        return (
                            <Row key={index} className="question-main">
                                <Col className={"padding-bottom10 " + (question.family !== "group" ? "question-hover" : "")}>
                                    <div onClick={(e) => this.handleSetQuestion(e, question.id)}>
                                        <div className="question question-hover heading" style={{ fontWeight: question.family === "group" ? "bold" : "" }}>
                                            {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                                            {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                                        </div>
                                        {this.buildAnswers(question)}
                                    </div>
                                    {question.family === "group" ? this.buildQuestions(question.id, question.questions) : null}
                                </Col>
                            </Row>
                        )
                    })}
                    <Row className="margin-top">
                        <Col>
                            <Button color="primary" onClick={(e) => this.handleAddQuestion(e, null)}><FontAwesomeIcon icon={faPlus} /> Novo</Button>
                        </Col>
                    </Row>
                </Container>

                <Modal isOpen={this.state.modalSummary.isOpen} toggle={this.toggleSummary} size="lg">
                    <ModalHeader toggle={this.toggleSummary}>Introdução</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Input id="Summary" type="textarea" value={this.state.modalSummary.summary} autoComplete="nope" rows="10"
                                        style={{ textAlign: "justify" }}
                                        onChange={(e) => { this.setState({ modalSummary: { ...this.state.modalSummary, summary: e.target.value } }) }} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSaveSummary}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Questão</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Label>Questão</Label>
                                    <Input id="Heading" type="text" value={this.state.modal.heading} autoComplete="nope" invalid={this.state.modal.error.heading}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, heading: e.target.value,
                                                    error: { ...this.state.modal.error, heading: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    <Label>Posição</Label>
                                    <Input type="number" value={this.state.modal.position} autoComplete="nope" invalid={this.state.modal.error.position} min="1"
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, position: e.target.value,
                                                    error: { ...this.state.modal.error, position: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    <Label>Numeração</Label>
                                    <Input type="select" value={this.state.modal.numering}
                                        onChange={(e) => { this.setState({ modal: { ...this.state.modal, numering: e.target.value } }) }}>
                                        <option value="none">Nenhuma</option>
                                        <option value="bullet">Bullet</option>
                                        <option value="number">Número</option>
                                    </Input>
                                    <Input id="Numering" type="number" value={this.state.modal.numeringText} autoComplete="nope" invalid={this.state.modal.error.numeringText}
                                        style={{ display: this.state.modal.numering === "number" ? "inherit" : "none", marginTop: 10 + "px" }}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, numeringText: e.target.value,
                                                    error: { ...this.state.modal.error, numeringText: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                                <Col>
                                    <Label>Tipo</Label>
                                    <Input type="select" value={this.state.modal.family} invalid={this.state.modal.error.family}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, family: e.target.value,
                                                    error: { ...this.state.modal.error, family: e.target.value === "group" && this.state.modal.subQuestionId === 0 }
                                                }
                                            })
                                        }}>
                                        <option value="group">Grupo</option>
                                        <option value={STRING}>Texto</option>
                                        <option value={SINGLE_CHOICE}>Avaliação</option>
                                    </Input>
                                    <Input id="Family" type="text" value={this.state.modal.familyText} autoComplete="nope" invalid={this.state.modal.error.familyText}
                                        style={{ display: this.state.modal.family === "single_choice" ? "inherit" : "none", marginTop: 10 + "px" }}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal, familyText: e.target.value,
                                                    error: { ...this.state.modal.error, familyText: e.target.value === "" }
                                                }
                                            })
                                        }} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" outline onClick={this.handleDeleteQuestion}
                            style={{ display: this.state.modal.questionId === null || this.state.modal.subQuestionId === 0 ? "none" : null }}>
                            <FontAwesomeIcon icon={faTrash} /> Remover
                        </Button>
                        <Button color="primary" onClick={this.handleSaveQuestion}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const STRING = "string"
const SINGLE_CHOICE = "single_choice"

const mapStateToProps = state => ({ access_token: state.main.access_token })

export default connect(mapStateToProps)(SurveysAdmEdit)
