import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Spinner } from "reactstrap";

const KPISingle = (props) => {

    const [state, setState] = useState({ loading: true, value: "" });
    const access_token = useSelector(state => state.main.access_token);

    useEffect(async () => {

        try {
            const requestOptions = { method: "GET", headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" } };

            const response = await fetch(`/api/dashboard/${props.kpi}`, requestOptions);
            if (!response.ok)
                return setState({ loading: false, value: 0 });

            const json = await response.json();
            setState({ loading: false, value: json });
        } catch (e) {
            setState({ loading: false, value: 0 });
        }

    }, []);

    return (
        <Container style={{ color: `rgb(115, 135, 156)`, paddingLeft: `10px`, paddingRight: `10px` }}>
            <nav style={{ borderBottom: `2px solid #e6e9ed`, padding: `1px 5px 6px` }}>
                <h4 style={{ marginBottom: `0px` }}>{props.title}</h4>
            </nav>
            {state.loading ?
                <div style={{ minHeight: `36vh`, position: `relative` }}>
                    <div style={{ top: `30%`, left: `50%`, position: `absolute`, maxWidth: `30px` }}>
                        <Spinner color="warning" />
                    </div>
                </div>
                :
                <div style={{ minHeight: `36vh`, position: `relative` }}>
                    <h1 style={{ top: `30%`, left: `50%`, position: `absolute`, fontSize: `4rem` }}>{state.value}</h1>
                </div>
            }
        </Container>
    );
}

export default KPISingle;