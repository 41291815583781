import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Container, ModalFooter, Label, Button } from 'reactstrap';
import { Loading, Toast, ToastTypes, formatHour, formatDate, IsValidNIF, formatDateHours } from "../../global/Utils";
import "react-datepicker/dist/react-datepicker.css";

const Templates = (props) => {

    const [idCandidate] = useState(props.idCandidate);
    const [modalTemplates, setModalTemplates] = useState({ isOpen: props.isOpen });
    const [Templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(-1);


    useEffect(() => {
        LoadTemplates();
    }, []);

    function toggleTemplates() {
        setModalTemplates({ isOpen: !modalTemplates.isOpen })
        props.toggle(!modalTemplates.isOpen)
        setTemplate(-1)
    }

    function LoadTemplates() {

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/templates", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {

                var objTemplates = json.map(result => {
                    return { Key: result.idTemplate, value: result.description, selected: false }
                });

                setTemplates(objTemplates)

                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível obter os templates.", ToastTypes.Danger, false)))
    }

    function handleChangeComboTemplates(e) {
        var value = e.target.value
        setTemplate(value)
    }

    function handleSaveTemplate() {

        if (template === parseInt(-1)) {
            props.dispatch(Toast("Por favor selecione um template.", ToastTypes.Warning, true))
            return
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/candidates/templates?idCandidate=" + parseInt(idCandidate) +"&idTemplate=" + parseInt(template), requestOptions)
            .then(response =>
            {

                if (response.status == 200) {
                    props.dispatch(Toast("O template será preenchido e ficará disponível na tab documentos.", ToastTypes.Success, false));
                    props.toggle(!modalTemplates.isOpen)
                    return
                }

                props.dispatch(Loading(false))
            })
            .catch(() => props.dispatch(Toast("Não foi possível inserir informação para envio do ficheiro.", ToastTypes.Danger, false)))
    }

    return (
        <>

            <Modal isOpen={props.isOpen} toggle={toggleTemplates} size="md">
                <ModalHeader toggle={toggleTemplates}>Templates</ModalHeader>
                <ModalBody>
                    <Container>
                        <Input type="select" id="lstTemplates" value={template} onChange={(e) => handleChangeComboTemplates(e)}>
                            <option key={-1} value={-1}></option>
                            {Templates.map(a => <option key={a.Key} value={a.Key}>{a.value}</option>)}
                        </Input>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSaveTemplate()}>Gerar</Button>
                </ModalFooter>
            </Modal>

        </>
    )

}

const mapStateToProps = state => ({ access_token: state.main.access_token, profileId: state.main.profileId })

export default connect(mapStateToProps)(Templates)